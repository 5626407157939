import React, { useState, createContext, useContext } from 'react'
import { googleLogout } from '@react-oauth/google'
import { useHistory } from 'react-router-dom'

import { deleteCookie, getCookie, setCookie } from '../utils/cookies'
import { UserContext } from './user'

const authTokenCookieName: string = 'ssr-auth-token'

interface AuthContextType {
  authToken?: string,
  isLogged: boolean,

  updateAuthToken: (nextValue: string) => void,
  logout: (soft?: boolean) => void,
  redirectToLogin: () => void,
}

const AuthContext = createContext<AuthContextType>({
  updateAuthToken: () => {},
  redirectToLogin: () => {},
  logout: () => {},

  authToken: undefined,
  isLogged: false,
})

const AuthContextProvider = ({ children }: { children: JSX.Element }) => {
  const [authToken, setAuthToken] = useState<string | undefined>(getCookie(authTokenCookieName))
  const [redirectPathname, setRedirectPathname] = useState<string | null>(null)
  const { user } = useContext(UserContext)
  const history = useHistory()

  const isLogged: boolean = !!authToken

  const updateAuthToken = (nextValue: string): void => {
    setCookie(authTokenCookieName, nextValue)
    setAuthToken(nextValue)

    if (redirectPathname) {
      history.push(redirectPathname)
      setRedirectPathname(null)
    } else {
      history.push('/subject-subscriptions')
    }
  }

  const logout = (soft: boolean = false): void => {
    deleteCookie(authTokenCookieName)
    setAuthToken(undefined)

    if (user?.isGoogleAuth) {
      googleLogout()
    }
    
    if (!soft) {
      location.reload();
    }
  }

  const redirectToLogin = (): void => {
    setRedirectPathname(window.location.pathname)
    history.push('/login')
  }

  return (
    <AuthContext.Provider
      value={{
        updateAuthToken,
        redirectToLogin,
        authToken,
        isLogged,
        logout,
      }}
    >
      { children }
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthContextProvider }