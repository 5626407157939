import React, { createContext, useContext, useState } from 'react'

import { Invite, SubjectMember, SubjectSubscription } from '../../../common/types'
import { useRequest } from '../hooks/request'
import { LoaderContext } from './loader'

interface SubjectSubscriptionsContextType {
  loadSubjectSubscription: (subjectSubscriptionId: string) => Promise<void>,
  loadSubjectMembers: (subjectSubscriptionId: string) => Promise<void>,
  loadSubjectInvites: (subjectSubscriptionId: string) => Promise<void>,
  subjectSubscription: SubjectSubscription | null,
  loadSubjectSubscriptions: () => Promise<void>,
  subjectSubscriptions: SubjectSubscription[],
  subjectMembers: SubjectMember[],
  subjectInvites: Invite[],
}

const SubjectSubscriptionsContext = createContext<SubjectSubscriptionsContextType>({
  loadSubjectSubscriptions: async () => {},
  loadSubjectSubscription: async () => {},
  loadSubjectMembers: async () => {},
  loadSubjectInvites: async () => {},
  subjectSubscription: null,
  subjectSubscriptions: [],
  subjectMembers: [],
  subjectInvites: [],
})

const SubjectSubscriptionsContextProvider = ({ children }: { children: JSX.Element }) => {
  const [subjectSubscription, setSubjectSubscription] = useState<SubjectSubscription | null>(null)
  const [subjectSubscriptions, setSubjectSubscriptions] = useState<SubjectSubscription[]>([])
  const [subjectMembers, setSubjectMembers] = useState<SubjectMember[]>([])
  const [subjectInvites, setSubjectInvites] = useState<Invite[]>([])
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { fetchData } = useRequest()

  const loadSubjectSubscriptions = async (): Promise<void> => {
    displayLoader()
    const response = await fetchData('/subject-subscription/all')
    if (response.subjectSubscriptions) {
      setSubjectSubscriptions(response.subjectSubscriptions)
    }
    hideLoader()
  }

  const loadSubjectSubscription = async (subjectSubscriptionId: string): Promise<void> => {
    displayLoader()
    const response = await fetchData('/subject-subscription', { subjectSubscriptionId })
    if (response.subjectSubscription) {
      setSubjectSubscription(response.subjectSubscription)
    }
    hideLoader()
  }

  const loadSubjectMembers = async (subjectSubscriptionId: string): Promise<void> => {
    displayLoader()
    const response = await fetchData('/subject-subscription/members', { subjectSubscriptionId })
    if (response.members) {
      setSubjectMembers(response.members)
    }
    hideLoader()
  }

  const loadSubjectInvites = async (subjectSubscriptionId: string): Promise<void> => {
    displayLoader()
    const response = await fetchData('/subject-subscription/invites', { subjectSubscriptionId })
    if (response.invites) {
      setSubjectInvites(response.invites)
    }
    hideLoader()
  }

  return (
    <SubjectSubscriptionsContext.Provider
      value={{
        loadSubjectSubscriptions,
        loadSubjectSubscription,
        subjectSubscriptions,
        subjectSubscription,
        loadSubjectMembers,
        loadSubjectInvites,
        subjectMembers,
        subjectInvites,
      }}
    >
      { children }
    </SubjectSubscriptionsContext.Provider>
  )
}

export { 
  SubjectSubscriptionsContextProvider, 
  SubjectSubscriptionsContextType,
  SubjectSubscriptionsContext, 
}

