import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import SendVerifyEmailButton from '../../common/components/send-verify-email-button'
import { displaySuccessToast, displayWarningToast } from '../../common/utils/toast'
import UserPseudoForm from '../../common/components/forms/user/user-pseudo'
import CenteredTitle from '../../common/components/centered-title'
import { LoaderContext, UserContext } from '../../common/contexts'
import CustomIcon from '../../common/components/custom-icon'
import { useRequest } from '../../common/hooks/request'
import { palette } from '../../common/theme'

import './styles.scss'

const CompleteProfile = (): JSX.Element | null => {
  const [isPseudoUnavailable, setIsPseudoUnavailable] = useState<boolean>(false)
  const { user, getUser, isUserProfileComplete } = useContext(UserContext)
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { postData } = useRequest()
  const intl = useIntl()

  const updateUserPseudo = async (values: { pseudo: string }): Promise<void> => {
    displayLoader()
    const response = await postData('/user/pseudo', values)
    if (response.success) {
      displaySuccessToast(intl.formatMessage({id: 'complete_profile_pseudo_success'}))
      await getUser()
    } else if (response === 'pseudo unavailable') {
      setIsPseudoUnavailable(true)
    } else {
      displayWarningToast(intl.formatMessage({id: 'complete_profile_error'}))
    }
    hideLoader()
  }
  
  if (!user) return null

  return (
    <Stack spacing={6} alignItems="center" className="page_content complete_profile_page_container">
      <CenteredTitle 
        subtitle={intl.formatMessage({id: 'complete_profile_subtitle'})}
        title={intl.formatMessage({id: 'complete_profile_title'})}
      />
      
      <Stack spacing={2} width="100%">
        <Stack 
          textAlign="center" 
          className="panel" 
          spacing={2}
        >
          <Stack spacing={1}>
            <Stack 
              justifyContent="center"
              alignItems="center" 
              direction="row" 
              spacing={1} 
            >
              <h2>{intl.formatMessage({id: 'complete_profile_choose_pseudo'})}</h2>
              {
                !!user.pseudo && (
                  <CustomIcon name="check_circle" color={palette.success.light} />
                )
              }
            </Stack>
            <p>{intl.formatMessage({id: 'complete_profile_pseudo_rule'})}</p>
          </Stack>
          <UserPseudoForm
            setIsPseudoUnavailable={setIsPseudoUnavailable}
            isPseudoUnavailable={isPseudoUnavailable}
            onSubmit={updateUserPseudo} 
            pseudo={user.pseudo} 
          />
        </Stack>
        
        {/* <Stack 
          justifyContent="space-between"
          alignItems="center" 
          textAlign="center"
          className="panel"
          spacing={3}
        >
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <h2>{intl.formatMessage({id: 'complete_profile_verify_email'})}</h2>
              {
                user.emailVerified && (
                  <CustomIcon name="check_circle" color={palette.success.light} />
                )
              }
            </Stack>

            {
              user.emailVerified ? (
                <p>{intl.formatMessage({id: 'complete_profile_email_verified'})}</p>
              ) : (
                <p>{intl.formatMessage({id: 'complete_profileemail_text'}, {userMail: user.mail})}</p>
              )
            }
          </Stack>
          {
            !user.emailVerified && (
              <SendVerifyEmailButton />
            )
          }
        </Stack> */}
        
      </Stack>

      {
        (user && isUserProfileComplete) && (
          <Redirect to="/subject-subscriptions" />
        )
      }
    </Stack>
  )
}

export default CompleteProfile