import { Stack } from '@mui/material'
import React from 'react'

import './styles.scss'

interface CenteredTitleProps {
  subtitle?: string,
  title: string,
}

const CenteredTitle = ({
  subtitle,
  title,
}: CenteredTitleProps): JSX.Element => {
  return (
    <Stack spacing={2} alignItems="center" className="centered_title_container">
      <div className="title_container">
        <h1>{title}</h1>
        {subtitle && (<h3>{subtitle}</h3>)}
      </div>
      <div className="divider" />
    </Stack>
  )
}

export default CenteredTitle
