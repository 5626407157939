import React, { useState, createContext, useEffect, useMemo, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { compact } from 'lodash'

import { SubjectPublicView } from '../../../common/types'
import { SearchParameters } from '../../pages/browser'
import { CategoriesContext } from './categories'
import { useRequest } from '../hooks/request'
import { LoaderContext } from './loader'

interface BrowserContextType {
  onUpdateSearchParameter: (nextValue: SearchParameters) => void,
  onSelectCategory: (categoryId: string) => void,
  searchParameters: SearchParameters,
  searchResults: SubjectPublicView[],
  hasSearchParameters: boolean,
  hasNoSearchResults: boolean,
}

const BrowserContext = createContext<BrowserContextType>({
  onUpdateSearchParameter: () => {},
  onSelectCategory: () => {},
  hasSearchParameters: false,
  hasNoSearchResults: false,
  searchParameters: {},
  searchResults: [],
})

const BrowserContextProvider = ({ children }: { children: JSX.Element }) => {
  const [searchParameters, setSearchParameters] = useState<SearchParameters>({})
  const [searchResults, setSearchResults] = useState<SubjectPublicView[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  const { updateCategoriesSelection } = useContext(CategoriesContext)
  const { hideLoader } = useContext(LoaderContext)
  const { fetchData } = useRequest()
  const location = useLocation()
  const history = useHistory()
  
  const hasSearchParameters: boolean = useMemo(() => {
    return Boolean(searchParameters.searchText) || (searchParameters.categories || []).length > 0
  }, [searchParameters])

  const hasNoSearchResults = useMemo(() => {
    return Boolean(hasSearchParameters && !isLoading && searchResults.length === 0)
  }, [hasSearchParameters, searchResults, isLoading])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    const categories = urlSearchParams.get('categories')
    const searchText = urlSearchParams.get('searchText')

    const nextSearchParameters: SearchParameters = {}

    if (searchText) {
      nextSearchParameters.searchText = searchText
    }

    if (categories) {
      nextSearchParameters.categories = compact((categories || '').split(','))
    }

    setSearchParameters(nextSearchParameters)
  }, [location.search])

  useEffect(() => {
    if (Boolean(searchParameters.searchText) || (searchParameters.categories || []).length > 0) {
      onSearch()
    }
  }, [searchParameters])

  const onUpdateSearchParameter = (nextSearchParameters: SearchParameters): void => {
    const hasCategories: boolean = (nextSearchParameters.categories || []).length > 0
    const hasSearchText: boolean = Boolean(nextSearchParameters.searchText)

    if (hasCategories && hasSearchText) {
      history.push(`/browse?${new URLSearchParams(nextSearchParameters as any).toString()}`)
    } else if (hasCategories) {
      history.push(`/browse?${new URLSearchParams({
        categories: nextSearchParameters.categories,
      } as any).toString()}`)
    } else if (hasSearchText) {
      history.push(`/browse?${new URLSearchParams({
        searchText: nextSearchParameters.searchText,
      } as any).toString()}`)
    } else {
      history.push('/browse')
    }
  }

  const onSelectCategory = (categoryId: string): void => {
    onUpdateSearchParameter({
      ...searchParameters,
      categories: updateCategoriesSelection(searchParameters.categories || [], categoryId),
    })
  }

  const onSearch = async (): Promise<void> => {
    setIsLoading(true)
    const response = await fetchData('/search', searchParameters)
    setSearchResults(response.results || [])
    setIsLoading(false)
    hideLoader()
  }

  return (
    <BrowserContext.Provider
      value={{
        onUpdateSearchParameter,
        hasSearchParameters,
        hasNoSearchResults,
        searchParameters,
        onSelectCategory,
        searchResults,
      }}
    >
      { children }
    </BrowserContext.Provider>
  )
}

export { BrowserContext, BrowserContextProvider }