import React, { useContext } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useIntl } from 'react-intl'

import { Question, QuestionSubscription, SubjectSubscription } from '../../../../common/types'
import { displaySuccessToast, displayWarningToast } from '../../utils/toast'
import { getCanUserEditSubject } from '../../../../common/utils/subject'
import { LoaderContext, UserContext } from '../../contexts'
import { useRequest } from '../../hooks/request'

interface DeleteQuestionModalProps {
  onQuestionDeleted: () => void | Promise<void>,
  questionSubscription: QuestionSubscription,
  subjectSubscription: SubjectSubscription,
  onClose: () => void,
  open: boolean,
}

const DeleteQuestionModal = ({
  questionSubscription,
  subjectSubscription,
  onQuestionDeleted,
  onClose,
  open,
}: DeleteQuestionModalProps): JSX.Element => {  
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { user } = useContext(UserContext)
  const { postData } = useRequest()
  const intl = useIntl()
  
  const canUserEditSubject = getCanUserEditSubject(subjectSubscription, user?._id)

  const onDeleteQuestion = async (): Promise<void> => {
    displayLoader()

    const response = await postData('/question/delete', { 
      questionId: (questionSubscription.question as Question)._id, 
    })

    if (response.success) {
      onQuestionDeleted()  
      displaySuccessToast(canUserEditSubject ? intl.formatMessage({id: 'delete_question_modale_success'}) : intl.formatMessage({id: 'delete_question_modale_success_from_space'}))
      onClose()
    } else {
      displayWarningToast(intl.formatMessage({id: 'delete_question_modale_fail'}))
    }
    hideLoader()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {canUserEditSubject ? intl.formatMessage({id: 'delete_question_modale_confirm'}) : intl.formatMessage({id: 'delete_question_modale_confirm_from_space'})}
      </DialogTitle>
      <DialogContent>
        <p> {intl.formatMessage({id: 'delete_question_modale_warning'})} </p>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {intl.formatMessage({id: 'cancel'})} 
        </Button>
        <Button variant="contained" color="error" onClick={onDeleteQuestion}>
          {intl.formatMessage({id: 'delete'})}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteQuestionModal