import Compressor from 'compressorjs'
import uniqid from 'uniqid'

import { isDataUrl, isUrl } from '../../../common/utils/string'

const readImagesFiles = async (files: (File | string)[]): Promise<string[]> => {
  const promises: Promise<string>[] = []

  for (const file of Array.from(files)) {
    promises.push(readImageFile(file))
  }

  return Promise.all(promises)
}

const readImageFile = (file: File | string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (file instanceof File) {
        const compressedFile: File | Blob = await compressImage(file)
        const reader = new FileReader()
        reader.onloadend = function() {
          resolve(reader.result as string)
        }
        reader.readAsDataURL(compressedFile)
      } else {
        resolve(file)
      }
    } catch (err) {
      reject(err)
    }
  })
}

const compressImage = async (file: File): Promise<File | Blob> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.5,
      maxHeight: 2000,
      maxWidth: 2000,
      success: resolve,
      error: reject,
    })
  })
}

const imageToSrc = (imageString: string): string => {
  return isDataUrl(imageString) || isUrl(imageString) ? imageString : `${process.env.API_URL}/${imageString}`
}

const dataUrlToFile = async (dataUrl: string): Promise<File> => {
  const mimeType = dataUrl.split(',')[0].split(':')[1].split(';')[0]
  const extension = mimeType.split('/')[1]

  const imageData = await fetch(dataUrl)
  const imageBuffer = await imageData.arrayBuffer()

  return new File([imageBuffer], `${uniqid()}.${extension}`, { type: mimeType })
}

export { 
  readImagesFiles, 
  dataUrlToFile,
  readImageFile, 
  compressImage, 
  imageToSrc,
}
