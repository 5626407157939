

import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { Button, InputAdornment, Menu, MenuItem, Stack, TextField } from '@mui/material'
import { reverse, sortBy, max, compact } from 'lodash'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Mark from 'mark.js'

import { DeviceTypesEnum, useResponsive } from '../../common/hooks/responsive'
import { SubjectSubscriptionsContext } from '../../common/contexts'
import SubjectSubscriptionsNoContent from './components/empty-content'
import { getCookie, setCookie } from '../../common/utils/cookies'
import SubjectSubscriptionItem from './components/subject-item'
import CustomIcon from '../../common/components/custom-icon'
import Title from '../../common/components/title'
import { palette } from '../../common/theme'
import { 
  QuestionSubscription, 
  SubjectSubscription, 
  TextQuestionContent, 
  QcmQuestionContent, 
  TextResponse, 
  QcmResponse, 
  Question, 
  Subject, 
} from '../../../common/types'

import './styles.scss'

enum SubjectSubscriptionsSortOptions {
  LATEST = 'LATEST',
  OLDEST = 'OLDEST',
  ALPHABETIC = 'ALPHABETIC',
}

enum SubjectSubscriptionsSortOptionsLabels {
  LATEST = 'Du plus récent au plus ancien',
  OLDEST = 'Du plus ancien au plus récent',
  ALPHABETIC = 'Par ordre alphabétique',
}

const subjectSubscriptionsSortOptionCookieKey: string = 'sort_subjects_by'

const SubjectSubscriptionsPage = (): JSX.Element => {
  const { loadSubjectSubscriptions, subjectSubscriptions } = useContext(SubjectSubscriptionsContext)
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<Element | null>(null)
  const [searchText, setSearchText] = useState<string>('')
  const [sortSubjectsBy, setSortSubjectsBy] = useState<SubjectSubscriptionsSortOptions>(
    (getCookie(subjectSubscriptionsSortOptionCookieKey) as SubjectSubscriptionsSortOptions | undefined ) || SubjectSubscriptionsSortOptions.LATEST
  )
    
  const subjectsContainer = useRef(null)
  const markInstance = new Mark(subjectsContainer.current)
  const responsive = useResponsive()
  const intl = useIntl()
  
  useEffect(() => {
    loadSubjectSubscriptions()
  }, [])

  const onSearchTextChange = (nextVal: string): void => {
    setSearchText(nextVal)
    markInstance.unmark({
      done: () => {
        markInstance.mark(nextVal, {
          caseSensitive: false,
        })
      }
    })
  }

  const getSortedSubjectSubscriptions = (subjectSubscriptionsToSort: SubjectSubscription[]): SubjectSubscription[] => {
    switch (sortSubjectsBy) {

      case SubjectSubscriptionsSortOptions.LATEST: {
        return reverse(sortBy(subjectSubscriptionsToSort, (subjectSubscription: SubjectSubscription) => {
          const dates: number[] = [
            ...(subjectSubscription.questionSubscriptions || []).map((questionSubscription: QuestionSubscription) => questionSubscription.createdAt!),
            subjectSubscription.createdAt!,
          ]
          return max(dates)
        }))
      }

      case SubjectSubscriptionsSortOptions.OLDEST: {
        return sortBy(subjectSubscriptionsToSort, (subjectSubscription: SubjectSubscription) => {
          const dates: number[] = [
            ...(subjectSubscription.questionSubscriptions || []).map((questionSubscription: QuestionSubscription) => questionSubscription.createdAt!),
            subjectSubscription.createdAt!,
          ]
          return max(dates)
        })
      }

      case SubjectSubscriptionsSortOptions.ALPHABETIC: {
        return sortBy(subjectSubscriptionsToSort, (subjectSubscription: SubjectSubscription) => (subjectSubscription.subject as Subject).label.toLowerCase())
      }
      
      default: {
        return subjectSubscriptionsToSort
      }
    }
  }

  const onSortOptionChange = (nextValue: SubjectSubscriptionsSortOptions): void => {
    setCookie(subjectSubscriptionsSortOptionCookieKey, nextValue)
    setSortSubjectsBy(nextValue)
    onCloseSortMenu()
  }

  const getFilteredSubjects = (): SubjectSubscription[] => {
    if (!searchText) return subjectSubscriptions.map((subjectSubscription: SubjectSubscription) => ({
      ...subjectSubscription,
      questionSubscriptions: [],
    }))

    const resp: SubjectSubscription[] = []

    subjectSubscriptions.forEach((subjectSubscription: SubjectSubscription) => {
      const labelMatch: boolean = (subjectSubscription.subject as Subject).label.toLowerCase().includes(searchText.toLowerCase())
      const questionSubscriptionsWithMatch: QuestionSubscription[] = (subjectSubscription.questionSubscriptions || []).filter((questionSubscription: QuestionSubscription) => {
        const question: Question = questionSubscription.question as Question
        const questionContent: QcmQuestionContent | TextQuestionContent = question.content as TextQuestionContent | QcmQuestionContent

        const questionMatch: boolean = questionContent.question.toLowerCase().includes(searchText.toLowerCase())
        let responsesMatch: boolean = false 

        questionContent.responses.forEach((response: QcmResponse | TextResponse) => {
          const responseStr: string = response.response || ''
          if (responseStr.toLowerCase().includes(searchText.toLowerCase())) {
            responsesMatch = true
          }
        })

        return questionMatch || responsesMatch
      })

      if (labelMatch || questionSubscriptionsWithMatch.length) {
        resp.push({
          ...subjectSubscription,
          questionSubscriptions: questionSubscriptionsWithMatch,
        })
      }
    })

    return resp
  }

  const onCloseSortMenu = (): void => setSortMenuAnchorEl(null)

  const sortedFilteredSubjects: SubjectSubscription[] = getSortedSubjectSubscriptions(getFilteredSubjects())
  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isSmallDevice: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)

  return (
    <div className="page_content user_subjects_container">
      <Stack spacing={2}>
        <Title 
          title={intl.formatMessage({id: 'subjects_title'})}
          rightButtons={isSmallDevice ? [] : compact([isLargeDevice ? {
            label: intl.formatMessage({id: 'subjects_create_subject_long'}),
            link: '/create-subject',
            variant: 'outlined',
            color: 'secondary',
            endIcon: 'add',
            bold: false,
          } : undefined,{
            label: intl.formatMessage({id: 'subjects_study'}),
            endIcon: 'keyboard_arrow_right',
            link: '/study/all',
          }])}
        />
        
        <Stack spacing={3}>
          {
            subjectSubscriptions.length > 0 && (
              <>
                {
                  isSmallDevice ? (
                    <Stack spacing={1}>
                      <Stack direction="row" spacing={1}>
                        <Link to={`/create-subject`} style={{ flex: 1 }}>
                          <Button 
                          className="full_width"
                            variant="outlined" 
                            color="secondary"
                          >
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} className="full_width">
                              <span>{intl.formatMessage({id: 'subjects_create_subject_short'})}</span> 
                              <CustomIcon name="add" small />
                            </Stack>
                          </Button>
                        </Link>

                        <Link to="/study/all" >
                          <Button 
                            variant="contained" 
                            color="primary"
                            endIcon={(
                              <CustomIcon name="keyboard_arrow_right" />
                            )}
                          >
                            {intl.formatMessage({id: 'subjects_study'})}
                          </Button>
                        </Link>
                      </Stack>
                      
                      <Stack direction="row" spacing={1}>
                        <TextField 
                          onChange={(event: ChangeEvent<HTMLInputElement>) => onSearchTextChange(event.target.value)}
                          placeholder={intl.formatMessage({id: 'subjects_search'})}
                          value={searchText}
                          sx={{ flex: 1 }}
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CustomIcon name="search" color={palette.text.disabled} />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <Button
                          onClick={e => setSortMenuAnchorEl(e.currentTarget)}
                          className="mui_icon_button"
                          variant="outlined"
                          color="secondary"
                        >
                          <CustomIcon name="sort" small />
                        </Button>
                      </Stack>

                    </Stack>
                  ) : (
                    <Stack direction="row" spacing={1}>
                      <TextField 
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onSearchTextChange(event.target.value)}
                        placeholder={intl.formatMessage({id: 'subjects_search'})}
                        value={searchText}
                        sx={{ flex: 1 }}
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CustomIcon name="search" color={palette.text.disabled} />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Button
                        endIcon={(<CustomIcon name="keyboard_arrow_down" />)}
                        onClick={e => setSortMenuAnchorEl(e.currentTarget)}
                        variant="outlined"
                        color="secondary"
                      >
                        {intl.formatMessage({id: `subjects_sort_${sortSubjectsBy}`})}
                      </Button>
                    </Stack>
                  )
                }

                <Menu
                  anchorEl={sortMenuAnchorEl}
                  open={!!sortMenuAnchorEl}
                  onClose={onCloseSortMenu}
                  sx={{ mt: 1 }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {
                    Object.keys(SubjectSubscriptionsSortOptions).map((sortOptionKey: string) => {
                      const isSelected: boolean = SubjectSubscriptionsSortOptions[sortOptionKey] === sortSubjectsBy
                      return (
                        <MenuItem 
                          sx={{ color: isSelected ? palette.primary.main : palette.secondary.main }}
                          onClick={() => onSortOptionChange(SubjectSubscriptionsSortOptions[sortOptionKey])}
                          key={`sort_option_${sortOptionKey}`}
                        >
                          {intl.formatMessage({id: `subjects_sort_${sortOptionKey}`})}
                        </MenuItem>
                      )
                    })
                  }
                </Menu>
              </>
            )
          }

          {
            subjectSubscriptions.length === 0 ? (
              <SubjectSubscriptionsNoContent />
            ) : sortedFilteredSubjects.length === 0 ? (
              <p className="text_italic">{intl.formatMessage({id: 'subjects_no_result'})}</p>
            ) : (
              <Stack spacing={1} ref={subjectsContainer}>
                {
                  sortedFilteredSubjects.map((subjectSubscription: SubjectSubscription) => (
                    <SubjectSubscriptionItem 
                      key={`user_subject_${subjectSubscription._id}`}
                      subjectSubscription={subjectSubscription}
                      sortSubjectsBy={sortSubjectsBy}
                    />
                  ))
                }
              </Stack>
            )
          }
        </Stack>
      </Stack>
    </div>
  )
}

export { SubjectSubscriptionsSortOptions }
export default SubjectSubscriptionsPage