import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import SelectQuestionType from '../../../../../common/components/forms/components/select-question-type'
import { useLoadSubjectSubscription } from '../../../../../common/hooks/subjects-subscription'
import { displaySuccessToast, displayWarningToast } from '../../../../../common/utils/toast'
import { QcmQuestionForm } from '../../../../../common/components/forms/question-form'
import { useResponsive } from '../../../../../common/hooks/responsive'
import Breadcrumbs from '../../../../../common/components/breadcrumbs'
import { QuestionTypesEnum } from '../../../../../../common/types'
import { useRequest } from '../../../../../common/hooks/request'
import useRouterUtils from '../../../../../common/hooks/router'
import { LoaderContext } from '../../../../../common/contexts'
import Title from '../../../../../common/components/title'

import './styles.scss'

const CreateQcmQuestionPage = (): JSX.Element | null => {
  const { subjectSubscription, subject, canUserEditSubject } = useLoadSubjectSubscription()
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { goBackOrRedirect } = useRouterUtils()
  const responsive = useResponsive()
  const { postData } = useRequest()
  const history = useHistory()
  const intl = useIntl()

  useEffect(() => {
    if (subjectSubscription && !canUserEditSubject) {
      displayWarningToast(intl.formatMessage({id: 'subject_edit_unauthorized'}))
      goBackOrRedirect(`/subject-subscription/${subjectSubscription._id}`)
    }
  }, [subjectSubscription, canUserEditSubject])

  if (!subjectSubscription || !subject) return null

  const onSubmit = async (formData: FormData, addAnotherQuestionAfterSubmit?: boolean): Promise<void> => {
    displayLoader()
    const response = await postData('/question/create', formData)
    hideLoader()

    if (response.createdQuestion) {
      displaySuccessToast(intl.formatMessage({id: 'create_question_success'}))

      if (!addAnotherQuestionAfterSubmit) {
        history.push(`/subject-subscription/${subjectSubscription._id}`)
      }
    } else {
      displayWarningToast(intl.formatMessage({id: 'create_question_fail'}))
    }
  }

  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <div className="page_content create_qcm_question_page">
      <Stack spacing={2}>
        <Breadcrumbs 
          pages={[
            {
              label: intl.formatMessage({id: 'page_subject_subscriptions'}),
              path: '/subject-subscriptions',
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}`,
              label: subject.label,
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}/create-question`,
              label: intl.formatMessage({id: 'subject_create_question_short'}),
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}/create-question/qcm`,
              label: intl.formatMessage({id: 'question_type_label_QCM'}),
            }
          ]}
        />

        <Title 
          rightContent={isSmallDevice ? undefined : (<SelectQuestionType selectedQuestionType={QuestionTypesEnum.QCM} />)}
          title={intl.formatMessage({id: 'subject_create_question_short'})} 
        />
        
        <QcmQuestionForm 
          subjectSubscriptionId={subjectSubscription._id!}
          subjectId={subject._id!} 
          onSubmit={onSubmit} 
        />
      </Stack>
    </div>
  )
}

export default CreateQcmQuestionPage