import React, { useState, useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import { Stack } from '@mui/material'

import { readImagesFiles } from '../../../../utils/image'
import ImageDisplay from '../../../image-display'

interface FormImagesViewerProps {
  setValue: any, // Note : we have to put any here, otherwise we get a typescript error from react-hook-form
  control: any, // Note : we have to put any here, otherwise we get a typescript error from react-hook-form
  fieldName: string,
}

const FormImagesViewer = ({
  fieldName,
  setValue,
  control,
}: FormImagesViewerProps): JSX.Element | null => {
  const [imagesDataUrls, setImagesDataUrls] = useState<string[]>([]);
  
  const [images] = useWatch({
    name: [fieldName as any],
    control,
  })

  useEffect(() => {
    readImages()
  }, [images])

  const readImages = async (): Promise<void> => {
    if (images) {
      const imagesInfos: string[] = await readImagesFiles(Array.isArray(images) ? images : [images])
      setImagesDataUrls(imagesInfos)
    } else {
      setImagesDataUrls([])
    }
  }

  const onDeleteImage = (imageIndex: number): void => {
    if (Array.isArray(images)) {
      setValue(fieldName as any, images.filter((image: string, index: number) => {
        return index !== imageIndex
      }), { shouldDirty: true })
    } else {
      setValue(fieldName as any, undefined, { shouldDirty: true })
    }
  }

  if (!imagesDataUrls || imagesDataUrls.length === 0) return null

  return (
    <Stack mb={2} mx="auto" alignItems="center">
      <ImageDisplay 
        onDeleteImage={onDeleteImage}
        images={imagesDataUrls} 
        editable 
      />
    </Stack>
  )
}

export default FormImagesViewer
