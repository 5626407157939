import React, { useContext, useState, useEffect } from 'react'
import { Button, IconButton, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import QuestionSubscriptionInfosModal from '../../../common/components/subject/question-subscription-infos-modal'
import { Question, QuestionSubscription, SubjectSubscription } from '../../../../common/types'
import DeleteQuestionModal from '../../../common/components/subject/delete-question-modal'
import { getCanUserEditSubject } from '../../../../common/utils/subject'
import CustomIcon from '../../../common/components/custom-icon'
import { UserContext } from '../../../common/contexts'

interface QuestionActionsProps {
  updateIsAcquired: (questionSubscriptionId: string, isAcquired: boolean) => Promise<void>,
  questionSubscription: QuestionSubscription,
  subjectSubscription: SubjectSubscription,
  onQuestionDeleted: () => void,
  question: Question,
}

const QuestionActions = ({
  questionSubscription,
  subjectSubscription,
  onQuestionDeleted,
  updateIsAcquired,
  question,
}: QuestionActionsProps): JSX.Element | null => {
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState<boolean>(false)
  const [displayActions, setDisplayActions] = useState<boolean>(false)
  const [displayInfos, setDisplayInfos] = useState<boolean>(false)

  const { user } = useContext(UserContext)
  const intl = useIntl()

  useEffect(() => {
    setDisplayActions(false)
  }, [question])

  if (!user) return null

  const canEditSubject: boolean = getCanUserEditSubject(subjectSubscription, user._id)
  
  return (
    <Stack spacing={1} alignItems="center">
      {
        displayActions && (
          <>
            <Link target="_blank" className="full_width" to={`/subject-subscription/${subjectSubscription._id}`}>
              <Button 
                className="full_width"
                variant="outlined" 
                color="secondary"
                endIcon={(
                  <CustomIcon name="open_in_new" />
                )}
              >
                <span>{intl.formatMessage({id: 'study_all_go_to_subject'})}</span>
              </Button>
            </Link>

            <Button 
              onClick={() => setDisplayInfos(true)}
              className="full_width"
              variant="outlined" 
              color="secondary"
              endIcon={(
                <CustomIcon name="info" outlined />
              )}
            >
              <span>{intl.formatMessage({id: 'study_all_display_infos'})}</span>
            </Button>
            
            {
              canEditSubject && (
                <Link className="full_width" to={`/subject-subscription/${subjectSubscription._id}/update-question/${question._id}`}>
                  <Button 
                    className="full_width"
                    variant="outlined" 
                    color="secondary"
                    endIcon={(
                      <CustomIcon name="edit" />
                    )}
                  >
                    <span>{intl.formatMessage({id: 'update_question'})}</span>
                  </Button>
                </Link>
              )
            }
            
            <Button 
              onClick={() => updateIsAcquired(questionSubscription._id!, !questionSubscription.isAcquired)}
              className="full_width"
              variant="outlined" 
              color="secondary"
            >
              {
                questionSubscription.isAcquired ? (
                  <span>{intl.formatMessage({id: 'question_mark_not_acquired'})}</span>
                ) : (
                  <span>{intl.formatMessage({id: 'study_all_mark_acquired'})}</span>
                )
              }
            </Button>
            
            <Button 
              onClick={() => setOpenDeleteQuestionModal(true)}
              className="full_width"
              variant="outlined" 
              color="secondary" 
              endIcon={(
                <CustomIcon name="delete" />
              )}
            >
              {
                canEditSubject ? (
                  <span>{intl.formatMessage({id: 'study_all_delete_question'})}</span>
                ) : (
                  <span>{intl.formatMessage({id: 'study_all_delete_question_from_space'})}</span>
                )
              }
            </Button>
          </>
        )
      }
      
      <IconButton onClick={() => setDisplayActions(!displayActions)}>
        <CustomIcon name={displayActions ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
      </IconButton>

      <DeleteQuestionModal 
        onClose={() => setOpenDeleteQuestionModal(false)}
        questionSubscription={questionSubscription}
        subjectSubscription={subjectSubscription}
        onQuestionDeleted={onQuestionDeleted}
        open={openDeleteQuestionModal}
      />

      <QuestionSubscriptionInfosModal 
        questionSubscription={questionSubscription}
        subjectSubscription={subjectSubscription}
        onClose={() => setDisplayInfos(false)}
        open={displayInfos}
      />
    </Stack>
  )
}

export default QuestionActions
