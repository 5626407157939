import React from 'react'
import { Stack } from '@mui/material'

import CustomIcon from '../../../../common/components/custom-icon'
import { Question } from '../../../../../common/types'
import QuestionItem from '../question-item'

interface QuestionsListProps {
  questions: Question[],
}

const QuestionsList = ({
  questions,
}: QuestionsListProps): JSX.Element => {
  return (
    <>
      {
        questions.length === 0 ? (
          <div className="empty_content_container">
            <CustomIcon name="help" outlined />
            <p>Il n'y a aucune question à afficher pour le moment.</p>
          </div>
        ) : (
          <Stack spacing={1}>
            {
              questions.map((questionItem: Question | string) => {
                const question = questionItem as Question
                return (
                  <QuestionItem 
                    key={`question_${question._id}`} 
                    question={question} 
                  />
                )
              })
            }
          </Stack>
        )
      }
    </>
  )
}

export default QuestionsList