import React from 'react'
import { Route, Switch } from 'react-router'

import StudyAllPage from './pages/study-all'
import NotFound from '../not-found'

const StudyPageRouter = (): JSX.Element | null => {
  
  return (
    <Switch>
      <Route exact path="/study/all" component={StudyAllPage} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default StudyPageRouter