

const validateEmail = (str: string): boolean => {
  return Boolean(String(str)
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  )
}

function isUrl(string: string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

const isDataUrl = (str: string): boolean => {
  return str.includes('data:')
}

export { 
  validateEmail, 
  isDataUrl, 
  isUrl, 
}

