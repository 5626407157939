import React, { useContext, useState } from 'react'
import { Button, Divider, Stack } from '@mui/material'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { AuthContext, LoaderContext, TranslationContext } from '../../common/contexts'
import { DeviceTypesEnum, useResponsive } from '../../common/hooks/responsive'
import GoogleLoginButton from '../../common/components/google-login-button'
import RegisterForm from '../../common/components/forms/auth/register'
import { displaySuccessToast } from '../../common/utils/toast'
import CustomIcon from '../../common/components/custom-icon'
import { useRequest } from '../../common/hooks/request'
import { Credentials } from '../../../common/types'

import './styles.scss'

const RegisterPage = (): JSX.Element => {
  const [userAlreadyExist, setUserAlreadyExist] = useState<boolean>(false)

  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { currentLocale } = useContext(TranslationContext)
  const { updateAuthToken } = useContext(AuthContext)
  const responsive = useResponsive()
  const { postData } = useRequest()
  const history = useHistory()
  const intl = useIntl()

  const onSubmit = async (credentials: Credentials): Promise<void> => {
    displayLoader()
    const response = await postData('/auth/register', { credentials: {...credentials, language: currentLocale} })
    hideLoader()

    if (response.token) {
      updateAuthToken(response.token)
      displaySuccessToast(intl.formatMessage({id: 'register_success'}))
      history.push('/subject-subscriptions')
    } else if (response === 'user already exists') {
      setUserAlreadyExist(true)
    }
  }

  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)

  return (
    <Stack spacing={8} alignItems="center" className="page_content register_page_container">
      <Stack  flex={1} width="100%"  direction={isLargeDevice ? 'row' : 'column'} alignItems="center">
        <Stack 
          alignItems="center" 
          spacing={2} 
          flex={1}
        >
          <h1>{intl.formatMessage({id: 'register'})}</h1>

          <Stack spacing={1} alignItems="center">
            <p>{intl.formatMessage({id: 'register_use_credentials'})}</p>
            <RegisterForm 
              setUserAlreadyExist={setUserAlreadyExist}
              userAlreadyExist={userAlreadyExist}
              onSubmit={onSubmit} 
            />
          </Stack>
        </Stack>

        <Divider 
          orientation={isLargeDevice ? 'vertical' : 'horizontal'} 
          variant="middle" 
          flexItem 
          sx={{ 
            my: isLargeDevice ? 0 : 4, 
            width: isLargeDevice ? undefined : '60%',
            mx: isLargeDevice ? undefined : 'auto',
          }}
        />

        <Stack 
          justifyContent="center" 
          alignItems="center" 
          spacing={2}
          flex={1} 
        >
          <Stack spacing={1} alignItems="center">
            <p> {intl.formatMessage({id: 'register_use_google_account'})} </p>
            <GoogleLoginButton />
          </Stack> 
        </Stack>
      </Stack>

      <Stack alignItems="center" spacing={1}>
        <p> {intl.formatMessage({id: 'register_have_account'})} </p>
        <Link to="/login">
          <Button 
            endIcon={(<CustomIcon name="keyboard_arrow_right" />)}
            variant="outlined" 
            color="secondary"
          >
            {intl.formatMessage({id: 'register_login'})}
          </Button>
        </Link>
      </Stack>
    </Stack>
  )
}

export default RegisterPage
