import React, { useState } from 'react'
import { Button, IconButton, Menu, Stack, MenuItem, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import CustomIcon from '../custom-icon'

import './styles.scss'

interface TitleRightButtonProps {
  variant?: 'text' | 'contained' | 'outlined',
  color?: 'primary' | 'secondary',
  type?: 'button' | 'iconButton',
  startIconOutlined?: boolean,
  endIconOutlined?: boolean,
  onClick?: () => void,
  invisible?: boolean,
  startIcon?: string,
  className?: string,
  disabled?: boolean,
  endIcon?: string,
  label?: string,
  bold?: boolean,
  link?: string,
  icon?: string,
  sx?: any,
}

interface TitleProps {
  rightButtons?: TitleRightButtonProps[],
  displayRightButtonsInMenu?: boolean,
  titleRightIconTooltip?: string,
  rightContent?: JSX.Element,
  titleRightIcon?: string,
  withoutDot?: boolean,
  titleLevel2?: string,
  wrapTitle?: boolean,
  level?: number,
  title: string,
  sx?: any,
}

const Title = ({
  displayRightButtonsInMenu,
  titleRightIconTooltip,
  titleRightIcon,
  rightButtons,
  rightContent,
  titleLevel2,
  withoutDot,
  wrapTitle,
  level = 1,
  title,
  sx,
}: TitleProps): JSX.Element => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)

  return (
    <Stack direction="row" spacing={1} alignItems="center" className="title_container" sx={sx}>
      <Stack 
        alignItems="center" 
        direction="row" 
        minWidth="0"
        spacing={1} 
        flex={1} 
      >
        { !withoutDot && (
            <div 
              className="dot" 
              style={{ 
                marginTop: 
                  level === 1 ? '17px' 
                  : level === 2 ? '10px' 
                  : '7px'
              }} 
            />
          ) 
        }
        { level === 1 && <h1 className={classnames({ wrapText: wrapTitle })}>{title}</h1> }
        { level === 2 && <h2>{title}</h2> }
        { level === 3 && <h3>{title}</h3> }
        { 
          (titleRightIcon && titleRightIconTooltip) && (
            <Tooltip title={titleRightIconTooltip} placement="right">
              <Stack direction="row" alignItems="center"><CustomIcon name={titleRightIcon} /></Stack>
            </Tooltip>
          ) 
        }
        { titleLevel2 && (<span>{titleLevel2}</span>) }
      </Stack>
      
      {
        rightContent && (
          <div className="title_right_content_container"> {rightContent} </div>
        )
      }
      
      {
        (rightButtons && rightButtons.length > 0) && (
          <>
            {
              displayRightButtonsInMenu ? (
                <div className="title_right_content_container">
                  <IconButton onClick={e => setMenuAnchorEl(e.currentTarget)}>
                    <CustomIcon name="more_horiz" />
                  </IconButton>

                  <Menu
                    onClose={() => setMenuAnchorEl(null)}
                    anchorEl={menuAnchorEl}
                    open={!!menuAnchorEl}
                    autoFocus={false}
                    sx={{ml: 1}}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {
                      rightButtons.map((rightButton: TitleRightButtonProps, index: number) => {
                        if (rightButton.link) {
                          return (
                            <Link 
                              className={classnames('full_width', { disabled: rightButton.disabled })}
                              key={`title_menu_item_${index}`} 
                              to={rightButton.link} 
                            >
                              <MenuItem onClick={() => setMenuAnchorEl(null)}>{rightButton.label}</MenuItem>
                            </Link>
                          )
                        } else {
                          return (
                            <MenuItem 
                              key={`title_menu_item_${index}`} 
                              onClick={() => {
                                if (rightButton.onClick) {
                                  rightButton.onClick()
                                }
                                setMenuAnchorEl(null)
                              }}
                            >{rightButton.label}</MenuItem>
                          )
                        }
                      })
                    }
                  </Menu>
                </div> 
              ) : (
                <Stack 
                  className="title_right_content_container"
                  direction="row"
                  spacing={1} 
                  alignItems="center"
                >
                  {
                    rightButtons.map((rightButton: TitleRightButtonProps, index: number) => {
                      if (rightButton.link) {
                        return (
                          <Link 
                            className={classnames('title_right_button', { disabled_greyed: rightButton.disabled })}
                            key={`title_right_button_${index}`} 
                            to={rightButton.link}
                          >
                            <TitleRightButton {...rightButton} />
                          </Link>
                        )
                      } else {
                        return (
                          <TitleRightButton 
                            key={`title_right_button_${index}`} 
                            {...rightButton} 
                          />
                        )
                      }
                    })      
                  }
                </Stack>
              )
            }
          </>
        ) 
      }
    </Stack>
  )
}

const TitleRightButton = ({
  startIconOutlined,
  endIconOutlined,
  bold = true,
  startIcon,
  className,
  invisible,
  disabled,
  variant,
  onClick,
  endIcon,
  color,
  label,
  type,
  icon,
  sx,
}: TitleRightButtonProps): JSX.Element | null => {
  const isIconButton: boolean = type === 'iconButton'

  if (invisible) return null

  return (
    <Button 
      startIcon={startIcon ? <CustomIcon name={startIcon} outlined={startIconOutlined} /> : undefined}
      endIcon={endIcon ? <CustomIcon name={endIcon} outlined={endIconOutlined} /> : undefined}
      variant={variant || 'contained'}
      className={className} 
      disabled={disabled}
      onClick={onClick}
      color={color}
      sx={{
        ...sx,
        px: isIconButton ? 1 : sx?.px,
        minWidth: isIconButton ? 'initial' : sx?.minWidth,
        fontWeight: bold ? 'bold' : 'initial',
      }}
    >
      {
        isIconButton ? (
          <CustomIcon name={icon || 'more_horiz'} />
        ) : (
          <>
            {label || null}
          </>
        )
      }
    </Button>
  )
}

export { TitleRightButtonProps }
export default Title 
