import React, { createContext, useEffect, useState } from 'react'
import { getCookie, setCookie } from '../utils/cookies'

enum LocalesEnum {
  FR = 'FR',
  EN = 'EN',
}

const localeCookieKey = 'study_space_repeat_locale'

interface TranslationContextType {
  setCurrentLocale: (nextValue: LocalesEnum) => void,
  currentLocale: LocalesEnum,
}

const TranslationContext = createContext<TranslationContextType>({
  currentLocale: LocalesEnum.EN,
  setCurrentLocale: () => {},
})

const TranslationContextProvider = ({ children }: { children: JSX.Element }) => {
  const [currentLocale, setCurrentLocale] = useState<LocalesEnum>(LocalesEnum.FR)

  useEffect(() => {
    const localeCookie: string | undefined = getCookie(localeCookieKey)

    if (localeCookie && Object.values(LocalesEnum).includes(localeCookie as LocalesEnum)) {
      setCurrentLocale(localeCookie as LocalesEnum)
    } else if (navigator.language.toLocaleLowerCase().includes('fr')) {
      setCurrentLocale(LocalesEnum.FR)
    } else {
      setCurrentLocale(LocalesEnum.EN)
    }
  }, [])

  useEffect(() => {
    setCookie(localeCookieKey, currentLocale)
  }, [currentLocale])

  return (
    <TranslationContext.Provider
      value={{ 
        setCurrentLocale,
        currentLocale,
      }}
    >
      { children }
    </TranslationContext.Provider>
  )
}

export { 
  TranslationContextProvider, 
  TranslationContext, 
  LocalesEnum,
}

