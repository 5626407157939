import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'
import { sortBy } from 'lodash'

import { displaySuccessToast, displayWarningToast } from '../../../../common/utils/toast'
import { LoaderContext, SubjectSubscriptionsContext } from '../../../../common/contexts'
import { DeviceTypesEnum, useResponsive } from '../../../../common/hooks/responsive'
import { decomposeSubjectSubscription } from '../../../../../common/utils/subject'
import QuestionStudyDisplay from '../../../study/components/question-study-display'
import QuestionCount from '../../../study/components/question-count'
import Breadcrumbs from '../../../../common/components/breadcrumbs'
import StudySumUp from '../../../study/components/study-sum-up'
import { UserContext } from '../../../../common/contexts/user'
import { useLoadStudy } from '../../../../common/hooks/study'
import { useRequest } from '../../../../common/hooks/request'
import Title from '../../../../common/components/title'
import { 
  AcquisitionStatusEnum, 
  QuestionSubscription, 
  StudyQuestionsInfos,
  Question, 
  Subject,
} from '../../../../../common/types'

import './styles.scss'

const StudyPage = (): JSX.Element | null => {
  const [currentQuestionSubscription, setCurrentQuestionSubscription] = useState<QuestionSubscription | null>(null)
  const [questionIndex, setQuestionIndex] = useState<number | null>(null)
  const [isStudyDone, setIsStudyDone] = useState<boolean>(false)
  
  const { loadSubjectSubscription, subjectSubscription } = useContext(SubjectSubscriptionsContext)
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { study, loadStudy } = useLoadStudy()
  const { user } = useContext(UserContext)
  const responsive = useResponsive()
  const { postData } = useRequest()
  const history = useHistory()
  const intl = useIntl()

  useEffect(() => {
    if (study && study.questionSubscriptions.length) {
      setNextQuestion()
    }
  }, [study])

  useEffect(() => {
    if (study?.subjectSubscription) {
      loadSubjectSubscription(study.subjectSubscription as string)
    }
  }, [study?.subjectSubscription])

  useEffect(() => {
    if (study && subjectSubscription && study.questionSubscriptions.length === 0) {
      displayWarningToast(intl.formatMessage({id: 'study_ended'}))
      history.push(subjectSubscription ? `/subject-subscription/${subjectSubscription._id}` : '/subject-subscriptions')
    }
  }, [study, subjectSubscription])

  const setNextQuestion = (): void => {
    if (!study) return

    let foundQuestion: boolean = false

    sortBy(study.questionsInfos, 'order').some((studyQuestionInfos: StudyQuestionsInfos) => {
      if (!studyQuestionInfos.isDone) {
        const foundQuestionSubscription: QuestionSubscription | undefined = (study.questionSubscriptions as QuestionSubscription[])
          .find((questionSubscription: QuestionSubscription) => (questionSubscription._id === studyQuestionInfos.questionSubscriptionId))

        if (foundQuestionSubscription) {
          setCurrentQuestionSubscription(foundQuestionSubscription)
          setQuestionIndex(studyQuestionInfos.order)
          foundQuestion = true
          return true;
        }
      }
    })

    if (!foundQuestion) {
      setIsStudyDone(true)
    }
  }

  const onSubmitQuestionProgress = async (acquisitionStatus: AcquisitionStatusEnum): Promise<void> => {
    if (study && currentQuestionSubscription) {
      displayLoader()
      await postData('/study/question-done', { 
        questionSubscriptionId: currentQuestionSubscription._id,
        studyId: study._id,
        acquisitionStatus,
      })
      setCurrentQuestionSubscription(null)
      setQuestionIndex(null)
      await loadStudy()
      hideLoader()
    }
  }

  const updateIsAcquired = async (
    questionSubscriptionId: string, 
    isAcquired: boolean,
  ): Promise<void> => {
    displayLoader()
    const response = await postData('/question-subscription/update-is-acquired', {
      questionSubscriptionId,
      isAcquired,
    })

    if (response.success) {
      await loadStudy()

      if (isAcquired) {
        displaySuccessToast(intl.formatMessage({id: 'question_marked_acquired'}))
      } else {
        displaySuccessToast(intl.formatMessage({id: 'question_marked_unacquired'}))
      }
    } else {
      displayWarningToast(intl.formatMessage({id: 'question_update_error'}))
      hideLoader()
    }
  }

  if ((!isStudyDone && !currentQuestionSubscription) || !subjectSubscription || !study) return null

  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isSmallDevice: boolean = responsive.isSmallDevice()
  const question: Question | undefined = currentQuestionSubscription?.question as Question
  const { subject } = decomposeSubjectSubscription(subjectSubscription)

  if ((!isStudyDone && !question) || !user) return null

  return (
    <Stack className="page_content study_page_container" spacing={2}>
      <Breadcrumbs 
        pages={[
          {
            label: intl.formatMessage({id: 'page_subject_subscriptions'}),
            path: '/subject-subscriptions',
          },
          {
            path: `/subject-subscription/${subjectSubscription._id}`,
            label: subject.label,
          },
          {
            label: isSmallDevice ? intl.formatMessage({id: 'study'}) : intl.formatMessage({id: `study_label_${study.type}`}),
            path: `/study/${study._id}`,
          },
        ]}
      />

      <Title
        title={intl.formatMessage({id: 'study_title'}, {subjectLabel: (study?.subject as Subject)?.label || '...'})}
        wrapTitle={isSmallDevice}
        rightContent={(isLargeDevice && !!study && !!currentQuestionSubscription) ? (
          <QuestionCount
            questionsCount={study.questionSubscriptions.length}
            questionIndex={questionIndex || 0}
          />
        ) : undefined}
      />

      {
        (!!study && !!currentQuestionSubscription && !isStudyDone) && (
          <Stack spacing={2} alignItems="center">
            {
              !isLargeDevice && (
                <QuestionCount
                  questionsCount={study.questionSubscriptions.length}
                  questionIndex={questionIndex || 0}
                />
              )
            }
            
            <QuestionStudyDisplay 
              onSubmitQuestionProgress={onSubmitQuestionProgress}
              questionSubscription={currentQuestionSubscription}
              subjectSubscription={subjectSubscription}
              updateIsAcquired={updateIsAcquired}
              onQuestionDeleted={loadStudy}
            />
          </Stack>
        )
      }

      {
        (!!study && isStudyDone) && (
          <StudySumUp 
            subjectUrl={`/subject-subscription/${study.subjectSubscription}`}  
            questionsCount={study.questionSubscriptions.length}
            questionsInfos={study.questionsInfos}
          />
        )
      }

    </Stack>
  )
}

export default StudyPage