import React from 'react'
import { Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { useResponsive } from '../../hooks/responsive'
import CustomIcon from '../custom-icon'

import './styles.scss'

const Footer = (): JSX.Element => {
  const responsive = useResponsive()
  const intl = useIntl()

  const isSmallDevice: boolean = responsive.isSmallDevice()
  
  return (
    <Stack className="footer_container" justifyContent="center" alignItems="center" py={4} spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <CustomIcon name="school" className="icon" color="#0d8180" />
        <h2 className="title">Study space Repeat</h2>
      </Stack>

      <Stack className="links" direction={isSmallDevice ? 'column' : 'row'} spacing={isSmallDevice ? 1 : 2}>
        <Link to="/terms">
          <p>{intl.formatMessage({id: 'footer_terms'})}</p>
        </Link>
        <Link to="/privacy-policy">
          <p>{intl.formatMessage({id: 'footer_privacy'})}</p>
        </Link>
        <Link to="/legal-infos">
          <p>{intl.formatMessage({id: 'footer_legals'})}</p>
        </Link>
      </Stack>
    </Stack>
  )
}

export default Footer