import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { displayWarningToast } from '../utils/toast'
import { Question } from '../../../common/types'
import { useRequest } from './request'
import useRouterUtils from './router'
import { LoaderContext } from '../contexts'

const useLoadQuestion = (): {
  question: Question | null,
  loadQuestion: () => Promise<void>,
} => {
  const [question, setQuestion] = useState<Question | null>(null)

  const { questionId, subjectSubscriptionId } : { questionId: string, subjectSubscriptionId: string } = useParams()
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { goBackOrRedirect } = useRouterUtils()
  const { fetchData } = useRequest()

  useEffect(() => {
    loadQuestion()
  }, [])

  const loadQuestion = async (): Promise<void> => {
    if (questionId) {
      displayLoader()
      const response = await fetchData('/question', { questionId })
      hideLoader()
      
      if (response.question) {
        setQuestion(response.question || null)
      } else {
        displayWarningToast('La question est inconnu !')
        goBackOrRedirect(`/subject-subscription/${subjectSubscriptionId}`)
      }
    }
  }

  return {
    loadQuestion,
    question,
  }
}

export { useLoadQuestion }