import { useContext, useEffect } from 'react'
import axios, { AxiosInstance } from 'axios'

import { convertToFormData } from '../utils/form'
import { AuthContext } from '../contexts/auth'

let axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.API_URL}/api`,
  timeout: 5000,
  headers: { },
})

const useRequest = () => {
  const { authToken } = useContext(AuthContext)

  useEffect(() => {
    axiosInstance = axios.create({
      paramsSerializer: (params) => new URLSearchParams(params as any).toString(),
      headers: { Authorization: authToken || '' },
      baseURL: `${process.env.API_URL}/api`,
      timeout: 5000,
    })
  }, [authToken])

  const fetchData = async (path: string, params?: any): Promise<any> => {
    try {
      const response = await axiosInstance.get(path, { params, headers: { Authorization: authToken || '' }})
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  const postData = async (path: string, data?: any): Promise<any> => {
    try {
      const response = await axiosInstance.post(path, data, { headers: { Authorization: authToken || '' } })
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  const postFormData = async (path: string, data: any): Promise<any> => {
    try {
      const formData: FormData = convertToFormData(data)
      const response = await axiosInstance.post(path, formData)
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  return {
    postFormData,
    fetchData,
    postData,
  }
}

export { useRequest }
