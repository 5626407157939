import React, { useState } from 'react'
import { Button, Stack } from '@mui/material'

import { useLoadSubjectSubscription } from '../../../../../common/hooks/subjects-subscription'
import DeleteSubjectModal from '../../../../../common/components/subject/delete-subject-modal'
import CustomIcon from '../../../../../common/components/custom-icon'
import { palette } from '../../../../../common/theme'

const DeleteSubjectPanel = (): JSX.Element | null => {
  const [openDeleteSubjectModal, setOpenDeleteSubjectModal] = useState<boolean>(false)

  const { subjectSubscription, canUserDeleteSubject } = useLoadSubjectSubscription()

  if (!subjectSubscription) return null 
  
  return (
    <Stack className="panel" direction="row" alignItems="center" justifyContent="space-between">
      <p>Supprimer le sujet</p>

      <Button 
        variant="outlined" 
        color="error"
        endIcon={(<CustomIcon name="delete" outlined color={palette.error.main} />)}
        onClick={() => {
          setOpenDeleteSubjectModal(true)
        }}
      >
        <p style={{ color: palette.error.main }}>Supprimer{canUserDeleteSubject ? '' : ' de mon espace'}</p>
      </Button>
      
      <DeleteSubjectModal 
        onClose={() => setOpenDeleteSubjectModal(false)}
        subjectSubscription={subjectSubscription}
        open={openDeleteSubjectModal}
      />
    </Stack>
  )
}

export default DeleteSubjectPanel