import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { displayWarningToast } from '../utils/toast'
import { Study } from '../../../common/types'
import { useRequest } from './request'
import { LoaderContext } from '../contexts'

const useLoadStudy = (): {
  study: Study | null,
  loadStudy: () => Promise<void>,
} => {
  const [study, setStudy] = useState<Study | null>(null)
  
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { studyId } : { studyId: string } = useParams()
  const { fetchData } = useRequest()
  const history = useHistory()

  useEffect(() => {
    loadStudy()
  }, [])

  const loadStudy = async (): Promise<void> => {
    if (studyId) {
      displayLoader()
      const response = await fetchData('/study', { studyId })
      hideLoader()

      if (response.study) {
        setStudy(response.study)
      } else {
        displayWarningToast('La session de révision est inconnue !')
        history.push(`/subject-subscriptions`)
      }
    }
  }

  return {
    loadStudy,
    study,
  }
}

export { useLoadStudy }