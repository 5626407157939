import React, { useContext, useState } from 'react'
import { Button, Menu, MenuItem, Stack } from '@mui/material'

import { useLoadSubjectSubscription } from '../../../../../common/hooks/subjects-subscription'
import { displaySuccessToast, displayWarningToast } from '../../../../../common/utils/toast'
import { useResponsive } from '../../../../../common/hooks/responsive'
import CustomIcon from '../../../../../common/components/custom-icon'
import UserAvatar from '../../../../../common/components/user-avatar'
import { useRequest } from '../../../../../common/hooks/request'
import { LoaderContext } from '../../../../../common/contexts'
import { 
  SubjectSubscriptionUserRoleLabelsEnum,
  SubjectSubscriptionUserRoleEnum, 
  InviteStatusEnum,
  UserPublicView,
  SubjectMember,
  Invite,
  User,
} from '../../../../../../common/types'

interface SendInviteRowProps {
  userResult?: UserPublicView,
  email?: string,
}

const SendInviteRow = ({
  userResult,
  email,
}: SendInviteRowProps): JSX.Element | null => {
  const [displayButtons, setDisplayButtons] = useState<boolean>(false)
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const responsive = useResponsive()
  const { postData } = useRequest()
  const { 
    subjectSubscription, 
    loadSubjectInvites, 
    subjectMembers, 
    subjectInvites,
  } = useLoadSubjectSubscription()

  const [selectedRole, setSelectedRole] = useState<SubjectSubscriptionUserRoleEnum>(SubjectSubscriptionUserRoleEnum.USER)
  const [rolesMenuAnchorEl, setRolesMenuAnchorEl] = useState<HTMLElement | null>(null)

  if (!subjectSubscription) return null

  const sendInvite = async (
    role: SubjectSubscriptionUserRoleEnum, 
    subjectSubscriptionId: string, 
    userId?: string,
    email?: string, 
  ): Promise<void> => {
    displayLoader()
    const response = await postData('/invite', {
      subjectSubscriptionId,
      userId,
      email,
      role,
    })

    if (response.success) {
      await loadSubjectInvites()
      displaySuccessToast('L\'invitation a bien été envoyée')
    } else if (response.errorMessage === 'Invite already sent') {
      displayWarningToast('Une invitation a déjà été envoyée et à cette adresse.')
    } else if (response.errorMessage === 'User already member') {
      displayWarningToast('Cet utilisateur est déjà membre du sujet.')
    } else {
      displayWarningToast('Une erreur est survenue lors de l\'envoi de l\'invitation.')
    }

    hideLoader()
  }

  const onSelectRole = (role: SubjectSubscriptionUserRoleEnum): void => {
    setRolesMenuAnchorEl(null)
    setSelectedRole(role)
  }
  
  const subjectMember: SubjectMember | undefined = userResult ? subjectMembers.find((subjectMember: SubjectMember) => {
    return subjectMember.userId === userResult._id
  }) : undefined
  const invite: Invite | undefined = subjectInvites.find((invite: Invite) => {
    return ((invite.user as User)?._id === userResult?._id || invite.email === email)
  })
  const isInviteAlreadySent: boolean = Boolean(invite && invite.status === InviteStatusEnum.SENT)
  const isAlreadyMember: boolean = Boolean(subjectMember)
  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <Stack 
      direction={isSmallDevice ? 'column' : 'row'} 
      width={isSmallDevice ? undefined : 450}
      justifyContent="space-between"
      spacing={1} 
    >
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" width="100%">
        {
          userResult && (
            <Stack direction="row" spacing={1} alignItems="center">
              <UserAvatar picture={userResult.picture} size={30} />
              <p>{userResult.pseudo}</p>
            </Stack>
          ) 
        }

        {
          email && (
            <Stack direction="row" spacing={1} alignItems="center" flex={1} minWidth={0}>
              <CustomIcon name="alternate_email" size={30} color="lightgrey" />
              <p className="wrapText">{ email }</p>
            </Stack>
          )
        }

        {
          isAlreadyMember && (
            <Stack direction="row" spacing={1} alignItems="center">
              <p> Déjà membre </p>
              {
                !isSmallDevice && (
                  <p className="text_italic text_light">({SubjectSubscriptionUserRoleLabelsEnum[subjectMember!.role]})</p>
                )
              }
            </Stack>
          )
        }

        {
          (!isAlreadyMember && isInviteAlreadySent) && (
            <Stack direction="row" spacing={1} alignItems="center">
              <p> Invitation envoyée </p>
              {
                !isSmallDevice && (
                  <p className="text_italic text_light">({SubjectSubscriptionUserRoleLabelsEnum[invite!.role]})</p>
                )
              }
            </Stack>
          )
        }

        {
          (isSmallDevice && !isAlreadyMember && !isInviteAlreadySent) && (
            <Button
              onClick={() => setDisplayButtons(!displayButtons)}
              className="mui_icon_button"
              variant="outlined"
              color="secondary"
            >
              <CustomIcon name={displayButtons ? 'keyboard_arrow_up' : 'keyboard_arrow_right'} />
            </Button>
          )
        }
      </Stack>
      
      {
        ((!isAlreadyMember && !isInviteAlreadySent) && (!isSmallDevice || displayButtons)) && (
          <Stack direction="row" spacing={1}>
            <Button
              endIcon={(<CustomIcon name="keyboard_arrow_down" />)}
              onClick={e => setRolesMenuAnchorEl(e.currentTarget)}
              sx={{ flex: isSmallDevice ? 1 : undefined }}
              variant="outlined" 
              color="secondary"
            >
              { SubjectSubscriptionUserRoleLabelsEnum[selectedRole] }
            </Button>

            <Menu 
              onClose={() => setRolesMenuAnchorEl(null)}
              anchorEl={rolesMenuAnchorEl}
              open={!!rolesMenuAnchorEl}
              sx={{ mt: 1 }}
            >
              <MenuItem 
                onClick={() => onSelectRole(SubjectSubscriptionUserRoleEnum.USER)}
                selected={ selectedRole === SubjectSubscriptionUserRoleEnum.USER}
                value={SubjectSubscriptionUserRoleEnum.USER} 
              >
                { SubjectSubscriptionUserRoleLabelsEnum[SubjectSubscriptionUserRoleEnum.USER] }
              </MenuItem>
              <MenuItem 
                onClick={() => onSelectRole(SubjectSubscriptionUserRoleEnum.EDITOR)}
                selected={ selectedRole === SubjectSubscriptionUserRoleEnum.EDITOR}
                value={SubjectSubscriptionUserRoleEnum.EDITOR} 
              >
                { SubjectSubscriptionUserRoleLabelsEnum[SubjectSubscriptionUserRoleEnum.EDITOR] }
              </MenuItem>
            </Menu>

            <Button 
              onClick={() => sendInvite(selectedRole, subjectSubscription._id!, userResult?._id, email)}
              endIcon={(<CustomIcon name="send" outlined />)}
              sx={{ flex: isSmallDevice ? 1 : undefined }}
              variant="outlined" 
              color="secondary"
            >
              Envoyer
            </Button>
          </Stack>
        )
      }

    </Stack>
  )
}

export default SendInviteRow

