import React, { useState, useEffect } from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import { useDebounce } from 'use-debounce'

import { useResponsive } from '../../../../../common/hooks/responsive'
import { validateEmail } from '../../../../../../common/utils/string'
import { useRequest } from '../../../../../common/hooks/request'
import { UserPublicView } from '../../../../../../common/types'
import SendInviteRow from './send-invite-row'

interface SendInvitesModalProps {
  onClose: () => void,
  open: boolean,
}

const SendInvitesModal = ({
  onClose,
  open,
}: SendInvitesModalProps): JSX.Element => {
  const [didFirstRender, setDidFirstRender] = useState<boolean>(false)
  const [users, setUsers] = useState<UserPublicView[] | null>(null)
  const [isEmailInput, setIsEmailInput] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const responsive = useResponsive()
  
  const [debouncedValue] = useDebounce(inputValue, 500)
  const { fetchData } = useRequest()

  useEffect(() => {
    if (didFirstRender) {
      onSearch()
    } else {
      setDidFirstRender(true)
    }
  }, [debouncedValue])

  useEffect(() => {
    setIsEmailInput(validateEmail(inputValue))
  }, [inputValue])

  const onSearch = async (): Promise<void> => {
    setIsLoading(true)
    const response = await fetchData('/search/users', { searchText: inputValue })
    setIsLoading(false)

    if (response.users) {
      setUsers(response.users)
    }
  }

  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Envoyer des invitations
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <p>Trouver un utilisateur ou saisir un email</p>

          <TextField 
            onChange={event => setInputValue(event.target.value)}
            placeholder="Pseudo ou adresse email" 
            sx={{ width: isSmallDevice ? undefined : 450 }}
            value={inputValue}
            size="small"
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                </React.Fragment>
              ),
            }}
          />

          <Stack spacing={1}>
            {
              isEmailInput && (
                <SendInviteRow email={inputValue} />
              )
            }

            {
              users && users.length === 0 && !isEmailInput && inputValue && (
                <p className="text_center text_italic"> Aucun résultat </p>
              )
            }

            {
              users && users.length > 0 && users.map((userResult: UserPublicView, index: number) => (
                <SendInviteRow 
                  key={`user_result_${index}`}
                  userResult={userResult} 
                />
              ))
            }
          </Stack>

        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SendInvitesModal