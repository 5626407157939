import { useHistory } from 'react-router'

const useRouterUtils = () => {
  const history = useHistory()

  const canGoBack: boolean = (history.action === 'PUSH')

  const goBackOrRedirect = (redirectTo?: string): void => {
    if (canGoBack) {
      history.goBack()
    } else {
      history.push(redirectTo || '/')
    }
  }

  return { 
    goBackOrRedirect, 
    canGoBack, 
  }
}

export default useRouterUtils