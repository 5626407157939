import React from 'react'
import { Button, Stack } from '@mui/material'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import CustomIcon from '../../../common/components/custom-icon'
import { palette } from '../../../common/theme'
import { DeviceTypesEnum, useResponsive } from '../../../common/hooks/responsive'

interface PageInfos {
  label: string,
  path: string,
}

const pagesInfos: PageInfos[] = [
  {
    label: 'Mon profil',
    path: '/settings/profile',
  },
  {
    label: 'Paramètres de répétition espacée',
    path: '/settings/spaced-repetition',
  },
  {
    label: 'Questions à inclure dans les révisions globales',
    path: '/settings/study-questions',
  },
  {
    label: 'Mot de passe',
    path: '/settings/password',
  },
  {
    label: 'Compte',
    path: '/settings/account',
  },
]

const SettingsMenu = (): JSX.Element => {
  const responsive = useResponsive()
  const location = useLocation()

  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)

  return (
    <Stack 
      width={isDesktop ? '250px' : undefined} 
      flex={isDesktop ? undefined : 1} 
      className="panel" 
      spacing={1}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <CustomIcon name="settings" style={{ color: palette.primary.main }} />
        <h2>Paramètres</h2>
      </Stack>

      <Stack>
        {
          pagesInfos.map((pageInfos: PageInfos, index: number) => {
            const isSelected: boolean = location.pathname === pageInfos.path

            return (
              <Link key={`settings_menu_item_${index}`} to={pageInfos.path} style={{ flex: 1 }}>
                <Button 
                  color="secondary" 
                  sx={{ 
                    justifyContent: 'flex-start', 
                    textAlign: 'left', 
                    fontWeight: isSelected ? 'bold' : undefined,
                    width: '100%',
                  }}
                >
                  {pageInfos.label}
                </Button>      
              </Link>
            )
          })
        }
      </Stack>
    </Stack>
  )
}

export default SettingsMenu