import React from 'react'
import { Stack } from '@mui/material'
import toast from 'react-hot-toast'

import CustomIcon from '../components/custom-icon'
import { palette } from '../theme'

function displaySuccessToast(message: string): void {
  toast(() => (
    <Stack direction='row' alignItems='center' spacing={1}>
      <CustomIcon name='check_circle' color='#fff' />
      <b className='text_white'>{message}</b>
    </Stack>
  ), {
    style: {
      backgroundColor: palette.success.main,
    },
  });
}

function displayWarningToast(message: string): void {
  toast(() => (
    <Stack direction='row' alignItems='center' spacing={1}>
      <CustomIcon name='error' color='#fff' />
      <b className='text_white'>{message}</b>
    </Stack>
  ), {
    style: {
      backgroundColor: palette.warning.main,
    },
  });
}

function displayInfoToast(message: string): void {
  toast(() => (
    <Stack direction='row' alignItems='center' spacing={1}>
      <CustomIcon name='info' color='#fff' />
      <b className='text_white'>{message}</b>
    </Stack>
  ), {
    style: {
      backgroundColor: palette.info.main,
    },
  });
}

export {
  displaySuccessToast,
  displayWarningToast,
  displayInfoToast,
}
