import React, { useContext, useState, useRef, useEffect } from 'react'
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material'

import { AuthContext, TranslationContext, UserContext } from '../../contexts'
import { LocalesEnum } from '../../contexts/translation'
import { useRequest } from '../../hooks/request'
import CustomIcon from '../custom-icon'

enum LocalesLabelsShortEnum {
  FR = 'FR',
  EN = 'EN',
}

enum LocalesLabelsEnum {
  FR = 'Français',
  EN = 'English',
} 

const LanguagesMenu = (): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const { setCurrentLocale, currentLocale } = useContext(TranslationContext)
  const { isLogged } = useContext(AuthContext)
  const { user } = useContext(UserContext)
  const { postData } = useRequest()

  useEffect(() => {
    if (!!user && !!user.language && user.language !== currentLocale) {
      setCurrentLocale(user.language)
    }
  }, [user])

  const onClose = () => {
    setIsMenuOpen(false)
  }

  const onSelectLocale = (nextLocale: LocalesEnum) => {
    setCurrentLocale(nextLocale)
    onClose()

    if (!!user && isLogged) {
      postData('/user/update-language', { language: nextLocale })
    }
  }

  return (
    <>
      <Button 
        endIcon={(<CustomIcon name={isMenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />)}
        className="languages_menu_container"
        onClick={() => setIsMenuOpen(true)}
        variant="outlined"
        color="secondary"
        ref={anchorRef}
      >
        { LocalesLabelsShortEnum[currentLocale] }
      </Button>
    
      <Popper
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{zIndex: 10}}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
                <MenuList>
                  {
                    Object.values(LocalesEnum).map((locale: LocalesEnum) => {
                      return (
                        <MenuItem 
                          key={`language_menu_locale_${locale}`}
                          onClick={() => onSelectLocale(locale)}
                          selected={currentLocale === locale}
                        >
                          { LocalesLabelsEnum[locale] }
                        </MenuItem>
                      )
                    })
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default LanguagesMenu
