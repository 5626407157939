import { CommonEntityFields, Subject, SubjectSubscriptionUserRoleEnum, User } from '.'

interface Invite extends CommonEntityFields {
  role: SubjectSubscriptionUserRoleEnum,
  subject: string | Subject,
  status: InviteStatusEnum,
  author: string | User,
  user?: string | User,
  email?: string,
}

enum InviteStatusEnum {
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  SENT = 'SENT',
}

enum InviteStatusLabelsEnum {
  ACCEPTED = 'Invitation acceptée',
  DECLINED = 'Invitation refusée',
  CANCELED = 'Invitation annulée',
  SENT = 'Invitation envoyée',
}

export { 
  InviteStatusLabelsEnum,
  InviteStatusEnum, 
  Invite, 
}

