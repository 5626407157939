import { Question, QuestionSubscription } from '../types'

const decomposeQuestionSubscription = (
  questionSubscription: QuestionSubscription,
) : {
  question: Question,
} => {
  return {
    question: questionSubscription.question as Question,
  }
}

export { decomposeQuestionSubscription }
