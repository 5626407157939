import React, { useContext } from 'react'
import { Stack } from '@mui/material'

import { displaySuccessToast, displayWarningToast } from '../../common/utils/toast'
import ResetPasswordForm from '../../common/components/forms/auth/reset-password'
import { useRequest } from '../../common/hooks/request'
import { LoaderContext } from '../../common/contexts'

import './styles.scss'
import { useIntl } from 'react-intl'

interface ResetPasswordPageProps {
  match: {
    params: {
      verificationCode?: string,
    },
  },
}

const ResetPasswordPage = ({
  match: {
    params: {
      verificationCode,
    },
  },
}: ResetPasswordPageProps): JSX.Element => {
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { postData } = useRequest()
  const intl = useIntl()
  
  const onSubmit = async (password: string): Promise<void> => {
    displayLoader()
    const response = await postData('/user/reset-password', {
      verificationCode,
      password,
    })
    if (response.success) {
      displaySuccessToast(intl.formatMessage({id: 'reset_password_success'}))
    } else if (response === 'invalid code') {
      displayWarningToast(intl.formatMessage({id: 'reset_password_invalid_link'}))
    } else {
      displayWarningToast(intl.formatMessage({id: 'reset_password_error'}))
    }
    hideLoader()
  }

  return (
    <Stack spacing={3} alignItems="center" className="page_content forgot_password_container">
      <Stack alignItems="center">
        <h1>{intl.formatMessage({id: 'reset_password_title'})}</h1>
        <p>{intl.formatMessage({id: 'reset_password_subtitle'})}</p>
      </Stack>
      <ResetPasswordForm onSubmit={onSubmit} />
    </Stack>
  )
}

export default ResetPasswordPage
