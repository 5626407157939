import React, { useContext, useState } from 'react'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { displaySuccessToast, displayWarningToast } from '../../common/utils/toast'
import ForgotPasswordForm from '../../common/components/forms/auth/forgot-password'
import { useRequest } from '../../common/hooks/request'
import { LoaderContext } from '../../common/contexts'

import './styles.scss'

const ForgotPasswordPage = (): JSX.Element => {
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false);
  const [isUserUnknown, setIsUserUnknown] = useState<boolean>(false)
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { postData } = useRequest()
  const intl = useIntl()
  
  const onSubmit = async (values: { mail: string }): Promise<void> => {
    disableForm()
    displayLoader()
    const response = await postData('/user/forgot-password', values)
    if (response.success) {
      displaySuccessToast(intl.formatMessage({id: 'forgotten_password_mail_success'}))
    } else if (response === 'user unknown') {
      setIsUserUnknown(true)
    } else {
      displayWarningToast(intl.formatMessage({id: 'forgotten_password_mail_error'}))
    }
    hideLoader()
  }

  const disableForm = (): void => {
    setDisableSubmitButton(true)
    setTimeout(() => {
      setDisableSubmitButton(false)
    }, 10000)
  }

  return (
    <Stack spacing={2} alignItems="center" className="page_content forgot_password_container">
      <Stack alignItems="center">
        <h1>{intl.formatMessage({id: 'forgotten_password_title'})}</h1>
        <p>{intl.formatMessage({id: 'forgotten_password_subtitle'})}</p>
      </Stack>
      
      <ForgotPasswordForm 
        disableSubmitButton={disableSubmitButton}
        setIsUserUnknown={setIsUserUnknown}
        isUserUnknown={isUserUnknown}
        onSubmit={onSubmit}
      />
    </Stack>
  )
}

export default ForgotPasswordPage
