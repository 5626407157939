import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material'
import { useWatch } from 'react-hook-form'
import Dropzone from 'react-dropzone'
import { useIntl } from 'react-intl'

import { DeviceTypesEnum, useResponsive } from '../../../../hooks/responsive'
import { isDataUrl, isUrl } from '../../../../../../common/utils/string'
import { displayWarningToast } from '../../../../utils/toast'
import { dataUrlToFile } from '../../../../utils/image'
import CustomIcon from '../../../custom-icon'

import './styles.scss'

interface ImagesPromptButtonProps {
  setValue: any, // Note : we have to put any here, otherwise we get a typescript error from react-hook-form
  control: any, // Note : we have to put any here, otherwise we get a typescript error from react-hook-form
  multiple?: boolean,
  fieldName: string,
  small?: boolean,
  label?: string,
  icon?: string,
}

const ImagesPromptButton = ({
  fieldName,
  setValue,
  multiple,
  control,
  small,
  label,
  icon,
}: ImagesPromptButtonProps): JSX.Element => {
  const [openImagesDialog, setOpenImagesDialog] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string | undefined>()
  
  const responsive = useResponsive()
  const intl = useIntl()

  const [images] = useWatch({
    name: [fieldName],
    control,
  })

  useEffect(() => {
    if (!openImagesDialog && !!imageUrl) {
      setImageUrl(undefined)
    }
  }, [openImagesDialog])

  const onAddImages = async (files: File[]) => {
    setOpenImagesDialog(false)
    if (multiple) {
      setValue(fieldName, [
        ...Array.from(images || []),
        ...Array.from(files),
      ], { shouldDirty: true })
    } else {
      setValue(fieldName, files[0], { shouldDirty: true })
    }
  }

  const onConfirm = async (): Promise<void> => {
    if (imageUrl) {
      if (isDataUrl(imageUrl)) {
        onAddImages([await dataUrlToFile(imageUrl)])
      } else if (isUrl(imageUrl)) {
        setValue(fieldName, [
          ...Array.from(images || []),
          imageUrl,
        ], { shouldDirty: true })
      } else {
        displayWarningToast(intl.formatMessage({id: 'form_image_button_invalid_url'}))
      }
    }
    setOpenImagesDialog(false)
  }

  const isDesktop = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)

  return (
    <>
      {
        small ? (
          <Button
            onClick={() => setOpenImagesDialog(true)}
            className="bg_light mui_icon_button"
            variant="outlined"
            component="label"
            color="secondary"
          >
            <CustomIcon name={icon || 'add_photo_alternate'} outlined />
          </Button>
        ) : (
          <Button
            onClick={() => setOpenImagesDialog(true)}
            endIcon={(<CustomIcon name={icon || 'add_photo_alternate'} outlined />)}
            className="bg_light"
            variant="outlined"
            component="label"
            color="secondary"
          >
            {label || multiple ? intl.formatMessage({id: 'form_image_button_multiple'}) : intl.formatMessage({id: 'form_image_button_single'})}
          </Button>
        )
      }

      <Dialog
        onClose={() => setOpenImagesDialog(false)}
        open={openImagesDialog}
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {
              multiple ? intl.formatMessage({id: 'form_image_button_multiple'}) : intl.formatMessage({id: 'form_image_button_single'})
            }
            <IconButton size="small" onClick={() => setOpenImagesDialog(false)}>
              <CustomIcon name="clear" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>

            <TextField
              placeholder={intl.formatMessage({id: 'image_dialog_add_link'})}
              onChange={e => setImageUrl(e.target.value)}
              value={imageUrl || ''}
              size="small"
            />

            <p className="text_center">{intl.formatMessage({id: 'image_dialog_or'})}</p>

            <Dropzone onDrop={onAddImages} multiple={multiple} accept={{
              'image/jpeg': [],
              'image/png': [],
              'image/webp': [],
              'image/heic': [],
              'image/jfif': [],
            }}>
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone_container">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Stack alignItems="center" spacing={2}>
                      <Stack>
                        <b className="text_center">{intl.formatMessage({id: 'image_dialog_import_1'})}</b>
                        {
                          isDesktop && (
                            <p className="text_center">{intl.formatMessage({id: 'image_dialog_import_2'})}</p>
                          )
                        }
                      </Stack>
                      <CustomIcon name="devices" />
                    </Stack>
                  </div>
                </section>
              )}
            </Dropzone>

          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button variant="text" color="secondary" onClick={() => setOpenImagesDialog(false)}>
              {intl.formatMessage({id: 'image_dialog_cancel'})}
            </Button>
            <Button
              onClick={onConfirm}
              variant="outlined"
              color="secondary"
            >
              {intl.formatMessage({id: 'image_dialog_confirm'})}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ImagesPromptButton
