import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Stack } from '@mui/material'

import './styles.scss'

const LegalInfosPage = (): JSX.Element => {
  return (
    <Stack className="page_content legal_infos_page_container" spacing={2}>
      <h1 className="text_center"><FormattedMessage id="legals_1" /></h1>
      
      <p><FormattedMessage id="legals_2" /></p>

      <Stack spacing={1}>
        <h2><FormattedMessage id="legals_3" /></h2>
        <p><FormattedMessage id="legals_4" /></p>

        <Stack>
          <p><FormattedMessage id="legals_5" /></p>
          <p><FormattedMessage id="legals_6" /></p>
          <p><FormattedMessage id="legals_7" /></p>
          <p><FormattedMessage id="legals_8" /></p>
          <p><FormattedMessage id="legals_9" /></p>
          <p><FormattedMessage id="legals_10" /></p>
          <p><FormattedMessage id="legals_11" /></p>
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="legals_12" /></h2>

        <p><FormattedMessage id="legals_13" /></p>

        <Stack>
          <p><FormattedMessage id="legals_14" /></p>
          <p><FormattedMessage id="legals_15" /></p>
          <p><FormattedMessage id="legals_16" /></p>
          <p><FormattedMessage id="legals_17" /></p>
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="legals_18" /></h2>

        <p><FormattedMessage id="legals_19" /></p>

        <Stack>
          <p><FormattedMessage id="legals_20" /></p>
          <p><FormattedMessage id="legals_21" /></p>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default LegalInfosPage
