import React, { useState, useEffect } from 'react'
import { Button, Stack, TextField } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Credentials } from '../../../../../common/types'
import CustomIcon from '../../custom-icon'

interface RegisterFormValues {
  mail: string,
  password: string,
  passwordConfirm: string,
}

interface RegisterFormProps {
  setUserAlreadyExist: (nextValue: boolean) => void,
  onSubmit: (credentials: Credentials) => void,
  userAlreadyExist: boolean,
}

const RegisterForm = ({
  setUserAlreadyExist,
  userAlreadyExist,
  onSubmit,
}: RegisterFormProps): JSX.Element => {
  const [displayPasswordsError, setDisplayPasswordError] = useState<boolean>(false)
  const intl = useIntl()

  const { register, control, handleSubmit, formState: { errors }, } = useForm<RegisterFormValues>({
    defaultValues: {
      passwordConfirm: '',
      password: '',
      mail: '',
    },
  })

  const values = useWatch<RegisterFormValues>({control})

  useEffect(() => {
    if (displayPasswordsError) {
      setDisplayPasswordError(false)
    }
    if (userAlreadyExist) {
      setUserAlreadyExist(false)
    }
  }, [values])

  const onFormSubmit = (values: RegisterFormValues) => {
    if (values.password !== values.passwordConfirm) {
      setDisplayPasswordError(true)
    } else {
      onSubmit({
        mail: values.mail,
        password: values.password,
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={2} alignItems="center">
        <Stack spacing={1}>
          <TextField
            placeholder={intl.formatMessage({id: 'register_form_email'})}
            size="small"
            type="email"
            error={!!errors.mail || userAlreadyExist}
            inputProps={{ autoComplete: 'username' }}
            {...register('mail', { 
              required: true,
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
            })}
          />
          <TextField
            placeholder={intl.formatMessage({id: 'register_form_password'})}
            type="password"
            size="small"
            error={!!errors.password || displayPasswordsError}
            inputProps={{ autoComplete: 'new-password' }}
            {...register('password', { required: true, minLength: 5 })}
          />
          <TextField
            placeholder={intl.formatMessage({id: 'register_form_confirm_password'})}
            type="password"
            size="small"
            error={!!errors.passwordConfirm || displayPasswordsError}
            inputProps={{ autoComplete: 'new-password' }}
            {...register('passwordConfirm', { required: true, minLength: 5 })}
          />
        </Stack>
        
        {
          displayPasswordsError && (
            <p className="text_error">{intl.formatMessage({id: 'register_form_passwords_different'})}</p>
          )
        }

        {
          userAlreadyExist && (
            <p className="text_error">{intl.formatMessage({id: 'register_form_email_already_used'})}</p>
          )
        }
        
         <Button 
            endIcon={(<CustomIcon name="keyboard_arrow_right" />)}
            variant="contained"
            color="primary"
            type="submit"
          >
            {intl.formatMessage({id: 'register_form_submit'})}
          </Button>
      </Stack>
    </form>
  )
}

export default RegisterForm