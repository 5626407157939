import React, { useEffect } from 'react'
import { Button, Stack, TextField } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface UserPseudoFormValues {
  pseudo: string,
}

interface UserPseudoFormProps {
  onSubmit: (values: UserPseudoFormValues) => Promise<void>,
  setIsPseudoUnavailable: (nextValue: boolean) => void,
  isPseudoUnavailable: boolean,
  pseudo?: string,
}

const UserPseudoForm = ({
  setIsPseudoUnavailable,
  isPseudoUnavailable,
  pseudo = '',
  onSubmit,
}: UserPseudoFormProps): JSX.Element => {
  const { register, handleSubmit, control, formState } = useForm<UserPseudoFormValues>({ 
    defaultValues: { pseudo },
  })
  const values = useWatch<UserPseudoFormValues>({ control })
  const intl = useIntl()

  useEffect(() => {
    if (isPseudoUnavailable) {
      setIsPseudoUnavailable(false)
    }
  }, [values])

  const canSubmit = !!values.pseudo && values.pseudo.length >= 4

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={1} alignItems="center">
        <TextField
          {...register('pseudo', { required: false, minLength: 4 })}
          placeholder={intl.formatMessage({id: 'pseudo_form_pseudo'})}
          size="small"
        />

        {
          formState.errors.pseudo && formState.errors.pseudo.type === 'minLength' && (
            <p className="text_error">{intl.formatMessage({id: 'pseudo_form_min_length'})}</p>
          )
        }

        {
          isPseudoUnavailable && (
            <p className="text_error">{intl.formatMessage({id: 'pseudo_form_unavailable'})}</p>
          )
        }

        <Button 
          disabled={!canSubmit}
          variant="contained" 
          color="primary" 
          type="submit" 
        >
          {intl.formatMessage({id: 'save'})}
        </Button>
      </Stack>
    </form>
  )
}

export default UserPseudoForm
