import React, { useContext, useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { Button, Stack } from '@mui/material'
import { useIntl } from 'react-intl'
import { sortBy } from 'lodash'

import { FeaturedSubject, SubjectPublicView } from '../../../../common/types'
import { DeviceTypesEnum, useResponsive } from '../../hooks/responsive'
import FeaturedSubjectItem from './featured-subject-item'
import { SubjectsContext } from '../../contexts'
import Title from '../title'

import './styles.scss'

const FeaturedSubjects = (): JSX.Element | null => {
  const { loadFeaturedSubjects, featuredSubjects } = useContext(SubjectsContext)
  const responsive = useResponsive()
  const intl = useIntl()

  const [displayAll, setDisplayAll] = useState<boolean>(false)

  useEffect(() => {
    if (!featuredSubjects.length) {
      loadFeaturedSubjects()
    }
  }, [])

  const isMobileDevice: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)
  const isMediumDevice: boolean = responsive.isMediumDevice()

  if (!featuredSubjects.length) return (
    <Stack 
      alignItems="center" 
      className="center" 
      textAlign="center"
      sx={{ width: isMobileDevice ? 'calc(100vw - 40px)' : 'auto' }}
    >
      {
        isMobileDevice ? (
          <h2> {intl.formatMessage({id: 'brower_start_search'})} </h2>
        ) : (
          <h1> {intl.formatMessage({id: 'brower_start_search'})} </h1>
        )
      }
          
      <p>{intl.formatMessage({id: 'brower_start_search_1'})}</p>
      <p>{intl.formatMessage({id: 'brower_start_search_2'})}</p>
      <p>{intl.formatMessage({id: 'brower_start_search_3'})}</p>
    </Stack>
  )

  return (
    <Stack spacing={1} className="featured_subjects_container">
      <Title title="Sujets à la une" level={2} />
      
      <Stack spacing={2}>
        <Grid container spacing={2} className="grid_container_spacing_1">
          {
            sortBy(featuredSubjects, 'order').map((featuredSubject: FeaturedSubject, index: number) => {

              if (!displayAll) {
                if (isMobileDevice && index >= 3) return null
                if (isMediumDevice && index >= 3) return null
                if (index >= 4) return null
              }

              const subjectPublicView: SubjectPublicView = featuredSubject.subject as SubjectPublicView

              return (
                <FeaturedSubjectItem 
                  subjectPublicView={subjectPublicView}
                  featuredSubject={featuredSubject}
                  key={`featured_subject_${index}`}
                />
              )
            })
          }
        </Grid>
        
        <Stack direction="row" justifyContent={isMobileDevice ? 'center' : 'flex-end'}>
          <Button 
            onClick={() => setDisplayAll(!displayAll)}
            variant="outlined"
            color="secondary"
          >
            { displayAll ? 'Voir moins' : 'Voir plus' }
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default FeaturedSubjects