import React, { useContext, useEffect, useState } from 'react'
import { Dialog, DialogTitle, Stack, IconButton, DialogContent, DialogActions, Button } from '@mui/material'
import { useIntl } from 'react-intl'

import { CategoriesContext } from '../../contexts'
import SelectCategories from './select-categories'
import CustomIcon from '../custom-icon'

interface SelectCategoriesModalProps {
  onChange: (categoriesIds: string[]) => void,
  onClose: () => void,
  open: boolean,
  value: string[],
}

const SelectCategoriesModal = ({
  onChange,
  onClose,
  open,
  value,
}: SelectCategoriesModalProps): JSX.Element => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>(value)

  const { updateCategoriesSelection } = useContext(CategoriesContext)
  const intl = useIntl()

  useEffect(() => {
    setSelectedCategories(value || [])
  }, [value])


  const onSelectCategory = (categoryId: string) => {
    setSelectedCategories(updateCategoriesSelection(selectedCategories, categoryId))
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {intl.formatMessage({id: 'select_categories'})}
          <IconButton size="small" onClick={onClose}>
            <CustomIcon name="clear" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <SelectCategories 
          selectedCategories={selectedCategories}
          onSelectCategory={onSelectCategory}
        />
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button variant="text" color="secondary" onClick={onClose}>
            {intl.formatMessage({id: 'cancel'})}
          </Button>
          <Button
            onClick={() => onChange(selectedCategories)}
            variant="outlined"
            color="secondary"
          >
            {intl.formatMessage({id: 'confirm'})}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default SelectCategoriesModal
