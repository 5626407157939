import React, { createContext, useState } from 'react'

interface MobilesMenuContextType {
  setIsMobilesMenuOpen: (nextValue: boolean) => void,
  toggleMobilesMenu: () => void,
  isMobilesMenuOpen: boolean,
}

const MobilesMenuContext = createContext<MobilesMenuContextType>({
  setIsMobilesMenuOpen: () => {},
  toggleMobilesMenu: () => {},
  isMobilesMenuOpen: false,
})

const MobilesMenuContextProvider = ({ children }: { children: JSX.Element }) => {
  const [isMobilesMenuOpen, setIsMobilesMenuOpen] = useState<boolean>(true)
  
  const toggleMobilesMenu = (): void => {
    setIsMobilesMenuOpen(!isMobilesMenuOpen)
  }

  return (
    <MobilesMenuContext.Provider
      value={{ 
        setIsMobilesMenuOpen,
        isMobilesMenuOpen,
        toggleMobilesMenu,
      }}
    >
      { children }
    </MobilesMenuContext.Provider>
  )
}

export { 
  MobilesMenuContextProvider, 
  MobilesMenuContext, 
}

