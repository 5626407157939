import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { AcquisitionStatusEnum, Question, QuestionSubscription, SubjectSubscription } from '../../../../../common/types'
import { displayInfoToast, displaySuccessToast, displayWarningToast } from '../../../../common/utils/toast'
import { DeviceTypesEnum, useResponsive } from '../../../../common/hooks/responsive'
import QuestionStudyDisplay from '../../components/question-study-display'
import { useRequest } from '../../../../common/hooks/request'
import { LoaderContext } from '../../../../common/contexts'
import Title from '../../../../common/components/title'

import './styles.scss'

const StudyAllPage = (): JSX.Element | null => {
  const [currentQuestionSubscription, setCurrentQuestionSubscription] = useState<QuestionSubscription | null>(null)
  const [currentSubjectSubscription, setCurrentSubjectSubscription] = useState<SubjectSubscription | null>(null)
  
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { fetchData, postData } = useRequest()
  const responsive = useResponsive()
  const history = useHistory()
  const intl = useIntl()

  useEffect(() => {
    loadNextQuestionSubscription()
  }, [])

  const loadNextQuestionSubscription = async (): Promise<void> => {
    displayLoader()
    const response = await fetchData('/study/next-question')
    hideLoader()

    if (response.questionSubscription) {
      setCurrentQuestionSubscription(response.questionSubscription)
      setCurrentSubjectSubscription(response.subjectSubscription)
    } else {
      displayInfoToast(intl.formatMessage({id: 'study_all_no_more_questions'}))
      history.push('/subject-subscriptions')
    }
  }

  const onSubmitQuestionProgress = async (progress: AcquisitionStatusEnum): Promise<void> => {
    if (currentQuestionSubscription) {
      displayLoader()
      const response = await postData('/study/all/question-done', {
        questionSubscriptionId: currentQuestionSubscription?._id,
        progress,
      })
      hideLoader()
  
      if (response.nextQuestionSubscription) {
        setCurrentQuestionSubscription(response.nextQuestionSubscription)
        setCurrentSubjectSubscription(response.nextSubjectSubscription)
      } else {
        displayInfoToast(intl.formatMessage({id: 'study_all_no_more_questions'}))
        history.push('/subject-subscriptions')
      }
    }
  }

  const updateIsAcquired = async (
    questionSubscriptionId: string, 
    isAcquired: boolean,
  ): Promise<void> => {
    displayLoader()
    const response = await postData('/question-subscription/update-is-acquired', {
      questionSubscriptionId,
      isAcquired,
    })

    if (response.success) {
      loadNextQuestionSubscription()

      if (isAcquired) {
        displaySuccessToast(intl.formatMessage({id: 'question_marked_acquired'}))
      } else {
        displaySuccessToast(intl.formatMessage({id: 'question_marked_unacquired'}))
      }
    } else {
      displayWarningToast(intl.formatMessage({id: 'question_update_error'}))
      hideLoader()
    }
  }

  if (!currentSubjectSubscription || !currentQuestionSubscription) return null

  const question: Question | undefined = currentQuestionSubscription?.question as Question
  const isMobile = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)

  return (
    <Stack spacing={2} className="page_content study_all_page">

      {
        !isMobile && (
          <Title title={intl.formatMessage({id: 'study_all_title'})} />
        )
      }

      {
        (!!currentQuestionSubscription && !!question) && (
          <QuestionStudyDisplay 
            onSubmitQuestionProgress={onSubmitQuestionProgress}
            questionSubscription={currentQuestionSubscription}
            subjectSubscription={currentSubjectSubscription}
            onQuestionDeleted={loadNextQuestionSubscription}
            updateIsAcquired={updateIsAcquired}
          />
        )
      }
    </Stack>
  )
}

export default StudyAllPage
