import React from 'react'
import { Route, Switch } from 'react-router-dom'

import SubjectSubscriptionPageRouter from '../pages/subject-subscription/router'
import SubjectSubscriptionsPage from '../pages/subject-subscriptions'
import ForgotPasswordPage from '../pages/forgot-password'
import ResetPasswordPage from '../pages/reset-password'
import CompleteProfile from '../pages/complete-profile'
import CreateSubjectPage from '../pages/create-subject'
import PrivacyPolicyPage from '../pages/privacy-policy'
import StudyPageRouter from '../pages/study/router'
import LegalInfosPage from '../pages/legal-infos'
import SubjectPage from '../pages/subject/router'
import VerifyEmail from '../pages/verify-email'
import RegisterPage from '../pages/register'
import SettingsPage from '../pages/settings'
import Wrapper from './components/wrapper'
import BrowserPage from '../pages/browser'
import NotFound from '../pages/not-found'
import InvitePage from '../pages/invite'
import LoginPage from '../pages/login'
import TermsPage from '../pages/terms'
import HomePage from '../pages/home'
import { checkUser } from './hoc'

const Router = () => {
  return (
    <Wrapper>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/register" component={RegisterPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password/:verificationCode" component={ResetPasswordPage} />
        <Route path="/subject" component={SubjectPage} />
        <Route exact path="/invite/:inviteId" component={InvitePage} />

        <Route exact path="/terms" component={TermsPage} />
        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route exact path="/legal-infos" component={LegalInfosPage} />
        <Route exact path="/browse" component={BrowserPage} />

        <Route path="/subject-subscription/:subjectSubscriptionId" component={props => checkUser(SubjectSubscriptionPageRouter, props)} />
        <Route exact path="/subject-subscriptions" component={props => checkUser(SubjectSubscriptionsPage, props)} />
        <Route exact path="/verify-email/:verificationCode" component={props => checkUser(VerifyEmail, props)} />
        <Route exact path="/complete-profile" component={props => checkUser(CompleteProfile, props)} />
        <Route exact path="/create-subject" component={props => checkUser(CreateSubjectPage, props)} />
        <Route path="/settings" component={props => checkUser(SettingsPage, props)} />
        <Route path="/study" component={props => checkUser(StudyPageRouter, props)} />

        <Route path="*" component={NotFound} />
      </Switch>
    </Wrapper>
  )
}

export default Router
