import React, { useState, useEffect } from 'react'
import { Button, Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { QcmQuestionContent, QcmResponse, Question } from '../../../../../common/types'
import ImageDisplay from '../../../../common/components/image-display'
import { useResponsive } from '../../../../common/hooks/responsive'
import CustomIcon from '../../../../common/components/custom-icon'
import ExplanationDialog from '../explanation-dialog'
import { palette } from '../../../../common/theme'

import './styles.scss'

interface QcmQuestionPanelProps {
  question: Question,
}

const QcmQuestionPanel = ({
  question,
}: QcmQuestionPanelProps): JSX.Element => {
  const [displayExplanation, setDisplayExplanation] = useState<boolean>(false)
  const [selectedResponses, setSelectedResponses] = useState<string[]>([])
  const [isValidated, setIsValidated] = useState<boolean>(false)
  
  const responsive = useResponsive()
  const intl = useIntl()

  useEffect(() => {
    if (isValidated) {
      setIsValidated(false)
    }
  }, [question])

  const onResponseClick = (response: string): void => {
    if (selectedResponses.includes(response)) {
      setSelectedResponses(selectedResponses.filter((selectedResponse: string) => selectedResponse !== response))
    } else {
      setSelectedResponses([
        ...selectedResponses,
        response,
      ])
    }
  }

  const questionContent: QcmQuestionContent = question.content as QcmQuestionContent
  const isMediumDevice: boolean = responsive.isMediumDevice()
  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <Stack spacing={2} alignItems="center" className="question_container">
      <div className="question_content qcm_question_container panel">
        <Stack alignItems="center" spacing={isSmallDevice ? 2 : isMediumDevice ? 3 : 4}>
          <div className="question_type_container">
            <span>{intl.formatMessage({id: `question_type_${question.type}`})}</span>
          </div>

          {
            questionContent.images && (
              <ImageDisplay images={questionContent.images as string[]} />
            )
          }

          <h2>{questionContent.question}</h2>

          <Stack spacing={1}>
            {
              questionContent.responses.map((qcmResponse: QcmResponse, index: number) => {
                const isSelected: boolean = selectedResponses.includes(qcmResponse.response)

                const buttonColor = isValidated ? (
                  qcmResponse.isCorrect ? 'success' 
                  : isSelected ? 'warning' 
                  : 'secondary'
                ) : (
                  isSelected ? 'info' : 'secondary'
                )

                return (
                  <Stack 
                    className="qcm_response_container"
                    key={`qcm_response_${index}`} 
                    justifyContent="center"
                    direction="row" 
                    spacing={1}
                  >
                    <Button 
                      onClick={() => !isValidated && onResponseClick(qcmResponse.response)}
                      variant={(isSelected || isValidated && qcmResponse.isCorrect) ? 'contained' : 'outlined'}
                      className="qcm_response_button"
                      sx={{ fontWeight: 'bold' }}
                      color={buttonColor}
                    >
                      {qcmResponse.response}
                    </Button>

                    {
                      (isValidated && isSelected) && (
                        <CustomIcon 
                          color={qcmResponse.isCorrect ? palette.success.light : palette.warning.main}
                          name={qcmResponse.isCorrect ? 'check_circle' : 'cancel'} 
                          className="qcm_response_value_icon"
                        />                        
                      )
                    }
                  </Stack>
                )
              })
            }
          </Stack>
          
          {
            !isValidated && (
              <Button 
                onClick={() => setIsValidated(true)}
                sx={{ fontWeight: 'bold' }}
                variant="contained" 
                color="primary" 
              >
                {intl.formatMessage({id: 'display_answer'})}
              </Button>
            )
          }

          {
            isValidated && questionContent.explanation && (
              <Button 
                onClick={() => setDisplayExplanation(true)}
                variant="outlined" 
                color="secondary"
              >
                {intl.formatMessage({id: 'display_explanation'})}
              </Button>
            )
          }

          <ExplanationDialog 
            onClose={() => setDisplayExplanation(false)}
            explanation={questionContent.explanation}
            open={displayExplanation}
          />
        </Stack>
      </div>
    </Stack>
  )
}

export default QcmQuestionPanel