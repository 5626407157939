import React, { createContext, useContext, useState } from 'react'

import { Invite, SubjectSubscriptionUserRoleEnum } from '../../../common/types'
import { displaySuccessToast, displayWarningToast } from '../utils/toast'
import { useRequest } from '../hooks/request'
import { LoaderContext } from './loader'

interface InvitesContextType {
  loadInvite: (inviteId: string) => Promise<boolean>,
  loadInvites: (subjectId: string) => Promise<void>,
  invite: Invite | null,
  invites: Invite[],
  sendInvite: (
    role: SubjectSubscriptionUserRoleEnum, 
    subjectId: string, 
    userId?: string,
    email?: string, 
  ) => Promise<void>,
}

const InvitesContext = createContext<InvitesContextType>({
  loadInvite: async () => false,
  loadInvites: async () => {},
  sendInvite: async () => {},
  invite: null,
  invites: [],
})

const InvitesContextProvider = ({ children }: { children: JSX.Element }) => {
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const [invite, setInvite] = useState<Invite | null>(null)
  const [invites, setInvites] = useState<Invite[]>([])
  const { fetchData, postData } = useRequest()

  const loadInvites = async (subjectSubscriptionId: string): Promise<void> => {
    displayLoader()
    const response = await fetchData('/invite/by-subject-subscription', { subjectSubscriptionId })
    if (response.invites) {
      setInvites(response.invites)
    }
    hideLoader()
  }

  const loadInvite = async (inviteId: string): Promise<boolean> => {
    displayLoader()
    const response = await fetchData('/invite', { inviteId })
    hideLoader()
    if (response.invite) {
      setInvite(response.invite)
      return true
    } else {
      displayWarningToast('Invitation inconnue')
      return false
    }
  }

  const sendInvite = async (
    role: SubjectSubscriptionUserRoleEnum, 
    subjectSubscriptionId: string, 
    userId?: string,
    email?: string, 
  ): Promise<void> => {
    displayLoader()
    const response = await postData('/invite', {
      subjectSubscriptionId,
      userId,
      email,
      role,
    })

    if (response.success) {
      await loadInvites(subjectSubscriptionId)
      displaySuccessToast('L\'invitation a bien été envoyée')
    } else {
      displayWarningToast('Une erreur est survenue lors de l\'envoi de l\'invitation')
    }

    hideLoader()
  }

  return (
    <InvitesContext.Provider
      value={{
        loadInvites,
        loadInvite,
        sendInvite,
        invites,
        invite,
      }}
    >
      { children }
    </InvitesContext.Provider>
  )
}

export { InvitesContext, InvitesContextProvider }

