import React from 'react';
import Icon from '@mui/material/Icon';

interface ICustomIconProps {
  className?: string,
  outlined?: boolean,
  small?: boolean,
  color?: string,
  size?: number,
  name: string,
  style?: any,
}

const CustomIcon = (props: ICustomIconProps) => {
  const { className, name, color, style, outlined, size, small } = props
  return (
    <Icon
      className={`icon ${className} ${outlined ? 'material-icons-outlined' : 'material-icons'}`}
      color="secondary"
      style={{
        ...style,
        textTransform: 'none',
        color: color || (style && style.color),
        fontSize: size || (small ? 20 : undefined),
      }}
    >
      {name}
    </Icon>
  )
}

export default CustomIcon;
