import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { QuestionTypesFormPathsEnum, QuestionTypesIconsEnum } from '../../../../common/types'
import { useLoadSubjectSubscription } from '../../../../common/hooks/subjects-subscription'
import GoBackButton from '../../../../common/components/go-back-button'
import { displayWarningToast } from '../../../../common/utils/toast'
import { useResponsive } from '../../../../common/hooks/responsive'
import Breadcrumbs from '../../../../common/components/breadcrumbs'
import WideButton from '../../../../common/components/wide-button'
import { QuestionTypesEnum } from '../../../../../common/types'
import useRouterUtils from '../../../../common/hooks/router'
import Title from '../../../../common/components/title'

import './styles.scss'

const CreateQuestionPage = (): JSX.Element | null => {
  const { subjectSubscription, subject, canUserEditSubject } = useLoadSubjectSubscription()
  const { goBackOrRedirect } = useRouterUtils()
  const responsive = useResponsive()
  const intl = useIntl()

  useEffect(() => {
    if (subjectSubscription && !canUserEditSubject) {
      displayWarningToast(intl.formatMessage({id: 'subject_edit_unauthorized'}))
      goBackOrRedirect(`/subject-subscription/${subjectSubscription._id}`)
    }
  }, [subjectSubscription, canUserEditSubject])

  if (!subjectSubscription || !subject) return null

  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <div className="page_content create_question_page">
      <Stack spacing={2}>
        <Breadcrumbs 
          pages={[
            {
              label: intl.formatMessage({id: 'page_subject_subscriptions'}),
              path: '/subject-subscriptions',
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}`,
              label: subject.label,
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}/create-question`,
              label: intl.formatMessage({id: 'subject_create_question_short'}),
            }
          ]}
        />

        <div>
          <Title title={isSmallDevice ? intl.formatMessage({id: 'subject_create_question_short'}) : intl.formatMessage({id: 'subject_create_question'})} />
          <h3>{intl.formatMessage({id: 'subject_choose_question_type'})}</h3>
        </div>

        {
          Object.values(QuestionTypesEnum).map((questionType: QuestionTypesEnum) => {
            return (
              <WideButton 
                link={`/subject-subscription/${subjectSubscription._id}/create-question/${QuestionTypesFormPathsEnum[questionType]}`}
                text={intl.formatMessage({id: `question_type_description_${questionType}`})}
                title={intl.formatMessage({id: `question_type_label_${questionType}`})}
                key={`question_type_option_${questionType}`}
                icon={QuestionTypesIconsEnum[questionType]}
              />
            )
          })
        }

        <Stack direction="row" justifyContent="flex-start">
          <GoBackButton 
            defaultUrl={`/subject-subscription/${subjectSubscription._id}`} 
            variant="outlined" 
            withIcon 
          />
        </Stack>
      </Stack>
    </div>
  )
}

export default CreateQuestionPage