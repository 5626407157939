import React, { useContext, useState, useEffect, useMemo } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material'
import { useIntl } from 'react-intl'
import { max } from 'lodash'

import { SpacedRepetitionSuccessRatios, SpacedRepetitionFailRatios } from '../../../../common/constants'
import { displaySuccessToast, displayWarningToast } from '../../../common/utils/toast'
import { DeviceTypesEnum, useResponsive } from '../../../common/hooks/responsive'
import GoBackButton from '../../../common/components/go-back-button'
import CustomIcon from '../../../common/components/custom-icon'
import { UserContext } from '../../../common/contexts/user'
import { useRequest } from '../../../common/hooks/request'
import { LoaderContext } from '../../../common/contexts'
import Title from '../../../common/components/title'

const SpacedRepetitionSettings = (): JSX.Element => {
  const { displayLoader } = useContext(LoaderContext)
  const { user, getUser } = useContext(UserContext)
  const responsive = useResponsive()
  const { postData } = useRequest()
  const intl = useIntl()
  
  const [openHowItWorksModal, setOpenHowItWorksModal] = useState<boolean>(false)
  const [exampleResponses, setExampleResponses] = useState<boolean[]>([])
  const [showExample, setShowExample] = useState<boolean>(false)
  const [successRatio, setSuccessRatio] = useState<number>(SpacedRepetitionSuccessRatios.MEDIUM)
  const [failRatio, setFailRatio] = useState<number>(SpacedRepetitionFailRatios.HIGH)

  useEffect(() => {
    onReset()
  }, [user])

  useEffect(() => {
    setExampleResponses([])
  }, [showExample])

  useEffect(() => {
    if (exampleResponses.length) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [exampleResponses])

  const onSubmit = async (): Promise<void> => {
    displayLoader()
    const response = await postData('/user/update-spaced-repetition-settings', {
      successRatio,
      failRatio,
    })

    if (response.success) {
      displaySuccessToast(intl.formatMessage({id: 'settings_update_success'}))
    } else {
      displayWarningToast(intl.formatMessage({id: 'error_occured'}))
    }

    getUser()
  }

  const onReset = (): void => {
    setSuccessRatio(typeof user?.spacedRepetitionSuccessRatio === 'number' ? user.spacedRepetitionSuccessRatio : SpacedRepetitionSuccessRatios.MEDIUM)
    setFailRatio(typeof user?.spacedRepetitionFailRatio === 'number' ? user.spacedRepetitionFailRatio : SpacedRepetitionFailRatios.MEDIUM)
  }

  const canSubmit: boolean = useMemo(() => {
    return Boolean(!!user && (
      successRatio !== user.spacedRepetitionSuccessRatio ||
      failRatio !== user.spacedRepetitionFailRatio
    ))
  }, [successRatio, failRatio, user])

  const getNextSpacing = (currentSpacing: number, responseSuccess: boolean): number => {
    const nextSpacing = max([Math.floor(currentSpacing * (responseSuccess ? successRatio : failRatio)), 1])

    if (responseSuccess && nextSpacing < 24) {
      return 24
    } else if (!responseSuccess && nextSpacing < 24) {
      return 1
    } else {
      return nextSpacing
    }
  }

  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isMobile: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)

  return (
    <Stack flex={1} spacing={1} className="page_content setting_page_content">
      <Title 
        title={intl.formatMessage({id: 'page_settings_spaced_repetition'})}
        rightButtons={isMobile ? [] : [{
          label: intl.formatMessage({id: 'how_it_works'}),
          onClick: () => setOpenHowItWorksModal(true),
          endIconOutlined: true,
          variant: 'outlined',
          color: 'secondary',
          endIcon: 'help',
          bold: false,
        }]}
      />

      <Dialog
        onClose={() => setOpenHowItWorksModal(false)}
        open={openHowItWorksModal}
      >
        <DialogTitle>
          {intl.formatMessage({id: 'how_it_works'})}
        </DialogTitle>
        <DialogContent>
            <p> {intl.formatMessage({id: 'spaced_repetition_settings_how_1'})} </p> 

            <br/>
            
            <b> {intl.formatMessage({id: 'spaced_repetition_settings_how_2'})} </b>
            <ul>
              <li>
                {intl.formatMessage({id: 'spaced_repetition_settings_how_3'}, {ratio: SpacedRepetitionSuccessRatios.LOW})}
              </li>
              <li>
                {intl.formatMessage({id: 'spaced_repetition_settings_how_4'}, {ratio: SpacedRepetitionSuccessRatios.MEDIUM})}
              </li>
              <li>
                {intl.formatMessage({id: 'spaced_repetition_settings_how_5'}, {ratio: SpacedRepetitionSuccessRatios.HIGH})}
              </li>
            </ul>

            <b>{intl.formatMessage({id: 'spaced_repetition_settings_how_6'})}</b>
            <ul>
              <li>
                {intl.formatMessage({id: 'spaced_repetition_settings_how_7'}, {ratio: SpacedRepetitionFailRatios.LOW})}
              </li>
              <li>
                {intl.formatMessage({id: 'spaced_repetition_settings_how_8'}, {ratio: SpacedRepetitionFailRatios.MEDIUM})}
              </li>
              <li>
                {intl.formatMessage({id: 'spaced_repetition_settings_how_9'}, {ratio: SpacedRepetitionFailRatios.HIGH})}
              </li>
            </ul>

            <p>{intl.formatMessage({id: 'spaced_repetition_settings_how_10'})}</p>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenHowItWorksModal(false)}
            variant="outlined" 
            color="secondary"
          >
            {intl.formatMessage({id: 'close'})}
          </Button>
        </DialogActions>
      </Dialog>

      <Stack spacing={2}>
        <Stack 
          direction={isMobile ? 'column' : 'row'} 
          justifyContent="flex-start"
          spacing={isMobile ? 2 : 4} 
          alignItems="center" 
          className="panel" 
        >
          <Stack spacing={1}>
            <b>{intl.formatMessage({id: 'spaced_repetition_settings_how_2'})}</b>
            
            <RadioGroup 
              onChange={(e, nextValue: any) => setSuccessRatio(nextValue)}
              value={successRatio}
            >
              <FormControlLabel 
                label={`${intl.formatMessage({id: 'spaced_repetition_settings_how_11'})} ${isDesktop ? intl.formatMessage({id: 'spaced_repetition_settings_how_12'}, {ratio: SpacedRepetitionSuccessRatios.LOW}) : ''}`}
                value={SpacedRepetitionSuccessRatios.LOW} 
                control={(
                  <Radio color="primary" />
                )} 
              />
              <FormControlLabel 
                label={`${intl.formatMessage({id: 'spaced_repetition_settings_how_13'})} ${isDesktop ? intl.formatMessage({id: 'spaced_repetition_settings_how_12'}, {ratio: SpacedRepetitionSuccessRatios.MEDIUM}) : ''}`}
                value={SpacedRepetitionSuccessRatios.MEDIUM} 
                control={(
                  <Radio color="primary" />
                )} 
              />
              <FormControlLabel 
                label={`${intl.formatMessage({id: 'spaced_repetition_settings_how_14'})} ${isDesktop ? intl.formatMessage({id: 'spaced_repetition_settings_how_12'}, {ratio: SpacedRepetitionSuccessRatios.HIGH}) : ''}`}
                value={SpacedRepetitionSuccessRatios.HIGH} 
                control={(
                  <Radio color="primary" />
                )} 
              />
            </RadioGroup> 
          </Stack>

          <Stack spacing={1}>
            <b>{intl.formatMessage({id: 'spaced_repetition_settings_how_6'})}</b>
            
            <RadioGroup 
              onChange={(e, nextValue: any) => setFailRatio(nextValue)}
              value={failRatio}
            >
              <FormControlLabel 
                label={`${intl.formatMessage({id: 'spaced_repetition_settings_how_15'})} ${isDesktop ? intl.formatMessage({id: 'spaced_repetition_settings_how_12'},{ratio: SpacedRepetitionFailRatios.LOW}) : ''}`}
                value={SpacedRepetitionFailRatios.LOW} 
                control={(
                  <Radio color="primary" />
                )} 
              />
              <FormControlLabel 
                label={`${intl.formatMessage({id: 'spaced_repetition_settings_how_16'})} ${isDesktop ? intl.formatMessage({id: 'spaced_repetition_settings_how_12'},{ratio: SpacedRepetitionFailRatios.MEDIUM}) : ''}`}
                value={SpacedRepetitionFailRatios.MEDIUM} 
                control={(
                  <Radio color="primary" />
                )} 
              />
              <FormControlLabel 
                label={`${intl.formatMessage({id: 'spaced_repetition_settings_how_17'})} ${isDesktop ? intl.formatMessage({id: 'spaced_repetition_settings_how_12'},{ratio: SpacedRepetitionFailRatios.HIGH}) : ''}`} 
                value={SpacedRepetitionFailRatios.HIGH} 
                control={(
                  <Radio color="primary" />
                )} 
              />
            </RadioGroup> 
          </Stack>
        </Stack>

        {/* <Stack direction="row" alignItems="center" spacing={1} className="panel">
          <b style={{ lineHeight: '40px' }}>Concidérer qu'une question est acquise au bout de 
            <TextField
              defaultValue={4}
              sx={{ mx: 1 }}
              size="small"
              select
            >
              <MenuItem value={3}>
                3
              </MenuItem>
              <MenuItem value={4}>
                4
              </MenuItem>
              <MenuItem value={5}>
                5
              </MenuItem>
              <MenuItem value={6}>
                6
              </MenuItem>
              <MenuItem value={7}>
                7
              </MenuItem>
              <MenuItem value={8}>
                8
              </MenuItem>
              <MenuItem value={9}>
                9
              </MenuItem>
              <MenuItem value={10}>
                10
              </MenuItem>
            </TextField>
            bonnes réponses consécutives
          </b>
        </Stack> */}

        {
            isMobile && (
              <Stack direction="column" alignItems="center" spacing={1} className="panel">
                <Button
                  onClick={() => setOpenHowItWorksModal(true)}
                  variant="outlined"
                  color="secondary"
                >
                  {intl.formatMessage({id: 'how_it_works'})}
                </Button>
                <Button 
                  endIcon={(<CustomIcon name={showExample ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} outlined />)}
                  onClick={() => setShowExample(!showExample)} 
                  variant="outlined"
                  color="secondary" 
                >
                  {intl.formatMessage({id: showExample ? 'hide_example' : 'show_example'})}
                </Button>    
              </Stack>
            )
          }

        {
          showExample && (
            <Stack className="panel" spacing={isMobile ? 2 : 1}>
              <p> <b>{`${intl.formatMessage({id: 'example'})} :`}</b> {intl.formatMessage({id: 'spaced_repetition_settings_how_18'})}</p>

              <Stack spacing={1} alignItems="center">
                <Button 
                  color="secondary"
                  variant="outlined"
                  sx={{ 
                    pointerEvents: 'none',
                    border: '1px solid rgba(62, 75, 87, 0.2)'
                  }}
                >
                  {intl.formatMessage({id: 'spaced_repetition_settings_how_19'})}
                </Button>

                <CustomIcon name={isMobile ? 'south' : 'south'} />
                <p>{intl.formatMessage({id: 'spaced_repetition_settings_how_20'})}</p>
                <CustomIcon name={isMobile ? 'south' : 'south'} />
                
                {
                  exampleResponses.map((response: boolean, index: number) => {
                    let spacing = 1

                    for (let i = 0; i <= index; i++) {
                      spacing = getNextSpacing(spacing, exampleResponses[i])                 
                    }

                    return (
                      <Stack key={`example_item_${index}`} spacing={1} alignItems="center">
                        <Button 
                          color={response ? 'success' : 'error'}
                          variant="outlined"
                          sx={{ 
                            pointerEvents: 'none',
                            border: '1px solid rgba(62, 75, 87, 0.2)'
                          }}
                        >
                          { response ? intl.formatMessage({id: 'good_answer'}) : intl.formatMessage({id: 'wrong_answer'}) }
                        </Button>
                        <CustomIcon name={isMobile ? 'south' : 'south'} />
                        {
                          spacing < 24 ? (
                            <p>{intl.formatMessage({ id: "spaced_repetition_settings_how_21" }, { number: spacing })}</p>
                          ) : (
                            <p>{intl.formatMessage({ id: "spaced_repetition_settings_how_22" }, { number: Math.floor(spacing/24) })}</p>
                          )
                        }
                        <CustomIcon name={isMobile ? 'south' : 'south'} />
                      </Stack>      
                    )
                  })
                }
                
                <Stack spacing={1} alignItems="center">
                  <Stack direction="row" spacing={1}>
                    <Button 
                      onClick={() => setExampleResponses([...exampleResponses, true])}
                      variant="outlined"
                      color="success"
                    >
                      {intl.formatMessage({id: 'good_answer'})}
                    </Button>
                    
                    <Button 
                      onClick={() => setExampleResponses([...exampleResponses, false])}
                      variant="outlined"
                      color="error"
                    >
                      {intl.formatMessage({id: 'wrong_answer'})}
                    </Button>
                  </Stack>

                  <Button 
                    disabled={exampleResponses.length === 0}
                    onClick={() => setExampleResponses([])}
                    variant="outlined"
                    color="secondary"
                  >
                    {intl.formatMessage({id: 'reset'})}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          )
        }

        <Stack direction="row" justifyContent={isDesktop ? 'flex-end' : 'space-between'} alignItems="center">
          { !isDesktop && <GoBackButton defaultUrl="/settings" withIcon /> }

          <Stack direction="row" alignItems="center" spacing={1}>
            {
              !isMobile && (
                <Button onClick={() => setShowExample(!showExample)} color="secondary" variant="text">
                  {intl.formatMessage({id: showExample ? 'hide_example' : 'show_example'})}
                </Button> 
              )
            }

            <Button 
              disabled={!canSubmit}
              variant="outlined"
              onClick={onReset}
              color="secondary" 
            >
              {intl.formatMessage({id: 'cancel'})}
            </Button>
            <Button 
              disabled={!canSubmit}
              variant="contained"
              onClick={onSubmit}
              color="primary" 
            >
              {intl.formatMessage({id: 'save'})}
            </Button>
          </Stack>
        </Stack>

      </Stack>
    </Stack>
  )
}

export default SpacedRepetitionSettings
