import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { decomposeSubjectSubscription, getCanUserEditSubject } from '../../../../../common/utils/subject'
import { useLoadSubjectSubscription } from '../../../../common/hooks/subjects-subscription'
import { displaySuccessToast, displayWarningToast } from '../../../../common/utils/toast'
import Breadcrumbs from '../../../../common/components/breadcrumbs'
import { SubjectForm } from '../../../../common/components/forms'
import { UserContext } from '../../../../common/contexts/user'
import { useRequest } from '../../../../common/hooks/request'
import { LoaderContext } from '../../../../common/contexts'
import Title from '../../../../common/components/title'

import './styles.scss'

const UpdateSubjectPage = (): JSX.Element | null => {
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { subjectSubscription } = useLoadSubjectSubscription()
  const { user } = useContext(UserContext)
  const { postData } = useRequest()
  const history = useHistory()
  const intl = useIntl()

  useEffect(() => {
    if (!!subjectSubscription && !!user && !getCanUserEditSubject(subjectSubscription, user._id!)) {
      displayWarningToast(intl.formatMessage({id: 'subject_edit_unauthorized'}))
      history.push('/')
    }
  }, [user, subjectSubscription])
  
  const onSubmit = async (formData: FormData): Promise<void> => {
    displayLoader()
    const response = await postData('/subject/update', formData)
    hideLoader()
    
    if (response.updatedSubject) {
      displaySuccessToast(intl.formatMessage({id: 'subject_edit_success'}))
      history.goBack()
    } else { 
      // TODO : better error messages
      displayWarningToast(intl.formatMessage({id: 'subject_edit_fail'}))
    }
  }
  
  if (!subjectSubscription || !user) return null
  
  const { subject } = decomposeSubjectSubscription(subjectSubscription)

  return (
    <Stack className="page_content update_subject_page">
      <Stack spacing={2}>
        <Breadcrumbs 
          pages={[
            {
              label: intl.formatMessage({id: 'page_subject_subscriptions'}),
              path: '/subject-subscriptions',
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}`,
              label: subject.label,
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}/update-subject`,
              label: intl.formatMessage({id: 'page_subject_subscription_update'}),
            },
          ]}
        />
        <Title title={intl.formatMessage({id: 'subject_update_title'})} />
      </Stack>
      <SubjectForm onSubmit={onSubmit} subject={subject} />
    </Stack>
  )
}

export default UpdateSubjectPage