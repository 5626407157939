import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Stack } from '@mui/system'

import CustomIcon from '../custom-icon'

import './styles.scss'

interface BreadcrumbPage {
  label: string,
  path?: string,
}

interface BreadcrumbsProps {
  pages: BreadcrumbPage[],
}

const Breadcrumbs = ({
  pages: pagesProps,
}: BreadcrumbsProps): JSX.Element | null => {
  const { state } : { state : { breadcrumbsPages?: BreadcrumbPage[] } } = useLocation()

  const pages: BreadcrumbPage[] = state?.breadcrumbsPages || pagesProps

  return (
    <Stack direction="row" alignItems="center" spacing={1} className="breadcrumbs_container">
      {
        pages.map((page: BreadcrumbPage, index: number) => {

          if (index < pages.length - 1) {
            return (
              <Stack key={`breadcrumb_${index}`} direction="row" alignItems="center" spacing={1}>
                <Link to={page.path}>
                  <p className="link_inline wrapText">{page.label}</p>
                </Link>

                <CustomIcon name="chevron_right" />
              </Stack>
            )
          } else {
            return (
              <p key={`breadcrumb_${index}`} className="wrapText">{page.label}</p>
            )
          }
        })
      }
    </Stack>       
  )
}

export type { BreadcrumbPage }
export default Breadcrumbs