import React, { useContext } from 'react'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import SelectCategories from '../../../../common/components/categories/select-categories'
import { DeviceTypesEnum, useResponsive } from '../../../../common/hooks/responsive'
import { BrowserContext } from '../../../../common/contexts'

import './styles.scss'

const BrowserMenu = (): JSX.Element | null => {
  const { searchParameters, onSelectCategory } = useContext(BrowserContext)
  const responsive = useResponsive()
  const intl = useIntl()
  
  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const selectedCategories: string[] = searchParameters.categories || []

  if (!isDesktop) return null

  return (
    <Stack 
      className="page_menu_container browser_menu_container shadow_light" 
      alignItems="center" 
      spacing={2}
    >
      <Stack 
        className="browser_menu_content" 
        alignItems="center"
      >
        <h2>{intl.formatMessage({id: 'categories'})}</h2>

        <div className="categories_list_container">
          <SelectCategories 
            selectedCategories={selectedCategories}
            onSelectCategory={onSelectCategory}
          />
        </div>
      </Stack>
    </Stack>
  )
}

export default BrowserMenu
