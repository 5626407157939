import React from 'react'

import './styles.scss'

interface LoaderProps {}

const Loader = ({}: LoaderProps): JSX.Element => {
  return (
    <div className="loader_container">
      <div className="spinner spinner_center" />
    </div>
  )
}

export default Loader