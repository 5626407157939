import { matchPath } from 'react-router'

import { mainPages, Page } from '../constants/routes'

const getMainPage = (locationPathname: string): Page | undefined => mainPages.find((page: Page) => {
  return matchPath(locationPathname, { 
    path: page.path, 
    exact: false, 
  })
})

const getIsMenuedPage = (locationPathname: string): boolean => {
  const currentPage: Page | undefined = getMainPage(locationPathname)
  return Boolean(currentPage && (currentPage.pages || []).length > 0 || currentPage?.hasMenu)
}

const getPathWithParameters = (path: string, params: {
  subjectSubscriptionId?: string,
}): string => {
  return path.replace(':subjectSubscriptionId', params.subjectSubscriptionId || '')
}

const isPageSelected = (page: Page, locationPathname: string): boolean => {
  let isSelected = !!matchPath(locationPathname, { path: page.path, exact: true })
  if (!isSelected && page.matchPaths) {
    page.matchPaths.forEach((path: string) => {
      isSelected = isSelected || !!matchPath(locationPathname, { path, exact: false })
    })
  }
  return isSelected
}

export { 
  getPathWithParameters,
  getIsMenuedPage,
  isPageSelected,
  getMainPage, 
}
