import React from 'react'
import { Control, UseFormReset, useWatch } from 'react-hook-form'
import { Button, Stack } from '@mui/material'
import { useParams } from 'react-router'
import { useIntl } from 'react-intl'

import { compareTextQuestionContents, validateTextQuestionContent } from '../../../../../../common/utils/question'
import { DeviceTypesEnum, useResponsive } from '../../../../hooks/responsive'
import { TextQuestionContent } from '../../../../../../common/types'
import GoBackButton from '../../../go-back-button'

interface SubjectFormSubmitButtonsProps {
  onSubmitForm: (
    textQuestionContent: TextQuestionContent,
    addAnotherQuestionAfterSubmit?: boolean,
  ) => Promise<void>,
  control: Control<TextQuestionContent, any>,
  reset: UseFormReset<TextQuestionContent>,
  defaultValues: TextQuestionContent,
  isEdit: boolean,
}

const TextQuestionFormSubmitButtons = ({
  defaultValues,
  onSubmitForm,
  control,
  isEdit,
  reset,
}: SubjectFormSubmitButtonsProps): JSX.Element => {
  const { subjectSubscriptionId } : { subjectSubscriptionId: string } = useParams()
  const responsive = useResponsive()
  const intl = useIntl()
  
  const values = useWatch<TextQuestionContent>({control})

  const onSubmitAndAddAnotherQuestionButtonClick = () => {
    onSubmitForm(values as TextQuestionContent, true)
  }

  const isLargeOrMediumDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.SMALL)
  const hasChanges: boolean = !compareTextQuestionContents(values as TextQuestionContent, defaultValues)
  const canSubmit: boolean = validateTextQuestionContent(values as TextQuestionContent)
  const isSmallDevice: boolean = responsive.isSmallDevice()

  if (isEdit) {
    return (
      <Stack justifyContent="flex-end" mt={2} direction="row" spacing={1}>
        <GoBackButton defaultUrl={`/subject-subscription/${subjectSubscriptionId}`} />
        <Button 
          onClick={() => reset()} 
          disabled={!hasChanges}
          variant="outlined" 
          color="secondary" 
        >
          {intl.formatMessage({id: 'reset'})}
        </Button>
        <Button 
          disabled={!canSubmit || !hasChanges}
          variant="contained" 
          type="submit" 
        >
          {isSmallDevice ? intl.formatMessage({id: 'save'}) : intl.formatMessage({id: 'update_question'})}
        </Button>
      </Stack>
    )
  } else if (isLargeOrMediumDevice) {
    return (
      <Stack justifyContent="flex-end" mt={2} direction="row" spacing={1}>
        <GoBackButton defaultUrl={`/subject-subscription/${subjectSubscriptionId}`} />
        <Button 
          onClick={() => reset()} 
          disabled={!hasChanges}
          variant="outlined" 
          color="secondary" 
        >
          {intl.formatMessage({id: 'reset'})}
        </Button>
        <Button 
          onClick={onSubmitAndAddAnotherQuestionButtonClick}
          disabled={!canSubmit || !hasChanges}
          variant="outlined" 
        >
          {intl.formatMessage({id: 'question_form_save_and_create_another'})}
        </Button>
        <Button 
          disabled={!canSubmit || !hasChanges}
          variant="contained" 
          type="submit" 
        >
          {intl.formatMessage({id: 'question_form_create'})}
        </Button>
      </Stack>
    )
  } else {
    return (
      <Stack spacing={1}>  
        <Button 
          onClick={onSubmitAndAddAnotherQuestionButtonClick}
          disabled={!canSubmit || !hasChanges} 
          variant="outlined" 
          type="submit" 
        >
          {intl.formatMessage({id: 'question_form_save_and_create_another'})}
        </Button>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <GoBackButton defaultUrl={`/subject-subscription/${subjectSubscriptionId}`} />
          <Stack direction="row" spacing={1}>
            <Button 
              onClick={() => reset()} 
              disabled={!hasChanges}
              variant="outlined" 
              color="secondary" 
            >
              {intl.formatMessage({id: 'reset'})}
            </Button>
            <Button 
              disabled={!canSubmit || !hasChanges}
              variant="contained" 
              type="submit" 
            >
              {intl.formatMessage({id: 'save'})}
            </Button>
          </Stack>  
        </Stack>
      </Stack>
    )
  }
}

export default TextQuestionFormSubmitButtons