import React, { useContext, useState } from 'react'
import { Button, Menu, MenuItem, Stack } from '@mui/material'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import { getSubjectAcquisitionLevel } from '../../../../../common/utils/subject-subscription'
import { useLoadSubjectSubscription } from '../../../../common/hooks/subjects-subscription'
import { displaySuccessToast, displayWarningToast } from '../../../../common/utils/toast'
import CategoriesChips from '../../../../common/components/categories/categories-chips'
import { DeviceTypesEnum, useResponsive } from '../../../../common/hooks/responsive'
import { getSubjectShareLink } from '../../../../../common/utils/subject'
import SendInvitesModal from '../settings/components/send-invites-modal'
import ImageDisplay from '../../../../common/components/image-display'
import Breadcrumbs from '../../../../common/components/breadcrumbs'
import CustomIcon from '../../../../common/components/custom-icon'
import { useRequest } from '../../../../common/hooks/request'
import { LoaderContext } from '../../../../common/contexts'
import QuestionsList from './components/questions-list'
import Title from '../../../../common/components/title'
import InfoTile from './components/info-tile'

import './styles.scss'

const SubjectSubscriptionPage = (): JSX.Element | null => {
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [openSendInvitesModal, setOpenSendInvitesModal] = useState<boolean>(false)
  const { 
    loadSubjectSubscription, 
    subjectSubscription, 
    canUserEditSubject, 
    subject, 
  } = useLoadSubjectSubscription({
    loadSubjectSubscriptionOnMount: true,
  })
  const { subjectSubscriptionId } : { subjectSubscriptionId: string } = useParams()
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const responsive = useResponsive()
  const { postData } = useRequest()
  const intl = useIntl()
  
  const updateIsAcquired = async (
    questionSubscriptionId: string, 
    isAcquired: boolean,
  ): Promise<void> => {
    displayLoader()
    const response = await postData('/question-subscription/update-is-acquired', {
      questionSubscriptionId,
      isAcquired,
    })

    if (response.success) {
      await loadSubjectSubscription()

      if (isAcquired) {
        displaySuccessToast(intl.formatMessage({id: 'question_marked_acquired'}))
      } else {
        displaySuccessToast(intl.formatMessage({id: 'question_marked_unacquired'}))
      }
    } else {
      displayWarningToast(intl.formatMessage({id: 'question_update_error'}))
      hideLoader()
    }
  }

  const copyShareLink = (): void => {
    navigator.clipboard.writeText(getSubjectShareLink(subjectSubscription!))
    setOptionsMenuAnchorEl(null)
    displaySuccessToast(intl.formatMessage({id: 'subject_link_copied'}))
  }

  if (!subjectSubscription || !subject) return null

  const canStudy: boolean = ((subjectSubscription.questionSubscriptions || []).length > 0)
  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <div className="page_content subject_page_container">
      <Stack spacing={2}>
        <Breadcrumbs 
          pages={[
            {
              label: intl.formatMessage({id: 'page_subject_subscriptions'}),
              path: '/subject-subscriptions',
            },
            {
              path: `/subject-subscriptions/${subjectSubscription._id}`,
              label: subject.label,
            }
          ]}
        />

        <Title 
          title={`${subject.label}`} 
          rightContent={isSmallDevice ? undefined : (
            <Stack direction="row" spacing={1}>

              {
                isLargeDevice && (canUserEditSubject || subject.enableShareSubject || subject.enableShareLink) && (
                  <Button 
                    onClick={e => setOptionsMenuAnchorEl(e.currentTarget)}
                    className="bg_light" 
                    variant="outlined"
                    color="secondary" 
                  >
                    <Stack justifyContent="space-between" direction="row" width="100%" alignItems="center" spacing={1}>
                      <p>{intl.formatMessage({id: 'share'})}</p>
                      <CustomIcon name="link" small outlined />
                    </Stack>
                  </Button>
                )
              }
          
              <Link className={classnames({ disabled_greyed: !canStudy })} to={`/subject-subscription/${subjectSubscription._id}/create-study`}>
                <Button variant="contained" color="primary">
                  <Stack justifyContent="space-between" direction="row" width="100%" alignItems="center">
                    <b>{intl.formatMessage({id: 'study'})}</b>
                    <CustomIcon name="keyboard_arrow_right" small />
                  </Stack>
                </Button>
              </Link>
            </Stack>
          )} 
        />

        <Stack direction={isSmallDevice ? 'column' : 'row'} spacing={2}>
          {
            subject.image && (
              <ImageDisplay 
                image={subject.image as string} 
                height="250px"
                width="425px"
                fillContainer
              />
            )
          }

          {
            isSmallDevice && (
              <Stack direction="row" spacing={1} className="mobiles_actions_container">
                <Button 
                  onClick={e => setOptionsMenuAnchorEl(e.currentTarget)}
                  className="bg_light" 
                  variant="outlined"
                  color="secondary" 
                  sx={{ flex: 1 }}
                >
                  <Stack justifyContent="space-between" direction="row" width="100%" alignItems="center">
                    <p>{intl.formatMessage({id: 'share'})}</p>
                    <CustomIcon name="link" small />
                  </Stack>
                </Button>
              
                <Link className={classnames({ disabled_greyed: !canStudy })} to={`/subject-subscription/${subjectSubscription._id}/create-study`}>
                  <Button sx={{ flex: 1 }} variant="contained">
                    <Stack justifyContent="space-between" direction="row" width="100%" alignItems="center">
                      <b>{intl.formatMessage({id: 'study'})}</b>
                      <CustomIcon name="keyboard_arrow_right" />
                    </Stack>
                  </Button>
                </Link>
              </Stack>
            )
          }

          <Stack spacing={2} flex={1}>
            <Stack direction={isLargeDevice ? 'row' : 'column'} spacing={isSmallDevice ? 1 : 2} flex={isLargeDevice ? undefined : 1}>
              <InfoTile 
                info={`${getSubjectAcquisitionLevel(subjectSubscription)}%`}
                title={intl.formatMessage({id: 'mastery_level'})}
              />

              <InfoTile 
                info={subjectSubscription.questionSubscriptions?.length || 0}
                title={intl.formatMessage({id: 'subject_questions'})}
              />

              {
                subject.enableShareSubject ? (
                  <InfoTile 
                    title={intl.formatMessage({id: 'subject_users'})}
                    info={subject.membersCount || 0}
                  />
                ) : (
                  <InfoTile 
                    title={intl.formatMessage({id: 'subject_private'})}
                    infoIcon="lock"
                  />
                )
              }
            </Stack>

            {
              isLargeDevice && subject.image && (
                <CategoriesChips 
                  categoriesIds={subject.categories || []} 
                  groupCategories
                />
              )
            }
          </Stack>
        </Stack>
        
        {
          !isLargeDevice && subject.description && (
            <Title 
              title={intl.formatMessage({id: 'subject_description'})} 
              withoutDot
              level={2}
            />
          )
        }

        {
          subject.description && (
            <Stack 
              alignItems="flex-start" 
              className="panel" 
              direction="row"
              spacing={1} 
            >
              {isLargeDevice && (<b> {intl.formatMessage({id: 'subject_description'})} </b>)}
              <p className="text_italic" style={{ flex: 1 }}>{ subject.description || intl.formatMessage({id: 'subject_no_description'}) }</p>
            </Stack>  
          )
        }

        {
          !(isLargeDevice && subject.image) && (
            <CategoriesChips 
              categoriesIds={subject.categories || []} 
              groupCategories
            />
          )
        }

        <Menu
          sx={{ mt: 1 }}
          onClose={() => setOptionsMenuAnchorEl(null)}
          anchorEl={optionsMenuAnchorEl}
          open={!!optionsMenuAnchorEl}
          autoFocus={false}
          anchorOrigin={isSmallDevice ? {
            vertical: 'bottom',
            horizontal: 'left',
          } : {
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={isSmallDevice ? {
            vertical: 'top',
            horizontal: 'left',
          } : {
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Stack>
            {
              (subject.enableShareLink || subject.enableShareSubject) && (
                <Link target="_blank" to={`/subject/${subject._id}`} >
                  <MenuItem>
                    <Stack direction="row" spacing={2}>
                      <CustomIcon name="open_in_new"  outlined />
                      <p>{intl.formatMessage({id: 'subject_view_public_view'})}</p>
                    </Stack>
                  </MenuItem>
                </Link>
              )
            }

            {
              (subject.enableShareLink || subject.enableShareSubject) && (
                <MenuItem onClick={copyShareLink}>
                  <Stack direction="row" spacing={2}>
                    <CustomIcon name="content_copy"  outlined />
                    <p>{intl.formatMessage({id: 'copy_share_link'})}</p>
                  </Stack>
                </MenuItem>
              )
            }
            
            {
              canUserEditSubject && (
                <Link to={`/subject-subscription/${subjectSubscription._id}/settings`} >
                  <MenuItem>
                    <Stack direction="row" spacing={2}>
                      <CustomIcon name="group_add"  outlined />
                      <p>{intl.formatMessage({id: 'share_options'})}</p>
                    </Stack>
                  </MenuItem>
                </Link>
              )
            }

            {
              canUserEditSubject && (
                <MenuItem onClick={() => setOpenSendInvitesModal(true)}>
                  <Stack direction="row" spacing={2}>
                    <CustomIcon name="mail"  outlined />
                    <p>{intl.formatMessage({id: 'send_invites'})}</p>
                  </Stack>
                </MenuItem>
              )
            }
          </Stack>
        </Menu>
        
        <Stack spacing={2}>
          <Title 
            title={intl.formatMessage({id: 'subject_questions'})} 
            withoutDot
            level={2}
            rightButtons={(!canUserEditSubject || isSmallDevice) ? [] : [{
              link: `/subject-subscription/${subjectSubscriptionId}/create-question`,
              label: intl.formatMessage({id: 'subject_create_question'}),
              endIcon: 'add',
              icon: 'add',
            }]}
          />

          <QuestionsList 
            questionSubscriptions={subjectSubscription.questionSubscriptions || []} 
            subjectSubscription={subjectSubscription}
            canUserEditSubject={canUserEditSubject!}
            updateIsAcquired={updateIsAcquired}
          />
        </Stack>
      </Stack>

      {
        openSendInvitesModal && (
          <SendInvitesModal 
            onClose={() => setOpenSendInvitesModal(false)}
            open={openSendInvitesModal}
          />
        )
      }
    </div>
  )
}

export default SubjectSubscriptionPage
