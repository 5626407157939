import React, { useEffect, useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import { imageToSrc, readImageFile } from '../../utils/image'
import CustomIcon from '../custom-icon'

import './styles.scss'

interface CircularImageDisplayProps {
  onDeleteImage?: () => void,
  image: string | File,
  editable?: boolean,
  size?: number,
}

// Note : displays image either if it's a file or a string
// Note : is a bit shitty
const CircularImageDisplay = ({
  onDeleteImage,
  size = 50,
  editable,
  image,
}: CircularImageDisplayProps): JSX.Element | null => {
  const [parsedImage, setParsedImage] = useState<string | null>(null)
  const intl = useIntl()

  useEffect(() => {
    readImage()
  }, [image])

  const readImage = async (): Promise<void> => {
    if (image) {
      // Note : will convert image to dataUrl if it is a file or return the path if it's a path.
      const imagesInfos: string = await readImageFile(image)
      setParsedImage(imagesInfos)
    } else {
      setParsedImage(null)
    }
  }

  if (!parsedImage) return null

  return (
    <div 
      className={classnames('circular_image_display_container', { 
        circular_image_display_editable: editable 
      })} 
      style={{ 
        height: size, 
        width: size, 
      }}>
      <img src={imageToSrc(parsedImage)} />

      {
        editable && (
          <Tooltip title={intl.formatMessage({id: 'delete_image'})}>
            <IconButton className="edit_button" onClick={onDeleteImage}>
              <CustomIcon name="delete" />
            </IconButton>
          </Tooltip>
        )
      }
    </div>
  )
}

export default CircularImageDisplay