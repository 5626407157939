import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Stack } from '@mui/material'

import './styles.scss'

const TermsPage = (): JSX.Element => {
  return (
    <Stack className="page_content terms_page_container" spacing={2}>
      <h1 className="text_center"><FormattedMessage id="terms_1" /></h1>
      
      <p><FormattedMessage id="terms_2" /></p>
      
      <p><FormattedMessage id="terms_3" /></p>
      
      <Stack spacing={1}>
        <h2><FormattedMessage id="terms_4" /></h2>
        <h3><FormattedMessage id="terms_5" /></h3>
        <p><FormattedMessage id="terms_6" /></p>
        
        <h3><FormattedMessage id="terms_7" /></h3>
        <p><FormattedMessage id="terms_8" /></p>

        <ul>
          <li> <FormattedMessage id="terms_9" /> </li>
          <li> <FormattedMessage id="terms_10" /> </li>
          <li> <FormattedMessage id="terms_11" /> </li>
        </ul>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="terms_12" /></h2>
        <h3><FormattedMessage id="terms_13" /></h3>
        <p><FormattedMessage id="terms_14" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="terms_15" /></h2>
        <h3><FormattedMessage id="terms_16" /></h3>
        <p><FormattedMessage id="terms_17" /></p>

        <h3><FormattedMessage id="terms_18" /></h3>
        <p><FormattedMessage id="terms_19" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="terms_20" /></h2>
        <h3><FormattedMessage id="terms_21" /></h3>
        <p><FormattedMessage id="terms_22" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="terms_23" /></h2>
        <h3><FormattedMessage id="terms_24" /></h3>
        <p><FormattedMessage id="terms_25" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="terms_26" /></h2>
        <h3><FormattedMessage id="terms_27" /></h3>
        <p><FormattedMessage id="terms_28" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="terms_29" /></h2>
        <h3><FormattedMessage id="terms_30" /></h3>
        <p><FormattedMessage id="terms_31" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="terms_32" /></h2>
        <h3><FormattedMessage id="terms_33" /></h3>
        <p><FormattedMessage id="terms_34" /></p>
      </Stack>

      <p><FormattedMessage id="terms_35" /></p>
    </Stack>
  )
}

export default TermsPage
