import React, { useContext, useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router'
import { Toaster } from 'react-hot-toast'
import classnames from 'classnames'

import DesktopNavigationMenu from './navigation-menu/desktop-menus'
import MobilesNavigationMenu from './navigation-menu/mobiles-menu'
import { DesktopMenuContext, LoaderContext } from '../contexts'
import Header from './header'
import Loader from './loader'
import Footer from './footer'

const publicPages = [
  '/',
  '/login',
  '/register',
  '/terms',
  '/privacy-policy',
  '/legal-infos',
]

const Wrapper = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { shouldDiplayDesktopMenu, isDesktopMenuOpen, isMenuedPage } = useContext(DesktopMenuContext)
  const [isPublicPage, setIsPublicPage] = useState<boolean>(true)
  
  const { isLoading } = useContext(LoaderContext)
  const location = useLocation()

  useEffect(() => {
    setIsPublicPage(Boolean(
      publicPages.some((path: string) => {
        return Boolean(matchPath(location.pathname, { path, exact: true }))
      })
    ))
  }, [location.pathname])

  return (
    <>
      <div className="global_container"> 
        <Header />
        
        { isLoading && <Loader /> }

        <DesktopNavigationMenu />
        <MobilesNavigationMenu />
        
        <div className={classnames('page_container greyed_background', { 
          page_container_menu_closed: Boolean(shouldDiplayDesktopMenu && (!isDesktopMenuOpen || isMenuedPage)), 
          page_container_menu_open: Boolean(shouldDiplayDesktopMenu && isDesktopMenuOpen && !isMenuedPage),
        })}>
          { children }
        </div>

        { isPublicPage && ( <Footer /> ) }
      </div>
      <Toaster position="top-center" toastOptions={{duration: 7000}} />
    </>
  )

}

export default Wrapper
