import React, { useContext, useState } from 'react'
import { ClickAwayListener, IconButton, MenuItem, Paper, Popper, Stack } from '@mui/material'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { AuthContext, UserContext } from '../../contexts'
import UserAvatar from '../user/user-avatar'
import CustomIcon from '../custom-icon'
import { palette } from '../../theme'

const AccountButton = (): JSX.Element | null => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const { logout } = useContext(AuthContext)
  const { user } = useContext(UserContext)
  const location = useLocation()

  const onOpenMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    setMenuAnchorEl(e.currentTarget)
  }

  const onCloseMenu = (): void => {
    setMenuAnchorEl(null)
  }

  if (!user) return null

  const isParametersPage: boolean = location.pathname.includes('/user/parameters')

  return (
    <>
      <Stack 
        onClick={menuAnchorEl ? onCloseMenu : onOpenMenu}
        alignItems="center" 
        direction="row" 
        spacing={1}
        sx={{ 
          cursor: 'pointer', 
          ':hover': { 
            '& p': { 
              textDecoration: 'underline', 
            }, 
          }, 
        }}
      >
        <p> {user.pseudo} </p>
        <IconButton>
          <UserAvatar user={user} size={35} />
        </IconButton>
      </Stack>

      <Popper 
        anchorEl={menuAnchorEl}
        placement="bottom-end"
        open={!!menuAnchorEl}
        disablePortal
        modifiers={[{
          name: "offset",
          options: {
            offset: [0, 8],
          },
        }]}
      >
        <ClickAwayListener onClickAway={onCloseMenu}>
          <Paper>
            <MenuItem 
              onClick={onCloseMenu}
              component={Link}
              to="/settings"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <CustomIcon 
                  color={isParametersPage ? palette.primary.main : palette.secondary.main} 
                  outlined={!isParametersPage} 
                  name="settings" 
                />
                <span style={{ color: isParametersPage ? palette.primary.main : palette.secondary.main }}>Paramètres</span>
              </Stack>
            </MenuItem>

            <MenuItem onClick={() => logout()}>
              <Stack direction="row" spacing={1}>
                <CustomIcon name="logout" outlined />
                <span>Se déconnecter</span>
              </Stack>
            </MenuItem>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default AccountButton
