import { Button, Dialog, DialogTitle, Stack, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import React, { useContext, useState } from 'react'
import { format } from 'date-fns'

import { AuthContext, LoaderContext, UserContext } from '../../../common/contexts'
import { DeviceTypesEnum, useResponsive } from '../../../common/hooks/responsive'
import GoBackButton from '../../../common/components/go-back-button'
import { displayWarningToast } from '../../../common/utils/toast'
import CustomIcon from '../../../common/components/custom-icon'
import { useRequest } from '../../../common/hooks/request'
import Title from '../../../common/components/title'
import { palette } from '../../../common/theme'
import { useIntl } from 'react-intl'

const AccountSettings = (): JSX.Element | null => {
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState<boolean>(false)
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { logout } = useContext(AuthContext)
  const { user } = useContext(UserContext)
  const responsive = useResponsive()
  const { postData } = useRequest()
  const intl = useIntl()

  const deleteAccount = async (): Promise<void> => {
    displayLoader()
    const response = await postData('/user/delete-account')
    if (response.success) {
      hideLoader()
      logout()
    } else {
      displayWarningToast(intl.formatMessage({id: 'error_occured'}))
    }
    hideLoader()
  }

  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)

  if (!user) return null

  return (
    <Stack flex={1} spacing={2} className="page_content setting_page_content">
      <Title title={intl.formatMessage({id: 'account_settings'})} />

      <Stack spacing={1}>
        <Stack className="panel">
          <p><b>{intl.formatMessage({id: 'account_settings_mail'})}</b> {user.mail}</p>
          <p><b>{intl.formatMessage({id: 'account_settings_google_auth'})}</b> {intl.formatMessage({id: user?.isGoogleAuth ? 'yes' : 'no'})} </p>  
          <p><b>{intl.formatMessage({id: 'account_settings_email_verified'})}</b> {intl.formatMessage({id: user?.emailVerified ? 'yes' : 'no'})} </p>    
          <p><b>{intl.formatMessage({id: 'account_settings_created_at'})}</b> {format(user.createdAt!, 'dd/MM/yyyy')} </p>  
        </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="center" className="panel">
          <b>{intl.formatMessage({id: 'account_settings_login'})}</b>
          <Button
            endIcon={(<CustomIcon name="logout" />)}
            onClick={() => logout()}
            variant="outlined"
            color="secondary"
          >
            {intl.formatMessage({id: 'logout'})}
          </Button>
        </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="center" className="panel">
          <b>{intl.formatMessage({id: 'account'})}</b>
          <Button
            endIcon={(<CustomIcon name="delete" outlined color={palette.error.main} />)}
            onClick={() => setOpenDeleteAccountModal(true)}
            variant="outlined"
            color="error"
          >
            {intl.formatMessage({id: 'delete_account'})}
          </Button>
        </Stack>
      </Stack>

      {
        !isDesktop && (
          <Stack direction="row" justifyContent="flex-start">
            <GoBackButton defaultUrl="/settings" withIcon  />
          </Stack>
        )
      }

      <Dialog
        onClose={() => setOpenDeleteAccountModal(false)}
        open={openDeleteAccountModal}
      >
        <DialogTitle>
          {intl.formatMessage({id: 'delete_account_modal_title'})}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({id: 'delete_account_modal_1'})}
            {intl.formatMessage({id: 'delete_account_modal_2'})}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => setOpenDeleteAccountModal(false)}>
            {intl.formatMessage({id: 'cancel'})}
          </Button>
          <Button color="error" onClick={deleteAccount} autoFocus>
            {intl.formatMessage({id: 'delete_account_modal_3'})}
          </Button>
        </DialogActions>
      </Dialog>
      
    </Stack>
  )
}

export default AccountSettings