
interface Page {
  hasOfflineMenu?: boolean,
  matchPaths?: string[],
  isMainRoute: boolean,
  hasMenu?: boolean,
  labelCode: string,
  pages?: Page[],
  label: string,
  icon?: string,
  path: string,

}

const subjectSubscriptionPages: Page[] = [
  {
    path: '/subject-subscription/:subjectSubscriptionId',
    labelCode: 'subject_subscription_questions',
    label: 'Questions du sujet',
    isMainRoute: false,
    icon: 'subject',
  },
  {
    path: '/subject-subscription/:subjectSubscriptionId/update-subject',
    labelCode: 'subject_subscription_update',
    label: 'Modifier les informations',
    isMainRoute: false,
    icon: 'edit',
  },
  {
    path: '/subject-subscription/:subjectSubscriptionId/create-question',
    labelCode: 'subject_subscription_add_question',
    label: 'Ajouter des questions',
    isMainRoute: false,
    icon: 'add',
  },
  {
    path: '/subject-subscription/:subjectSubscriptionId/create-study',
    labelCode: 'subject_subscription_create_study',
    isMainRoute: false,
    label: 'Réviser',
    icon: 'school',
    matchPaths: [
      '/subject-subscription/:subjectSubscriptionId/study/:studyId',
      '/subject-subscription/:subjectSubscriptionId/create-study',
    ],
  },
  {
    path: '/subject-subscription/:subjectSubscriptionId/settings',
    labelCode: 'subject_subscription_settings',
    label: 'Paramètres',
    isMainRoute: false,
    icon: 'settings',
  },
]

const settingsPages: Page[] = [
  {
    labelCode: 'settings_profile',
    label: 'Modifier le profil',
    path: '/settings/profile',
    isMainRoute: false,
    icon: 'person'
  },
  {
    label: 'Paramètres de répétition espacée',
    labelCode: 'settings_spaced_repetition',
    path: '/settings/spaced-repetition',
    isMainRoute: false,
    icon: 'history',
  },
  {
    label: 'Questions à inclure dans les révisions globales',
    labelCode: 'settings_study_questions',
    path: '/settings/study-questions',
    isMainRoute: false,
    icon: 'priority',
  },
  {
    labelCode: 'settings_password',
    path: '/settings/password',
    label: 'Mot de passe',
    isMainRoute: false,
    icon: 'lock',
  },  
  {
    labelCode: 'settings_account',
    path: '/settings/account',
    icon: 'account_circle',
    isMainRoute: false,
    label: 'Compte',
  },
]

const browserPage: Page = {
  label: 'Trouver des sujets',
  hasOfflineMenu: true,
  labelCode: 'browse',
  isMainRoute: true,
  path: '/browse',
  icon: 'search',
  hasMenu: true,
}

const mainPages: Page[] = [
  {
    matchPaths: ['/subject-subscription'],
    labelCode: 'subject_subscriptions',
    path: '/subject-subscriptions',
    label: 'Mes sujets',
    isMainRoute: true,
    icon: 'home',
  },
  {
    matchPaths: ['/study'],
    labelCode: 'study_all',
    path: '/study/all',
    isMainRoute: true,
    label: 'Réviser',
    icon: 'school',
  },
  browserPage,
  {
    labelCode: 'create_subject',
    label: 'Créer un sujet',
    path: '/create-subject',
    isMainRoute: true,
    icon: 'add',
  },
  {
    labelCode: 'settings',
    pages: settingsPages,
    label: 'Paramètres',
    isMainRoute: true,
    path: '/settings',
    icon: 'settings',
  },
  {
    path: '/subject-subscription/:subjectSubscriptionId',
    labelCode: 'subject_subscription',
    pages: subjectSubscriptionPages, 
    label: 'Page du sujet',
    isMainRoute: false,
    icon: 'school',
  },
]

const offlineMenuPages: Page[] = [
  {
    path: '/',
    label: 'Accueil',
    isMainRoute: true,
    labelCode: 'home',
    icon: 'home',
  },
  browserPage,
  {
    label: 'Créer une compte',
    icon: 'account_circle',
    labelCode: 'register',
    path: '/register',
    isMainRoute: true,
  },
  {
    label: 'Se connecter',
    labelCode: 'login',
    isMainRoute: true,
    path: '/login',
    icon: 'login',
  },
]

export { 
  subjectSubscriptionPages,
  offlineMenuPages,
  settingsPages,
  browserPage,
  mainPages,
}

export type { Page }