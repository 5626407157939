import React from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { AcquisitionStatusEnum, StudyQuestionsInfos } from '../../../../../common/types'
import { ProgressColorsEnum } from '../../../../common/types'
import { palette } from '../../../../common/theme'

import './styles.scss'

interface StudySumpUpProps {
  questionsInfos: StudyQuestionsInfos[],
  questionsCount: number,
  subjectUrl: string,
}

interface ScoreItem {
  value: number, 
  name: string, 
}

const StudySumUp = ({ 
  questionsInfos,
  questionsCount,
  subjectUrl,
}: StudySumpUpProps): JSX.Element | null => {
  const intl = useIntl()

  const scores: ScoreItem[] = Object.values(AcquisitionStatusEnum).map((acquisitionStatus: AcquisitionStatusEnum) => {
    const questionInfos: StudyQuestionsInfos[] = questionsInfos.filter((studyQuestionInfos: StudyQuestionsInfos) => {
      return studyQuestionInfos.acquisitionStatus === acquisitionStatus
    }) || []

    return {
      name: acquisitionStatus,
      value: questionInfos.length,
    }
  })
  
  return (
    <Stack spacing={2} alignItems="center" className="question_container study_sum_up_container">
      <div className="question_content panel">
        <Stack alignItems="center" spacing={4}>
          <div className="question_type_container">
            <span>{intl.formatMessage({id: 'study_sum_up_title'})}</span>
          </div>
      
          <h2>{intl.formatMessage({id: 'study_sum_up_subtitle'})}</h2>

          <Stack spacing={1} alignItems="center">
            
            <p> {intl.formatMessage({id: 'you_answered'})} <b>{questionsCount} {intl.formatMessage({id: 'questions'})}</b> : </p>

            <PieChart width={400} height={130}>
              <Pie
                animationDuration={500}
                data={scores}
                startAngle={180}
                cy={100}
                endAngle={0}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {scores.map((scoreItem: ScoreItem, index) => (
                  <Cell key={`cell-${index}`} fill={ProgressColorsEnum[scoreItem.name] || palette.primary.main} />
                ))}
              </Pie>
            </PieChart>
            
            <Stack spacing={1}>
              {
                scores.map((scoreItem: ScoreItem, index: number) => {
                  return (
                    <Stack 
                      key={`study_summary_score_${index}`} 
                      justifyContent="space-between"
                      alignItems="center" 
                      direction="row"
                      spacing={2}
                    >
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <div className="natacha" style={{ backgroundColor: ProgressColorsEnum[scoreItem.name] || palette.primary.main }} />
                        <p>{intl.formatMessage({id: `progress_${scoreItem.name}`})} : </p>
                      </Stack>
                      <b>{scoreItem.value}</b>
                    </Stack>
                  )
                })
              }
            </Stack>
          </Stack>
          
          <Link to={subjectUrl}>
            <Button color="secondary" variant="outlined">
              {intl.formatMessage({id: `back_to_subject`})}
            </Button>
          </Link>
        </Stack>
      </div>
    </Stack>
  )
}

export default StudySumUp
