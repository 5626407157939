import React, { useState, useEffect } from 'react'
import { Button, Stack, TextField } from '@mui/material'
import { useIntl } from 'react-intl'

import { Question, TextQuestionContent, TextResponse } from '../../../../../common/types'
import { useKeyboardListenner } from '../../../../common/hooks/keyboard'
import ImageDisplay from '../../../../common/components/image-display'
import { useResponsive } from '../../../../common/hooks/responsive'
import ExplanationDialog from '../explanation-dialog'

import './styles.scss'

interface TextQuestionPanelProps {
  question: Question,
}

const TextQuestionPanel = ({
  question,
}: TextQuestionPanelProps): JSX.Element => {
  const [displayExplanation, setDisplayExplanation] = useState<boolean>(false)
  const [isValidated, setIsValidated] = useState<boolean>(false)
  const [responses, setResponses] = useState<string[]>([])
  
  const responsive = useResponsive()
  const intl = useIntl()
  
  useEffect(() => {
    if (question) {
      setIsValidated(false)
      setResponses((question.content as TextQuestionContent).responses.map(() => ''))
    }
  }, [question])

  useKeyboardListenner(13, () => setIsValidated(true))

  const questionContent:  TextQuestionContent = question.content as TextQuestionContent
  const isMediumDevice: boolean = responsive.isMediumDevice()
  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <Stack spacing={2} alignItems="center" className="question_container">
      <div className="question_content panel">
        <Stack alignItems="center" spacing={isSmallDevice ? 2 : isMediumDevice ? 3 : 4}>
          <div className="question_type_container">
            <span>{intl.formatMessage({id: `question_type_${question.type}`})}</span>
          </div>

          {
            questionContent.images && (
              <ImageDisplay images={questionContent.images as string[]} />
            )
          }

          <h2>{questionContent.question}</h2>

          <Stack spacing={1}>
            {
              questionContent.responses.map((textResponse: TextResponse, index: number) => {
                const placeholder: string = textResponse.label || (questionContent.responses.length > 1 ? 
                  `${intl.formatMessage({id: 'answer'})} ${index+1}`
                  : intl.formatMessage({id: 'answer'})
                )
                  
                return (
                  <Stack key={`response_field_${index}`} spacing={1}>
                    <TextField
                      sx={{ minWidth: isSmallDevice ? 200 : 300 }}
                      placeholder={placeholder}
                      size="small"
                      fullWidth
                      value={responses[index] || ''}
                      onChange={event => setResponses(responses.map((resp: string, respIndex: number) => {
                        if (respIndex === index) {
                          return event.target.value
                        } else {
                          return resp
                        }
                      }))}
                    />
                    { 
                      isValidated && (
                        <p>{intl.formatMessage({id: 'answer'})} : <b>{textResponse.response || intl.formatMessage({id: 'no_answer'})}</b></p>
                      )
                    }
                  </Stack>
                )
              })
            }
          </Stack>
          
          {
            !isValidated && (
              <Button 
                onClick={() => setIsValidated(true)}
                variant="outlined" 
                color="secondary" 
              >
                {intl.formatMessage({id: 'display_answer'})}
              </Button>
            )
          }

          {
            isValidated && questionContent.explanation && (
              <Button 
                onClick={() => setDisplayExplanation(true)}
                variant="outlined" 
                color="secondary"
              >
                {intl.formatMessage({id: 'display_explanation'})}
              </Button>
            )
          }

          <ExplanationDialog 
            onClose={() => setDisplayExplanation(false)}
            explanation={questionContent.explanation}
            open={displayExplanation}
          />
        </Stack>
      </div>
    </Stack>
  )
}

export default TextQuestionPanel