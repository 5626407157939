import React, { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { IconButton, InputAdornment, Stack, TextField, MenuItem, Popper, SxProps } from '@mui/material'
import { useLocation } from 'react-router'
import { useIntl } from 'react-intl'
import { debounce } from 'lodash'

import SelectCategoriesButton from '../categories/select-categories/select-categories-button'
import { SearchAutocompleteResult } from '../../../../common/types'
import { useRequest } from '../../hooks/request'
import { BrowserContext } from '../../contexts'
import CustomIcon from '../custom-icon'
import { palette } from '../../theme'

import './styles.scss'

interface SearchBarProps {
  renderCategoriesButton?: boolean,
  sx?: SxProps,
}

const SearchBar = ({ 
  renderCategoriesButton = false,
  sx,
}: SearchBarProps): JSX.Element => {
  const [autocompleteResults, setAutocompleteResults] = useState<SearchAutocompleteResult[]>([])
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  
  const { onUpdateSearchParameter, searchParameters } = useContext(BrowserContext)
  const { fetchData } = useRequest()
  const location = useLocation()
  const inputRef = useRef()
  const intl = useIntl()

  const getAutocompleteResults = async (searchText: string): Promise<void> => {
    const response = await fetchData('/search/autocomplete', { searchText })
    setAutocompleteResults(response.results || [])
  }

  const debouncedChangeHandler = useCallback(debounce(getAutocompleteResults, 500), [])

  const handleChange = (nextValue: string) => {
    debouncedChangeHandler(nextValue)
    setInputValue(nextValue)
  }

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    const searchText = urlSearchParams.get('searchText')
    
    if (searchText) {
      setInputValue(searchText)
    } else {
      setInputValue('')
    }
  }, [location.search])

  const onKeyUp = (event): void => {
    if (event.keyCode === 13 || event.which === 13) {
      onSubmitSeach()
    }
  }

  const onClearInput = () => {
    setAutocompleteResults([])
    setInputValue('')

    if (inputRef.current && location.pathname.includes('/browse')) {
      const inputElement = inputRef.current as HTMLInputElement
      onUpdateSearchParameter({ ...searchParameters, searchText: '' })
      inputElement.blur()
    }
  }

  const onSubmitSeach = (categories: string[] = []): void => {
    setAutocompleteResults([])

    if (inputRef.current) {
      const inputElement = inputRef.current as HTMLInputElement
      if (inputElement.value || categories.length > 0) {
        onUpdateSearchParameter({ 
          ...searchParameters, 
          searchText: inputElement.value, 
          categories: categories || searchParameters.categories,
        })
        inputElement.blur()
      }
    }
  }

  const onSubmitSearchValue = (value: string): void => {
    if (inputRef.current) {
      const inputElement = inputRef.current as HTMLInputElement
      onUpdateSearchParameter({ ...searchParameters, searchText: value })
      setInputValue(value)
      inputElement.blur()
    }
  }

  const onFocus = () => {
    window.addEventListener('keyup', onKeyUp)
    setIsFocused(true)
  }

  const onBlur = debounce(() => {
    window.removeEventListener('keyup', onKeyUp)
    setIsFocused(false)
  }, 500)

  return (
    <>
      <Stack direction="row" spacing={1} sx={sx}>
        <TextField 
          placeholder={intl.formatMessage({id: 'search_bar_placeholder'})}
          onChange={event => handleChange(event.target.value)}
          inputRef={inputRef}
          value={inputValue}
          id="search-input"
          onFocus={onFocus}
          onBlur={onBlur}
          size="small"
          sx={sx}
          InputProps={{
            sx: { pr: '5px', flex: 1 },
            endAdornment: (
              <InputAdornment position="end">
                <Stack direction="row" spacing={0} alignItems="center">
                  {
                    !!inputValue && (
                      <IconButton onClick={onClearInput}>
                        <CustomIcon name="clear" color={palette.secondary.light} />
                      </IconButton>    
                    )
                  }
                  <IconButton onClick={() => onSubmitSeach()}>
                    <CustomIcon name="search" className="flip_x" color={palette.secondary.light} />
                  </IconButton>
                </Stack>
              </InputAdornment>
            ),
          }}
        />

        {
          renderCategoriesButton && (
            <SelectCategoriesButton 
              onSelectCategory={(categoryId: string) => onSubmitSeach([categoryId])}
            />
          )
        }
      </Stack>

      <Popper 
        open={!!inputRef.current && isFocused && autocompleteResults.length > 0} 
        sx={{ width: (inputRef.current as any)?.clientWidth }}
        className="results_container shadow_light"
        anchorEl={inputRef.current}
        placement="bottom-start"
      >
        {
          autocompleteResults.map((autocompleteResult: SearchAutocompleteResult, index: number) => {
            return (
              <MenuItem 
                onClick={() => onSubmitSearchValue(autocompleteResult.label)}
                key={`autocomplete_result_${index}`} 
              >
                {autocompleteResult.label}
              </MenuItem>
            )
          })
        }
      </Popper>
    </>
  )
}

export default SearchBar