import { QcmQuestionContent, QcmResponse, Question, QuestionSubscription, TextQuestionContent, TextResponse } from '../types'
import { maxQcmQuestionResponsesCount, maxTextResponsesCount, minQcmQuestionResponsesCount } from '../constants'

const compareQcmQuestionContents = (content1: QcmQuestionContent, content2: QcmQuestionContent): boolean => {
  let areEqual: boolean = (
    content1.question === content2.question 
    && content1.explanation === content2.explanation
    && content1.responses.length === content2.responses.length
    && content1.images?.length === content2.images?.length
  )

  if (areEqual) {
    content1.responses.forEach((qcmResponse1: QcmResponse, index: number) => {
      if (areEqual) {
        const qcmResponse2: QcmResponse = content2.responses[index]
  
        areEqual = (
          qcmResponse1.isCorrect === qcmResponse2.isCorrect
          && qcmResponse1.response === qcmResponse2.response
        )
      }
    })
  }

  if (areEqual) {
    areEqual = compareImagesList((content1.images || []), (content2.images || []))
  }

  return areEqual
}

const validateQcmQuestionContent = (content: QcmQuestionContent): boolean => {
  const responses: QcmResponse[] = content.responses || []

  let canSubmit: boolean = Boolean(content.question && responses.length >= minQcmQuestionResponsesCount && responses.length < maxQcmQuestionResponsesCount)
  
  if (canSubmit) {
    responses.forEach((qcmResponse: QcmResponse | string) => {
      if (canSubmit) {
        const response = qcmResponse as QcmResponse
        canSubmit = Boolean(response.response)
      }
    })
  }

  return canSubmit
}

const compareTextQuestionContents = (content1: TextQuestionContent, content2: TextQuestionContent): boolean => {
  let areEqual: boolean = (
    content1.question === content2.question
    && content1.explanation === content2.explanation
    && content1.responses.length === content2.responses.length
    && content1.images?.length === content2.images?.length
  )
  
  if (areEqual) {
    content1.responses.forEach((textResponse1: TextResponse, index: number) => {
      if (areEqual) {
        const textResponse2: TextResponse = content2.responses[index]

        areEqual = (
          textResponse1.label === textResponse2.label
          && textResponse1.response === textResponse2.response
        )
      }
    })
  }

  if (areEqual) {
    areEqual = compareImagesList((content1.images || []), (content2.images || []))
  }

  return areEqual
}

const validateTextQuestionContent = (content: TextQuestionContent): boolean => {
  return !!content.question && content.responses.length <= maxTextResponsesCount
}

const compareImagesList = (list1: (string | File)[], list2: (string | File)[]): boolean => {
  let areEqual: boolean = true 

  list1.forEach((image1: string | File, index: number) => {
    if (areEqual) {
      const image2: string | File = list2[index]
      if (image1 instanceof File && image2 instanceof File) {
        areEqual = image1.name === image2.name
      } else {
        areEqual = image1 === image2
      }
    }
  })

  return areEqual
}

const decomposeQuestionSubscription = (
  questionSubscription: QuestionSubscription,
): {
  question: string,
} => {
  const question: Question = questionSubscription.question as Question
  const questionContent: TextQuestionContent | QcmQuestionContent = question.content as TextQuestionContent | QcmQuestionContent

  return {
    question: questionContent.question
  }
}

export { 
  decomposeQuestionSubscription,
  compareTextQuestionContents, 
  validateTextQuestionContent, 
  compareQcmQuestionContents, 
  validateQcmQuestionContent, 
}
