import { Avatar } from '@mui/material';
import React from 'react'

import CustomIcon from './custom-icon';

interface UserAvatarProps {
  picture?: string,
  size?: number,
}

const UserAvatar = ({
  size = 40,
  picture,
}: UserAvatarProps): JSX.Element => {
  if (picture) {
    return (
      <Avatar src={picture} sx={{ width: size, height: size }} />
    )
  } else {
    return (
      <CustomIcon name="account_circle" size={size} color="lightgrey" />
    )
  }
}

export default UserAvatar
