import React, { useState } from 'react'
import { Button, Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { useLoadSubjectSubscription } from '../../../../../common/hooks/subjects-subscription'
import { useResponsive } from '../../../../../common/hooks/responsive'
import CustomIcon from '../../../../../common/components/custom-icon'
import { SubjectMember } from '../../../../../../common/types'
import Title from '../../../../../common/components/title'
import SubjectMemberRow from './subject-member-row'
import SendInvitesModal from './send-invites-modal'

const SubjectMembersPanel = (): JSX.Element | null => {
  const { subjectSubscription, subjectMembers, subject } = useLoadSubjectSubscription()
  const [openSendInvitesModal, setOpenSendInvitesModal] = useState<boolean>(false)
  const responsive = useResponsive()
  const intl = useIntl()
  
  if (!subjectSubscription || !subject) return null 

  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <Stack spacing={1} className="panel">
      <Stack>
        <Title 
          title={intl.formatMessage({id: 'subject_users_long'})}
          withoutDot
          level={3}
          rightButtons={isSmallDevice ? [] : [{
            label: intl.formatMessage({id: 'send_invites'}),
            onClick: () => setOpenSendInvitesModal(true),
            endIconOutlined: true,
            variant: 'outlined',
            color: 'secondary',
            endIcon: 'mail',
            bold: false,
          }]}
        />
        
        <Stack>
          {
            subjectMembers.map((subjectMember: SubjectMember, index: number) => (
              <SubjectMemberRow 
                key={`subject_member_${subjectMember.userId}`}
                isLast={index === (subjectMembers.length - 1)}
                subjectMember={subjectMember}
              /> 
            ))
          }
        </Stack>
      </Stack>

      {
        isSmallDevice && (
          <Button color="secondary" variant="outlined" onClick={() => setOpenSendInvitesModal(true)}>
            <Stack direction="row" justifyContent="space-between" width="100%">
              <p>{intl.formatMessage({id: 'send_invites'})}</p>
              <CustomIcon name="email" outlined />
            </Stack>
          </Button>
        )
      }

      
      
      {
        openSendInvitesModal && (
          <SendInvitesModal 
            onClose={() => setOpenSendInvitesModal(false)}
            open={openSendInvitesModal}
          />
        )
      }

    </Stack>
  )
}

export default SubjectMembersPanel

