import { useState, useEffect } from 'react'
import { debounce } from 'lodash'

enum DeviceTypesEnum {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
}

enum DeviceTypesBreakpointsEnum {
  EXTRA_SMALL = 400,
  SMALL = 576,
  MEDIUM = 820,
  LARGE = 992,
  EXTRA_LARGE = 1200, 
}

const resizeDebounce = 100

const useResponsive = () => {
  const onResize = debounce(() => {
    setCurrentDeviceType(getCurrentDeviceType())
  }, resizeDebounce)

  const getCurrentDeviceType = (): DeviceTypesEnum => {
    if (window.innerWidth <= DeviceTypesBreakpointsEnum.EXTRA_SMALL) {
      return DeviceTypesEnum.EXTRA_SMALL
    } else if (window.innerWidth <= DeviceTypesBreakpointsEnum.SMALL) {
      return DeviceTypesEnum.SMALL
    } else if (window.innerWidth <= DeviceTypesBreakpointsEnum.MEDIUM) {
      return DeviceTypesEnum.MEDIUM
    } else if (window.innerWidth <= DeviceTypesBreakpointsEnum.LARGE) {
      return DeviceTypesEnum.LARGE
    } else {
      return DeviceTypesEnum.EXTRA_LARGE
    }
  }

  const [currentDeviceType, setCurrentDeviceType] = useState<DeviceTypesEnum>(getCurrentDeviceType())

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const isLowerThan = (deviceType: DeviceTypesEnum): boolean => {
    return DeviceTypesBreakpointsEnum[currentDeviceType] < DeviceTypesBreakpointsEnum[deviceType]
  }

  const isGreaterThan = (deviceType: DeviceTypesEnum): boolean => {
    return DeviceTypesBreakpointsEnum[currentDeviceType] > DeviceTypesBreakpointsEnum[deviceType]
  }

  const isExtraSmallDevice = (): boolean => currentDeviceType === DeviceTypesEnum.EXTRA_SMALL
  const isSmallDevice = (): boolean => currentDeviceType === DeviceTypesEnum.SMALL || currentDeviceType === DeviceTypesEnum.EXTRA_SMALL
  const isMediumDevice = (): boolean => currentDeviceType === DeviceTypesEnum.MEDIUM
  const isLargeDevice = (): boolean => currentDeviceType === DeviceTypesEnum.LARGE
  const isExtraLargeDevice = (): boolean => currentDeviceType === DeviceTypesEnum.EXTRA_LARGE

  return { 
    currentDeviceType, 
    isGreaterThan,
    isLowerThan,
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isExtraLargeDevice,
    isExtraSmallDevice,
  }
}

export { 
  DeviceTypesBreakpointsEnum, 
  DeviceTypesEnum, 
  useResponsive, 
}
