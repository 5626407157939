import React, { useContext } from 'react'
import { Button, IconButton, Stack } from '@mui/material'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { AuthContext, DesktopMenuContext, MobilesMenuContext, UserContext } from '../../contexts'
import { DeviceTypesEnum, useResponsive } from '../../hooks/responsive'
import { browserPage } from '../../constants/routes'
import { isPageSelected } from '../../utils/routes'
import LanguagesMenu from '../language-menu'
import AccountButton from './account-button'
import CustomIcon from '../custom-icon'
import SearchBar from '../search-bar'

import './styles.scss'

const Header = (): JSX.Element => {
  const { shouldDiplayDesktopMenu, toggleDesktopMenu } = useContext(DesktopMenuContext)
  const { toggleMobilesMenu, isMobilesMenuOpen } = useContext(MobilesMenuContext)
  const { isLogged } = useContext(AuthContext)
  const { user } = useContext(UserContext)
  const responsive = useResponsive()
  const location = useLocation()
  const intl = useIntl()

  const onMenuButtonClick = (): void => {
    if (!isMobilesMenuOpen) {
      toggleMobilesMenu()
    }
  }
  
  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isBrowserPage: boolean = isPageSelected(browserPage, location.pathname)

  return (
    <div className="header_container shadow_light">
      <Stack direction="row" spacing={1} alignItems="center" sx={{ pl: shouldDiplayDesktopMenu ? 2 : 4 }}>

        {
          shouldDiplayDesktopMenu && (
            <IconButton className="desktops_menu_button" onClick={toggleDesktopMenu}>
              <CustomIcon name="menu" />
            </IconButton>
          )
        }

        <Link to={isLogged ? '/subject-subscriptions' : '/'}>
          <div className="left">
            <CustomIcon name="school" className="icon" color="#0d8180" />
            <h2 className="title">Study space repeat</h2>
          </div>
        </Link>

        {
          isLargeDevice && !isBrowserPage && (
            <SearchBar renderCategoriesButton />
          )
        }
      </Stack>

      <Stack className="right" direction="row" alignItems="center">
        {
          responsive.isGreaterThan(DeviceTypesEnum.SMALL) && (
            <Stack direction="row" spacing={isLogged ? 2 : 1} alignItems="center" pr={2}>
              {
                isLargeDevice && (
                  <LanguagesMenu />
                )
              }

              {
                !isLogged && isLargeDevice && (
                  <>
                    <Link to="/login">
                      <Button color="secondary" variant="outlined">
                        {intl.formatMessage({id: 'header_login'})}
                      </Button>
                    </Link>
                    <Link to="/register">
                      <Button variant="contained">
                        <b>{intl.formatMessage({id: 'header_register'})}</b>
                      </Button>
                    </Link>
                  </>
                )
              }

              {
                responsive.isGreaterThan(DeviceTypesEnum.MEDIUM) && isLogged && !!user && (
                  <AccountButton />
                )
              }
            </Stack>
          )
        }

        {
          responsive.isLowerThan(DeviceTypesEnum.LARGE) && (
            <IconButton sx={{ mr: 2 }} onClick={onMenuButtonClick}>
              <CustomIcon name={isMobilesMenuOpen ? 'clear' : 'menu'} />
            </IconButton>
          )
        }
        
      </Stack>
    </div>
  )
}

export default Header