import React from 'react'

import { SubjectSubscriptionsContext, SubjectSubscriptionsContextProvider } from './subject-subscriptions'
import { DesktopMenuContext, DesktopMenuContextProvider } from './desktop-menu'
import { MobilesMenuContextProvider, MobilesMenuContext } from './mobiles-menu'
import { TranslationContextProvider, TranslationContext } from './translation'
import { CategoriesContextProvider, CategoriesContext } from './categories'
import { SubjectsContext, SubjectsContextProvider } from './subjects'
import { InvitesContext, InvitesContextProvider } from './invites'
import { BrowserContext, BrowserContextProvider } from './browser'
import { LoaderContext, LoaderContextProvider } from './loader'
import { RouterContext, RouterContextProvider } from './router'
import { AuthContext, AuthContextProvider } from './auth'
import { UserContext, UserContextProvider } from './user'

const RenderContexts = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <LoaderContextProvider>
      <AuthContextProvider>
        <UserContextProvider>
          <SubjectsContextProvider>
            <SubjectSubscriptionsContextProvider>
              <InvitesContextProvider>
                <DesktopMenuContextProvider>
                  <MobilesMenuContextProvider>
                    <CategoriesContextProvider>
                      <RouterContextProvider>
                        <BrowserContextProvider>
                          <TranslationContextProvider>
                            {children}
                          </TranslationContextProvider>
                        </BrowserContextProvider>
                      </RouterContextProvider>
                    </CategoriesContextProvider>
                  </MobilesMenuContextProvider>
                </DesktopMenuContextProvider>
              </InvitesContextProvider>
            </SubjectSubscriptionsContextProvider>
          </SubjectsContextProvider>
        </UserContextProvider>
      </AuthContextProvider>
    </LoaderContextProvider>
  )
}

export default RenderContexts
export {
  SubjectSubscriptionsContext,
  DesktopMenuContext,
  MobilesMenuContext,
  TranslationContext,
  CategoriesContext,
  SubjectsContext, 
  BrowserContext,
  InvitesContext,
  RouterContext,
  LoaderContext,
  AuthContext,
  UserContext,
}