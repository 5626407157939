import React, { useMemo, useState } from 'react'
import { MenuItem, Pagination, Select, Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { DeviceTypesEnum, useResponsive } from '../hooks/responsive'

interface PaginatedListProps {
  renderItem: (item: any, index: number) => JSX.Element,
  defaultItemsPerPage?: number,
  spacing?: number,
  list: any[],
}

const PaginatedList = ({
  defaultItemsPerPage = 10,
  spacing = 1,
  renderItem,
  list,
}: PaginatedListProps): JSX.Element => {
  const responsive = useResponsive()
  const intl = useIntl()
  
  const [itemsPerPage, setItemsPerPage] = useState<number>(defaultItemsPerPage)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const pagesCount: number = useMemo(() => {
    return Math.ceil(list.length / itemsPerPage)
  }, [list, itemsPerPage])

  const onItemsPerPageChange = (nextVal: number): void => {
    setItemsPerPage(nextVal)
    setCurrentPage(1)
  }

  const startIndex = itemsPerPage * (currentPage - 1)
  const endIndex = (itemsPerPage * currentPage) - 1

  const isMobile: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)

  return (
    <Stack spacing={spacing}>

      {
        list.map((item: any, index: number) => {
          if (index >= startIndex && index <= endIndex) {
            return renderItem(item, index)
          } else {
            return null
          }
        })
      }

      {
        Math.ceil(list.length / defaultItemsPerPage) > 1 && (
          <Stack 
            justifyContent="space-between"
            alignItems="center" 
            direction="row" 
            spacing={1} 
          >
            <Stack 
              alignItems="center" 
              direction="row" 
              spacing={1} 
            >
              <p>{intl.formatMessage({id: 'number_per_pages'})}</p>
              <Select
                onChange={event => onItemsPerPageChange(event.target.value as number)}
                value={itemsPerPage}
                size="small"
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </Stack>
            
            {
              isMobile ? (
                <Stack 
                  alignItems="center" 
                  direction="row" 
                  spacing={1} 
                >
                  <p>{intl.formatMessage({id: 'page'})} :</p>
                  <Select
                    onChange={event => setCurrentPage(event.target.value as number)}
                    value={currentPage}
                    size="small"
                  >
                    {
                      Array.from({length: pagesCount}, (_, i) => i + 1).map((pageNumber: number) => {
                        return (
                          <MenuItem 
                            key={`page_select_${pageNumber}`} 
                            value={pageNumber}
                          >
                            {pageNumber}
                          </MenuItem>
                        )
                      })
                    }
                  </Select>
                </Stack>    
              ) : (
                <Pagination 
                  onChange={(event, page: number) => page ? setCurrentPage(page) : undefined}
                  boundaryCount={isMobile ? 0 : undefined}
                  siblingCount={isMobile ? 0 : undefined}
                  count={pagesCount} 
                  page={currentPage}
                  size="small"
                />
              )
            }
            
          </Stack>
        )
      }
    </Stack>
  )
}

export default PaginatedList
