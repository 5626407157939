import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IconButton, Stack } from '@mui/material'
import classnames from 'classnames'

import { DeviceTypesEnum, useResponsive } from '../../hooks/responsive'
import ImageRender from './image-render'
import CustomIcon from '../custom-icon'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import './styles.scss'

interface ImageDisplayProps {
  onDeleteImage?: (imageIndex: number) => void,
  height?: string | number,
  width?: string | number,
  fillContainer?: boolean,
  fitContainer?: boolean,
  editable?: boolean,
  images?: string[],
  image?: string,
}

const ImageDisplay = ({
  height: heightProp,
  width: widthProp,
  editable = false,
  onDeleteImage,
  fillContainer,
  fitContainer,
  images,
  image,
}: ImageDisplayProps): JSX.Element | null => {
  const [slidesCount, setSlidesCount] = useState<number>(0)
  const [slideIndex, setSlideIndex] = useState<number>(0)
  const [swiper, setSwiper] = useState<any>(null)
  const responsive = useResponsive()
  
  const imagesList: string[] = images || (image ? [image] : [])
  
  useEffect(() => {
    // Note : go to the last slide when images change in edit mode (for forms) and go to the first slide when images change in view mode
    if (swiper && !swiper.destroyed) {
      if (editable) {
        if (imagesList.length && slidesCount > 0 && (imagesList.length > slidesCount)) {
          swiper.slideTo(imagesList.length - 1)
        }
      } else {
        if (slideIndex > 0) {
          swiper.slideTo(0)
        }
      }
    }
    setSlidesCount(imagesList.length)
  }, [imagesList])

  const isNextButtonDisabled: boolean = (slideIndex === imagesList.length - 1)
  const isPrevButtonDisabled: boolean = (slideIndex === 0)

  const isSmallDevice: boolean = responsive.isSmallDevice()
  const isSmallOrMediumDevice: boolean = responsive.isLowerThan(DeviceTypesEnum.LARGE)

  let width: number | string = widthProp || 420
  let height: number | string = heightProp || 250

  if (isSmallDevice) {
    width = window.innerWidth - 60
    height = width * 0.6
  }
  
  if (!imagesList.length) return null

  return (
    <Stack spacing={1} className="images_display_container">
      <Stack direction="row" spacing={1}>
        
        {
          !isSmallOrMediumDevice && imagesList.length > 1 && (
            <Stack justifyContent="center" className="prev_next_button_container">
              {
                (imagesList.length > 1 && swiper && !isPrevButtonDisabled) && (
                  <IconButton 
                    className={classnames('prev_button bg_light', { disabled_greyed: isPrevButtonDisabled })} 
                    onClick={() => swiper.slidePrev()}
                    disabled={isPrevButtonDisabled}
                  >
                    <CustomIcon name="keyboard_arrow_left" />
                  </IconButton>
                )
              }
            </Stack>
          )
        }

        <div className="image_container" style={{ width, height }}>
          <Swiper
            onSlideChange={({ activeIndex }) => setSlideIndex(activeIndex)}
            onSwiper={swiperObj => setSwiper(swiperObj)}
            slidesPerView={1}
            spaceBetween={50}
          >
            {
              imagesList.map((image: string, index: number) => {
                return (
                  <SwiperSlide key={`form_image_${index}`}>
                    <ImageRender 
                      onDeleteImage={onDeleteImage}
                      fillContainer={fillContainer}
                      fitContainer={fitContainer}
                      editable={editable}
                      height={height}
                      width={width}
                      image={image}
                      index={index}
                    />
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </div>
        
        {
          !isSmallOrMediumDevice && imagesList.length > 1 && (
            <Stack justifyContent="center" className="prev_next_button_container">
              {
                (imagesList.length > 1 && swiper && !isNextButtonDisabled) && (
                  <IconButton 
                    className={classnames('next_button bg_light', { disabled_greyed: isNextButtonDisabled })} 
                    onClick={() => swiper.slideNext()}
                    disabled={isNextButtonDisabled}
                  >
                    <CustomIcon name="keyboard_arrow_right" />
                  </IconButton>
                )
              }
            </Stack>
          )
        }

      </Stack>
      
      {
        (isSmallOrMediumDevice && swiper && (editable || imagesList.length > 1)) && (
          <Stack 
            className="mobiles_buttons_container" 
            justifyContent={editable ? 'space-between' : 'center'}
            direction="row" 
            spacing={1}
            mt={1} 
          >
            {
              editable && (
                <IconButton 
                  onClick={() => onDeleteImage ? onDeleteImage(slideIndex) : null}
                  className="bg_light edit_button"
                >
                  <CustomIcon name="delete" />
                </IconButton>
              )
            }
            {
              (imagesList.length > 1 && swiper) && (
                <Stack direction="row" spacing={1}>
                  <IconButton 
                    className={classnames('bg_light', { disabled_greyed: isPrevButtonDisabled })} 
                    onClick={() => swiper.slidePrev()}
                    disabled={isPrevButtonDisabled}
                  >
                    <CustomIcon name="keyboard_arrow_left" />
                  </IconButton>
          
                  <IconButton 
                    className={classnames('bg_light', { disabled_greyed: isNextButtonDisabled })} 
                    onClick={() => swiper.slideNext()}
                    disabled={isNextButtonDisabled}
                  >
                    <CustomIcon name="keyboard_arrow_right" />
                  </IconButton>
                </Stack>
              )
            }
            
          </Stack>
        )
      }

    </Stack>
  )
}

export default ImageDisplay
