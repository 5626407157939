import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { useLoadSubjectSubscription } from '../../../../common/hooks/subjects-subscription'
import { displayWarningToast } from '../../../../common/utils/toast'
import { useResponsive } from '../../../../common/hooks/responsive'
import Breadcrumbs from '../../../../common/components/breadcrumbs'
import DeleteSubjectPanel from './components/delete-subject-panel'
import ShareSubjectPanel from './components/share-subject-panel'
import MembersPanel from './components/subject-members-panels'
import useRouterUtils from '../../../../common/hooks/router'
import ShareLinkPanel from './components/share-link-panel'
import Title from '../../../../common/components/title'

import './styles.scss'

const SubjectSettingsPage = (): JSX.Element | null => {
  const { goBackOrRedirect } = useRouterUtils()
  const responsive = useResponsive()
  const intl = useIntl()
  const { 
    subjectSubscription, 
    canUserEditSubject,
    subject,
  } = useLoadSubjectSubscription({
    loadSubjectSubscriptionOnMount: true,
    loadSubjectMembersOnMount: true,
    loadSubjectInvitesOnMount: true,
  })

  useEffect(() => {
    if (subjectSubscription && !canUserEditSubject) {
      displayWarningToast(intl.formatMessage({id: 'subject_edit_unauthorized'}))
      goBackOrRedirect(`/subject-subscription/${subjectSubscription._id}`)
    }
  }, [subjectSubscription, canUserEditSubject])

  if (!subjectSubscription || !subject) return null

  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <div className="page_content share_subject_page_container">
      <Stack spacing={2}>
        <Breadcrumbs 
          pages={[
            {
              label: intl.formatMessage({id: 'page_subject_subscriptions'}),
              path: '/subject-subscriptions',
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}`,
              label: subject.label,
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}/settings`,
              label: intl.formatMessage({id: 'settings'}),
            },
          ]}
        />

        <Title 
          title={intl.formatMessage({id: 'settings_of'}, {subjectLabel: subject.label})} 
          wrapTitle 
        />

        <Stack direction={isSmallDevice ? 'column' : 'row'} spacing={2}>
          <ShareSubjectPanel />
          <ShareLinkPanel />
        </Stack>
        
        <MembersPanel />

        <h3>{intl.formatMessage({id: 'other_parameters'})}</h3>

        <DeleteSubjectPanel />
      </Stack>
    </div>
  )
}

export default SubjectSettingsPage
