import React from 'react'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { QuestionSubscription, SubjectSubscription } from '../../../../../../../common/types'
import { useResponsive } from '../../../../../../common/hooks/responsive'
import CustomIcon from '../../../../../../common/components/custom-icon'
import QuestionItem from '../question-item'

interface QuestionsListProps {
  updateIsAcquired: (questionSubscriptionId: string, isAcquired: boolean) => Promise<void>,
  questionSubscriptions: QuestionSubscription[],
  subjectSubscription: SubjectSubscription,
  canUserEditSubject: boolean,
}

const QuestionsList = ({
  questionSubscriptions,
  subjectSubscription,
  canUserEditSubject,
  updateIsAcquired,
}: QuestionsListProps): JSX.Element => {
  const responsive = useResponsive()
  const intl = useIntl()
  
  const isSmallDevice: boolean = responsive.isSmallDevice()
  
  return (
    <>
      {
        (canUserEditSubject && questionSubscriptions.length === 0) && (
          <Link to={`/subject-subscription/${subjectSubscription._id}/create-question`} className="full_width">
            <div className="empty_content_container">
              <CustomIcon name="add_notes" outlined />
              <p>{intl.formatMessage({id: 'subject_no_question'})}</p>
              <p>{isSmallDevice ? intl.formatMessage({id: 'subject_no_question_cta_short'}) : intl.formatMessage({id: 'subject_no_question_cta_long'})} <b>"{intl.formatMessage({id: 'subject_create_question'})}"</b>.</p>
            </div>
          </Link>
        )
      }

      {
        (!canUserEditSubject && questionSubscriptions.length === 0) && (
          <div className="empty_content_container">
            <CustomIcon name="list" outlined />
            <p>{intl.formatMessage({id: 'subject_no_question'})}</p>
          </div>
        )
      }

      {
        questionSubscriptions.length > 0 && (
          <Stack spacing={1}>
            {
              (canUserEditSubject && isSmallDevice) && (
                <Link to={`/subject-subscription/${subjectSubscription._id}/create-question`} className="full_width">
                  <Button variant="outlined" color="secondary" fullWidth sx={{ fontSize: 16 }} className="bg_light">
                    <Stack direction="row" justifyContent="space-between" width="100%">
                      <span>{intl.formatMessage({id: 'subject_create_question_short'})}</span>
                      <CustomIcon name="add" />
                    </Stack>
                  </Button>
                </Link>
              )
            }
            {
              questionSubscriptions.map((questionSubscription: QuestionSubscription) => {
                return (
                  <QuestionItem 
                    key={`question_subscription_${questionSubscription._id}`} 
                    questionSubscription={questionSubscription}
                    subjectSubscription={subjectSubscription}
                    canUserEditSubject={canUserEditSubject}
                    updateIsAcquired={updateIsAcquired}
                  />
                )
              })
            }
          </Stack>
        )
      }
    </>
  )
}

export default QuestionsList