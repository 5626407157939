import React from 'react'
import { Checkbox, FormControlLabel, IconButton, Stack } from '@mui/material'
import { intersection, sortBy } from 'lodash'
import { useIntl } from 'react-intl'

import { Category, CategoryChild } from '../../../../../common/types'
import CustomIcon from '../../custom-icon'
import { theme } from '../../../theme'

interface SelectCategoriesListProps {
  categoriesParents: { [categoryId: string]: string[] },
  onSelectCategory: (categoryId: string) => void,
  onToggleCategory: (categoryId: string) => void,
  selectedCategories: string[],
  openedCategoryIds: string[],
  categories: Category[],
  level?: number,
}

const SelectCategoriesList = ({
  selectedCategories,
  openedCategoryIds,
  categoriesParents,
  onSelectCategory,
  onToggleCategory,
  categories,
  level = 0,
}: SelectCategoriesListProps): JSX.Element => {
  const intl = useIntl()

  return (
    <Stack spacing={0.5} pl={theme.spacing(level+1)} >
      {
        sortBy(categories, 'order').map((category: Category) => {
          const isParentSelected: boolean = intersection(categoriesParents[category._id!], selectedCategories).length > 0
          const isSelected: boolean = Boolean(selectedCategories.includes(category._id!) || isParentSelected)
          const isOpen: boolean = openedCategoryIds.includes(category._id!)

          return (
            <Stack key={`browser_menu_category_item_${category._id}`} spacing={0.5}>
              <Stack 
                justifyContent="space-between" 
                direction="row" 
                spacing={1}
                width="100%"
              >
                <FormControlLabel 
                  label={isOpen ? (<b>{intl.formatMessage({id: `category_${category.labelCode}`})}</b>) : (<span>{intl.formatMessage({id: `category_${category.labelCode}`})}</span>)}
                  control={(
                    <Checkbox 
                      onChange={() => onSelectCategory(category._id!)}
                      sx={{ root: {backgroundColor: '#fff'} }}
                      className="select_category_radio"
                      checked={isSelected}
                    />
                  )} 
                />

                { 
                  (category.childrens || []).length > 0 && (
                    <IconButton onClick={() => onToggleCategory(category._id!)}>
                      <CustomIcon name={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
                    </IconButton>
                  ) 
                }
              </Stack>

              {
                isOpen && (
                  <SelectCategoriesList 
                    categories={sortBy(category.childrens || [], 'order').map((categoryChild: CategoryChild) => categoryChild.category)}
                    selectedCategories={selectedCategories}
                    openedCategoryIds={openedCategoryIds}
                    categoriesParents={categoriesParents}
                    onToggleCategory={onToggleCategory}
                    onSelectCategory={onSelectCategory}
                    level={level+1}
                  />
                )
              }
            </Stack>
          )
        })
      }
    </Stack>
  )
}

export default SelectCategoriesList
