import React, { useContext, useState } from 'react'
import { Button, Menu, MenuItem, Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { useLoadSubjectSubscription } from '../../../../../common/hooks/subjects-subscription'
import { decomposeSubjectSubscription } from '../../../../../../common/utils/subject'
import { displayWarningToast } from '../../../../../common/utils/toast'
import CustomIcon from '../../../../../common/components/custom-icon'
import { useRequest } from '../../../../../common/hooks/request'
import { LoaderContext } from '../../../../../common/contexts'
import Title from '../../../../../common/components/title'

const ShareSubjectPanel = (): JSX.Element | null => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const { loadSubjectSubscription, subjectSubscription } = useLoadSubjectSubscription()
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { postData } = useRequest()
  const intl = useIntl()
  
  if (!subjectSubscription) return null

  const onShareSubjectChange = async (): Promise<void> => {
    setMenuAnchorEl(null)
    displayLoader()
    
    const response = await postData('/subject/update-share-subject', { 
      subjectSubscriptionId: subjectSubscription._id,
      nextValue: !subject.enableShareSubject,
    })

    if (response.success) {
      await loadSubjectSubscription()
    } else {
      displayWarningToast(intl.formatMessage({id: 'error_occured'}))
    }

    hideLoader()
  }

  const { subject } = decomposeSubjectSubscription(subjectSubscription)

  return (
    <Stack className="panel" spacing={1}>
      <Title level={3} title="Partager le sujet sur Study space repeat" withoutDot />
      <Stack direction="row" spacing={1}>
        <Button 
          startIcon={(<CustomIcon name={subject.enableShareSubject ? 'lock_open' : 'lock'} />)}
          onClick={e => setMenuAnchorEl(e.currentTarget)}
          variant="outlined" 
          color="secondary"
          fullWidth
        >
          {
            subject.enableShareSubject ? (
              <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
                <b>{intl.formatMessage({id: 'yes'})}</b>
                <CustomIcon name="keyboard_arrow_down" outlined />
              </Stack>
            ) : (
              <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
                <b>{intl.formatMessage({id: 'no'})}</b>
                <CustomIcon name="keyboard_arrow_down" outlined />
              </Stack>
            )
          }
        </Button>

        <Menu 
          onClose={() => setMenuAnchorEl(null)}
          anchorEl={menuAnchorEl}
          open={!!menuAnchorEl}
        >
          <MenuItem onClick={onShareSubjectChange}>
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomIcon name="lock_open" />
              <span>{intl.formatMessage({id: 'yes'})}</span>
              { subject.enableShareSubject && (<CustomIcon name="check" small />) }
            </Stack>
          </MenuItem>

          <MenuItem onClick={onShareSubjectChange}>
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomIcon name="lock" />
              <span>{intl.formatMessage({id: 'no'})}</span>
              { !subject.enableShareSubject && (<CustomIcon name="check" small />) }
            </Stack>
          </MenuItem>
      
        </Menu>
      </Stack>
    </Stack>
  )
}

export default ShareSubjectPanel

{/* <ToggleButtonGroup
  color="primary"
  value={subject.visibility}
  exclusive
  onChange={(event: any, value: VisibilityOptionsEnum) => onVisibilityChange(value)}
>
  {
    Object.keys(VisibilityOptionsEnum).map((visibilityOption: string) => {
      const isSelected: boolean = Boolean(subject.visibility === visibilityOption)
      return (
        <ToggleButton 
          key={`visibility_button_${visibilityOption}`} 
          value={visibilityOption}
        >
          <Stack direction="row" spacing={1}>
            <CustomIcon 
              color={isSelected ? palette.primary.main : palette.text.primary}
              name={VisibilityIconsEnum[visibilityOption]}  
            />
            <Typography
              color={isSelected ? palette.primary.main : palette.text.primary}
              fontWeight={isSelected ? 'bold' : 'normal'}
            >
              { VisibilityLabelsEnum[visibilityOption] }
            </Typography>
          </Stack>
        </ToggleButton>
      )
    })
  }
</ToggleButtonGroup> */}