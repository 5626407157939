import React, { useContext, useState } from 'react'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { AcquisitionStatusEnum, QuestionSubscription, QuestionTypesEnum, SubjectSubscription } from '../../../../../common/types'
import QuestionSubscriptionInfosModal from '../../../../common/components/subject/question-subscription-infos-modal'
import { decomposeQuestionSubscription } from '../../../../../common/utils/question-subscription'
import { getQuestionAcquisitionLevel } from '../../../../../common/utils/subject-subscription'
import DeleteQuestionModal from '../../../../common/components/subject/delete-question-modal'
import { DeviceTypesEnum, useResponsive } from '../../../../common/hooks/responsive'
import { getCanUserEditSubject } from '../../../../../common/utils/subject'
import CustomProgress from '../../../../common/components/custom-progress'
import CustomIcon from '../../../../common/components/custom-icon'
import { UserContext } from '../../../../common/contexts'
import TextQuestionPanel from '../text-question-panel'
import QcmQuestionPanel from '../qcm-question-panel'
import { palette } from '../../../../common/theme'
import QuestionActions from '../question-actions'
import ResultPrompt from '../result-prompt'

import './styles.scss'

interface QuestionStudyDisplayProps {
  onSubmitQuestionProgress: (acquisitionStatus: AcquisitionStatusEnum) => Promise<void>,
  questionSubscription: QuestionSubscription,
  subjectSubscription: SubjectSubscription,
  onQuestionDeleted: () => void,
  updateIsAcquired: (
    questionSubscriptionId: string, 
    isAcquired: boolean,
  ) => Promise<void>,
}

const QuestionStudyDisplay = ({
  onSubmitQuestionProgress,
  questionSubscription,
  subjectSubscription,
  onQuestionDeleted,
  updateIsAcquired,
}: QuestionStudyDisplayProps): JSX.Element | null => {
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState<boolean>(false)
  const [openInfosModal, setOpenInfosModal] = useState<boolean>(false)
  
  const { user } = useContext(UserContext)
  const responsive = useResponsive()
  const intl = useIntl()

  if (!user) return null

  const canEditSubject: boolean = getCanUserEditSubject(subjectSubscription, user._id)
  const acquisitionLevel: number = getQuestionAcquisitionLevel(questionSubscription)
  const { question } = decomposeQuestionSubscription(questionSubscription)
  const isDesktop = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isMobile = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)

  return (
    <Stack spacing={isMobile ? 3 : 4} paddingBottom="75px">
      <Stack direction="row" spacing={4} className="question_study_display_container">

        {
          question.type === QuestionTypesEnum.TEXT && (
            <TextQuestionPanel question={question} />
          )
        }

        {
          question.type === QuestionTypesEnum.QCM && (
            <QcmQuestionPanel question={question} />
          )
        }

        
        {
          isDesktop && (
            <Stack spacing={2} className="sidebar" width="275px">
              <Stack className="panel" direction="row" alignItems="center" justifyContent="space-between">
                <b>{intl.formatMessage({id: 'study_all_progress'})}</b>
                <Stack direction="row" spacing={1} alignItems="center">
                  <b>{acquisitionLevel} %</b>
                  <CustomProgress size={25} value={acquisitionLevel} />
                </Stack>
              </Stack>


              <Stack spacing={1}>
                <Button 
                  onClick={() => setOpenInfosModal(true)}
                  color="secondary" 
                  variant="text"
                >
                  <Stack 
                    justifyContent="space-between"
                    alignItems="center" 
                    direction="row" 
                    width="100%"
                  >
                    <span>{intl.formatMessage({id: 'study_all_display_infos'})}</span>
                    <CustomIcon name="info" color={palette.secondary.light} outlined />
                  </Stack>
                </Button>


                {
                  canEditSubject && (
                    <Link className="full_width" 
                      to={{
                        pathname: `/subject-subscription/${subjectSubscription._id}/update-question/${question._id}`,
                        state: {
                          breadcrumbsPages: [{
                            label: intl.formatMessage({id: 'study_all_title'}),
                            path: '/study/all',
                          },{
                            path: `/subject-subscription/${subjectSubscription._id}/update-question/${question._id}`,
                            label: intl.formatMessage({id: 'study_all_update_a_question'}),
                          }],
                        }  
                      }}
                    >
                      <Button color="secondary" variant="text">
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                          <span>{intl.formatMessage({id: 'study_all_update_question'})}</span>
                          <CustomIcon name="edit" color={palette.secondary.light} />
                        </Stack>
                      </Button>
                    </Link>
                  )
                }

                <Link target="_blank" className="full_width" to={`/subject-subscription/${subjectSubscription._id}`}>
                  <Button color="secondary" variant="text">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                      <p>{intl.formatMessage({id: 'study_all_go_to_subject'})}</p>
                      <CustomIcon name="open_in_new" color={palette.secondary.light} />
                    </Stack>
                  </Button>
                </Link>
                
                <Button 
                  onClick={() => updateIsAcquired(questionSubscription._id!, !questionSubscription.isAcquired)} 
                  color="secondary" 
                  variant="text"
                >
                  <Stack   
                    justifyContent="space-between"
                    alignItems="center" 
                    direction="row" 
                    width="100%"
                  >
                    {
                      questionSubscription.isAcquired ? (
                        <p>{intl.formatMessage({id: 'study_all_mark_unacquired'})}</p>
                      ) : (
                        <p>{intl.formatMessage({id: 'study_all_mark_acquired'})}</p>
                      )
                    }
                    <CustomIcon name={questionSubscription.isAcquired ? 'cancel' : 'check_circle'} color={palette.secondary.light} outlined />
                  </Stack>
                </Button>
                
                <Button 
                  onClick={() => setOpenDeleteQuestionModal(true)}  
                  color="secondary" 
                  variant="text"
                >
                  <Stack 
                    justifyContent="space-between" 
                    alignItems="center" 
                    direction="row" 
                    width="100%"
                  >
                    {
                      canEditSubject ? (
                        <p className="text_warn">{intl.formatMessage({id: 'study_all_delete_question'})}</p>
                      ) : (
                        <p className="text_warn">{intl.formatMessage({id: 'study_all_delete_question_from_space'})}</p>
                      )
                    }
                    <CustomIcon name={"delete"} color={palette.secondary.light} outlined />
                  </Stack>
                </Button>
              </Stack>

              <QuestionSubscriptionInfosModal 
                questionSubscription={questionSubscription}
                subjectSubscription={subjectSubscription}
                onClose={() => setOpenInfosModal(false)}
                open={openInfosModal}
              />

              <DeleteQuestionModal 
                onClose={() => setOpenDeleteQuestionModal(false)}
                questionSubscription={questionSubscription}
                subjectSubscription={subjectSubscription}
                onQuestionDeleted={onQuestionDeleted}
                open={openDeleteQuestionModal}
              />

            </Stack>
          )
        }
      </Stack>

      <ResultPrompt onSubmitQuestionProgress={onSubmitQuestionProgress} />

      {
        !isDesktop && (
          <QuestionActions 
            questionSubscription={questionSubscription}
            subjectSubscription={subjectSubscription}
            onQuestionDeleted={onQuestionDeleted}
            updateIsAcquired={updateIsAcquired}
            question={question}
          />
        )
      }
      
    </Stack>
  )
}

export default QuestionStudyDisplay
