import React, { useContext, useState, useEffect } from 'react'
import { Button, IconButton, Stack } from '@mui/material'
import { matchPath, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { getPathWithParameters, isPageSelected } from '../../utils/routes'
import { SubjectSubscriptionsContext } from '../../contexts'
import { Page } from '../../constants/routes'
import CustomIcon from '../custom-icon'
import { palette } from '../../theme'

interface MobilesMenuRouteProps {
  page: Page,
}

const MobilesMenuRoute = ({
  page,
}: MobilesMenuRouteProps): JSX.Element => {
  const [openSubPages, setOpenSubPages] = useState<boolean>(true)
  const { subjectSubscription } = useContext(SubjectSubscriptionsContext)
  const location = useLocation()
  const intl = useIntl()

  const isSelected: boolean = Boolean(matchPath(location.pathname, { path: page.path, exact: false, strict: false }))
  
  useEffect(() => {
    if (!isSelected) {
      setOpenSubPages(false)
    }
  }, [isSelected])

  const params = { subjectSubscriptionId: subjectSubscription?._id || undefined }
  const isSubPagesOpen: boolean = openSubPages || isSelected
  
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1}>
        <Link to={getPathWithParameters(page.path, params)}>
          <Button 
            color={isSelected ? 'primary' : 'secondary'}
            fullWidth
          >
            <Stack 
              justifyContent="flex-start"
              alignItems="center" 
              direction="row" 
              width="100%"
              spacing={2}
            >
              <CustomIcon 
                color={isSelected ? palette.primary.main : palette.secondary.main} 
                name={page.icon!} 
                outlined 
              />
              { isSelected ? (<b style={{ color: palette.primary.main }}>{intl.formatMessage({id: `page_${page.labelCode}`})}</b>) : (<p>{intl.formatMessage({id: `page_${page.labelCode}`})}</p>) }
            </Stack>
          </Button>
        </Link>
        
        {
          Boolean((page.pages || []).length) && !isSelected && (
            <IconButton onClick={() => setOpenSubPages(!openSubPages)}>
              <CustomIcon name={isSubPagesOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
            </IconButton>
          )
        }
      </Stack>

      {
        Boolean((page.pages || []).length) && isSubPagesOpen && (
          <Stack spacing={2} pl={4}>
            {
              (page.pages || []).map((subPage: Page, index: number) => {
                const isSubPageSelected: boolean = isPageSelected(subPage, location.pathname)
                
                return (
                  <Link to={getPathWithParameters(subPage.path, params)} key={`page_${page.label}_subPage_${index}`}>
                    <p style={{ color: isSubPageSelected ? palette.primary.main : palette.secondary.main }}>{intl.formatMessage({id: `page_${subPage.labelCode}`})}</p>
                  </Link>
                )
              })
            }
          </Stack>
        )
      }
    </Stack>
  )
}

export default MobilesMenuRoute
