import React, { useContext, useEffect, useMemo } from 'react'
import { IconButton, Stack } from '@mui/material'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

import { CategoriesContext } from '../../contexts'
import { palette, theme } from '../../theme'
import CustomIcon from '../custom-icon'

import './styles.scss'

interface CategoriesChipsProps {
  onDeleteCategory?: (categoryId: string) => void,
  groupCategories?: boolean,
  onDeleteAll?: () => void,
  categoriesIds: string[],
  deletable?: boolean,
  onEdit?: () => void,
  editable?: boolean,
  label?: string,
}

const CategoriesChips = ({
  groupCategories = false,
  deletable = false,
  editable = false,
  onDeleteCategory,
  categoriesIds,
  onDeleteAll,
  onEdit,
  label,
}: CategoriesChipsProps): JSX.Element | null => {
  const { loadCategoriesInfosIfNeeded, categoriesById, categories, groupSelection } = useContext(CategoriesContext)
  const intl = useIntl()

  useEffect(() => {
    loadCategoriesInfosIfNeeded()
  }, [])

  const categoriesToDisplay = useMemo(() => {
    return Boolean(categories.length && groupCategories) ? groupSelection(categoriesIds) : categoriesIds
  }, [groupCategories, categoriesIds, categories])

  if (!categoriesIds.length || !categories.length) return null

  return (
    <Stack 
      alignItems="flex-start" 
      direction="row" 
      spacing={2} 
      sx={{ 
        mb: `${theme.spacing(-1)} !important`,
      }}
    >
      {
        label && (
          <b style={{ paddingTop: theme.spacing(1) }}>{ label }</b>
        )
      }
      
      <Stack direction="row" alignItems="center" flexWrap="wrap" >

        {
          categoriesToDisplay.map((categoryId: string, index: number) => {
            if (!categoriesById[categoryId]) return null
            
            return (
              <Stack 
                onClick={(deletable && onDeleteCategory) ? () => onDeleteCategory(categoryId) : undefined}
                className={classNames('category_chip', { category_chip_editable: deletable })}
                key={`category_chip_${categoryId}_${index}`}
                alignItems="center"
                direction="row"
                spacing={1}
                mr={1}
                mb={1}
              >
                <p>{intl.formatMessage({id: `category_${categoriesById[categoryId].labelCode}`}) || ''}</p>
                {
                  deletable && (
                    <CustomIcon name="cancel" outlined size={20} color={palette.secondary.light} />
                  )
                }
              </Stack>
            )
          })
        }

        {
          editable && !!onEdit && (
            <IconButton 
              sx={{ width: '32px', height: '32px', mt: `${theme.spacing(-1)} !important` }}
              onClick={onEdit} 
              size="small"
            >
              <CustomIcon name="edit" size={20} outlined />
            </IconButton>
          )
        }

        {
          deletable && !!onDeleteAll && categoriesIds.length > 1 && (
            <IconButton 
              sx={{ width: '32px', height: '32px', mt: `${theme.spacing(-1)} !important` }}
              onClick={onDeleteAll} 
              size="small"
            >
              <CustomIcon name="clear" size={20} />
            </IconButton>
          )
        }
      </Stack>
    </Stack>
  )
}

export default CategoriesChips
