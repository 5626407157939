import Cookies from 'js-cookie'

function setCookie(cookieName: string, cookieValue: string): void {
  Cookies.set(cookieName, cookieValue)
}

function getCookie(cookieName: string): string | undefined {
  return Cookies.get(cookieName)
}

function deleteCookie(cookieName: string): void {
  Cookies.remove(cookieName)
}

export { setCookie, getCookie, deleteCookie }
