import React from 'react'
import { Button, Stack } from '@mui/material'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { DeviceTypesEnum, useResponsive } from '../../hooks/responsive'
import { isPageSelected } from '../../utils/routes'
import { Page } from '../../constants/routes'
import CustomIcon from '../custom-icon'
import { palette } from '../../theme'
import { useIntl } from 'react-intl'

interface PageMenuProps {
  titleLink: string,
  mainIcon: string,
  pages: Page[],
  title: string,
}

const PageMenu = ({
  titleLink,
  mainIcon,
  title,
  pages,
}: PageMenuProps): JSX.Element | null => {
  const responsive = useResponsive()
  const location = useLocation()
  const intl = useIntl()

  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)

  if (!isDesktop) return null

  return (
    <Stack 
      className="page_menu_container shadow_light" 
      alignItems="center" 
      spacing={2}
      pt={4}
    >
      <Link to={titleLink}>
        <Stack spacing={2} alignItems="center">
          <div className="main_icon_container shadow_light">
            <CustomIcon name={mainIcon} color={palette.primary.main} />
          </div>
          <h3>{title}</h3>
        </Stack>
      </Link>

      <Stack spacing={1} className="links_container" alignItems="center">
        {
          pages.map((page: Page, index: number) => {
            const isSelected: boolean =  isPageSelected(page, location.pathname)

            return (
              <Link key={`page_menu_link_${index}`} to={page.path} style={{ width: '100%' }}>
                <Button variant="text" color="secondary">
                  <Stack direction="row" spacing={1.5} alignItems="center" width="100%">
                    {
                      page.icon && (
                        <CustomIcon 
                          color={ isSelected ? palette.primary.main : palette.secondary.main } 
                          name={page.icon} 
                          outlined 
                        />
                      )
                    }

                    {
                      isSelected ? (
                        <b style={{ color: palette.primary.main }}>{intl.formatMessage({id: `page_${page.labelCode}`})}</b>
                      ) : (
                        <p>{intl.formatMessage({id: `page_${page.labelCode}`})}</p>
                      )
                    }
                  </Stack>
                </Button>
              </Link>
            )
          })
        }
      </Stack>
    </Stack>
  )
}

export default PageMenu
