import React, { useState } from 'react'
import { Menu, MenuItem, Stack, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import QuestionSubscriptionInfosModal from '../../../../../../common/components/subject/question-subscription-infos-modal'
import { Question, QuestionSubscription, SubjectSubscription } from '../../../../../../../common/types'
import { getQuestionAcquisitionLevel } from '../../../../../../../common/utils/subject-subscription'
import DeleteQuestionModal from '../../../../../../common/components/subject/delete-question-modal'
import { useLoadSubjectSubscription } from '../../../../../../common/hooks/subjects-subscription'
import { DeviceTypesEnum, useResponsive } from '../../../../../../common/hooks/responsive'
import CustomProgress from '../../../../../../common/components/custom-progress'
import CustomIcon from '../../../../../../common/components/custom-icon'

import './styles.scss'

interface QuestionsItemProps {
  updateIsAcquired: (questionSubscriptionId: string, isAcquired: boolean) => Promise<void>,
  questionSubscription: QuestionSubscription,
  subjectSubscription: SubjectSubscription,
  canUserEditSubject: boolean,
}

const QuestionsItem = ({
  questionSubscription,
  subjectSubscription,
  canUserEditSubject,
  updateIsAcquired,
}: QuestionsItemProps): JSX.Element => {
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState<boolean>(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [openInfosModal, setOpenInfosModal] = useState<boolean>(false)
  const { loadSubjectSubscription } = useLoadSubjectSubscription()
  const responsive = useResponsive()
  const intl = useIntl()

  const acquisitionLevel: number = getQuestionAcquisitionLevel(questionSubscription)
  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isSmallDevice: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)
  const question: Question = questionSubscription.question as Question

  return (
    <>
      <Stack direction="row" spacing={1}>
        {/* <Link style={{flex: 1}} to={`/subject-subscription/${subjectSubscription._id}/question-subscription/${questionSubscription._id}`}> */}
          <div className="question_item_container">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
              <span>{(question.content as any).question}</span>

              <Stack direction="row" alignItems="center" spacing={1}>
                {
                  !isSmallDevice && questionSubscription.isAcquired && (
                    <Tooltip title={intl.formatMessage({id: 'question_marked_acquired'})} placement="right">
                      <div><CustomIcon name="task_alt" /></div>
                    </Tooltip>
                  )
                }
                {
                  !isSmallDevice && !questionSubscription.isAcquired && acquisitionLevel < 100 && (
                    <Tooltip title={intl.formatMessage({id: 'question_acquisition_level'})} placement="right">
                      <div>
                        <CustomProgress size={25} value={acquisitionLevel} />
                      </div>
                    </Tooltip>
                  )
                }
                {
                  !isSmallDevice && !questionSubscription.isAcquired && acquisitionLevel === 100 && (
                    <Tooltip title={intl.formatMessage({id: 'question_acquisition_full'})} placement="right">
                      <div><CustomIcon name="task_alt" /></div>
                    </Tooltip>
                  )
                }
              </Stack>
            </Stack>
          </div>
        {/* </Link> */}
        <div className="icon_button ripple" onClick={e => setMenuAnchorEl(e.currentTarget)}>
          <CustomIcon name="more_horiz" />
        </div>
      </Stack>

      <Menu
        sx={isLargeDevice ? { ml: 1 } : { mt: 1 }}
        onClose={() => setMenuAnchorEl(null)}
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        autoFocus={false}
        anchorOrigin={isLargeDevice ? {
          vertical: 'top',
          horizontal: 'right',
        } : {
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={isLargeDevice ? {
          vertical: 'top',
          horizontal: 'left',
        } : {
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => {
          setMenuAnchorEl(null)
          updateIsAcquired(questionSubscription._id!, !questionSubscription.isAcquired)
        }}>
          <Stack direction="row" spacing={1}>
            <span>{intl.formatMessage({id: 'question_set_acquired'})}</span>
            { questionSubscription.isAcquired && (<CustomIcon name="check" />) }
          </Stack>
        </MenuItem>

        {
          canUserEditSubject && (
            <Link to={`/subject-subscription/${subjectSubscription._id}/update-question/${question._id}`} className="full_width">
              <MenuItem><span>{intl.formatMessage({id: 'edit'})}</span></MenuItem>
            </Link>
          )
        }

        <MenuItem onClick={() => {
          setOpenInfosModal(true)
          setMenuAnchorEl(null)
        }}>
          <span>{intl.formatMessage({id: 'study_all_display_infos'})}</span>
        </MenuItem>
        
        <MenuItem onClick={() => {
          setOpenDeleteQuestionModal(true)
          setMenuAnchorEl(null)
        }}>
          {
            canUserEditSubject ? (
              <span>{intl.formatMessage({id: 'delete'})}</span>
            ) : (
              <span>{intl.formatMessage({id: 'delete_from_space'})}</span>
            )
          }
        </MenuItem>
      </Menu>

      <DeleteQuestionModal 
        onClose={() => setOpenDeleteQuestionModal(false)}
        questionSubscription={questionSubscription}
        onQuestionDeleted={loadSubjectSubscription}
        subjectSubscription={subjectSubscription}
        open={openDeleteQuestionModal}
      />

      <QuestionSubscriptionInfosModal 
        questionSubscription={questionSubscription}
        subjectSubscription={subjectSubscription}
        onClose={() => setOpenInfosModal(false)}
        open={openInfosModal}
      />
    </>
  )
}

export default QuestionsItem