import React from 'react'
import { Stack } from '@mui/material'

import { Question } from '../../../../../common/types'

import './styles.scss'

interface QuestionsItemProps {
  question: Question,
}

const QuestionsItem = ({ question }: QuestionsItemProps): JSX.Element => {
  return (
    <div className="question_item_container">
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
        <span>{(question.content as any).question}</span>
      </Stack>
    </div>
  )
}

export default QuestionsItem