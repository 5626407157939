import React from 'react'
import { Box, CircularProgress } from '@mui/material'

import { palette } from '../../theme'
import './styles.scss'

interface CustomProgressProps {
  color?: 'primary' | 'secondary' | 'info' | 'warning' | 'error' | 'success',
  value: number,
  size: number,
}

const CustomProgress = ({
  color,
  value,
  size,
}: CustomProgressProps): JSX.Element => {
  return (
    <Box className="custom_progress_container" width={size} height={size}>
      <div className="custom_progress">
        <CircularProgress 
          sx={{ color: 'lightgrey' }}
          variant="determinate" 
          color="secondary"
          thickness={5}
          value={100} 
          size={size}
        />
      </div>
      <div className="custom_progress">
        <CircularProgress 
          sx={{ color: color ? palette[color].main : palette.primary.main }}
          variant="determinate" 
          color={color || "primary"}
          thickness={5}
          value={value} 
          size={size}
        />
      </div>
    </Box>
  )
}

export default CustomProgress