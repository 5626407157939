import React, { useContext, useState } from 'react'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { compact } from 'lodash'

import SubscribeToSubjectModal from '../../../../common/components/subject/subscribe-to-subject-modal'
import InfoTile from '../../../subject-subscription/pages/subject-subscription/components/info-tile'
import CategoriesChips from '../../../../common/components/categories/categories-chips'
import { DeviceTypesEnum, useResponsive } from '../../../../common/hooks/responsive'
import ImageDisplay from '../../../../common/components/image-display'
import { useLoadSubject } from '../../../../common/hooks/subjects'
import CustomIcon from '../../../../common/components/custom-icon'
import QuestionsList from '../../components/questions-list'
import { AuthContext } from '../../../../common/contexts'
import Title from '../../../../common/components/title'
import { Question } from '../../../../../common/types'

import './styles.scss'

const SubjectPage = (): JSX.Element | null => {
  const [openSubscribeToSubjectModal, setOpenSubcribeToUpdatesModal] = useState<boolean>(false)
  const { redirectToLogin, isLogged } = useContext(AuthContext)
  const { subject } = useLoadSubject()
  const responsive = useResponsive()
  const intl = useIntl()

  if (!subject) return null

  const onSubscribeToSubject = () => {
    if (isLogged) {
      setOpenSubcribeToUpdatesModal(true)
    } else {
      redirectToLogin()
    }
  }

  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <div className="page_content subject_page_container">
      <Stack spacing={isSmallDevice ? 3 : 3}>
        <Stack spacing={1}>
          <Title
            title={`${subject.label}`}
            rightButtons={isSmallDevice ? [] : compact([
              subject.subjectSubscriptionId ? {
                link: `/subject-subscription/${subject.subjectSubscriptionId}`,
                label: intl.formatMessage({id: 'subject_open_in_my_account'}),
                endIcon: 'link',
                variant: 'outlined',
                color: 'secondary',
                bold: false,
              } : undefined,              
              subject.subjectSubscriptionId ? undefined : {
                link: `/subject/${subject._id}/try`,
                label: intl.formatMessage({id: 'subject_try_it'}),
                endIcon: 'keyboard_arrow_right',
                variant: subject.subjectSubscriptionId ? 'contained' : 'outlined',
                color: subject.subjectSubscriptionId ? 'primary' : 'secondary',
                bold: !!subject.subjectSubscriptionId,
              },
              subject.subjectSubscriptionId ? undefined : {
                onClick: () => onSubscribeToSubject(),
                label: intl.formatMessage({id: 'subject_add_long'}),
                endIcon: 'add',
              }
            ])}
          />
        </Stack>

        <Stack direction={isSmallDevice ? 'column' : 'row'} spacing={2} alignItems="flex-start">
          {
            subject.image && (
              <ImageDisplay 
                image={subject.image as string} 
                height="250px"
                width="425px"
                fillContainer
              />
            )
          }

          {
            isSmallDevice && !subject.subjectSubscriptionId && (
              <Stack 
                alignItems="center" 
                direction="row" 
                width="100%"
                spacing={1} 
              >
                <Link to={`/subject/${subject._id}/try`} style={{ flex: 1 }}>
                  <Button 
                    sx={{ width: '100%' }}
                    variant="outlined"
                    color="secondary" 
                  >
                    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                      <p>{intl.formatMessage({id: 'subject_try_it'})}</p>
                      <CustomIcon name="keyboard_arrow_right" />
                    </Stack>
                  </Button>
                </Link>
                
                <Button
                  onClick={() => onSubscribeToSubject()}
                  variant="contained"
                  sx={{ flex: 1 }}
                  color="primary"
                >
                  <Stack direction="row" spacing={1} width="100%" justifyContent="space-between">
                    <b>{intl.formatMessage({id: 'subject_add_short'})}</b>
                    <CustomIcon name="add" />
                  </Stack>
                </Button>
              </Stack>
            )
          }

          <Stack spacing={2} flex={1} width="100%">
            <Stack 
              direction={isLargeDevice ? 'row' : 'column'} 
              spacing={isSmallDevice ? 1 : 2} 
              width="100%"
              flex={1}
            >
              <InfoTile 
                info={subject.questions?.length || 0}
                title={intl.formatMessage({id: 'subject_questions'})}
              />

              <InfoTile 
                info={subject.membersCount || 0}
                title={intl.formatMessage({id: 'subject_users'})}
              />

              <InfoTile 
                title={intl.formatMessage({id: 'subjects_studies'})}
                info={subject.studiesCount || 0}
              />
            </Stack>
            
            {
              isLargeDevice && subject.image && (
                <CategoriesChips 
                  categoriesIds={subject.categories || []} 
                  groupCategories
                />
              )
            }
          </Stack>
        </Stack>
        
        <Stack spacing={1}>
          {
            subject.description && (
              <Title 
                title={intl.formatMessage({id: 'subject_description'})}
                withoutDot
                level={2}
              />
            )
          }

          {
            subject.description && (
              <Stack 
                alignItems="flex-start" 
                className="panel" 
                direction="row"
                spacing={1} 
              >
                <p className="text_italic" style={{ flex: 1 }}>{ subject.description || intl.formatMessage({id: 'subject_no_description'}) }</p>
              </Stack>  
            )
          }

          {
            !(isLargeDevice && subject.image) && (
              <CategoriesChips 
                categoriesIds={subject.categories || []} 
                groupCategories
              />
            )
          }
        </Stack>


        <Stack spacing={2}>
          <Title title={intl.formatMessage({id: 'subject_questions'})} withoutDot level={2} />
          <QuestionsList questions={(subject.questions as Question[]) || []} />
        </Stack>
      </Stack>

      {
        openSubscribeToSubjectModal && (
          <SubscribeToSubjectModal
            onClose={() => setOpenSubcribeToUpdatesModal(false)}
            open={openSubscribeToSubjectModal}
            subject={subject}
          />
        )
      }
    </div>
  )
}

export default SubjectPage
