import React, { useEffect } from 'react'
import { Button, Stack, TextField } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Credentials } from '../../../../../common/types'
import CustomIcon from '../../custom-icon'

interface LoginFormProps {
  setIncorrectPassword: (nextVal: boolean) => void,
  onSubmit: (credentials: Credentials) => void,
  setUnknownUser: (nextVal: boolean) => void,
  incorrectPassword: boolean,
  unknownUser: boolean,
}

const LoginForm = ({
  setIncorrectPassword,
  incorrectPassword,
  setUnknownUser,
  unknownUser,
  onSubmit,
}: LoginFormProps): JSX.Element => {
  const { register, handleSubmit, formState: { errors }, control } = useForm<Credentials>({
    defaultValues: {
      password: '',
      mail: '',
    },
  })

  const values = useWatch<Credentials>({ control })
  const intl = useIntl()

  useEffect(() => {
    if (unknownUser) {
      setUnknownUser(false)
    }
    if (incorrectPassword) {
      setIncorrectPassword(false)
    }
  }, [values])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} alignItems="center">
        <Stack spacing={1}>
          <TextField
            placeholder={intl.formatMessage({id: 'login_form_mail'})}
            size="small"
            type="email"
            error={!!errors.mail || unknownUser}
            inputProps={{ autoComplete: 'username' }}
            {...register('mail', { 
              required: true,
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
            })}
          />
          <TextField
            placeholder={intl.formatMessage({id: 'login_form_password'})}
            type="password"
            size="small"
            error={!!errors.password || incorrectPassword}
            inputProps={{ autoComplete: 'current-password' }}
            {...register('password', { required: true })}
          />
        </Stack>
        
        {
          incorrectPassword && (
            <p className="text_error">{intl.formatMessage({id: 'login_form_incorrect_password'})}</p>
          )
        }

        {
          unknownUser && (
            <p className="text_error">{intl.formatMessage({id: 'login_form_unknown_user'})}</p>
          )
        }

        <Button 
          endIcon={(<CustomIcon name="keyboard_arrow_right" />)}
          variant="contained"
          color="primary"
          type="submit"
        >
          {intl.formatMessage({id: 'login_form_submit'})}
        </Button>
      </Stack>
    </form>
  )
}

export default LoginForm