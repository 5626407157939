import React, { useState, useEffect } from 'react'
import { compact, isNil } from 'lodash'
import { Stack } from '@mui/material'

import { AcquisitionStatusEnum, Question, QuestionTypesEnum } from '../../../../../common/types'
import { DeviceTypesEnum, useResponsive } from '../../../../common/hooks/responsive'
import TextQuestionPanel from '../../../study/components/text-question-panel'
import QcmQuestionPanel from '../../../study/components/qcm-question-panel'
// import { getCookie, setCookie } from '../../../../common/utils/cookies'
import QuestionCount from '../../../study/components/question-count'
import { useLoadSubject } from '../../../../common/hooks/subjects'
import ResultPrompt from '../../../study/components/result-prompt'
import StudySumUp from '../../../study/components/study-sum-up'
import Title from '../../../../common/components/title'

interface StudyTrial {
  [subbjectId: string]: {
    [questionId: string]: AcquisitionStatusEnum,
  },
}

// const cookieKey: string = 'study_space_repeat_study_trials'

const TrySubjectPage = (): JSX.Element | null => {
  const [currentQuestion, setCurrentQuestion] = useState<Question | undefined>()
  const [studyTrials, setStudyTrials] = useState<StudyTrial | undefined>()
  const [questionIndex, setQuestionIndex] = useState<number>(-1)
  const [isStudyDone, setIsStudyDone] = useState<boolean>(false)

  const { subject } = useLoadSubject()
  const responsive = useResponsive()

  useEffect(() => {
    initStudy()
  }, [subject])

  // useEffect(() => {
  //   if (!studyTrials) return
  //   setCookie(cookieKey, JSON.stringify(studyTrials))
  // }, [studyTrials])

  useEffect(() => {
    if (!subject || !studyTrials || !studyTrials[subject._id!]) return

    const question: Question | undefined = (subject.questions as Question[]).find((question: Question, index: number) => {
      if (isNil(studyTrials[subject._id!][question._id!])) {
        setQuestionIndex(index)
        return true
      }
      return false
    })

    if (question) {
      setCurrentQuestion(question)
      setIsStudyDone(false)      
    } else {
      setCurrentQuestion(undefined)
      setIsStudyDone(true)
    }
  }, [studyTrials, subject])

  const initStudy = (): void => {
    if (subject && subject._id) {
      // const studyTrialCookie: string | undefined = getCookie(cookieKey)
      // const cachedStudyTrials = studyTrialCookie ? JSON.parse(studyTrialCookie) : undefined

      // if (cachedStudyTrials) {
      //   setStudyTrials(cachedStudyTrials)
      // } else {
        setStudyTrials({[subject._id]: {}})
      // }
    }
  }

  const onSubmitResponse = (status: AcquisitionStatusEnum): void => {
    if (!studyTrials || !subject || !currentQuestion) return
    
    setStudyTrials({
      ...studyTrials,
      [subject._id!]: {
        ...studyTrials[subject._id!],
        [currentQuestion._id!]: status,
      },
    })
  }

  if (!subject || !studyTrials) return null
  
  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isSmallDevice: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)

  return (
    <Stack spacing={3} pb="85px" className="page_content subject_page_container">
       <Title
        wrapTitle={isSmallDevice}
        title={subject.label}
        rightContent={(isLargeDevice && currentQuestion) ? (
          <QuestionCount
            questionsCount={(subject.questions || []).length}
            questionIndex={questionIndex || 0}
          />
        ) : undefined}
      />

      {
        (currentQuestion && !isStudyDone) && (
          <Stack alignItems="center" spacing={2}>
              {
                !isLargeDevice && (
                  <QuestionCount
                    questionsCount={(subject.questions || []).length}
                    questionIndex={questionIndex || 0}
                  />
                )
              }
            <Stack spacing={2}>
              
              {
                currentQuestion.type === QuestionTypesEnum.TEXT && (
                  <TextQuestionPanel question={currentQuestion} />
                )
              }

              {
                currentQuestion.type === QuestionTypesEnum.QCM && (
                  <QcmQuestionPanel question={currentQuestion} />
                )
              }

              <ResultPrompt onSubmitQuestionProgress={onSubmitResponse} />
            </Stack>
          </Stack>
        )
      }

      {
        isStudyDone && (
          <StudySumUp 
            questionsCount={(subject.questions || []).length}
            subjectUrl={`/subject/${subject._id!}`}
            questionsInfos={compact(
              (subject.questions || []).map((question: Question) => {
                if (!!studyTrials[subject._id!] && !!studyTrials[subject._id!][question._id!]) {
                  return {
                    acquisitionStatus: studyTrials[subject._id!][question._id!],
                    questionSubscriptionId: question._id,
                  }
                }
                return undefined
              })
            )}
          />
        )
      }

    </Stack>
  )
}

export default TrySubjectPage
