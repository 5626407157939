import React, { useContext, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import { Redirect, useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { displayInfoToast, displaySuccessToast, displayWarningToast } from '../../common/utils/toast'
import SubscribeToSubjectModal from '../../common/components/subject/subscribe-to-subject-modal'
import { AuthContext, InvitesContext, LoaderContext, UserContext } from '../../common/contexts'
import CustomIcon from '../../common/components/custom-icon'
import { useRequest } from '../../common/hooks/request'
import Title from '../../common/components/title'
import { 
  TextQuestionContent, 
  QcmQuestionContent, 
  InviteStatusEnum, 
  Question, 
  Subject, 
  User,
} from '../../../common/types'

const InvitePage = (): JSX.Element | null => {
  const [openSubscribeToSubjectModal, setOpenSubcribeToUpdatesModal] = useState<boolean>(false)
  const [openDeclineInviteModal, setOpenDeclineInviteModal] = useState<boolean>(false)
  const [isUnknownInvite, setIsUnknownInvite] = useState<boolean>(false)

  const { inviteId } : { inviteId: string } = useParams()

  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { isLogged, redirectToLogin } = useContext(AuthContext)
  const { loadInvite, invite } = useContext(InvitesContext)
  const { user } = useContext(UserContext)
  const { postData } = useRequest()
  const history = useHistory()
  const intl = useIntl()

  useEffect(() => {
    if (isLogged) {
      fetchInvite()
    }
  }, [inviteId, isLogged])

  const fetchInvite = async () => {
    setIsUnknownInvite(!(await loadInvite(inviteId)))
  }

  const onDeclineInvite = async (): Promise<void> => {
    setOpenDeclineInviteModal(false)
    displayLoader()

    const response = await postData('/invite/decline', { inviteId })

    hideLoader()
    if (response.success) {
      displaySuccessToast(intl.formatMessage({id: 'invite_page_refuse_success'}))
      history.push('/subject-subscriptions')
    } else {
      displayWarningToast(intl.formatMessage({id: 'invite_page_error'}))
    }
  }

  if (!isLogged) {
    displayInfoToast(intl.formatMessage({id: 'invite_page_login'}))
    redirectToLogin()
  }

  if (isUnknownInvite) {
    return (
      <div className="page_content">
        <Stack alignItems="center" spacing={1}>
            <h1> {intl.formatMessage({id: 'invite_page_unknown'})} </h1>
            <Link to="/">
              <Button
                endIcon={(<CustomIcon name="undo" />)}
                variant="outlined"
                color="secondary"
              >
                {intl.formatMessage({id: 'invite_page_go_back'})}
              </Button>
            </Link>
          </Stack>
      </div>
    )
  }

  if (!invite) return null
  
  // Note : is also checked in API
  if (user && (invite.user as User)._id !== user._id) {
    displayInfoToast(intl.formatMessage({id: 'invite_page_unauthorized'}))
    return (
      <Redirect to="/" />
    )
  }

  if (invite && invite.status !== InviteStatusEnum.SENT) {
    displayInfoToast(intl.formatMessage({id: 'invite_page_already_used'}))
    return (
      <Redirect to="/" />
    )
  }

  const subject: Subject = invite.subject as Subject
  const author: User = invite.author as User 
  const questions: Question[] = subject.questions as Question[]

  return (
    <div className="page_content">
      <Stack spacing={2}>
        <div className="panel">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} alignItems="center">
              <CustomIcon name="mail" outlined size={30} />
              <Stack>
                <b>{intl.formatMessage({id: 'invite_page_text_1'},{rightLabel: `subject_role_${invite.role}`})}</b>
                <p>{intl.formatMessage({id: 'invite_page_text_2'},{author: author.pseudo})}</p>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <Button color="secondary" variant="outlined" onClick={() => setOpenDeclineInviteModal(true)}>
                {intl.formatMessage({id: 'invite_page_refuse'})}
              </Button>
              <Button color="primary" variant="contained" onClick={() => setOpenSubcribeToUpdatesModal(true)}>
                {intl.formatMessage({id: 'invite_page_accept'})}
              </Button>
            </Stack>
          </Stack>
        </div>

        <Title title={subject.label} />
        
        {
          questions.length === 0 ? (
            <div className="empty_content_container">
              <CustomIcon name="list" outlined />
              <p>{intl.formatMessage({id: 'invite_page_no_question'})}</p>
            </div>
          ) : (
            <Stack spacing={1}>
              {
                questions.map((question: Question, index: number) => {
                  const questionContent: TextQuestionContent | QcmQuestionContent = question.content as TextQuestionContent | QcmQuestionContent
                  
                  return (
                    <Stack key={`question_${index}`} className="row" direction="row" spacing={1}>
                      <p>{ questionContent.question }</p>
                    </Stack>
                  )
                })
              }
            </Stack>
          )
        }

      </Stack>
      
      {
        openSubscribeToSubjectModal && (
          <SubscribeToSubjectModal 
            onClose={() => setOpenSubcribeToUpdatesModal(false)}
            open={openSubscribeToSubjectModal}
            subject={subject}
            invite={invite}
          />
        )
      }

      <Dialog
        onClose={() => setOpenDeclineInviteModal(false)}
        open={openDeclineInviteModal}
      >
        <DialogTitle>
          {intl.formatMessage({id: 'invite_page_refuse_modal_title'})}
        </DialogTitle>
        <DialogContent>
          <p> {intl.formatMessage({id: 'invite_page_refuse_modal_text'})} </p>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => setOpenDeclineInviteModal(false)}>
            {intl.formatMessage({id: 'no'})}
          </Button>
          <Button variant="outlined" color="secondary" onClick={onDeclineInvite}>
            {intl.formatMessage({id: 'yes'})}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default InvitePage
