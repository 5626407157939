import { Stack } from '@mui/material'
import React from 'react'

interface FeatureTileProps {
  description: string,
  title: string,
}

const FeatureTile = ({
  description,
  title,
}: FeatureTileProps): JSX.Element => {
  return (
    <Stack flex={1} className="feature_tile">
      <h3>{title}</h3>
      <p>{description}</p>
    </Stack>
  )
}

export default FeatureTile
