import React, { useContext } from 'react'
import { Button, Checkbox, IconButton, Stack, TextField, Tooltip } from '@mui/material'
import { useForm, useFieldArray } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { maxQcmQuestionResponsesCount, minQcmQuestionResponsesCount } from '../../../../../../common/constants'
import { QcmQuestionContent, QcmResponse, Question, QuestionTypesEnum } from '../../../../../../common/types'
import ImagesPromptButton from '../../components/images/images-prompt-button'
import SelectQuestionType from '../../components/select-question-type'
import FormImagesViewer from '../../components/images/images-viewer'
import { useResponsive } from '../../../../hooks/responsive'
import QcmQuestionFormSubmitButtons from './submit-buttons'
import { compressImage } from '../../../../utils/image'
import { UserContext } from '../../../../contexts/user'
import CustomIcon from '../../../custom-icon'

import './styles.scss'

interface QcmQuestionFormProps {
  onSubmit: (formData: FormData, addAnotherQuestionAfterSubmit?: boolean) => Promise<void>,
  subjectSubscriptionId: string,
  question?: Question,
  subjectId: string,
}

const defaultResponse: QcmResponse = {
  isCorrect: false,
  response: '',
}

const defaultValues: QcmQuestionContent = {
  responses: [{...defaultResponse}, {...defaultResponse}],
  explanation: '',
  question: '',
}

const QcmQuestionForm = ({
  subjectSubscriptionId,
  subjectId,
  question,
  onSubmit,
}: QcmQuestionFormProps): JSX.Element => {
  const { user } = useContext(UserContext)
  const responsive = useResponsive()
  const intl = useIntl()

  const { register, handleSubmit, reset, control, setValue } = useForm<QcmQuestionContent>({
    defaultValues: (question?.content as QcmQuestionContent) || defaultValues,
  });

  const {
    append: appendResponse,
    remove: removeResponse,
    update: updateResponse,
    fields: responses,
  } = useFieldArray<QcmQuestionContent>({
    name: 'responses',
    keyName: 'id',
    control,
  });

  const canAddResponses: boolean = (responses.length < maxQcmQuestionResponsesCount)

  const onAddResponse = (): void => {
    if (canAddResponses) {
      appendResponse(defaultResponse)
    }
  }

  const onSubmitForm = async (
    qcmQuestionContent: QcmQuestionContent,  
    addAnotherQuestionAfterSubmit?: boolean,
  ): Promise<void> => {
    const formData = new FormData()

    formData.append('question', JSON.stringify({
      ...question,
      type: QuestionTypesEnum.QCM,
      subject: subjectId,
      author: user!._id!,
      content: {
        ...qcmQuestionContent,
        images: (qcmQuestionContent.images || []).filter((image: string | File) => !(image instanceof File)),
      },
    }))

    formData.append('subjectSubscriptionId', subjectSubscriptionId)

    await Promise.all(
      (qcmQuestionContent.images || []).map(async (image: string | File) => {
        if (image instanceof File) {
          const compressedImage: File | Blob = await compressImage(image)
          formData.append('images', compressedImage, image.name)
        }
      })
    )

    onSubmit(formData, addAnotherQuestionAfterSubmit)

    if (addAnotherQuestionAfterSubmit) {
      reset()
    }
  }

  const isSmallDevice: boolean = responsive.isSmallDevice()
  const isEdit: boolean = !!question

  return (
    <>
      <form onSubmit={handleSubmit((values: QcmQuestionContent) => onSubmitForm(values))}>
        <Stack spacing={1}>

          {
            (isSmallDevice && !isEdit) && (
              <SelectQuestionType selectedQuestionType={QuestionTypesEnum.QCM} />
            )
          }

          <FormImagesViewer 
            setValue={setValue as any}
            control={control as any}
            fieldName="images"
          />

          <Stack direction="row" spacing={1}>
            <TextField 
              {...register('question', { required: true })}
              placeholder={intl.formatMessage({id: 'question_form_question'})}
              sx={{ flex: 1 }}
              size="small"
              multiline
            />

            <ImagesPromptButton
              setValue={setValue as any}
              control={control as any}
              small={isSmallDevice}
              fieldName="images"
              multiple
            />
          </Stack>

          <Stack spacing={1}>
            {
              responses.map((qcmResponse: QcmResponse | string, index: number) => {
                const response = qcmResponse as QcmResponse
                return (
                  <Stack key={`qcm_response_${index}`} direction="row" alignItems="flex-start" spacing={1}>
                    <Tooltip title={intl.formatMessage({id: 'good_answer'})} placement="left">
                      <Checkbox 
                        checked={Boolean(response.isCorrect)} 
                        onChange={() => updateResponse(index, {
                          ...response,
                          isCorrect: !response.isCorrect,
                        })}
                      />
                    </Tooltip>

                    <TextField
                      placeholder={intl.formatMessage({id: 'question_form_response'}, {num: index + 1})}
                      value={response.response}
                      sx={{ flex: 1 }}
                      size="small"
                      multiline
                      onChange={(e) => {
                        updateResponse(index, {
                          ...response,
                          response: e.target.value,
                        })
                      }}
                    />

                    {
                      index >= minQcmQuestionResponsesCount && (
                        <Tooltip title={intl.formatMessage({id: 'delete'})} placement="right">
                          <IconButton onClick={() => removeResponse(index)}>
                            <CustomIcon name="delete" />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  </Stack>
                )
              })
            }
            
            <Stack 
              alignItems={isSmallDevice ? 'flex-end' : 'center'} 
              direction={isSmallDevice ? 'column' : 'row'} 
              justifyContent="flex-end" 
              spacing={isSmallDevice ? 1 : 2}
            >
              {!canAddResponses && (<p style={{ fontStyle: 'italic', color: 'grey' }}> {intl.formatMessage({id: 'question_form_max_answers'})} </p>)}
              <Button
                endIcon={(<CustomIcon name="add" />)}
                disabled={!canAddResponses}
                onClick={onAddResponse}
                className="bg_light"
                variant="outlined"
                color="secondary"
              >
                {intl.formatMessage({id: 'question_form_add_answer'})}
              </Button>
            </Stack>
          </Stack>
          
          <TextField
            {...register('explanation')}
            placeholder={intl.formatMessage({id: 'question_form_explanation'})}
            size="small"
            minRows={4}
            multiline
            fullWidth
          />

          <QcmQuestionFormSubmitButtons 
            defaultValues={(question?.content as QcmQuestionContent) || defaultValues}
            onSubmitForm={onSubmitForm}
            control={control}
            isEdit={isEdit}
            reset={reset}
          />
        </Stack>
      </form>
    </>
  )
}

export default QcmQuestionForm