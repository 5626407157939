import React, { useContext } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'

import { displaySuccessToast, displayWarningToast } from '../../utils/toast'
import { Invite, Subject } from '../../../../common/types'
import { useRequest } from '../../hooks/request'
import { LoaderContext } from '../../contexts'

interface SubscribeToSubjectModalProps {
  onClose: () => void,
  subject: Subject,
  invite?: Invite,
  open: boolean,
}

const SubscribeToSubjectModal = ({
  subject,
  onClose,
  invite,
  open,
}: SubscribeToSubjectModalProps): JSX.Element => {
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { postData } = useRequest()
  const history = useHistory()
  const intl = useIntl()

  const onSubmit = async (): Promise<void> => {
    displayLoader()

    const response = await postData('/subject-subscription', {
      subjectId: subject._id,
      inviteId: invite?._id,
    })

    hideLoader()

    if (response.success) {
      displaySuccessToast(intl.formatMessage({id: 'subscribe_subject_modal_success'}))
      history.push('/subject-subscriptions')
    } else {
      displayWarningToast(intl.formatMessage({id: 'subscribe_subject_modal_fail'}))
    }
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        {intl.formatMessage({id: 'subscribe_subject_modal_title'}, {subjectLabel: subject.label || ''})}
      </DialogTitle>
      <DialogContent>
        <p> {intl.formatMessage({id: 'subscribe_subject_modal_confirm'}, {subjectLabel: subject.label || ''})} </p>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {intl.formatMessage({id: 'cancel'})}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          <b>{intl.formatMessage({id: 'confirm'})}</b>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SubscribeToSubjectModal