import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Stack } from '@mui/material'

import './styles.scss'

const PrivacyPolicyPage = (): JSX.Element => {
  return (
    <Stack className="page_content privacy_policy_page_container" spacing={2}>
      <h1 className="text_center"><FormattedMessage id="privacy_1" /></h1>
      
      <p><FormattedMessage id="privacy_2" /></p>
      
      <p><FormattedMessage id="privacy_3" /></p>
      
      <Stack spacing={1}>
        <h2><FormattedMessage id="privacy_4" /></h2>
        <h3><FormattedMessage id="privacy_5" /></h3>
        <p><FormattedMessage id="privacy_6" /></p>
        
        <h3><FormattedMessage id="privacy_7" /></h3>
        <p><FormattedMessage id="privacy_8" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="privacy_9" /></h2>
        <h3><FormattedMessage id="privacy_10" /></h3>
        <p><FormattedMessage id="privacy_11" /></p>

        <h3><FormattedMessage id="privacy_12" /></h3>
        <p><FormattedMessage id="privacy_13" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="privacy_14" /></h2>
        <h3><FormattedMessage id="privacy_15" /></h3>
        <p><FormattedMessage id="privacy_16" /></p>

        <h3><FormattedMessage id="privacy_17" /></h3>
        <p><FormattedMessage id="privacy_18" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="privacy_19" /></h2>
        <h3><FormattedMessage id="privacy_20" /></h3>
        <p><FormattedMessage id="privacy_21" /></p>

        <h3><FormattedMessage id="privacy_22" /></h3>
        <p><FormattedMessage id="privacy_23" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="privacy_24" /></h2>
        <h3><FormattedMessage id="privacy_25" /></h3>
        <p><FormattedMessage id="privacy_26" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="privacy_27" /></h2>
        <h3><FormattedMessage id="privacy_28" /></h3>
        <p><FormattedMessage id="privacy_29" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="privacy_30" /></h2>
        <h3><FormattedMessage id="privacy_31" /></h3>
        <p><FormattedMessage id="privacy_32" /></p>
      </Stack>

      <Stack spacing={1}>
        <h2><FormattedMessage id="privacy_33" /></h2>
        <h3><FormattedMessage id="privacy_34" /></h3>
        <p><FormattedMessage id="privacy_35" /></p>
      </Stack>

      <p><FormattedMessage id="privacy_36" /></p>
    </Stack>
  )
}

export default PrivacyPolicyPage
