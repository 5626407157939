import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import CustomIcon from '../../../common/components/custom-icon'

interface ExplanationDialogProps {
  onClose: () => void,
  explanation: string,
  open: boolean,
}

const ExplanationDialog = ({
  explanation,
  onClose,
  open,
}: ExplanationDialogProps): JSX.Element => {
  const intl = useIntl()

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{ minWidth: '500px' }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          {intl.formatMessage({id: 'explanation'})}
          <IconButton onClick={onClose}>
            <CustomIcon name="clear" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: '300px' }}>
        <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
          {explanation}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          {intl.formatMessage({id: 'close'})}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExplanationDialog
