import React, { useContext, useState } from 'react'
import { Button } from '@mui/material'
import { useIntl } from 'react-intl'

import { displayInfoToast, displaySuccessToast, displayWarningToast } from '../utils/toast'
import { useRequest } from '../hooks/request'
import { LoaderContext } from '../contexts'
import CustomIcon from './custom-icon'

const SendVerifyEmailButton = (): JSX.Element => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { fetchData } = useRequest()
  const intl = useIntl()

  const sendVerificationEmail = async (): Promise<void> => {
    disableButton()
    displayLoader()
    const response = await fetchData('/user/send-verification-email')
    hideLoader()

    if (response === 'email already verified') {
      displayWarningToast(intl.formatMessage({id: 'complete_profile_email_already_verified'}))
    } else {
      displaySuccessToast(intl.formatMessage({id: 'complete_profile_email_sent'}))
      displayInfoToast(intl.formatMessage({id: 'complete_profile_email_delay'}))
    }
  }

  const disableButton = () => {
    setIsButtonDisabled(true)
    setTimeout(() => {
      setIsButtonDisabled(false)
    }, 20000)
  }

  return (
    <Button
      endIcon={(<CustomIcon name="refresh" />)}
      onClick={sendVerificationEmail}
      disabled={isButtonDisabled}
      variant="outlined"
      color="secondary"
    >
      {intl.formatMessage({id: 'complete_profile_resend_email'})}
    </Button>
  )
}

export default SendVerifyEmailButton