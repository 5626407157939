import React, { useEffect, useState } from 'react'
import { Button, Stack, TextField } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash'

import { DeviceTypesEnum, useResponsive } from '../../../hooks/responsive'
import GoBackButton from '../../go-back-button'

interface DefinePasswordFormValues {
  passwordConfirm: string,
  password: string,
}

interface DefinePasswordFormProps {
  onSubmit: (password: string) => Promise<void>,
}

const DefinePasswordForm = ({
  onSubmit,
}: DefinePasswordFormProps): JSX.Element => {
  const [displayPasswordsError, setDisplayPasswordError] = useState<boolean>(false)
  
  const defaultValues: DefinePasswordFormValues = {
    passwordConfirm: '',
    password: '',
  }

  const { register, handleSubmit, reset, control, formState: { errors } } = useForm<DefinePasswordFormValues>({ defaultValues })
  const values = useWatch<DefinePasswordFormValues>({ control })
  const responsive = useResponsive()
  const intl = useIntl()

  useEffect(() => {
    if (displayPasswordsError) {
      setDisplayPasswordError(false)
    }
  }, [values])

  const onFormSubmit = (values: DefinePasswordFormValues) => {
    if (values.password !== values.passwordConfirm) {
      setDisplayPasswordError(true)
    } else {
      onSubmit(values.password)
    }
  }

  const hasChanges: boolean = !isEqual(values, defaultValues)
  const canSubmit: boolean = Boolean(hasChanges && !!values.password && !!values.passwordConfirm)
  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isMobile: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={2}>
        <Stack spacing={2} alignItems="center" className="panel">
          <Stack width="100%">
            <p><b>{intl.formatMessage({id: 'recommendation'})} : </b>{intl.formatMessage({id: 'password_tip'})}</p>
          </Stack>

          <Stack spacing={1} width={isMobile ? '100%' : undefined}>
            <TextField
              {...register('password', { required: false })}
              error={!!errors.password || displayPasswordsError}
              placeholder={intl.formatMessage({id: 'new_password'})}
              type="password"
              size="small"
              fullWidth
            />
            <TextField
              {...register('passwordConfirm', { required: false })}
              placeholder={intl.formatMessage({id: 'new_password_confirm'})}
              error={!!errors.passwordConfirm || displayPasswordsError}
              type="password"
              size="small"
              fullWidth
            />
          </Stack>
        
          {
            displayPasswordsError && (
              <p className="text_error">{intl.formatMessage({id: 'passwords_not_match'})}</p>
            )
          }

        </Stack>
        
        <Stack direction="row" justifyContent={!isDesktop ? 'space-between' : 'flex-end'} spacing={1}>
          {
            !isDesktop && (
              <GoBackButton defaultUrl="/settings" withIcon />
            )
          }
          <Button
            onClick={() => reset(defaultValues)}
            disabled={!hasChanges}
            variant="outlined"
            color="secondary"
          >
            {intl.formatMessage({id: 'reset'})}
          </Button>
          <Button 
            disabled={!canSubmit}
            variant="contained" 
            color="primary" 
            type="submit" 
          >
            {intl.formatMessage({id: 'save'})}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default DefinePasswordForm
