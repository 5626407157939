import React, { useState, useContext, useEffect, useMemo } from 'react'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Stack } from '@mui/material'
import { uniq, difference, isEqual } from 'lodash'
import { useIntl } from 'react-intl'

import { displaySuccessToast, displayWarningToast } from '../../../common/utils/toast'
import { LoaderContext, SubjectSubscriptionsContext } from '../../../common/contexts'
import { QuestionSubscription, SubjectSubscription } from '../../../../common/types'
import { DeviceTypesEnum, useResponsive } from '../../../common/hooks/responsive'
import { decomposeQuestionSubscription } from '../../../../common/utils/question'
import { decomposeSubjectSubscription } from '../../../../common/utils/subject'
import PaginatedList from '../../../common/components/paginated-list'
import GoBackButton from '../../../common/components/go-back-button'
import CustomIcon from '../../../common/components/custom-icon'
import { useRequest } from '../../../common/hooks/request'
import Title from '../../../common/components/title'

const StudyQuestionsSettings = (): JSX.Element => {
  const { displayLoader } = useContext(LoaderContext)
  const responsive = useResponsive()
  const { postData } = useRequest()
  const intl = useIntl()

  const [initialIncludeNewQuestionsSubjectIds, setInitialIncludeNewQuestionsSubjectIds] = useState<string[]>([])
  const [includeNewQuestionsSubjectIds, setIncludeNewQuestionsSubjectIds] = useState<string[]>([])
  
  const [initialSelectedQuestions, setInitialSelectedQuestions] = useState<string[]>([])
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([])
  
  const [allQuestionIds, setAllQuestionsIds] = useState<string[]>([])
  const [allSubjectIds, setAllSubjectIds] = useState<string[]>([])
  
  const [opennedSubjectsId, setOpennedSubjectsId] = useState<string | null>(null)
  const [openHowItWorksModal, setOpenHowItWorksModal] = useState<boolean>(false)
  
  const { loadSubjectSubscriptions, subjectSubscriptions } = useContext(SubjectSubscriptionsContext)
  
  useEffect(() => {
    loadSubjectSubscriptions()
  }, [])

  useEffect(() => {
    if (subjectSubscriptions.length) {
      setInitialValues()
    }
  }, [subjectSubscriptions])

  const setInitialValues = (): void => {
    const nextIncludeNewQuestionsSubjectIds: string[] = []
    const nextSelectedQuestions: string[] = []
    const nextAllQuestionIds: string[] = []
    const nextSubjectIds: string[] = []


    subjectSubscriptions.map((subjectSubscription: SubjectSubscription) => {
      nextSubjectIds.push(subjectSubscription._id!)
      if (subjectSubscription.includeNewQuestionsToGlobalStudy) {
        nextIncludeNewQuestionsSubjectIds.push(subjectSubscription._id!)
      }

      (subjectSubscription.questionSubscriptions || [] as QuestionSubscription[]).map((questionSubscription: QuestionSubscription) => {
        nextAllQuestionIds.push(questionSubscription._id!)
        if (questionSubscription.includeToGlobalStudy) {
          nextSelectedQuestions.push(questionSubscription._id!)
        }
      })
    })

    setInitialIncludeNewQuestionsSubjectIds(nextIncludeNewQuestionsSubjectIds)
    setIncludeNewQuestionsSubjectIds(nextIncludeNewQuestionsSubjectIds)
    
    setInitialSelectedQuestions(nextSelectedQuestions)
    setSelectedQuestions(nextSelectedQuestions)
    
    setAllQuestionsIds(nextAllQuestionIds)
    setAllSubjectIds(nextSubjectIds)
  }

  const onReset = (): void => {
    setIncludeNewQuestionsSubjectIds(initialIncludeNewQuestionsSubjectIds)
    setSelectedQuestions(initialSelectedQuestions)
  }

  const onSelectQuestion = (questionSubscriptionId: string): void => {
    if (selectedQuestions.includes(questionSubscriptionId)) {
      setSelectedQuestions(selectedQuestions.filter((selectedQuestionId: string) => selectedQuestionId !== questionSubscriptionId))
    } else {
      setSelectedQuestions([
        ...selectedQuestions,
        questionSubscriptionId,
      ])
    }
  }

  const onSelectIncludeNewQuestions = (subjectSubscriptionId: string): void => {
    if (includeNewQuestionsSubjectIds.includes(subjectSubscriptionId)) {
      setIncludeNewQuestionsSubjectIds(includeNewQuestionsSubjectIds.filter((subjectId: string) => subjectId !== subjectSubscriptionId))
    } else {
      setIncludeNewQuestionsSubjectIds([
        ...includeNewQuestionsSubjectIds,
        subjectSubscriptionId,
      ])
    }
  }

  const deselectQuestions = (questionSubscriptionIds: string[]): void => {
    setSelectedQuestions(selectedQuestions.filter((selectedQuestionId: string) => !questionSubscriptionIds.includes(selectedQuestionId)))
  }

  const selectQuestions = (questionSubscriptionIds: string[]): void => {
    setSelectedQuestions(uniq([
      ...selectedQuestions,
      ...questionSubscriptionIds,
    ]))
  }

  const toggleHowItWorksModal = (): void => setOpenHowItWorksModal(!openHowItWorksModal)

  const onSubmit = async (): Promise<void> => {
    displayLoader()
    const response = await postData('/user/update-global-study-questions', {
      includeNewQuestionsSubjectIds,
      selectedQuestions,
    })

    if (response.success) {
      displaySuccessToast(intl.formatMessage({id: 'settings_save_success'}))
    } else {
      displayWarningToast(intl.formatMessage({id: 'error_occured'}))
    }

    loadSubjectSubscriptions()
  }

  const includeAllNewQuestionsSelected: boolean = includeNewQuestionsSubjectIds.length === subjectSubscriptions.length
  const includeAllQuestions: boolean = selectedQuestions.length === allQuestionIds.length
  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isMobile: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)

  const canSubmit: boolean = useMemo(() => {
    return !isEqual(selectedQuestions.sort(), initialSelectedQuestions.sort()) || 
      !isEqual(includeNewQuestionsSubjectIds.sort(), initialIncludeNewQuestionsSubjectIds.sort())
  }, [
    initialIncludeNewQuestionsSubjectIds,
    includeNewQuestionsSubjectIds, 
    initialSelectedQuestions,
    selectedQuestions, 
  ])
  
  return (
    <Stack flex={1} spacing={isMobile ? 2 : 1} className="page_content setting_page_content">
      <Title 
        title={intl.formatMessage({id: 'settings_study_questions_title'})}
        rightButtons={isMobile ? [] : [{
          label: intl.formatMessage({id: 'how_it_works'}),
          onClick: toggleHowItWorksModal,
          endIconOutlined: true,
          variant: 'outlined',
          color: 'secondary',
          endIcon: 'help',
          bold: false,
        }]}
      />

      <Stack 
        direction={isMobile ? 'column' : 'row'} 
        gap={isMobile ? 1 : undefined} 
        justifyContent="flex-start" 
        alignItems="center" 
        className="panel"
      >
        <FormControlLabel 
          label={intl.formatMessage({
            id: 'settings_study_questions_include_all',
          },{
            selectedCount: selectQuestions.length, 
            allCount: allQuestionIds.length,
          })}
          control={(
            <Checkbox 
              checked={includeAllQuestions}
              color="primary"
              onChange={() => {
                if (includeAllQuestions) {
                  setSelectedQuestions([])
                } else {
                  setSelectedQuestions(allQuestionIds)
                }
              }}
            />
          )}  
        />
        
        <FormControlLabel 
          label={intl.formatMessage({
            id: 'settings_study_questions_include_new_all',
          },{
            selectedCount: includeNewQuestionsSubjectIds.length, 
            allCount: allSubjectIds.length,
          })}
          control={(
            <Checkbox 
              checked={includeAllNewQuestionsSelected}
              color="primary" 
              onChange={() => {
                if (includeAllNewQuestionsSelected) {
                  setIncludeNewQuestionsSubjectIds([])
                } else {
                  setIncludeNewQuestionsSubjectIds(allSubjectIds)
                }
              }}
            />
          )}  
        />
      </Stack>

      <PaginatedList 
        list={subjectSubscriptions}
        renderItem={(subjectSubscription: SubjectSubscription) => {
          const isOpen: boolean = opennedSubjectsId === subjectSubscription._id

          const { subject, questionSubscriptions } = decomposeSubjectSubscription(subjectSubscription)

          const questionSubscriptionIds: string[] = questionSubscriptions.map((questionSubscription: QuestionSubscription) => questionSubscription._id!)
          const selectedSubjectQuestionsCount: number = questionSubscriptions.length - difference(questionSubscriptionIds, selectedQuestions).length
          const areAllQuestionsSelected: boolean = selectedSubjectQuestionsCount === questionSubscriptions.length

          return (
            <Stack 
              key={`subject_subscription_${subjectSubscription._id}`}
              pb={isOpen ? 2 : undefined}
              spacing={1} 
              flex={1} 
            >
              <Stack
                onClick={() => setOpennedSubjectsId(isOpen ? null : subjectSubscription._id!)}
                justifyContent="space-between"
                alignItems="center"
                direction="row"
                className="row"
                sx={{ 
                  cursor: 'pointer',
                  pl: '20px', 
                }}
              >
                <Stack direction="row" alignItems="center">
                  <Checkbox 
                    checked={areAllQuestionsSelected}
                    color="primary" 
                    onClick={e => e.stopPropagation()}
                    onChange={() => {
                      if (areAllQuestionsSelected) {
                        deselectQuestions(questionSubscriptionIds)
                      } else {
                        selectQuestions(questionSubscriptionIds)
                      }
                    }}
                    sx={{ py: 0, ml: '-11px' }}
                  />
                  <p> {subject.label} </p>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <p>({selectedSubjectQuestionsCount}/{questionSubscriptions.length})</p>
                  <CustomIcon name={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
                </Stack>
              </Stack>

              {
                isOpen && (
                  <Stack spacing={1} >
                    <Stack
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                      className="row"
                      sx={{ 
                        backgroundColor: 'rgba(62, 75, 87, 0.075)' ,
                        cursor: 'pointer',
                        pl: '20px',
                      }}
                    >
                      <FormControlLabel 
                        label={intl.formatMessage({id: 'settings_study_questions_include_new_subject'})}
                        control={(
                          <Checkbox 
                            checked={includeNewQuestionsSubjectIds.includes(subjectSubscription._id!)}
                            onChange={() => onSelectIncludeNewQuestions(subjectSubscription._id!)}
                            color="primary" 
                            sx={{ py: 0 }}
                          />
                        )} 
                      />
                    </Stack>

                    <PaginatedList 
                      list={subjectSubscription.questionSubscriptions || []}
                      spacing={0}
                      renderItem={(questionSubscription: QuestionSubscription) => {
                        const isSelected: boolean = selectedQuestions.includes(questionSubscription._id!)
                        const { question } = decomposeQuestionSubscription(questionSubscription)

                        return (
                          <Stack
                            key={`question_subscription_${questionSubscription._id}`}
                            alignItems="center"
                            sx={{ pl: '20px' }}
                            direction="row"
                            spacing={1}
                          >
                            <FormControlLabel 
                              label={question} 
                              onChange={() => {
                                onSelectQuestion(questionSubscription._id!)
                              }}
                              control={(
                                <Checkbox 
                                  checked={isSelected}
                                  color="primary" 
                                />
                              )} 
                            />
                          </Stack>
                        )
                      }}
                    />
                  </Stack>
                )
              }
          </Stack>
        )}}
      />

      <Stack direction="row" justifyContent={isDesktop ? 'flex-end' : 'space-between'} alignItems="center">
        { !isDesktop && <GoBackButton defaultUrl="/settings" withIcon /> }
        
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button 
            disabled={!canSubmit}
            variant="outlined"
            color="secondary"
            onClick={onReset}
          >
            {intl.formatMessage({id: 'reset'})}
          </Button>
          <Button 
            disabled={!canSubmit}
            variant="contained"
            onClick={onSubmit}
            color="primary"
          >
            {intl.formatMessage({id: 'save'})}
          </Button>
        </Stack>
      </Stack>

      <Dialog
        onClose={toggleHowItWorksModal}
        open={openHowItWorksModal}
        PaperProps={{ sx: { maxWidth: '600px' } }}
      >
        <DialogTitle>
          {intl.formatMessage({id: 'how_it_works'})}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>{intl.formatMessage({id: 'settings_study_questions_modal_1'})}</p>
            <br />
            <p>{intl.formatMessage({id: 'settings_study_questions_modal_2'})}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={toggleHowItWorksModal}
            variant="outlined" 
            color="secondary"
          >
            {intl.formatMessage({id: 'close'})}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default StudyQuestionsSettings
