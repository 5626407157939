import React from 'react'
import { Button } from '@mui/material'

import useRouterUtils from '../../hooks/router'
import CustomIcon from '../custom-icon'
import { useIntl } from 'react-intl'

interface GoBackButtonProps {
  variant?: 'contained' | 'outlined' | 'text',
  defaultUrl?: string,
  withIcon?: boolean,
  className?: string,
}

const GoBackButton = ({
  defaultUrl,
  className,
  withIcon,
  variant,
}: GoBackButtonProps): JSX.Element | null => {
  const { goBackOrRedirect, canGoBack } = useRouterUtils()
  const intl = useIntl()

  const onGoBack = (): void => {
    goBackOrRedirect(defaultUrl)
  }

  if (canGoBack || !!defaultUrl) {
    return (
      <Button 
        startIcon={withIcon ? (<CustomIcon name="keyboard_arrow_left" />) : undefined}
        variant={variant || 'text'} 
        className={className}
        onClick={onGoBack}
        color="secondary" 
      >
        {intl.formatMessage({id: 'go_back'})}
      </Button>
    )
  } else {
    return null
  }
}

export default GoBackButton