import React, { useContext, useEffect, useMemo } from 'react'
import { Button, ClickAwayListener, Divider, Stack } from '@mui/material'
import { matchPath, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import { AuthContext, MobilesMenuContext, UserContext } from '../../contexts'
import { Page, offlineMenuPages, mainPages } from '../../constants/routes'
import { DeviceTypesEnum, useResponsive } from '../../hooks/responsive'
import MobilesMenuRoute from './mobiles-menu-route'
import { stopEvent } from '../../utils/event'
import UserAvatar from '../user/user-avatar'
import CustomIcon from '../custom-icon'
import { palette } from '../../theme'

import './styles.scss'
import LanguagesMenu from '../language-menu'

const MobilesNavigationMenu = (): JSX.Element | null => {
  const { setIsMobilesMenuOpen, isMobilesMenuOpen, toggleMobilesMenu } = useContext(MobilesMenuContext)
  const { isLogged, logout } = useContext(AuthContext)
  const { user } = useContext(UserContext)
  const responsive = useResponsive()
  const location = useLocation()
  const intl = useIntl()

  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)

  const currentPage: Page | undefined = useMemo(() => {
    return mainPages.find((page: Page) => Boolean(matchPath(location.pathname, { path: page.path })))
  }, [location.pathname])

  useEffect(() => {
    if (isMobilesMenuOpen) {
      setIsMobilesMenuOpen(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (isDesktop && isMobilesMenuOpen) {
      setIsMobilesMenuOpen(false)
    }
  }, [isDesktop, isMobilesMenuOpen])

  if (!isMobilesMenuOpen || isDesktop) return null

  return (
    <ClickAwayListener disableReactTree onClickAway={(e) => { stopEvent(e); setIsMobilesMenuOpen(false)}}>
      <Stack spacing={2} className={classnames('mobiles_menu_container', {
        mobiles_menu_close: !isMobilesMenuOpen,
        mobiles_menu_open: isMobilesMenuOpen,
        shadow_light: isMobilesMenuOpen,
      })}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {
            user && (
              <Link to="/settings/profile">
                <Stack direction="row" spacing={2} alignItems="center">
                  <UserAvatar user={user} size={40} />
                  <p>{user.pseudo}</p>
                </Stack>
              </Link>
            )  
          }

          <LanguagesMenu />
        </Stack>
        
        <Divider />
          
        {/* <Stack direction="row" >
          <LanguagesMenu />
        </Stack> */}

        {
          isLogged ? (
            <Stack flex={1} spacing={2}>
              
              {
                currentPage && !currentPage.isMainRoute && (currentPage.pages || []).length && (
                  <>
                    <MobilesMenuRoute page={currentPage} />
                    <Divider />
                  </>
                ) 
              }

              <Stack spacing={1} flex={1}>
                {
                  mainPages.map((page: Page, index: number) => {
                    if (!page.isMainRoute) return null

                    return (
                      <MobilesMenuRoute 
                        key={`mobile_menu_page_${index}`}
                        page={page}  
                      />
                    )
                  })
                }
              </Stack>
              
              <Divider />

              <Button fullWidth onClick={() => logout()}>
                <Stack 
                  justifyContent="center"
                  alignItems="center" 
                  direction="row" 
                  width="100%"
                  spacing={2}
                >
                  <p>{intl.formatMessage({id: 'logout'})}</p>
                  <CustomIcon 
                    color={palette.secondary.main} 
                    name="logout" 
                    outlined 
                  />
                </Stack>
              </Button>
            </Stack>
          ) : (
            <Stack 
              justifyContent="space-between"
              spacing={1} 
              flex={1} 
            >
              <Stack spacing={1}>
                {
                  offlineMenuPages.map((page: Page, index: number) => {
                    const isSelected: boolean = Boolean(matchPath(page.path, { path: location.pathname, exact: true }))

                    return (
                      <Link to={page.path} key={`mobile_menu_page_${index}`}>
                        <Button 
                          color={isSelected ? 'primary' : 'secondary'}
                          fullWidth
                        >
                          <Stack 
                            justifyContent="flex-start"
                            alignItems="center" 
                            direction="row" 
                            width="100%"
                            spacing={2}
                          >
                            <CustomIcon 
                              color={isSelected ? palette.primary.main : palette.secondary.main} 
                              name={page.icon!} 
                              outlined 
                            />
                            { isSelected ? (<b style={{ color: palette.primary.main }}>{intl.formatMessage({id: `page_${page.labelCode}`})}</b>) : (<p>{intl.formatMessage({id: `page_${page.labelCode}`})}</p>) }
                          </Stack>
                        </Button>
                      </Link>
                    )
                  })
                }

              </Stack>
              
              <Stack 
                className="login_buttons"
                alignItems="center" 
                width="100%" 
                spacing={1} 
                pb={2} 
              >
                <Link to="/login">
                  <Button color="secondary" variant="outlined">
                    {intl.formatMessage({id: 'login'})}
                  </Button>
                </Link>

                <Link to="/register">
                  <Button color="primary" variant="contained">
                    {intl.formatMessage({id: 'create_account'})}
                  </Button>
                </Link>
              </Stack>

            </Stack>
          )
        }
      </Stack>
    </ClickAwayListener>
  )
}

export default MobilesNavigationMenu
