import React, { useContext, useState } from 'react'
import { Button, Stack, Menu, MenuItem, Divider } from '@mui/material'
import { useIntl } from 'react-intl'

import { SubjectMember, SubjectSubscriptionUserRoleEnum } from '../../../../../../common/types'
import { useLoadSubjectSubscription } from '../../../../../common/hooks/subjects-subscription'
import { displaySuccessToast, displayWarningToast } from '../../../../../common/utils/toast'
import { LoaderContext, UserContext } from '../../../../../common/contexts'
import CustomIcon from '../../../../../common/components/custom-icon'
import UserAvatar from '../../../../../common/components/user-avatar'
import { useRequest } from '../../../../../common/hooks/request'

interface SubjectMemberRowProps {
  subjectMember: SubjectMember,
  isLast: boolean,
}

const SubjectMemberRow = ({
  subjectMember,
  isLast,
}: SubjectMemberRowProps): JSX.Element | null => {
  const [roleMenuAnchorEl, setRoleMenuAnchorEl] = useState<HTMLElement | null>(null)
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { user } = useContext(UserContext)
  const { postData } = useRequest()
  const intl = useIntl()
  const { 
    subjectSubscription, 
    loadSubjectMembers, 
    subject,
  } = useLoadSubjectSubscription()
  
  if (!subjectSubscription || !subject || !user) return null 

  const onRoleChange = async (nextRole: SubjectSubscriptionUserRoleEnum): Promise<void> => {
    setRoleMenuAnchorEl(null)
    displayLoader()

    const response = await postData('/subject/update-member-role', {
      subjectSubscriptionId: subjectSubscription._id,
      subjectMemberId: subjectMember.userId,
      nextRole,
    }) 

    if (response.success) {
      await loadSubjectMembers()
      displaySuccessToast(intl.formatMessage({id: 'subject_member_role_update_success'}))
    } else {
      displayWarningToast(intl.formatMessage({id: 'error_occured'}))
    }

    hideLoader()
  }

  const onDeleteMember = async (): Promise<void> => {
    setRoleMenuAnchorEl(null)
    displayLoader()

    const response = await postData('/subject/delete-member', {
      subjectSubscriptionId: subjectSubscription._id,
      subjectMemberId: subjectMember.userId,
    }) 

    if (response.success) {
      await loadSubjectMembers()
      displaySuccessToast(intl.formatMessage({id: 'subject_member_access_blocked'}))
    } else {
      displayWarningToast(intl.formatMessage({id: 'error_occured'}))
    }

    hideLoader()
  }

  const isUserSubjectOwner: boolean = subject.author === subjectMember.userId
  const isCurrentUser: boolean = user._id === subjectMember.userId
  
  return (
    <>
      <Stack 
        borderBottom={isLast ? undefined : "1px solid lightgrey"} 
        justifyContent="space-between" 
        alignItems="center"
        direction="row" 
        height="50px"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <UserAvatar picture={subjectMember.picture} size={30} />
          <p>{subjectMember.pseudo}</p>
        </Stack>
        <Stack direction="row" spacing={3} alignItems="center">
          {
            isUserSubjectOwner ? (
              <p>{intl.formatMessage({id: 'subject_owner'})}</p>
            ) : isCurrentUser ? (
              <p>{intl.formatMessage({id: `subject_member_role_label_${subjectMember.role}`})}</p>
            ) : (
              <Button
                endIcon={(<CustomIcon name={false ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />)}
                onClick={e => setRoleMenuAnchorEl(e.currentTarget)}
                variant="outlined"
                color="secondary"
              >
                {intl.formatMessage({id: `subject_member_role_label_${subjectMember.role}`})}
              </Button>
            )
          }
        </Stack>
      </Stack>

      <Menu 
        onClose={() => setRoleMenuAnchorEl(null)}
        anchorEl={roleMenuAnchorEl}
        open={!!roleMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => onRoleChange(SubjectSubscriptionUserRoleEnum.USER)}>
          <Stack direction="row" spacing={1}>
            <span>{intl.formatMessage({id: `subject_member_role_label_${SubjectSubscriptionUserRoleEnum.USER}`})}</span>
            { subjectMember.role === SubjectSubscriptionUserRoleEnum.USER && (<CustomIcon name="check" />) }
          </Stack>
        </MenuItem>

        <MenuItem onClick={() => onRoleChange(SubjectSubscriptionUserRoleEnum.EDITOR)}>
          <Stack direction="row" spacing={1}>
            <span>{intl.formatMessage({id: `subject_member_role_label_${SubjectSubscriptionUserRoleEnum.EDITOR}`})}</span>
            { subjectMember.role === SubjectSubscriptionUserRoleEnum.EDITOR && (<CustomIcon name="check" />) }
          </Stack>
        </MenuItem>


        {
          !subject.enableShareSubject && [
            (<Divider key="divider" />),
            (
              <MenuItem key="menu_item" onClick={onDeleteMember}>
                <span>{intl.formatMessage({id: 'remove_access'})}</span>
              </MenuItem>
            )
          ]
        }
      </Menu>
    </>
  )
}

export default SubjectMemberRow
