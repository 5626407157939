import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { useLoadSubjectSubscription } from '../../../../common/hooks/subjects-subscription'
import { displaySuccessToast, displayWarningToast } from '../../../../common/utils/toast'
import { QuestionSubscription, Study, StudyTypesEnum } from '../../../../../common/types'
import { getQuestionSubscriptionsForStudyType } from '../../../../../common/utils/study'
import GoBackButton from '../../../../common/components/go-back-button'
import { useResponsive } from '../../../../common/hooks/responsive'
import Breadcrumbs from '../../../../common/components/breadcrumbs'
import WideButton from '../../../../common/components/wide-button'
import { StudyTypesIconsEnum } from '../../../../common/types'
import { useRequest } from '../../../../common/hooks/request'
import { LoaderContext } from '../../../../common/contexts'
import Title from '../../../../common/components/title'

import './styles.scss'

const CreateStudyPage = (): JSX.Element | null => {
  const { subjectSubscription, subject } = useLoadSubjectSubscription()
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const responsive = useResponsive()
  const { postData } = useRequest()
  const history = useHistory()
  const intl = useIntl()

  if (!subjectSubscription || !subject) return null

  const createStudy = async (studyType: StudyTypesEnum): Promise<void> => {
    displayLoader()
    const createdStudy: Study | undefined = await postData('/study/create', {
      subjectSubscriptionId: subjectSubscription._id,
      studyType,
    })

    if (createdStudy) {
      displaySuccessToast(intl.formatMessage({id: 'study_lets_go'}))
      history.push(`/subject-subscription/${subjectSubscription._id}/study/${createdStudy._id}`)
    } else {
      displayWarningToast(intl.formatMessage({id: 'error_occured'}))
    }
    hideLoader()
  }

  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <div className="page_content create_study_page">
      <Stack spacing={2}>
        <Breadcrumbs 
          pages={[
            {
              label: intl.formatMessage({id: 'page_subject_subscriptions'}),
              path: '/subject-subscriptions',
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}`,
              label: subject.label,
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}/create-study`,
              label: intl.formatMessage({id: 'study'}),
            },
          ]}
        />

        <div>
          <Title title={`Réviser "${subject.label}"`} wrapTitle={isSmallDevice} />
          <h3>{intl.formatMessage({id: 'choose_study_type'})}</h3>
        </div>
        
        {
          Object.values(StudyTypesEnum).map((studyType: StudyTypesEnum) => {
            const questionsCount: number = subjectSubscription ? getQuestionSubscriptionsForStudyType(
              subjectSubscription.questionSubscriptions as QuestionSubscription[], 
              studyType,
            ).length : 0

            return (
              <WideButton
                titleLevel2={isSmallDevice ? undefined : intl.formatMessage({id: 'study_question_count'}, {count: questionsCount})}
                textLevel2={isSmallDevice ? intl.formatMessage({id: 'study_question_count'}, {count: questionsCount}) : undefined}
                text={intl.formatMessage({id: `study_type_description_${studyType}`})}
                title={intl.formatMessage({id: `study_label_${studyType}`})}
                onClick={() => createStudy(studyType)}
                icon={StudyTypesIconsEnum[studyType]}
                disabled={questionsCount === 0}
                key={`study_type_${studyType}`}
              />
            )
          })
        }
        <Stack direction="row" justifyContent="flex-start">
          <GoBackButton
            defaultUrl={`/subject-subscription/${subjectSubscription._id}`}
            variant="outlined"
            withIcon
          />
        </Stack>
      </Stack>
    </div>
  )
}

export default CreateStudyPage
