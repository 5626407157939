import React, { useState, useRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip } from '@mui/material'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { isNil } from 'lodash'

import { DeviceTypesEnum, useResponsive } from '../../hooks/responsive'
import { imageToSrc } from '../../utils/image'
import { stopEvent } from '../../utils/event'
import CustomIcon from '../custom-icon'

interface ImageRenderProps {
  onDeleteImage?: (imageIndex: number) => void,
  enableFullScreen?: boolean,
  height: number | string,
  fillContainer?: boolean,
  fitContainer?: boolean,
  width: number | string,
  editable?: boolean,
  index?: number,
  image: string,
}

const ImageRender = ({
  enableFullScreen = true,
  editable = false,
  onDeleteImage,
  fillContainer,
  fitContainer,
  index = -1,
  height,
  width,
  image,
}: ImageRenderProps): JSX.Element => {
  const [heightState, setHeightState] = useState<string | undefined>()
  const [widthState, setWidthState] = useState<string | undefined>()
  const [openImage, setOpenImage] = useState<boolean>(false)
  const responsive = useResponsive()
  
  const imageContainerRef = useRef<any>()
  const imageRef = useRef<any>()
  
  const onImageLoaded = () => {
    const imageHeightOverlapse: boolean = (imageRef.current?.clientHeight || 0) > (imageContainerRef.current?.clientHeight || 0)

    if (fitContainer) {
      if (imageHeightOverlapse) {
        setWidthState('auto')
        setHeightState('100%')
      } else {
        setWidthState('100%')
        setHeightState('auto')
      }
    }

    if (fillContainer) {
      if (imageHeightOverlapse) {
        setWidthState('100%')
        setHeightState('auto')
      } else {
        setWidthState('auto')
        setHeightState('100%')
      }
    }
  }

  const onDeleteClick = e => {
    stopEvent(e)
    
    if (onDeleteImage) {
      onDeleteImage(index)
    }
  }

  const isAutosize: boolean = !isNil(fitContainer) || !isNil(fillContainer)
  
  const dialogWidth: number = responsive.currentDeviceType === DeviceTypesEnum.EXTRA_LARGE ? 1000
    : responsive.currentDeviceType === DeviceTypesEnum.LARGE ? 900
    : responsive.currentDeviceType === DeviceTypesEnum.MEDIUM ? window.innerWidth - 80
    : window.innerWidth - 20

  return (
    <>
      <div 
        onClick={enableFullScreen ? () => setOpenImage(true) : undefined}
        className="image_container"
        style={{ width, height }}
        ref={imageContainerRef}
      >
        <img 
          src={imageToSrc(image)} 
          onLoad={onImageLoaded}
          ref={imageRef} 
          style={{
            height: isAutosize ? heightState || 'auto' : 'auto', 
            width: isAutosize ? widthState || '100%' : '100%',
          }}
        />

        {
          (editable || enableFullScreen) && (
            <Stack className="edit_overlay" alignItems="center" justifyContent="center">          
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>

                <Tooltip title="Agrandir" placement="bottom">
                  <IconButton className="bg_light edit_button">
                    <CustomIcon name="open_in_full" />
                  </IconButton>
                </Tooltip>

                {
                  editable && (
                    <div>
                      <Tooltip title="Supprimer" placement="bottom">
                        <IconButton 
                          className="bg_light edit_button"
                          onClick={onDeleteClick}
                        >
                          <CustomIcon name="delete" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )
                }
              </Stack>
            </Stack> 
          )
        }
      </div>

      {
        openImage && (
          <Dialog
            onClose={() => setOpenImage(false)}
            open={openImage}
            maxWidth="lg"
            fullWidth
            PaperProps={{
              sx: {
                maxWidth: dialogWidth,
                width: dialogWidth,
                margin: 0,
              },
            }}
          >
            <DialogTitle>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                Image
                <IconButton size="small" onClick={() => setOpenImage(false)}>
                  <CustomIcon name="clear" />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <TransformWrapper
                initialPositionX={0}
                initialPositionY={0}
                initialScale={1}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <Stack position="relative" alignItems="center" spacing={1}>
                    
                    <TransformComponent>
                      <img 
                        style={{ width: dialogWidth - 40, objectFit: "contain" }}
                        width={dialogWidth - 40}
                        src={imageToSrc(image)} 
                      />
                    </TransformComponent>

                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} className="pan_pinch_controls">
                      <IconButton 
                        onClick={() => zoomIn()}
                        size="small" 
                        sx={{ 
                          border: '1px solid lightgrey', 
                          backgroundColor: '#fff !important',
                        }} 
                      >
                        <CustomIcon name="add" />
                      </IconButton>
                      <IconButton 
                        onClick={() => zoomOut()}
                        size="small" 
                        sx={{ 
                          border: '1px solid lightgrey', 
                          backgroundColor: '#fff !important',
                        }} 
                      >
                        <CustomIcon name="remove" />
                      </IconButton>
                      <IconButton 
                        onClick={() => resetTransform()}
                        size="small" 
                        sx={{ 
                          border: '1px solid lightgrey', 
                          backgroundColor: '#fff !important',
                        }} 
                      >
                        <CustomIcon name="undo" />
                      </IconButton>
                    </Stack>
                  </Stack>
                )}
              </TransformWrapper>
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={() => setOpenImage(false)}
                color="secondary" 
              >
                Fermer
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  )
}

export default ImageRender