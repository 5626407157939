import React, { useState } from 'react'
import { Button, Menu, MenuItem, Stack } from '@mui/material'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { QuestionTypesEnum } from '../../../../../common/types'
import { QuestionTypesFormPathsEnum } from '../../../types'
import CustomIcon from '../../custom-icon'

interface SelectQuestionTypeProps {
  selectedQuestionType: QuestionTypesEnum,
}

const SelectQuestionType = ({
  selectedQuestionType,
}: SelectQuestionTypeProps): JSX.Element => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)

  const { subjectSubscriptionId } : { subjectSubscriptionId: string } = useParams()
  const intl = useIntl()

  return (
    <>
      <Button 
        onClick={e => setMenuAnchorEl(!!menuAnchorEl ? null :  e.currentTarget)}
        className="bg_light"
        variant="outlined"
        color="secondary" 
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          {intl.formatMessage({id: `question_type_label_${selectedQuestionType}`})}
          <CustomIcon name={!!menuAnchorEl ?  'keyboard_arrow_up' : 'keyboard_arrow_down'} />
        </Stack>
      </Button>
      
      <Menu
        onClose={() => setMenuAnchorEl(null)}
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        autoFocus={false}
        sx={{ mt: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          Object.values(QuestionTypesEnum).map((questionType: QuestionTypesEnum) => {
            return (
              <MenuItem
                to={`/subject-subscription/${subjectSubscriptionId}/create-question/${QuestionTypesFormPathsEnum[questionType]}`} 
                selected={questionType === selectedQuestionType}
                key={questionType}
                component={Link}
              >
                {intl.formatMessage({id: `question_type_label_${questionType}`})}
              </MenuItem>
            )
          })
        }
      </Menu>
    </>
  )
}

export default SelectQuestionType
