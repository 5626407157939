import React from 'react'
import { Stack } from '@mui/material'

import CustomIcon from '../../../../common/components/custom-icon'

import './styles.scss'

interface HowItWorksTileProps {
  description: string,
  title: string,
  icon: string,
}

const HowItWorksTile = ({
  description,
  title,
  icon,
}: HowItWorksTileProps): JSX.Element => {
  return (
    <div className="how_to_panel">
      <Stack direction="row" alignItems="flex-start" justifyContent="center" spacing={2}>
        <div className="icon_container shadow_light">
          <CustomIcon name={icon} />
        </div>
        <Stack flex={1}>
          <h3>{title}</h3>
          <p>
            {description}
          </p>
        </Stack>
      </Stack>
    </div>
  )
}

export default HowItWorksTile
