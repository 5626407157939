import { Subject, QuestionSubscription, CommonEntityFields, User, Study } from '.'

interface SubjectSubscription extends CommonEntityFields {
  questionSubscriptions?: QuestionSubscription[],
  includeNewQuestionsToGlobalStudy?: boolean,
  role: SubjectSubscriptionUserRoleEnum,
  studies?: Study[] | string[],
  subject?: Subject | string,
  user: string | User,
}

enum SubjectSubscriptionUserRoleEnum {
  EDITOR = 'EDITOR',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

enum SubjectSubscriptionUserRoleLabelsEnum {
  ADMIN = 'Administrateur',
  USER = 'Utilisateur',
  EDITOR = 'Editeur',
}

export { 
  SubjectSubscriptionUserRoleLabelsEnum,
  SubjectSubscriptionUserRoleEnum, 
  SubjectSubscription, 
}
