import { uniq, difference } from 'lodash'

import { Category, CategoryChild } from '../types'

const updateCategoriesSelection = (
  categoriesParents: { [categoryId: string]: string[] },
  categoriesChilds: { [categoryId: string]: string[] },
  selectedCategories: string[],
  categoryId: string,
): string[] => {
  const degroupedSelection = degroupSelection(categoriesChilds, selectedCategories)
  let nextSelection: string[] = []

  if (degroupedSelection.includes(categoryId)) {
    nextSelection = degroupedSelection.filter((id) => {
      return Boolean(
        id !== categoryId &&
        !categoriesChilds[categoryId].includes(id) &&
        !categoriesParents[categoryId].includes(id)
      )
    })
  } else {
    nextSelection = [...degroupedSelection, categoryId]
  }

  return groupSelection(categoriesChilds, nextSelection)
}

const degroupSelection = (
  categoriesChilds: {[categoryId: string]: string[]},
  selection: string[],
): string[] => {
  let resp: string[] = [...selection]

  selection.forEach((categoryId: string) => {
    if (categoriesChilds[categoryId].length > 0) {
      resp = resp.concat(categoriesChilds[categoryId])
    }
  })

  return uniq(resp)
}

const groupSelection = (
  categoriesChilds: {[categoryId: string]: string[]},
  selection: string[],
): string[] => {
  let categoriesToRemove: string[] = []
  
  degroupSelection(categoriesChilds, selection).forEach((categoryId: string) => {
    const childs: string[] = categoriesChilds[categoryId] || []
    if (childs.length > 0) {
      categoriesToRemove = categoriesToRemove.concat(childs)
    }
  })

  return uniq(difference(selection, categoriesToRemove))
}

const isCategorySelected = (
  categoriesChilds: { [categoryId: string]: string[] },
  selection: string[],
  categoryId: string, 
  ): boolean => {
  const categoryChilds: string[] = categoriesChilds[categoryId]
  let resp: boolean = true

  if (categoryChilds.length > 0) {
    categoryChilds.forEach((childId: string) => {
      if (resp && !isCategorySelected(categoriesChilds, selection, childId)) {
        resp = false
      }
    })
  } else if (!selection.includes(categoryId)) {
    resp = false
  }

  return resp
}

const getLabels = (
  categoriesById: {[categoryId: string]: Category},
  categoryIds: string[],
): string[] => {
  return categoryIds.map((categoryId: string) => categoriesById[categoryId]?.label || '')
}

const getCategoriesFromCategoriesChilds = (
  categoriesById: { [categoryId: string]: Category },
  categoriesChilds: CategoryChild[],
): Category[] => {
  return categoriesChilds.map((categoryChild: CategoryChild) => categoriesById[categoryChild.category as string])
}

export { 
  getCategoriesFromCategoriesChilds,
  updateCategoriesSelection, 
  isCategorySelected,
  degroupSelection, 
  groupSelection,
  getLabels,
}
