import React, { createContext, useMemo } from 'react'
import { getMainPage } from '../utils/routes'
import { useLocation } from 'react-router'
import { Page } from '../constants/routes'

interface RouterContextType {
  currentMainPage: Page | undefined,
}

const RouterContext = createContext<RouterContextType>({
  currentMainPage: undefined,
})

const RouterContextProvider = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()

  const currentMainPage: Page | undefined = useMemo(() => {
    return getMainPage(location.pathname)
  }, [location.pathname])

  return (
    <RouterContext.Provider
      value={{
        currentMainPage,
      }}
    >
      { children }
    </RouterContext.Provider>
  )
}

export { RouterContext, RouterContextProvider }

