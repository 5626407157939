import React, { useContext } from 'react'
import { matchPath, Redirect, useLocation } from 'react-router'
import { AuthContext, UserContext } from '../contexts'

const checkUser = (Children: (props?: any) => JSX.Element | null, otherProps: any): JSX.Element | null => {
  const { user, isUserProfileComplete } = useContext(UserContext)
  const { redirectToLogin, isLogged } = useContext(AuthContext)
  const location = useLocation()

  if (!isLogged) redirectToLogin()
  if (!user || !isLogged) return null

  const shouldRedirectToCompleteProfile: boolean = Boolean(
    !isUserProfileComplete && 
    !matchPath(location.pathname, '/verify-email/:verificationCode') &&
    !matchPath(location.pathname, '/complete-profile')
  )
  
  if (shouldRedirectToCompleteProfile) return <Redirect to="/complete-profile" />

  return (<Children {...otherProps} />)
}

export default checkUser
