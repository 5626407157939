import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { Box, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

import { FeaturedSubject, Subject, SubjectPublicView } from '../../../../common/types'
import { DeviceTypesEnum, useResponsive } from '../../hooks/responsive'
import ImageRender from '../image-display/image-render'

interface FeaturedSubjectItemProps {
  subjectPublicView: SubjectPublicView,
  featuredSubject: FeaturedSubject,
}

const FeaturedSubjectItem = ({
  subjectPublicView,
  featuredSubject,
}: FeaturedSubjectItemProps): JSX.Element => {
  const responsive = useResponsive()

  const isMobileDevice: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)
  const isMediumDevice: boolean = responsive.isMediumDevice()


  return (
    <Grid xs={isMobileDevice ? 12 : isMediumDevice ? 4 : 3}>
      <Stack
        to={`/subject/${(featuredSubject.subject as Subject)._id}`}
        className="featured_subject_container" 
        component={Link} 
      >
        <ImageRender 
          image={subjectPublicView.image || featuredSubject.image || ''} 
          enableFullScreen={false}
          fillContainer
          width="100%"
          height={175}
        />

        <Box p={2} flex={1}>
          <h3> {subjectPublicView.label} </h3>
        </Box>
        
        <Stack 
          justifyContent="flex-end" 
          alignItems="center" 
          direction="row" 
          py={1} 
          px={2}
        >
          <p>{subjectPublicView.questions.length} questions</p>
        </Stack>
      </Stack>
    </Grid>
  )
}

export default FeaturedSubjectItem
