import React, { useState, createContext, useEffect, useContext } from 'react'

import { displayWarningToast } from '../utils/toast'
import { useRequest } from '../hooks/request'
import { User } from '../../../common/types'
import { LoaderContext } from './loader'
import { AuthContext } from './auth'

interface UserContextType {
  isUserProfileComplete?: boolean,
  getUser: () => Promise<void>,
  user?: User,
}

const UserContext = createContext<UserContextType>({
  isUserProfileComplete: false,
  getUser: async () => {},
  user: undefined,
})

const UserContextProvider = ({ children }: { children: JSX.Element }) => {
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const [user, setUser] = useState<User | undefined>(undefined)
  const { authToken, logout } = useContext(AuthContext)
  const { fetchData } = useRequest()
  
  useEffect(() => {
    if (authToken) {
      getUser()
    }
  }, [authToken])
  
  const getUser = async () => {
    if (!!authToken) {
      displayLoader()
      const response: User | string | undefined = await fetchData('/user')
      hideLoader()

      if (response === 'Unauthorized') {
        displayWarningToast('Veuillez vous reconnecter')
        logout(true)
      } else if (typeof response === 'object') {
        setUser(response)
      } else {
        logout()
      }
    }
  }

  const isUserProfileComplete: boolean = Boolean(
    !!user 
    && !!user.pseudo 
  )

  return (
    <UserContext.Provider
      value={{
        isUserProfileComplete,
        getUser,
        user,
      }}
    >
      { children }
    </UserContext.Provider>
  )
}

export { UserContext, UserContextProvider }