import { CommonEntityFields, Question, Subject, User, SubjectSubscription, Study } from '.'

interface QuestionSubscription extends CommonEntityFields {
  subjectSubscription: string | SubjectSubscription,
  includeToGlobalStudy?: boolean,
  userResponses?: string[] | UserResponse[], // Note : can be retrieved by api but is not persisted in db
  question?: Question | string,
  subject: string | Subject,
  acquisitionLevel?: number,
  successesCount?: number,
  currentSpacing?: number,
  nextStudyDate?: number,
  lastStudyDate?: number,
  user: string | User,
  failsCount?: number,
  isAcquired: boolean,
}

interface UserResponse extends CommonEntityFields {
  questionSubscription: string | QuestionSubscription,
  subjectSubscription: string | SubjectSubscription,
  question: string | Question,
  subject: string | Subject,
  isCorrectAnswer: boolean,
  study?: string | Study,
  user: string | User,
  date: number,
}

enum AcquisitionStatusEnum {
  UNACQUIRED = 'UNACQUIRED',
  ACQUIRED = 'ACQUIRED',
}

enum ProgressColorsEnum {
  UNACQUIRED = 'secondary',
  INPROGRESS = 'secondary',
  ACQUIRED = 'success',
}

export {
  AcquisitionStatusEnum,
  QuestionSubscription,
  ProgressColorsEnum,
  UserResponse,
}
