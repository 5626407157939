import React, { createContext, useContext, useState } from 'react'

import { FeaturedSubject } from '../../../common/types'
import { useRequest } from '../hooks/request'
import { LoaderContext } from './loader'

interface SubjectsContextType {
  loadFeaturedSubjects: () => Promise<void>,
  featuredSubjects: FeaturedSubject[],
}

const SubjectsContext = createContext<SubjectsContextType>({
  loadFeaturedSubjects: async () => {},
  featuredSubjects: [],
})

const SubjectsContextProvider = ({ children }: { children: JSX.Element }) => {
  const [featuredSubjects, setFeaturedSubjects] = useState<FeaturedSubject[]>([])
  
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { fetchData } = useRequest()

  const loadFeaturedSubjects = async (): Promise<void> => {
    displayLoader()
    const response = await fetchData('/subject/featured')
    setFeaturedSubjects(response.featuredSubjects || [])
    hideLoader()
  }

  return (
    <SubjectsContext.Provider
      value={{
        loadFeaturedSubjects,
        featuredSubjects,
      }}
    >
      { children }
    </SubjectsContext.Provider>
  )
}

export { SubjectsContext, SubjectsContextProvider }

