import { CommonEntityFields, User } from '.'
import { Subject } from './subject'

enum QuestionTypesEnum {
  TEXT = 'TEXT',
  QCM = 'QCM',
}

interface Question extends CommonEntityFields {
  _id?: string,
  type: QuestionTypesEnum, 
  content: string | TextQuestionContent | QcmQuestionContent,
  subject: string | Subject,
  author: string | User,
}

interface TextQuestionContent extends CommonEntityFields {
  question: string,
  responses: TextResponse[],
  explanation: string,
  images?: (string | File)[], // Note : File[] type is only used in forms
}

interface TextResponse {
  response?: string,
  label?: string,
  id?: string,
}

interface QcmQuestionContent extends CommonEntityFields {
  question: string,
  responses: QcmResponse[],
  explanation: string,
  images?: (string | File)[], // Note : File[] type is only used in forms
}

interface QcmResponse extends CommonEntityFields {
  id?: string,
  response: string,
  isCorrect: boolean,
}

interface QuestionPublicView {
  _id: string,
  question: string,
  type: QuestionTypesEnum,
}

export { 
  TextQuestionContent,
  QcmQuestionContent,
  QuestionPublicView,
  QuestionTypesEnum,
  TextResponse,
  QcmResponse,
  Question,
}
