import React from 'react'
import { Avatar } from '@mui/material'

import { User } from '../../../../common/types'
import { imageToSrc } from '../../utils/image'

interface UserAvatarProps {
  size?: number,
  user: User,
}

const UserAvatar = ({
  size = 20,
  user,
}: UserAvatarProps): JSX.Element => {
  const imagePath: string = user.picture ? imageToSrc(user.picture) : (user.googlePicture || `${process.env.API_URL}/images/default-profile-picture.png`)

  return (
    <Avatar 
      imgProps={{ referrerPolicy: 'no-referrer' }}
      sx={{ width: size, height: size }} 
      src={imagePath} 
    />
  )
}

export default UserAvatar

