import React, { useMemo } from 'react'
import { Stack, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import ImagesPromptButton from '../components/images/images-prompt-button'
import SelectCategoriesField from '../components/select-categories-field'
import FormImagesViewer from '../components/images/images-viewer'
import { useResponsive } from '../../../hooks/responsive'
import SubjectFormSubmitButtons from './submit-buttons'
import { Subject } from '../../../../../common/types'
import { compressImage } from '../../../utils/image'

interface SubjectFormProps {
  onSubmit: (formData: FormData) => Promise<void>,
  subject?: Subject,
}

export interface SubjectFormValues {
  image?: string | File,
  categories: string[],
  description: string,
  label: string,
}

const SubjectForm = ({
  onSubmit,
  subject,
}: SubjectFormProps): JSX.Element => {
  const responsive = useResponsive()
  const intl = useIntl()
  
  const initialValues: SubjectFormValues = useMemo(() => {
    return {
      description: subject?.description || '',
      categories: subject?.categories || [],
      label: subject?.label || '',
      image: subject?.image,
    }
  }, [subject])

  const { register, handleSubmit, reset, control, setValue } = useForm<SubjectFormValues>({
    defaultValues: initialValues,
  })

  const onSubmitForm = async (subjectToSave: SubjectFormValues): Promise<void> => {
    const formData = new FormData()

    formData.append('label', subjectToSave.label)
    formData.append('description', subjectToSave.description)
    formData.append('categories', subjectToSave.categories.toString())
    
    if (subject && subject._id) {
      formData.append('_id', subject._id)
    }

    if (subjectToSave.image instanceof File) {
      const compressedImage: File | Blob = await compressImage(subjectToSave.image)
      formData.append('image', compressedImage, subjectToSave.image.name)
    } else if (!!subjectToSave.image) {
      formData.append('image', subjectToSave.image)
    }
    
    onSubmit(formData)
  }

  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <form onSubmit={handleSubmit((values: SubjectFormValues) => onSubmitForm(values))}>
      <Stack spacing={1} mt={2}>
        <FormImagesViewer 
          setValue={setValue as any}
          control={control as any}
          fieldName="image"
        />

        <Stack direction="row" spacing={1}>
          <TextField
            {...register('label', { required: true })}
            placeholder={intl.formatMessage({id: 'subject_form_label'})}
            sx={{ flex: 1 }}
            size="small"
          />

          <ImagesPromptButton
            setValue={setValue as any}
            control={control as any}
            small={isSmallDevice}
            fieldName="image"
          />

        </Stack>

        <SelectCategoriesField 
          setValue={setValue as any}
          control={control as any}
          fieldName="categories"
        />

        <TextField
          {...register('description')}
          placeholder={intl.formatMessage({id: 'subject_form_description'})}
          size="small"
          minRows={4}
          multiline
          fullWidth
        />
      </Stack>

      <SubjectFormSubmitButtons
        initialValues={initialValues}
        isEdit={!!subject}
        control={control}
        reset={reset}
      />
    </form>
  )
}


export default SubjectForm