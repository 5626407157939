import React, { useContext, useState } from 'react'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { displaySuccessToast, displayWarningToast } from '../../../common/utils/toast'
import UserProfileForm from '../../../common/components/forms/user/user-profile'
import { LoaderContext, UserContext } from '../../../common/contexts'
import { useRequest } from '../../../common/hooks/request'
import Title from '../../../common/components/title'

const ProfilSettings = (): JSX.Element => {
  const [isPseudoUnavailable, setIsPseudoUnavailable] = useState<boolean>(false)
  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false)
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { user, getUser } = useContext(UserContext)
  const { postData } = useRequest()
  const intl = useIntl()

  const updateUserProfile = async (formData: FormData): Promise<void> => {
    displayLoader()
    const response = await postData('/user', formData)
    if (response.success) {
      displaySuccessToast(intl.formatMessage({id: 'settings_profile_edit_success'}))
      await getUser()
      setShouldResetForm(true)
      hideLoader()
    } else if (response === 'pseudo unavailable') {
      setIsPseudoUnavailable(true)
    } else {
      displayWarningToast(intl.formatMessage({id: 'error_occured'}))
    }
    hideLoader()
  }
  
  return (
    <Stack flex={1} spacing={1} className="page_content setting_page_content">
      <Title title={intl.formatMessage({id: 'settings_profile_edit'})} />

      <UserProfileForm 
        setIsPseudoUnavailable={setIsPseudoUnavailable}  
        isPseudoUnavailable={isPseudoUnavailable}
        onSubmit={updateUserProfile} 
        user={user} 
        setShouldResetForm={setShouldResetForm}
        shouldResetForm={shouldResetForm}
      />
    </Stack>
  )
}

export default ProfilSettings