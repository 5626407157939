
const convertToFormData = (data: any): FormData => {
  const formData = new FormData()

  Object.keys(data).forEach((key: string) => {
    const value = data[key]

    const isFileArray: boolean = (Array.isArray(value) && value.length > 0 && value[0] instanceof File)
    const isFile: boolean = value instanceof File

    if (isFileArray) {
      value.forEach((file: File) => {
        formData.append(key, file, file.name)
      })
    } else {
      formData.append(key, value, isFile ? value.name : undefined)
    }
  })

  return formData 
}

export { convertToFormData }
