import React from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Container, createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'

import TranslationsProvider from './common/components/translations-provider'
import RenderContexts from './common/contexts'
import { theme } from './common/theme'
import Router from './common/router'

import './assets/css/styles.scss'
import './assets/css/responsive.scss'

function renderApp(App) {
  const container = document.getElementById('root') as Container
  const root = createRoot(container)

  root.render(
    <GoogleOAuthProvider clientId={process.env.GOOGLE_AUTH_CLIENT_ID || ''}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <RenderContexts>
            <TranslationsProvider>
              <App /> 
            </TranslationsProvider>
          </RenderContexts>
        </BrowserRouter>
      </ThemeProvider>
    </GoogleOAuthProvider>
  )
}

try {
  renderApp(Router)
} catch (err) {
  console.warn(err)
}
