import React, { useContext } from 'react'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { useHistory } from 'react-router'

import { AuthContext, LoaderContext, TranslationContext } from '../../contexts'
import { displaySuccessToast, displayWarningToast } from '../../utils/toast'
import { useRequest } from '../../hooks/request'

const GoogleLoginButton = (): JSX.Element => {
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { currentLocale } = useContext(TranslationContext)
  const { updateAuthToken } = useContext(AuthContext)
  const { postData } = useRequest()
  const history = useHistory()

  const onLoginSuccess = async (googleCredentials: CredentialResponse): Promise<void> => {
    displayLoader()
    const response = await postData('/auth/login-with-google', {...googleCredentials, language: currentLocale})
    hideLoader()

    if (response.token) {
      updateAuthToken(response.token)
      displaySuccessToast('Connexion réussie !')
      history.push('/subject-subscriptions')
    } else {
      displayWarningToast('La connexion a échoué')
    }
  }

  const onLoginError = () => {
    displayWarningToast('La connexion a échoué')
  }

  return (
    <GoogleLogin 
      onSuccess={onLoginSuccess} 
      onError={onLoginError} 
    />
  )
}

export default GoogleLoginButton