import React, { useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router'
import { Stack } from '@mui/material'
import classnames from 'classnames'

import { Page, subjectSubscriptionPages } from '../../common/constants/routes'
import { DeviceTypesEnum, useResponsive } from '../../common/hooks/responsive'
import SubjectPage from './pages/subject'
import TrySubjectPage from './pages/try'
import NotFound from '../not-found'

const SubjectPageRouter = (): JSX.Element | null => {
  return (
    <Switch>
      <Route exact path="/subject/:subjectId/try" component={TrySubjectPage} />
      <Route exact path="/subject/:subjectId" component={SubjectPage} />
      
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default SubjectPageRouter