import React, { useMemo } from 'react'
import { Control, UseFormReset, useWatch } from 'react-hook-form'
import { Button, Stack } from '@mui/material'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash'

import { useResponsive } from '../../../hooks/responsive'
import GoBackButton from '../../go-back-button'
import { SubjectFormValues } from '.'

interface SubjectFormSubmitButtonsProps {
  control: Control<SubjectFormValues, any>,
  reset: UseFormReset<SubjectFormValues>,
  initialValues: SubjectFormValues,
  isEdit: boolean,
}

const SubjectFormSubmitButtons = ({
  initialValues,
  control,
  isEdit,
  reset,
}: SubjectFormSubmitButtonsProps): JSX.Element => {
  const values: SubjectFormValues = useWatch<SubjectFormValues>({control}) as SubjectFormValues
  const responsive = useResponsive()
  const intl = useIntl()

  const { canSubmit, hasChanges }: { canSubmit: boolean, hasChanges: boolean } = useMemo(() => {
    const hasChanges: boolean = !isEqual(values, initialValues)
    const isValid: boolean = Boolean(values.label)
    return { canSubmit: hasChanges && isValid, hasChanges }
  }, [values, initialValues]) 

  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <Stack justifyContent="flex-end" mt={2} direction="row" spacing={1}>
      <GoBackButton defaultUrl="/subject-subscriptions" />
      <Button variant="outlined" onClick={() => reset(initialValues)} disabled={!hasChanges}>
        {isSmallDevice ? intl.formatMessage({id: 'cancel'}) : intl.formatMessage({id: 'cancel_updates'})}
      </Button>
      <Button variant="contained" type="submit" disabled={!canSubmit}>
        {isSmallDevice ? intl.formatMessage({id: 'save'}) : isEdit ? intl.formatMessage({id: 'subject_form_update_button'}) : intl.formatMessage({id: 'subject_form_create_button'})}
      </Button>
    </Stack>
  )
}

export default SubjectFormSubmitButtons