import React, { useEffect, useState } from 'react'
import { Button, Stack, TextField } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface ResetPasswordFormValues {
  confirmPassword: string,
  password: string,
}

interface ResetPasswordFormProps {
  onSubmit: (password: string) => Promise<void>,
}

const ResetPasswordForm = ({
  onSubmit,
}: ResetPasswordFormProps): JSX.Element => {
  const [displayPasswordsError, setDisplayPasswordError] = useState<boolean>(false)

  const { register, handleSubmit, control, formState: { errors } } = useForm<ResetPasswordFormValues>({ 
    defaultValues: { 
      confirmPassword: '',
      password: '',
    },
  })
  const values = useWatch<ResetPasswordFormValues>({ control })
  const intl = useIntl()

  useEffect(() => {
    if (displayPasswordsError) {
      setDisplayPasswordError(false)
    }
  }, [values])

  const onFormSubmit = (values: ResetPasswordFormValues) => {
    if (values.password !== values.confirmPassword) {
      setDisplayPasswordError(true)
    } else {
      onSubmit(values.password)
    }
  }

  const canSubmit = !!values.password && !!values.confirmPassword

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={2} alignItems="center">
        <Stack spacing={1} alignItems="center">
          <TextField
            placeholder={intl.formatMessage({id: 'reset_password_new_password'})}
            type="password"
            size="small"
            error={!!errors.password || displayPasswordsError}
            inputProps={{ autoComplete: 'new-password' }}
            {...register('password', { required: true, minLength: 5 })}
          />
          <TextField
            placeholder={intl.formatMessage({id: 'reset_password_confirm'})}
            type="password"
            size="small"
            error={!!errors.confirmPassword || displayPasswordsError}
            inputProps={{ autoComplete: 'new-password' }}
            {...register('confirmPassword', { required: true, minLength: 5 })}
          />
        </Stack>  

        {
          displayPasswordsError && (
            <p className="text_error">{intl.formatMessage({id: 'reset_password_passwords_differ'})}</p>
          )
        }

        <Button 
          disabled={!canSubmit}
          variant="contained" 
          color="primary" 
          type="submit" 
        >
          {intl.formatMessage({id: 'save'})}
        </Button>
      </Stack>
    </form>
  )
}

export default ResetPasswordForm
