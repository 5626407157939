import React from 'react'
import { Stack } from '@mui/material'
import { isNil } from 'lodash'

import { DeviceTypesEnum, useResponsive } from '../../../../../../common/hooks/responsive'
import CustomIcon from '../../../../../../common/components/custom-icon'

import './styles.scss'

interface InfoTileProps {
  title: string,
  infoIcon?: string,
  info?: string | number,
}

const InfoTile = ({
  infoIcon,
  title,
  info,
}: InfoTileProps): JSX.Element => {
  const responsive = useResponsive()
  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.LARGE)

  return (
    <Stack 
      alignItems={isLargeDevice ? undefined : 'center'}
      direction={isLargeDevice ? 'column' : 'row'}
      justifyContent="space-between" 
      className="panel info_tile" 
      spacing={1}
      flex={1}
    >
      <p>{title}</p>
      { !isNil(info) && ( <h1 className="text_right">{ info }</h1> ) }
      { infoIcon && ( <CustomIcon className="info_icon" name={infoIcon} /> ) }
    </Stack>
  )
}

export default InfoTile
