import { createTheme } from '@mui/material'
import { DeviceTypesBreakpointsEnum } from './hooks/responsive'

const palette = {
  primary: {
    main: '#0d8180',
    light: '#52A5A4',
    dark: '#75B7B6',
  },
  secondary: {
    main: '#3E4B57',
    light: '#757E87',
  },
  success: {
    light: '#4caf50',
    main: '#2e7d32',
    dark: '#1b5e20',
  },
  warning: {
    light: '#ff9800',
    main: '#ed6c02',
    dark: '#e65100',
  },
  info: {
    lighter: '#e1f5fe',
    light: '#03a9f4',
    main: '#0288d1',
    dark: '#01579b',
  },
  error: {
    light: '#ef5350',
    main: '#d32f2f',
    dark: '#c62828',
  },
  text: {
    primary: '#3E4B57',
    secondary: 'rgba(62, 75, 87, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.26)',
  },
}

const theme = createTheme({
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: DeviceTypesBreakpointsEnum.SMALL,
      md: DeviceTypesBreakpointsEnum.MEDIUM,
      lg: DeviceTypesBreakpointsEnum.LARGE,
      xl: DeviceTypesBreakpointsEnum.EXTRA_LARGE,
    },
  },
})

theme.components = {
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-root': {
          backgroundColor: '#fff',
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      contained: {
        '& .MuiIcon-root': {
          color: '#fff',
        },
      },
      outlined: {
        backgroundColor: '#fff !important',
      },
      outlinedPrimary: {
        '& .MuiIcon-root': {
          color: palette.primary.main,
        },
        '& span': {
          color: palette.primary.main,
        },
        '& b': {
          color: palette.primary.main,
        },
      },
      textPrimary: {
        '& .MuiIcon-root': {
          color: palette.primary.main,
        },
      },
      containedPrimary: {
        '& span': {
          color: '#fff',
        },
        '& b': {
          color: '#fff',
        },
        '&.Mui-disabled': {
          backgroundColor: palette.primary.light,
          color: '#fff !important',
        },
      },
      outlinedSecondary: {
        

        '&.Mui-disabled': {
          '& span': {
            color: `${palette.text.disabled} !important`,
          },
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: palette.primary.main,
        color: '#fff',
        '& span': {
          color: '#fff',
        },
        ':hover': {
          backgroundColor: palette.primary.light,
        },
      },
      colorSecondary: {
        backgroundColor: palette.secondary.main,
        color: '#fff',
        '& span': {
          color: '#fff',
        },
        ':hover': {
          backgroundColor: palette.secondary.light,
        }
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        backgroundColor: '#fff',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontWeight: 'bold',
        fontSize: 24,
        borderBottom: '1px solid lightgrey',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '20px !important',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        borderTop: '1px solid lightgrey',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      standardInfo: {
        '& p': {
          color: palette.info.main,
        }
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: `${palette.primary.main} !important`,
        },
      },
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        color: palette.secondary.main,
      }
    }
  }
}


export { theme, palette }