
const maxQcmQuestionResponsesCount: number = 20
const minQcmQuestionResponsesCount: number = 2
const maxTextResponsesCount: number = 20

enum SpacedRepetitionSuccessRatios {
  LOW = 1.5,
  MEDIUM = 2,
  HIGH = 3,
}

enum SpacedRepetitionFailRatios {
  LOW = 0.5,
  MEDIUM = 0.25,
  HIGH = 0,
}

export {
  SpacedRepetitionSuccessRatios,
  minQcmQuestionResponsesCount,
  maxQcmQuestionResponsesCount,
  SpacedRepetitionFailRatios,
  maxTextResponsesCount,
}
