import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'

import { displaySuccessToast, displayWarningToast } from '../../common/utils/toast'
import { DeviceTypesEnum, useResponsive } from '../../common/hooks/responsive'
import { SubjectForm } from '../../common/components/forms'
import { useRequest } from '../../common/hooks/request'
import { LoaderContext } from '../../common/contexts'
import Title from '../../common/components/title'

import './styles.scss'

const CreateSubjectPage = (): JSX.Element => {
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const responsive = useResponsive()
  const { postData } = useRequest()
  const history = useHistory()
  const intl = useIntl()
  
  const onSubmit = async (formData: FormData): Promise<void> => {
    displayLoader()
    const response = await postData('/subject/create', formData)
    hideLoader()

    if (response.createdSubject) {
      displaySuccessToast(intl.formatMessage({id: 'create_subject_success'}))
      history.push('/subject-subscriptions')
    } else {
      // TODO : better error messages
      displayWarningToast(intl.formatMessage({id: 'create_subject_fail'}))
    }
  }

  return (
    <div className="page_content create_subject_page">
      <Title title={responsive.isLowerThan(DeviceTypesEnum.MEDIUM) ? intl.formatMessage({id: 'create_subject_title_short'}) : intl.formatMessage({id: 'create_subject_title_long'})} />
      <SubjectForm onSubmit={onSubmit} />
    </div>
  )
}

export default CreateSubjectPage