import React, { useContext, useState } from 'react'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import UpdatePasswordForm from '../../../common/components/forms/user/update-password'
import { displaySuccessToast, displayWarningToast } from '../../../common/utils/toast'
import DefinePasswordForm from '../../../common/components/forms/user/define-password'
import { LoaderContext, UserContext } from '../../../common/contexts'
import { useRequest } from '../../../common/hooks/request'
import Title from '../../../common/components/title'

const PasswordSettings = (): JSX.Element | null => {
  const [incorrectPassword, setIncorrectPassword] = useState<boolean>(false)
  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false)
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { user, getUser } = useContext(UserContext)
  const { postData } = useRequest()
  const intl = useIntl()

  const updatePassword = async (currentPassword: string, newPassword: string): Promise<void> => {
    displayLoader()
    const response = await postData('/user/update-password', { currentPassword, newPassword })
    if (response.success) {
      displaySuccessToast(intl.formatMessage({id: 'settings_update_password_success'}))
      setShouldResetForm(true)
      hideLoader()
    } else if (response === 'incorrect password') {
      setIncorrectPassword(true)
    } else {
      displayWarningToast(intl.formatMessage({id: 'error_occured'}))
    }
    hideLoader()
  }

  const definePassword = async (password: string): Promise<void> => {
    displayLoader()
    const response = await postData('/user/define-password', { password })
    if (response.success) {
      displaySuccessToast(intl.formatMessage({id: 'settings_update_password_success'}))
      await getUser()
      hideLoader()
    } else if (response === 'user already has a password') {
      displayWarningToast(intl.formatMessage({id: 'settings_password_already_defined'}))
    } else {
      displayWarningToast(intl.formatMessage({id: 'error_occured'}))
    }
    hideLoader()
  }

  if (!user) return null
  
  if (user.hasPassword) {
    return (
      <Stack flex={1} spacing={1} className="page_content setting_page_content">
        <Title title={intl.formatMessage({id: 'change_password'})} />
  
        <UpdatePasswordForm 
          setIncorrectPassword={setIncorrectPassword}
          setShouldResetForm={setShouldResetForm}
          incorrectPassword={incorrectPassword}
          shouldResetForm={shouldResetForm}
          onSubmit={updatePassword}
        />
      </Stack>
    )
  } else {
    return (
      <Stack flex={1} spacing={1}>
        <Title title={intl.formatMessage({id: 'define_password'})} />
        <DefinePasswordForm onSubmit={definePassword} />
      </Stack>
    )
  }
}

export default PasswordSettings