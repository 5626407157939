import React, { useState, createContext } from 'react'

interface LoaderContextType {
  displayLoader: () => void, 
  hideLoader: () => void,
  isLoading: boolean,
}

const LoaderContext = createContext<LoaderContextType>({
  displayLoader: () => {}, 
  hideLoader: () => {},
  isLoading: false,
})

const LoaderContextProvider = ({ children }: { children: JSX.Element }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  const displayLoader = () => setIsLoading(true)
  const hideLoader = () => setIsLoading(false)

  return (
    <LoaderContext.Provider
      value={{
        displayLoader, 
        hideLoader,
        isLoading,
      }}
    >
      { children }
    </LoaderContext.Provider>
  )
}

export { LoaderContext, LoaderContextProvider }