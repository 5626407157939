import React, { useContext, useState } from 'react'
import { Button, Stack } from '@mui/material'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import SelectCategoriesModal from '../../common/components/categories/select-categories-modal'
import CategoriesChips from '../../common/components/categories/categories-chips'
import { DeviceTypesEnum, useResponsive } from '../../common/hooks/responsive'
import FeaturedSubjects from '../../common/components/featured-subjects'
import CustomIcon from '../../common/components/custom-icon'
import SearchBar from '../../common/components/search-bar'
import { BrowserContext } from '../../common/contexts'
import Title from '../../common/components/title'
import BrowserSearch from './components/search'
import BrowserMenu from './components/menu'

import './styles.scss'

interface SearchParameters {
  categories?: string[],
  searchText?: string,
}

const BrowserPage = (): JSX.Element => {
  const { searchParameters, onUpdateSearchParameter, onSelectCategory, hasSearchParameters } = useContext(BrowserContext)
  const responsive = useResponsive()
  const intl = useIntl()

  const [openCategoriesDialog, setOpenCategoriesDialog] = useState<boolean>(false)

  const isSmallDevice: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)
  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isMediumDevice: boolean = responsive.isMediumDevice()
  const categoriesFilter: string[] = searchParameters.categories || []

  return (
    <Stack direction="row" width="100%" className={classnames({ menu_page_container : isDesktop })}>
      <BrowserMenu />
      
      <Stack className="browser_container page_content" spacing={4}>
        <Stack spacing={1}>
          <Title title={intl.formatMessage({id: 'browser_title'})} />

          <Stack direction="row" spacing={1}>
            <SearchBar sx={{ width: '100%', flex: 1 }} />

            {
              isMediumDevice && (
                <Button 
                  endIcon={(<CustomIcon name="filter_list" />)}
                  onClick={() => setOpenCategoriesDialog(true)}
                  variant="outlined"
                  color="secondary"
                >
                  {intl.formatMessage({id: 'categories'})}
                </Button>
              )
            }

            <SelectCategoriesModal 
              onClose={() => setOpenCategoriesDialog(false)}
              open={openCategoriesDialog}
              onChange={(nextCategories: string[]) => {
                onUpdateSearchParameter({...searchParameters, categories: nextCategories})
                setOpenCategoriesDialog(false)
              }}
              value={categoriesFilter}
            />
          </Stack>

          {
            isSmallDevice && (
              <Button 
                onClick={() => setOpenCategoriesDialog(true)}
                variant="outlined"
                color="secondary"
              >
                <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
                  <p>{intl.formatMessage({id: 'categories'})}</p>
                  <CustomIcon name="filter_list" />
                </Stack>
              </Button>
            )
          }
      
          {
            categoriesFilter.length > 0 && (
              <CategoriesChips 
                onDeleteAll={() => onUpdateSearchParameter({ ...searchParameters, categories: [] })}  
                label={isSmallDevice ? undefined : intl.formatMessage({id: 'categories'})} 
                onDeleteCategory={(categoryId: string) => onSelectCategory(categoryId)}
                categoriesIds={categoriesFilter} 
                deletable
              />
            )
          }
        </Stack>      
      
        {
          hasSearchParameters ? (
            <BrowserSearch />
          ) : (
            <FeaturedSubjects />
          )
        }
      </Stack>
    </Stack>
  )
}

export type { SearchParameters }
export default BrowserPage
