import React, { useEffect } from 'react'
import { Button, Stack, TextField, Tooltip } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash'

import ImagesPromptButton from '../components/images/images-prompt-button'
import { DeviceTypesEnum, useResponsive } from '../../../hooks/responsive'
import CircularImageDisplay from '../../circular-image-display'
import { compressImage } from '../../../utils/image'
import { User } from '../../../../../common/types'
import GoBackButton from '../../go-back-button'
import CustomIcon from '../../custom-icon'

interface UserProfileFormValues {
  firstname: string,
  lastname: string,
  picture?: string | File,
  pseudo: string,
}

interface UserProfileFormProps {
  setIsPseudoUnavailable: (nextVal: boolean) => void,
  onSubmit: (formData: FormData) => Promise<void>,
  setShouldResetForm: (nextVal: boolean) => void,
  isPseudoUnavailable: boolean,
  shouldResetForm: boolean,
  user?: User,
}

const UserProfileForm = ({
  setIsPseudoUnavailable,
  isPseudoUnavailable,
  setShouldResetForm,
  shouldResetForm,
  onSubmit,
  user,
}: UserProfileFormProps): JSX.Element => {
  const defaultValues: UserProfileFormValues = {
    firstname: user?.firstname || '',
    lastname: user?.lastname || '',
    picture: user?.picture || '',
    pseudo: user?.pseudo || '',
  }
  
  const { register, handleSubmit, reset, control, formState, setValue } = useForm<UserProfileFormValues>({ defaultValues })
  const values = useWatch<UserProfileFormValues>({ control })
  const responsive = useResponsive()
  const intl = useIntl()

  useEffect(() => {
    if (isPseudoUnavailable) {
      setIsPseudoUnavailable(false)
    }
  }, [values])

  useEffect(() => {
    if (shouldResetForm) {
      reset(defaultValues)
      setShouldResetForm(false)
    }
  }, [shouldResetForm])

  const onSubmitForm = async (): Promise<void> => {
    const formData = new FormData()
    
    formData.append('firstname', values.firstname || user?.firstname || '')
    formData.append('lastname', values.lastname || user?.lastname || '')
    formData.append('pseudo', values.pseudo || user?.pseudo || '')

    if (values.picture instanceof File) {
      const compressedImage: File | Blob = await compressImage(values.picture)
      formData.append('picture', compressedImage, values.picture.name)
    } else if (values.picture) {
      formData.append('picture', values.picture)
    }

    onSubmit(formData)
  }

  const hasChanges: boolean = !isEqual(values, defaultValues)
  const canSubmit: boolean = Boolean(hasChanges && !!values.pseudo && values.pseudo.length >= 3)
  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)

  return (
    <form onSubmit={handleSubmit(() => onSubmitForm())}>
      <Stack spacing={2}>
        <Stack className="panel" alignItems="center" spacing={2}>

          <Stack width="100%">
            <p><b>{intl.formatMessage({id: 'observation'})} :</b> {intl.formatMessage({id: 'profile_form_observation'})}</p>
          </Stack>
          
          <CircularImageDisplay 
            image={values.picture || user?.googlePicture || '/images/default-profile-picture.png'}
            onDeleteImage={() => setValue('picture', undefined)}
            editable={!!values.picture}
            size={100}
          />

          {
            <Stack direction="row" spacing={1} justifyContent="center">
              <ImagesPromptButton 
                label={(values.picture || user?.googlePicture) ? intl.formatMessage({id: 'form_image_button_update_single'}) : intl.formatMessage({id: 'form_image_button_single'})}
                setValue={setValue}
                fieldName="picture"
                control={control}
                icon="photo"
              />

              {
                !!values.picture && (
                  <Tooltip title={intl.formatMessage({id: 'delete_image'})} placement="right">
                    <Button 
                      onClick={() => setValue('picture', undefined)}
                      sx={{ p: 1, minWidth: 0 }}
                      variant="outlined"
                      color="secondary"
                    >
                      <CustomIcon name="delete" outlined size={20} />
                    </Button>
                  </Tooltip>
                )
              }
            </Stack>
          }
        
          <TextField
            {...register('pseudo', { required: true, minLength: 4 })}
            placeholder={intl.formatMessage({id: 'profile_form_pseudo'})}
            label={intl.formatMessage({id: 'profile_form_pseudo'})}
            size="small"
          />

          <TextField
            {...register('firstname', { required: false })}
            placeholder={intl.formatMessage({id: 'profile_form_firstname'})}
            label={intl.formatMessage({id: 'profile_form_firstname'})}
            size="small"
          />

          <TextField
            {...register('lastname', { required: false })}
            placeholder={intl.formatMessage({id: 'profile_form_lastname'})}
            label={intl.formatMessage({id: 'profile_form_lastname'})}
            size="small"
          />

          {
            formState.errors.pseudo && formState.errors.pseudo.type === 'minLength' && (
              <p className="text_error">{intl.formatMessage({id: 'profile_form_pseudo_min_length'})}</p>
            )
          }

          {
            isPseudoUnavailable && (
              <p className="text_error">{intl.formatMessage({id: 'profile_form_pseudo_unavailable'})}</p>
            )
          }

        </Stack>

        <Stack direction="row" justifyContent={!isDesktop ? 'space-between' : 'flex-end'} spacing={1}>
          {
            !isDesktop && (
              <GoBackButton defaultUrl="/settings" withIcon />
            )
          }

          <Stack direction="row" spacing={1}>
            <Button
              onClick={() => reset(defaultValues)}
              disabled={!hasChanges}
              variant="outlined"
              color="secondary"
            >
              {intl.formatMessage({id: 'reset'})}
            </Button>
            <Button 
              disabled={!canSubmit}
              variant="contained" 
              color="primary" 
              type="submit" 
            >
              {intl.formatMessage({id: 'save'})}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  )
}

export default UserProfileForm
export { UserProfileFormValues }