
enum QuestionTypesLabelsEnum {
  QCM = 'Question à Choix Multiples',
  TEXT = 'Question texte',
}

enum QuestionTypesIconsEnum {
  QCM = 'format_list_bulleted',
  TEXT = 'edit_note',
}

enum QuestionTypesDescriptionEnum {
  QCM = 'Le format QCM présente plusieurs options de réponse pour une question donnée, dont une seule est correcte.',
  TEXT = 'Posez une question et attendez une réponse écrite en retour.',
}

enum QuestionTypesFormPathsEnum {
  TEXT = 'text',
  QCM = 'qcm',
}

enum ProgressLabelsEnum {
  ACQUIRED = 'Acquise',
  INPROGRESS = 'En cours d\'acquisition',
  UNACQUIRED = 'Non acquise',
}

enum ProgressStartIconsEnum {
  UNACQUIRED = 'cancel',
  INPROGRESS = '',
  ACQUIRED = '',
}

enum ProgressEndIconsEnum {
  UNACQUIRED = '',
  INPROGRESS = '',
  ACQUIRED = 'check_circle',
}

enum ProgressColorsEnum {
  UNACQUIRED = '#BADBDB',
  INPROGRESS = '#75B7B6',
  ACQUIRED = '#0d8180',
}

export {
  QuestionTypesDescriptionEnum,
  QuestionTypesFormPathsEnum,
  QuestionTypesLabelsEnum,
  QuestionTypesIconsEnum,
  ProgressStartIconsEnum,
  ProgressEndIconsEnum,
  ProgressColorsEnum,
  ProgressLabelsEnum,
}