import React, { useContext } from 'react'
import { Button, IconButton, Stack, TextField, Tooltip } from '@mui/material'
import { useForm, useFieldArray } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Question, QuestionTypesEnum, TextQuestionContent, TextResponse } from '../../../../../../common/types'
import ImagesPromptButton from '../../components/images/images-prompt-button'
import { maxTextResponsesCount } from '../../../../../../common/constants'
import SelectQuestionType from '../../components/select-question-type'
import { useResponsive } from '../../../../../common/hooks/responsive'
import CustomIcon from '../../../../../common/components/custom-icon'
import FormImagesViewer from '../../components/images/images-viewer'
import TextQuestionFormSubmitButtons from './submit-buttons'
import { compressImage } from '../../../../utils/image'
import { UserContext } from '../../../../contexts'

import './styles.scss'

interface TextQuestionFormProps {
  onSubmit: (formData: FormData, addAnotherQuestionAfterSubmit?: boolean) => Promise<void>,
  subjectSubscriptionId: string,
  question?: Question,
  subjectId: string,
}

const defaultResponse: TextResponse = {
  response: '',
  label: '',
}

const defaultValues: TextQuestionContent = {
  responses: [{...defaultResponse}],
  explanation: '',
  question: '',
}

const TextQuestionForm = ({
  subjectSubscriptionId,
  subjectId,
  question,
  onSubmit,
}: TextQuestionFormProps): JSX.Element => {
  const { user } = useContext(UserContext)
  const responsive = useResponsive()
  const intl = useIntl()

  const { register, handleSubmit, reset, control, setValue } = useForm<TextQuestionContent>({
    defaultValues: (question?.content as TextQuestionContent) || defaultValues,
  })

  const {
    append: appendResponse,
    remove: removeResponse,
    update: updateResponse,
    fields: responses,
  } = useFieldArray<TextQuestionContent>({
    name: 'responses',
    keyName: 'id',
    control,
  })

  const canAddResponses: boolean = (responses.length < maxTextResponsesCount)

  const onAddResponse = (): void => {
    if (canAddResponses) {
      appendResponse(defaultResponse)
    }
  }

  const onSubmitForm = async (
    textQuestionContent: TextQuestionContent,
    addAnotherQuestionAfterSubmit?: boolean,
  ): Promise<void> => {
    const formData = new FormData()

    formData.append('question', JSON.stringify({
      ...question,
      type: QuestionTypesEnum.TEXT,
      subject: subjectId,
      author: user!._id!,
      content: {
        ...textQuestionContent,
        images: (textQuestionContent.images || []).filter((image: string | File) => !(image instanceof File)),
      },
    }))

    formData.append('subjectSubscriptionId', subjectSubscriptionId)

    await Promise.all(
      (textQuestionContent.images || []).map(async (image: string | File) => {
        if (image instanceof File) {
          const compressedImage: File | Blob = await compressImage(image)
          formData.append('images', compressedImage, image.name)
        }
      })
    )

    onSubmit(formData, addAnotherQuestionAfterSubmit)

    if (addAnotherQuestionAfterSubmit) {
      reset()
    }
  }

  const isSmallDevice: boolean = responsive.isSmallDevice()
  const isEdit: boolean = !!question

  return (
    <form 
      onSubmit={handleSubmit((values: TextQuestionContent) => onSubmitForm(values))} 
      className="text_question_form_container"
    >
      <Stack spacing={1}>
        {
          (isSmallDevice && !isEdit) && (
            <SelectQuestionType selectedQuestionType={QuestionTypesEnum.TEXT} />
          )
        }
        
        <FormImagesViewer 
          setValue={setValue}
          fieldName="images"
          control={control}
        />

        <Stack direction="row" spacing={1}>
          <TextField
            {...register('question', { required: true })}
            placeholder={intl.formatMessage({id: 'question_form_question'})}
            sx={{ flex: 1 }}
            size="small"
            multiline
          />

          <ImagesPromptButton
            small={isSmallDevice}
            setValue={setValue}
            fieldName="images"
            control={control}
            multiple
          />
        </Stack>

        {
          responses.map((response: TextResponse, index: number) => {
            const multipleResponses: boolean = responses.length > 1
            const isDeletable: boolean = (index > 0)

            return (
              <Stack 
                direction={isSmallDevice ? 'column' : 'row'} 
                key={`response_field_${index}`}
                spacing={1}
              >
                <Stack direction="row" spacing={1}>
                  <TextField 
                    value={response.label || ''}
                    sx={{ flex: 1 }}
                    size="small"
                    placeholder={multipleResponses ? 
                      intl.formatMessage({id: 'question_form_response_label_multiple'}, {num: index + 1}) : 
                      intl.formatMessage({id: 'question_form_response_label_single'})
                    }
                    onChange={e => updateResponse(index, {
                      ...response,
                      label: e.target.value,
                    })}
                  />
                  {
                    (isSmallDevice && isDeletable) && (
                      <IconButton onClick={() => removeResponse(index)}>
                        <CustomIcon name="delete" />
                      </IconButton>
                    )
                  }
                </Stack>
                <TextField 
                  value={response.response || ''}
                  sx={{ flex: 1 }}
                  size="small"
                  multiline
                  placeholder={multipleResponses ? 
                    intl.formatMessage({id: 'question_form_response_optional_multiple'}, {num: index + 1}) : 
                    intl.formatMessage({id: 'question_form_response_optional_single'})
                  }
                  onChange={e => updateResponse(index, {
                    ...response,
                    response: e.target.value,
                  })}
                />
                {
                  (!isSmallDevice && isDeletable) && (
                    <Tooltip title={intl.formatMessage({id: 'delete'})} placement="right">
                      <IconButton onClick={() => removeResponse(index)}>
                        <CustomIcon name="delete" />
                      </IconButton>
                    </Tooltip>
                  )
                }
              </Stack>
            )
          })
        }
        
        <Stack 
          alignItems={isSmallDevice ? 'flex-end' : 'center'} 
          direction={isSmallDevice ? 'column' : 'row'} 
          justifyContent="flex-end" 
          spacing={isSmallDevice ? 1 : 2}
        >
          {!canAddResponses && (<p style={{ fontStyle: 'italic', color: 'grey' }}> {intl.formatMessage({id: 'question_form_max_answers'})} </p>)}
          <Button 
            endIcon={(<CustomIcon name="add" />)}
            disabled={!canAddResponses}
            onClick={onAddResponse}
            className="bg_light" 
            variant="outlined" 
            color="secondary"
          >
            {intl.formatMessage({id: 'question_form_add_response_field'})}
          </Button>
        </Stack>

        <TextField
          {...register('explanation')}
          placeholder={intl.formatMessage({id: 'question_form_explanation'})}
          size="small"
          minRows={4}
          multiline
          fullWidth
        />
        <TextQuestionFormSubmitButtons 
          defaultValues={(question?.content as TextQuestionContent) || defaultValues}
          onSubmitForm={onSubmitForm}
          control={control}
          isEdit={isEdit}
          reset={reset}
        />
      </Stack>
    </form>
  )
}

export default TextQuestionForm