import { useEffect } from 'react'

const useKeyboardListenner = (keyCode: number, callback: () => void) => {
  useEffect(() => {
    window.addEventListener('keyup', onKeyUp)
    return () => window.removeEventListener('keyup', onKeyUp)
  }, [])

  const onKeyUp = (e) => {
    if (e.which === keyCode || e.keyCode === keyCode) {
      callback()
    }
  }
}

export { useKeyboardListenner }
