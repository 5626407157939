import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import CategoriesChips from '../../../common/components/categories/categories-chips'
import { DeviceTypesEnum, useResponsive } from '../../../common/hooks/responsive'
import { SubjectPublicView } from '../../../../common/types'
import { BrowserContext } from '../../../common/contexts'

const BrowserSearch = (): JSX.Element | null => {
  const { searchResults, hasNoSearchResults } = useContext(BrowserContext)
  const responsive = useResponsive()
  const intl = useIntl()

  const isSmallDevice: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM) 

  return (
    <>
      {
        hasNoSearchResults && (
          <Stack 
            sx={{ width: isSmallDevice ? 'calc(100vw - 40px)' : 'auto' }}
            alignItems="center" 
            className="center" 
            textAlign="center"
          >
            {
              isSmallDevice ? (
                <h2>{intl.formatMessage({id: 'browser_no_result'})}</h2>
              ) : (
                <h1>{intl.formatMessage({id: 'browser_no_result'})}</h1>
              )
            }
            <p>{intl.formatMessage({id: 'browser_no_result_1'})}</p>
            <p>{intl.formatMessage({id: 'browser_no_result_2'})}</p>
          </Stack>
        )
      }

      {
        !hasNoSearchResults && searchResults.length > 0 && (
          <Stack spacing={2}>
            <h2>{intl.formatMessage({id: 'brower_results_title'})}</h2>
            
            <Stack spacing={1}>
              {
                searchResults.map((subject: SubjectPublicView, index: number) => {
                  return (
                    <Link key={`result_${index}`} to={`/subject/${subject._id}`}>
                      <Stack 
                        className="panel subject_result_container"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        direction="row"
                      >
                        <Stack spacing={1}>
                          <Stack>
                            <h3>{subject.label}</h3>
                            <p>{`${subject.questions.length} question${subject.questions.length > 1 ? 's' : ''}`} </p>
                          </Stack>
                          {
                            isSmallDevice && (
                              <CategoriesChips categoriesIds={subject.categories || []} />
                            )
                          }  
                        </Stack>

                        {
                          !isSmallDevice && (
                            <CategoriesChips categoriesIds={subject.categories || []} />
                          )
                        }
                      </Stack>
                    </Link>
                  )
                })
              }
            </Stack>
          </Stack>
        )
      }
    </>
  )
}

export default BrowserSearch
