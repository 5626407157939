import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { displayWarningToast } from '../utils/toast'
import { Subject } from '../../../common/types'
import { useRequest } from './request'
import { LoaderContext } from '../contexts'

const useLoadSubject = (): {
  loadSubject: () => Promise<void>,
  subject: Subject | null,
} => {
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const [subject, setSubject] = useState<Subject | null>(null)
  const { subjectId } : { subjectId: string } = useParams()
  const { fetchData } = useRequest()

  const history = useHistory()

  useEffect(() => {
    loadSubject()
  }, [subjectId])

  const loadSubject = async (): Promise<void> => {
    if (subjectId) {
      displayLoader()
      const response = await fetchData('/subject', { subjectId })
      hideLoader()
      
      if (response.subject) {
        setSubject(response.subject || null)
      } else {
        displayWarningToast('Le sujet est inconnu !')
        history.push('/subject-subscriptions')
      }
    }
  }

  return {
    loadSubject,
    subject,
  }
}

export { useLoadSubject }