import React from 'react'
import { Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import classnames from 'classnames'

import { useResponsive } from '../../hooks/responsive'
import CustomIcon from '../custom-icon'
import './styles.scss'

interface WideButtonProps {
  onClick?: () => void,
  titleLevel2?: string,
  textLevel2?: string,
  disabled?: boolean,
  title: string,
  link?: string,
  icon: string,
  text: string,
}

const WideButton = (props: WideButtonProps): JSX.Element | null => {
  if (!!props.link) {
    return (
      <Link to={props.link}>
        <WideButtonContent {...props} />
      </Link>
    )
  } else if (!!props.onClick) {
    return (
      <WideButtonContent {...props} />
    )
  } else {
    console.warn(`[WideButton]: please provide a link or an onClick props`)
    return null
  }
}

const WideButtonContent = ({
  titleLevel2,
  textLevel2,
  disabled,
  onClick,
  title,
  icon,
  text,
}: WideButtonProps): JSX.Element => {
  const responsive = useResponsive()
  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <div className={classnames('wide_button_container', {disabled_greyed: disabled})} onClick={onClick}>
      <Stack direction={isSmallDevice ? 'column' : 'row'} spacing={isSmallDevice ? 1 : 2} alignItems="center">
        <div className="wide_button_icon_container">
          <CustomIcon name={icon} />
        </div>
        <Stack 
          alignItems={isSmallDevice ? 'center' : 'left'} 
          textAlign={isSmallDevice ? 'center' : 'left'} 
          spacing={isSmallDevice ? 1 : 0}
        >
          <Stack direction={isSmallDevice ? 'column' : 'row'} spacing={isSmallDevice ? 0 : 1} alignItems="center">
            <h3>{ title } </h3>
            { !!titleLevel2 && (<span>{titleLevel2}</span>) }
          </Stack>
          <p>{ text }</p>
          { textLevel2 && (<p className="text_italic">{textLevel2}</p>) }
        </Stack>
      </Stack>
    </div>
  )
}

export default WideButton