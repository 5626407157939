import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import './styles.scss'

const NotFound = () => {
  const intl = useIntl()

  return (
    <div className="page_not_found_container">
      <div className="not_found text_center">
        <p>{intl.formatMessage({id: 'page_not_found'})}</p>
        <Link to="/">
          <div className="button border_hover margin_top_small bg_light">
            <span>{intl.formatMessage({id: 'page_not_found_go_home'})}</span>
          </div>
        </Link>
      </div>
    </div>
  )
}


export default NotFound

