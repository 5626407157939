import { useContext, useEffect } from 'react'
import { useParams } from 'react-router'

import { Invite, Subject, SubjectMember, SubjectSubscription } from '../../../common/types'
import { SubjectSubscriptionsContextType } from '../contexts/subject-subscriptions'
import { SubjectSubscriptionsContext, UserContext } from '../contexts'
import { getCanUserDeleteSubject, getCanUserEditSubject } from '../../../common/utils/subject'

const useLoadSubjectSubscription = ({
  loadSubjectSubscriptionOnMount,
  loadSubjectMembersOnMount,
  loadSubjectInvitesOnMount,
} : {
  loadSubjectSubscriptionOnMount?: boolean,
  loadSubjectMembersOnMount?: boolean,
  loadSubjectInvitesOnMount?: boolean,
} = {
  loadSubjectSubscriptionOnMount: false,
  loadSubjectMembersOnMount: false,
  loadSubjectInvitesOnMount: false,
}): {
  loadSubjectSubscriptionIfNeeded: () => Promise<void>,
  subjectSubscription: SubjectSubscription | null,
  loadSubjectSubscription: () => Promise<void>,
  loadSubjectMembers: () => Promise<void>,
  loadSubjectInvites: () => Promise<void>,
  canUserDeleteSubject: boolean | null,
  canUserEditSubject: boolean | null,
  isUserSubjectOwner: boolean | null,
  subjectMembers: SubjectMember[],
  subjectInvites: Invite[],
  subject: Subject | null,
} => {
  const { 
    loadSubjectSubscription, 
    subjectSubscription, 
    loadSubjectMembers,
    loadSubjectInvites,
    subjectMembers,
    subjectInvites
  } = useContext<SubjectSubscriptionsContextType>(SubjectSubscriptionsContext)
  const { subjectSubscriptionId } : { subjectSubscriptionId: string } = useParams()
  const { user } = useContext(UserContext)
  
  useEffect(() => { 
    if (loadSubjectSubscriptionOnMount) {
      checkIdAndLoadSubjectSubscription()
    } else {
      loadSubjectSubscriptionIfNeeded() 
    }

    if (loadSubjectMembersOnMount) {
      checkIdAndLoadSubjectMembers()
    }

    if (loadSubjectInvitesOnMount) {
      checkIdAndLoadSubjectInvites()
    }
  }, [])

  const loadSubjectSubscriptionIfNeeded = async (): Promise<void> => {
    if (!subjectSubscription || subjectSubscription._id !== subjectSubscriptionId) {
      await loadSubjectSubscription(subjectSubscriptionId)
    }
  }

  const checkIdAndLoadSubjectSubscription = async (): Promise<void> => {
    if (!!subjectSubscriptionId) {
      await loadSubjectSubscription(subjectSubscriptionId)
    }
  }

  const checkIdAndLoadSubjectMembers = async (): Promise<void> => {
    if (!!subjectSubscriptionId) {
      await loadSubjectMembers(subjectSubscriptionId)
    }
  }

  const checkIdAndLoadSubjectInvites = async (): Promise<void> => {
    if (!!subjectSubscriptionId) {
      await loadSubjectInvites(subjectSubscriptionId)
    }
  }

  const canUserDeleteSubject: boolean | null = (subjectSubscription && user) ? getCanUserDeleteSubject(subjectSubscription, user._id) : null
  const canUserEditSubject: boolean | null = (subjectSubscription && user) ? getCanUserEditSubject(subjectSubscription, user._id) : null
  
  const subject: Subject | null = subjectSubscription ? subjectSubscription.subject as Subject : null
  const isUserSubjectOwner: boolean | null = (subject && user) ? subject.author === user._id : null

  return {
    loadSubjectSubscription: checkIdAndLoadSubjectSubscription,
    loadSubjectMembers: checkIdAndLoadSubjectMembers,
    loadSubjectInvites: checkIdAndLoadSubjectInvites,
    loadSubjectSubscriptionIfNeeded,

    subjectSubscription: subjectSubscription?._id === subjectSubscriptionId ? subjectSubscription : null,
    canUserDeleteSubject,
    canUserEditSubject,
    isUserSubjectOwner,
    subjectMembers,
    subjectInvites,
    subject,
  }
}

export { useLoadSubjectSubscription }