import React, { useEffect } from 'react'
import { Button, Stack, TextField } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface ForgotPasswordFormValues {
  mail: string,
}

interface ForgotPasswordFormProps {
  onSubmit: (values: ForgotPasswordFormValues) => Promise<void>,
  setIsUserUnknown: (nextValue: boolean) => void,
  disableSubmitButton: boolean,
  isUserUnknown: boolean,
}

const ForgotPasswordForm = ({
  disableSubmitButton,
  setIsUserUnknown,
  isUserUnknown,
  onSubmit,
}: ForgotPasswordFormProps): JSX.Element => {
  const { register, handleSubmit, control, formState: { errors } } = useForm<ForgotPasswordFormValues>({ 
    defaultValues: { mail: '' },
  })
  const values = useWatch<ForgotPasswordFormValues>({ control })
  const intl = useIntl()

  useEffect(() => {
    if (isUserUnknown) {
      setIsUserUnknown(false)
    }
  }, [values])

  const canSubmit = !!values.mail

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} alignItems="center">
        <TextField
          placeholder={intl.formatMessage({id: 'forgotten_password_mail'})}
          size="small"
          error={!!errors.mail || isUserUnknown}
          inputProps={{ autoComplete: 'username' }}
          {...register('mail', { 
            required: true,
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
          })}
        />

        {
          isUserUnknown && (
            <p className="text_error">{intl.formatMessage({id: 'forgotten_password_unknown_user'})}</p>
          )
        }

        <Button 
          disabled={!canSubmit || disableSubmitButton}
          variant="contained" 
          color="primary" 
          type="submit" 
        >
          {intl.formatMessage({id: 'forgotten_password_send'})}
        </Button>
      </Stack>
    </form>
  )
}

export default ForgotPasswordForm
