import { Question, QuestionSubscription, StudyTypesEnum, Subject } from '../types'
import { getQuestionAcquisitionLevel } from './subject-subscription'

const getQuestionSubscriptionsForStudyType = (
  questionSubscriptions: QuestionSubscription[], 
  studyType: StudyTypesEnum,
): QuestionSubscription[] => {
  let resp: QuestionSubscription[] = []

  switch (studyType) {
    case StudyTypesEnum.ALL: {
      resp = questionSubscriptions
      break
    }
    case StudyTypesEnum.ACQUIRED: {
      resp = questionSubscriptions.filter((questionSubscription: QuestionSubscription) => getIsQuestionAquired(questionSubscription))
      break
    }
    case StudyTypesEnum.UNACQUIRED: {
      resp = resp = questionSubscriptions.filter((questionSubscription: QuestionSubscription) => !getIsQuestionAquired(questionSubscription))
      break
    }
    default: break
  }
  
  return resp
}

// Note : to improve with responses history
const getIsQuestionAquired = (questionSubscription: QuestionSubscription): boolean => {
  return getQuestionAcquisitionLevel(questionSubscription) === 100
}

const getAllQuestionsFromSubject = (subject: Subject): Question[] => {
  return (subject.questions as Question[])
}

export {
  getQuestionSubscriptionsForStudyType,
  getAllQuestionsFromSubject,
  getIsQuestionAquired,
}
