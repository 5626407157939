import { 
  AcquisitionStatusEnum, 
  QuestionSubscription, 
  SubjectSubscription, 
  CommonEntityFields, 
  Subject, 
  User, 
} from './'

interface Study extends CommonEntityFields {
  questionSubscriptions: string[] | QuestionSubscription[],
  subjectSubscription: string | SubjectSubscription,
  questionsInfos: StudyQuestionsInfos[],
  subject: string | Subject,
  type: StudyTypesEnum,
  user: string | User,
}

interface StudyQuestionsInfos {
  acquisitionStatus?: AcquisitionStatusEnum,
  questionSubscriptionId: string,
  isDone?: boolean,
  order: number,
}

enum StudyTypesEnum {
  ALL = 'ALL',
  ACQUIRED = 'ACQUIRED',
  UNACQUIRED = 'UNACQUIRED',
}

export { Study, StudyTypesEnum, StudyQuestionsInfos }