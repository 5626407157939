import React, { useContext, useEffect } from 'react'
import { Button, Stack } from '@mui/material'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { mainPages, offlineMenuPages, Page } from '../../constants/routes'
import { AuthContext, DesktopMenuContext } from '../../contexts'
import { isPageSelected } from '../../utils/routes'
import CustomIcon from '../custom-icon'
import { palette } from '../../theme'
import { useIntl } from 'react-intl'


const DesktopNavigationMenu = (): JSX.Element | null => {  
  const { isLogged, logout } = useContext(AuthContext)
  const location = useLocation()
  const intl = useIntl()

  const { 
    shouldDiplayDesktopMenu,
    isDesktopMenuOpen, 
    setIsFirstRender,
    isFirstRender,
    isOfflineMenu,
    isMenuedPage,
  } = useContext(DesktopMenuContext)

  useEffect(() => {
    setIsFirstRender(true)
  }, [isMenuedPage])

  if (!shouldDiplayDesktopMenu) return null

  return (
    <Stack 
      className={classnames('desktops_menu_container shadow_light', isMenuedPage ? {
        desktops_menu_container_open_slide_hover: true,
        desktops_menu_container_close: true, 
      } : isFirstRender ? {
        desktops_menu_container_close: !isDesktopMenuOpen, 
        desktops_menu_container_open: isDesktopMenuOpen,
      } : {
        desktops_menu_container_close_slide: !isDesktopMenuOpen,
        desktops_menu_container_open_slide: isDesktopMenuOpen,
      })}
    >
      <Stack 
        className="desktops_menu_content"
        justifyContent="space-between" 
        sx={{ p: 2, pt: 3 }}
      >
        <Stack spacing={1}>
          {
            (isOfflineMenu ? offlineMenuPages : mainPages).map((page: Page, index: number) => {
              if (!page.isMainRoute) return null

              const isSelected = isPageSelected(page, location.pathname)

              return (
                <Link to={page.path} key={`page_${index}`}>
                  <Button variant="text" color={isSelected ? 'primary' : 'secondary'}>
                    <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-start" width="100%">
                      <CustomIcon name={page.icon!} outlined />
                      {
                        isSelected ? (
                          <b style={{ color: palette.primary.main }}>{intl.formatMessage({id: `page_${page.labelCode}`})}</b>
                        ) : (
                          <p>{intl.formatMessage({id: `page_${page.labelCode}`})}</p>
                        )
                      }
                    </Stack>
                  </Button>
                </Link>
              )
            })
          }
        </Stack>
        
        {
          isLogged && (
            <Button variant="text" color="secondary" onClick={() => logout()}>
              <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-start" width="100%">
                <CustomIcon name="logout" outlined />
                <p>{intl.formatMessage({id: 'logout'})}</p>
              </Stack>
            </Button>
          )
        }

      </Stack>
    </Stack>
  )
}

export default DesktopNavigationMenu