import React from 'react'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import CustomProgress from '../../../../common/components/custom-progress'

import './styles.scss'

interface QuestionCountProps {
  questionsCount: number,
  questionIndex: number,
}

const QuestionCount = ({
  questionsCount = 0,
  questionIndex = 0,
}: QuestionCountProps): JSX.Element => {
  const intl = useIntl()

  return (
    <Stack direction="row" spacing={2} alignItems="center" className="question_count_container">
      <b>{intl.formatMessage({id: 'question'})} {(questionIndex+1)} / {questionsCount}</b>
      <CustomProgress size={25} value={(((questionIndex)/questionsCount) * 100)} />
    </Stack>
  )
}

export default QuestionCount