import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import { DeviceTypesEnum, useResponsive } from '../../common/hooks/responsive'
import PageMenu from '../../common/components/navigation-menu/page-menu'
import SpacedRepetitionSettings from './components/spaced-repetition'
import StudyQuestionsSettings from './components/study-questions'
import { settingsPages } from '../../common/constants/routes'
import SettingsMenu from './components/settings-menu'
import PasswordSettings from './components/password'
import AccountSettings from './components/account'
import ProfilSettings from './components/profile'

import './styles.scss'

const SettingsPage = (): JSX.Element => {
  const responsive = useResponsive()
  const intl = useIntl()

  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)

  return (
    <Stack direction="row" width="100%" className={classnames({ menu_page_container : isDesktop })}>
      <PageMenu 
        title={intl.formatMessage({id: 'settings'})}
        titleLink={`/settings/profile`}
        pages={settingsPages} 
        mainIcon="settings"  
      />

      <Switch>
        <Route exact path="/settings/spaced-repetition" component={SpacedRepetitionSettings} />
        <Route exact path="/settings/study-questions" component={StudyQuestionsSettings} />
        <Route exact path="/settings/password" component={PasswordSettings} />
        <Route exact path="/settings/account" component={AccountSettings} />
        <Route exact path="/settings/profile" component={ProfilSettings} />

        {
          !isDesktop && <Route exact path="/settings" component={SettingsMenu} />
        }

        <Route path="*" component={() => <Redirect to="/settings/profile" />} />
      </Switch>
    </Stack>
  )
}

export default SettingsPage