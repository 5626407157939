import React, { useContext, useMemo } from 'react'
import { IntlProvider } from 'react-intl'

import { LocalesEnum } from '../contexts/translation'
import frMessages from '../translations/fr-FR.json'
import enMessages from '../translations/en-EN.json'
import { TranslationContext } from '../contexts'

const TranslationsProvider = ({ children } : { children: JSX.Element }): JSX.Element => {
  const { currentLocale } = useContext(TranslationContext)

  const messages: any = useMemo(() => {
    return currentLocale === LocalesEnum.FR ? frMessages : enMessages
  }, [currentLocale])

  return (      
    <IntlProvider messages={messages} locale={currentLocale || 'fr'} defaultLocale="fr">
      {children}
    </IntlProvider>
  )
}

export default TranslationsProvider
