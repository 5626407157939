import React, { useContext, useEffect, useState } from 'react'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import SendVerifyEmailButton from '../../common/components/send-verify-email-button'
import { displaySuccessToast, displayWarningToast } from '../../common/utils/toast'
import { LoaderContext, UserContext } from '../../common/contexts'
import CustomIcon from '../../common/components/custom-icon'
import { useRequest } from '../../common/hooks/request'

import './styles.scss'

interface VerifyEmailProps {
  match: {
    params: {
      verificationCode?: string,
    },
  },
}

const VerifyEmail = ({
  match: {
    params: {
      verificationCode,
    },
  },
}: VerifyEmailProps): JSX.Element | null => {
  const [isCodeInvalid, setIsCodeInvalid] = useState<boolean>(false)
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { user, getUser } = useContext(UserContext)
  const { postData } = useRequest()
  const intl = useIntl()

  useEffect(() => {
    if (verificationCode) {
      verifyEmail(verificationCode)
    }
  }, [verificationCode])

  const verifyEmail = async (verificationCode: string): Promise<void> => {
    displayLoader()
    const response = await postData('/user/verify-email', { verificationCode })
    hideLoader()
    
    if (response.emailVerified) {
      displaySuccessToast(intl.formatMessage({id: 'verify_email_success'}))
      getUser()
    } else if (response === 'email already verified') {
      displayWarningToast(intl.formatMessage({id: 'verify_email_already_verified'}))
    } else if (response === 'incorrect validation code') {
      displayWarningToast(intl.formatMessage({id: 'verify_email_incorrect_code'}))
      setIsCodeInvalid(true)
    }
  }
  
  if (!user) return null
  
  return (
    <>
      {
        user.emailVerified ? (
          <Stack spacing={4} alignItems="center" className="page_content verify_email_page_container">
            <Stack spacing={2} alignItems="center">
              <h1>{intl.formatMessage({id: 'verify_email_success_2'})}</h1>
              <p>{intl.formatMessage({id: 'verify_email_thanks'})}</p>
            </Stack>
            <Link to="/subject-subscriptions">
              <Button
                endIcon={(<CustomIcon name="keyboard_arrow_right" />)}
                variant="outlined"
                color="secondary"
              >
                {intl.formatMessage({id: 'continue'})}
              </Button>
            </Link>
          </Stack>
        ) : (
          <Stack spacing={4} alignItems="center" className="page_content verify_email_page_container">
            <Stack spacing={2} alignItems="center">
              <h1>{intl.formatMessage({id: 'verify_email_verification'})}</h1>
              {
                isCodeInvalid && (
                  <div>
                    <p>{intl.formatMessage({id: 'verify_email_invalid_code_2'})}</p>
                    <p>{intl.formatMessage({id: 'verify_email__tip'})}</p>
                  </div>
                )
              }
            </Stack>
            <SendVerifyEmailButton />
          </Stack>
        )
      }
    </>
  )
}

export default VerifyEmail
