import React, { useContext, useEffect } from 'react'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { QcmQuestionForm, TextQuestionForm } from '../../../../common/components/forms/question-form'
import { useLoadSubjectSubscription } from '../../../../common/hooks/subjects-subscription'
import { displaySuccessToast, displayWarningToast } from '../../../../common/utils/toast'
import { useLoadQuestion } from '../../../../common/hooks/question'
import Breadcrumbs from '../../../../common/components/breadcrumbs'
import { QuestionTypesEnum } from '../../../../../common/types'
import { useRequest } from '../../../../common/hooks/request'
import useRouterUtils from '../../../../common/hooks/router'
import { LoaderContext } from '../../../../common/contexts'
import Title from '../../../../common/components/title'

import './styles.scss'

const UpdateQuestionPage = (): JSX.Element | null => {
  const { subjectSubscription, canUserEditSubject, subject } = useLoadSubjectSubscription()
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { goBackOrRedirect } = useRouterUtils()
  const { question } = useLoadQuestion()
  const { postData } = useRequest()
  const intl = useIntl()

  useEffect(() => {
    if (subjectSubscription && !canUserEditSubject) {
      displayWarningToast(intl.formatMessage({id: 'edit_question_unauthorized'}))
      goBackOrRedirect(`/subject-subscription/${subjectSubscription._id}`)
    }
  }, [subjectSubscription, canUserEditSubject])

  if (!subjectSubscription || !subject || !question) return null

  const onSubmit = async (formData: FormData): Promise<void> => {
    displayLoader()
    const response = await postData('/question/update', formData)
    hideLoader()

    if (response.updatedQuestion) {
      displaySuccessToast(intl.formatMessage({id: 'edit_question_success'}))
      goBackOrRedirect(`/subject-subscription/${subjectSubscription._id}`)
    } else {
      displayWarningToast(intl.formatMessage({id: 'edit_question_fail'}))
    }
  }
  
  return (
    <div className="page_content update_question_page">
      <Stack spacing={2}>
        <Breadcrumbs 
          pages={[
            {
              label: intl.formatMessage({id: 'page_subject_subscriptions'}),
              path: '/subject-subscriptions',
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}`,
              label: subject.label,
            },
            {
              path: `/subject-subscription/${subjectSubscription._id}/update-question/${question._id}`,
              label: intl.formatMessage({id: 'page_update_question'}),
            },
          ]}
        />

        <Title title={intl.formatMessage({id: 'page_update_question'})} />

        {
          question && (
            <>
              {
                question.type === QuestionTypesEnum.QCM && (
                  <QcmQuestionForm 
                    subjectSubscriptionId={subjectSubscription._id!}
                    subjectId={subject._id!} 
                    question={question} 
                    onSubmit={onSubmit}
                  />
                )
              }
              {
                question.type === QuestionTypesEnum.TEXT && (
                  <TextQuestionForm 
                    subjectSubscriptionId={subjectSubscription._id!}
                    subjectId={subject._id!} 
                    question={question} 
                    onSubmit={onSubmit} 
                  />
                )
              }
            </>
          )
        }

      </Stack>
    </div>
  )
}

export default UpdateQuestionPage
