import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { DeviceTypesEnum, useResponsive } from '../../common/hooks/responsive'
import { AuthContext, TranslationContext } from '../../common/contexts'
import { LocalesEnum } from '../../common/contexts/translation'
import CustomIcon from '../../common/components/custom-icon'
import HowItWorksTile from './components/how-it-works-tile'
import FeatureTile from './components/feature-tile'
import { palette } from '../../common/theme'

import './styles.scss'
import 'swiper/css'

const largeDevicesFrImages: string[] = [
  `${process.env.API_URL}/images/homepage/new/subjects/subjects_desktops_fr_2.png`,
  `${process.env.API_URL}/images/homepage/new/subject/subject_desktops_fr.png`,
  `${process.env.API_URL}/images/homepage/new/study_1/study_desktops_fr_2.png`,
]

const mediumDevicesFrImages: string[] = [
  `${process.env.API_URL}/images/homepage/new/subjects/subjects_tablets_fr.png`,
  `${process.env.API_URL}/images/homepage/new/subject/subject_tablets_fr.png`,
  `${process.env.API_URL}/images/homepage/new/study_2/study_tablets_fr_2.png`,
]

const smallDevicesFrImages: string[] = [
  `${process.env.API_URL}/images/homepage/new/subjects/subjects_mobiles_fr.png`,
  `${process.env.API_URL}/images/homepage/new/subject/subject_mobiles_fr.png`,
  `${process.env.API_URL}/images/homepage/new/study_2/study_mobiles_fr_2.png`,
]

const largeDevicesEnImages: string[] = [
  `${process.env.API_URL}/images/homepage/new/subjects/subjects_desktops_en_2.png`,
  `${process.env.API_URL}/images/homepage/new/subject/subject_desktops_en.png`,
  `${process.env.API_URL}/images/homepage/new/study_1/study_desktops_en_2.png`,
]

const mediumDevicesEnImages: string[] = [
  `${process.env.API_URL}/images/homepage/new/subjects/subjects_tablets_en.png`,
  `${process.env.API_URL}/images/homepage/new/subject/subject_tablets_en.png`,
  `${process.env.API_URL}/images/homepage/new/study_2/study_tablets_en_2.png`,
]

const smallDevicesEnImages: string[] = [
  `${process.env.API_URL}/images/homepage/new/subjects/subjects_mobiles_en.png`,
  `${process.env.API_URL}/images/homepage/new/subject/subject_mobiles_en.png`,
  `${process.env.API_URL}/images/homepage/new/study_2/study_mobiles_en_2.png`,
]

const HomePage = (): JSX.Element | null => {
  const [viewMoreFeatures, setViewMoreFeatures] = useState<boolean>(false)
  const { currentLocale } = useContext(TranslationContext)
  const [swiper, setSwiper] = useState<any>(null)
  const { isLogged } = useContext(AuthContext)
  const responsive = useResponsive()
  const history = useHistory()
  const intl = useIntl()

  useEffect(() => {
    if (isLogged) {
      history.push('/subject-subscriptions')
    }
  }, [isLogged])

  const isExtraSmallDevice: boolean = responsive.isExtraSmallDevice()
  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isMediumDevice: boolean = responsive.isMediumDevice()
  const isSmallDevice: boolean = responsive.isSmallDevice()

  const screensImages: string[] = useMemo(() => {
    return currentLocale === LocalesEnum.FR ?
      isLargeDevice ? largeDevicesFrImages : isMediumDevice ? mediumDevicesFrImages : smallDevicesFrImages
      : isLargeDevice ? largeDevicesEnImages : isMediumDevice ? mediumDevicesEnImages : smallDevicesEnImages  
  }, [currentLocale, isLargeDevice, isMediumDevice])
  
  return (
    <Stack width="100%">
      <Stack direction={isLargeDevice ? 'row' : 'column'} className="hompage_container">
        <Stack flex={isLargeDevice ? 1 : undefined} className="headline_container">
          <Stack spacing={4} className="headline_content">
            <Stack spacing={1}>
              <h1> <span style={{ color: palette.primary.main }}>
                <FormattedMessage id="home_main_title_1" />
              </span> <FormattedMessage id="home_main_title_2" /> <span style={{ color: palette.primary.main }}>
                <FormattedMessage id="home_main_title_3" />
              </span> <FormattedMessage id="home_main_title_4" /> <span style={{ color: palette.primary.main }}>
                <FormattedMessage id="home_main_title_5" />
              </span>
              </h1>
              <p>
                <FormattedMessage id="home_main_title_subtitle" />
              </p>
            </Stack>
            <Stack 
              justifyContent={isLargeDevice ? 'left' : 'center'}
              direction={isExtraSmallDevice ? 'column' : 'row'} 
              spacing={1} 
            >
              <Link to="/browse">
                <Button
                  endIcon={(<CustomIcon name="search" />)}
                  variant="contained"
                  color="primary"
                >
                  <b><FormattedMessage id="home_browse_subjects" /></b>
                </Button>
              </Link>
              <Link to="/register">
                <Button
                  endIcon={(<CustomIcon name="keyboard_arrow_right" />)}
                  color="secondary"
                  variant="outlined"
                >
                  <FormattedMessage id="home_create_account" />
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Stack>

        <Stack flex={1} className="screens_container">
          <Stack className="screens_content">
            <div className="swiper_container">
              <Swiper
                onSwiper={swiperObj => setSwiper(swiperObj)}
                slidesPerView={1}
                spaceBetween={50}
                loop
              >
                {
                  screensImages.map((imagePath: string, index: number) => {
                    return (
                      <SwiperSlide key={`screen_image_${index}`}>
                        <img className="shadow_large" src={imagePath} />
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
            </div>
            <Stack justifyContent="flex-end" spacing={2} direction="row" paddingRight="15px">
              <IconButton
                onClick={() => swiper?.slidePrev()}
                className="bg_light shadow_light"
                size="small"
              >
                <CustomIcon name="keyboard_arrow_left" />
              </IconButton>
              <IconButton
                onClick={() => swiper?.slideNext()}
                className="bg_light shadow_light"
                size="small"
              >
                <CustomIcon name="keyboard_arrow_right" />
              </IconButton>
            </Stack>
          </Stack>
          
          {
            isMediumDevice && (
              <img className="home_shapes" src={`${process.env.API_URL}/images/homepage/homepage_tablets_shapes_01.svg`} />
            )
          }

          {
            isSmallDevice && (
              <img className="home_shapes" src={`${process.env.API_URL}/images/homepage/homepage_shapes_mobiles_02.svg`} />
            )
          }
        </Stack>

        {
          isLargeDevice && (
            <img className="home_shapes" src={`${process.env.API_URL}/images/homepage/homepage_shapes_05.svg`} />
          )
        }
      </Stack>

      <Stack className="how_it_works_container" alignItems="center" spacing={2}>
        <Stack className="title_container" alignItems="center">
          <h1><FormattedMessage id="home_how_it_works_title" /></h1>
          <h2><FormattedMessage id="home_how_it_works_subtitle" /></h2>
        </Stack>

        <Stack 
          direction={isLargeDevice ? 'row' : 'column'} 
          className="tiles_container" 
          justifyContent="center"
          alignItems="stretch" 
        >
          <Stack 
            direction={isMediumDevice ? 'row' : 'column'} 
            justifyContent="center"
            alignItems="center" 
            spacing={2} 
          >
            <HowItWorksTile 
              description={intl.formatMessage({id: 'home_how_it_works_2'})}
              title={intl.formatMessage({id: 'home_how_it_works_1'})}
              icon="edit"
            />

            <p><FormattedMessage id="home_how_it_works_3" /></p>

            <HowItWorksTile 
              description={intl.formatMessage({id: 'home_how_it_works_5'})}
              title={intl.formatMessage({id: 'home_how_it_works_4'})}
              icon="search"
            />
          </Stack>

          {
            isLargeDevice && (
              <Stack flex={1} direction="row" alignItems="center" minWidth="25px">
                <Box 
                  borderRadius="0px 20px 20px 0px"
                  borderBottom="1px solid grey"
                  borderRight="1px solid grey"
                  borderTop="1px solid grey"
                  height="60%"
                  flex={1}
                />
                <Box 
                  borderTop="1px solid grey"
                  flex={1}
                />
              </Stack>
            )
          }

          {
            isMediumDevice && (
              <Stack alignItems="center" justifyContent="center" height="60px">
                <Box 
                  borderRadius="0px 0px 20px 20px"
                  borderBottom="1px solid grey"
                  borderRight="1px solid grey"
                  borderLeft="1px solid grey"
                  width="60%"
                  flex={1}
                />
                <Box 
                  borderLeft="1px solid grey"
                  flex={1}
                />
              </Stack>
            )
          }

          {
            isSmallDevice && (
              <Stack alignItems="center" justifyContent="center" height="30px">
                <Box 
                  borderLeft="1px solid grey"
                  flex={1}
                />
              </Stack>
            )
          }

          <Stack alignItems="center" justifyContent="center">
            <HowItWorksTile 
              description={intl.formatMessage({id: 'home_how_it_works_7'})}
              title={intl.formatMessage({id: 'home_how_it_works_6'})}
              icon="school"
            />
          </Stack>

          <Stack flex={1} alignItems="center" justifyContent="center" direction="row" minWidth="25px">
            <Box 
              borderLeft={!isLargeDevice ? '1px solid grey' : undefined}
              borderTop={isLargeDevice ? '1px solid grey' : undefined}
              height={isLargeDevice ? undefined : '30px'}
              flex={isLargeDevice ? 1 : undefined}
            />
          </Stack>

          <Stack alignItems="center" justifyContent="center">
            <HowItWorksTile 
              description={intl.formatMessage({id: 'home_how_it_works_9'})}
              title={intl.formatMessage({id: 'home_how_it_works_8'})}
              icon="moving"
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack className="features_container" alignItems="center" spacing={4}>
        <Stack className="title_container" alignItems="center">
          <h1><FormattedMessage id="home_features_title" /></h1>
          <h2><FormattedMessage id="home_features_subtitle" /></h2>
        </Stack>
        
        {
          isLargeDevice && (
            <Stack className="feature_tiles_container" spacing={3}>
              <Stack direction="row" spacing={3}>
                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_2'})}
                  title={intl.formatMessage({id: 'home_features_1'})}
                />

                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_4'})}
                  title={intl.formatMessage({id: 'home_features_3'})}
                />

                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_6'})}
                  title={intl.formatMessage({id: 'home_features_5'})}
                />
              </Stack>

              <Stack direction="row" spacing={3}>
                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_8'})}
                  title={intl.formatMessage({id: 'home_features_7'})}
                />
                
                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_10'})}
                  title={intl.formatMessage({id: 'home_features_9'})}
                />

                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_12'})}
                  title={intl.formatMessage({id: 'home_features_11'})}
                />
              </Stack>

              <Stack direction="row" spacing={3}>
                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_14'})}
                  title={intl.formatMessage({id: 'home_features_13'})}
                />

                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_16'})}
                  title={intl.formatMessage({id: 'home_features_15'})}
                />

                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_18'})}
                  title={intl.formatMessage({id: 'home_features_17'})}
                />
              </Stack>
            </Stack>
          )
        }

        {
          !isLargeDevice && (
            <Stack className="feature_tiles_container" spacing={3}>
              <FeatureTile 
                description={intl.formatMessage({id: 'home_features_2'})}
                title={intl.formatMessage({id: 'home_features_1'})}
              />

              <Stack direction={isMediumDevice ? 'row' : 'column'} spacing={3}>
                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_8'})}
                  title={intl.formatMessage({id: 'home_features_7'})}
                />

                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_10'})}
                  title={intl.formatMessage({id: 'home_features_9'})}
                />
              </Stack>

              <FeatureTile 
                description={intl.formatMessage({id: 'home_features_12'})}
                title={intl.formatMessage({id: 'home_features_11'})}
              />

              <Stack direction={isMediumDevice ? 'row' : 'column'} spacing={3}>
                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_16'})}
                  title={intl.formatMessage({id: 'home_features_15'})}
                />

                <FeatureTile 
                  description={intl.formatMessage({id: 'home_features_14'})}
                  title={intl.formatMessage({id: 'home_features_13'})}
                />
              </Stack>

              {
                viewMoreFeatures && (
                  <>
                    <FeatureTile 
                      description={intl.formatMessage({id: 'home_features_20'})}
                      title={intl.formatMessage({id: 'home_features_19'})}
                    />
                    
                    <Stack direction={isMediumDevice ? 'row' : 'column'} spacing={3}>
                      <FeatureTile 
                        description={intl.formatMessage({id: 'home_features_14'})}
                        title={intl.formatMessage({id: 'home_features_3'})}
                      />

                      <FeatureTile 
                        description={intl.formatMessage({id: 'home_features_6'})}
                        title={intl.formatMessage({id: 'home_features_5'})}
                      />
                    </Stack>

                    <FeatureTile 
                      description={intl.formatMessage({id: 'home_features_18'})}
                      title={intl.formatMessage({id: 'home_features_17'})}
                    />
                  </>
                )
              }
              
              <Stack direction="row" justifyContent="center">
                <Button 
                  endIcon={(<CustomIcon name={viewMoreFeatures ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />)}
                  onClick={() => setViewMoreFeatures(!viewMoreFeatures)}
                  variant="outlined"
                  color="secondary"
                >
                  { viewMoreFeatures ? intl.formatMessage({id: 'home_see_less'}) : intl.formatMessage({id: 'home_see_more'}) }
                </Button>
              </Stack>
            </Stack>
          )
        }
      </Stack>

      <Stack className="cta_container">
        <Stack className="cta" justifyContent="center" spacing={4}>
          <h1>{intl.formatMessage({id: 'home_cta_1'})} <span style={{ color: '#0d8180' }}>{intl.formatMessage({id: 'home_cta_2'})}</span> {intl.formatMessage({id: 'home_cta_3'})} <span style={{ color: '#0d8180' }}>{intl.formatMessage({id: 'home_cta_4'})}</span> {intl.formatMessage({id: 'home_cta_5'})}</h1>

          <Stack direction="row" justifyContent="center">
            <Link to="/register">
              <Button variant="contained" color="primary" startIcon={(<CustomIcon name="rocket_launch" />)}>
                <b>{intl.formatMessage({id: 'home_cta_6'})}</b>
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default HomePage
