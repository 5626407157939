import { ProgressColorsEnum, QuestionSubscription, AcquisitionStatusEnum, UserResponse } from './question-subscription'
import { InviteStatusLabelsEnum, InviteStatusEnum, Invite } from './invite'
import { StudyQuestionsInfos, StudyTypesEnum, Study } from './study'
import { Credentials, UserPublicView, User } from './user'
import { SearchAutocompleteResult } from './search'
import { CategoryChild, Category } from './category'
import { 
  SubjectPublicView, 
  FeaturedSubject,
  SubjectMember, 
  Subject, 
} from './subject'
import { 
  SubjectSubscriptionUserRoleLabelsEnum,
  SubjectSubscriptionUserRoleEnum, 
  SubjectSubscription, 
} from './subject-subscription'
import { 
  TextQuestionContent,
  QuestionPublicView,
  QcmQuestionContent,
  QuestionTypesEnum,
  TextResponse,
  QcmResponse,
  Question,
} from './question'

interface CommonEntityFields {
  _id?: string,
  createdAt?: number,
  updatedAt?: number,
}

interface VersioningFields {
  originalDocument?: string | any,
  version: number,
}

export {
  SubjectSubscriptionUserRoleLabelsEnum,
  SubjectSubscriptionUserRoleEnum,
  SearchAutocompleteResult,
  InviteStatusLabelsEnum,
  AcquisitionStatusEnum,
  QuestionSubscription,
  StudyQuestionsInfos,
  SubjectSubscription,
  TextQuestionContent,
  QuestionPublicView,
  QcmQuestionContent,
  CommonEntityFields,
  ProgressColorsEnum,
  SubjectPublicView,
  QuestionTypesEnum,
  InviteStatusEnum, 
  VersioningFields,
  FeaturedSubject,
  StudyTypesEnum,
  UserPublicView,
  CategoryChild,
  SubjectMember,
  UserResponse,
  TextResponse,
  Credentials,
  QcmResponse,
  Category,
  Question,
  Subject,
  Invite,
  Study,
  User,
}
