import React, { useContext, useState } from 'react'
import { Button, Stack, Switch, TextField, Tooltip } from '@mui/material'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import { decomposeSubjectSubscription, getSubjectShareLink } from '../../../../../../common/utils/subject'
import { useLoadSubjectSubscription } from '../../../../../common/hooks/subjects-subscription'
import { displayWarningToast } from '../../../../../common/utils/toast'
import { useResponsive } from '../../../../../common/hooks/responsive'
import CustomIcon from '../../../../../common/components/custom-icon'
import { useRequest } from '../../../../../common/hooks/request'
import { LoaderContext } from '../../../../../common/contexts'
import Title from '../../../../../common/components/title'

const ShareLinkPanel = (): JSX.Element | null => {
  const { subjectSubscription, loadSubjectSubscription } = useLoadSubjectSubscription()
  const [didCopyShareLink, setDidCopyShareLink] = useState<boolean>(false)
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const responsive = useResponsive()
  const { postData } = useRequest()
  const intl = useIntl()
  
  if (!subjectSubscription) return null

  const { subject } = decomposeSubjectSubscription(subjectSubscription)
  const shareLink: string = getSubjectShareLink(subjectSubscription)

  const onEnableSharLinkChange = async (): Promise<void> => {
    displayLoader()
    
    const response = await postData('/subject/update-enable-share-link', { 
      subjectSubscriptionId: subjectSubscription._id,
      enableShareLink: !subject.enableShareLink,
    })

    if (response.success) {
      await loadSubjectSubscription()
    } else {
      displayWarningToast(intl.formatMessage({id: 'error_occured'}))
    }

    hideLoader()
  }

  const copyShareLink = (): void => {
    navigator.clipboard.writeText(shareLink)
    setDidCopyShareLink(true)
    setTimeout(() => setDidCopyShareLink(false), 3000) 
  }

  const isSmallDevice: boolean = responsive.isSmallDevice()

  return (
    <Stack spacing={1} flex={1} className="panel">
      <Title 
        level={3} 
        title={intl.formatMessage({id: 'share_link'})}
        withoutDot 
        rightContent={!subject.enableShareSubject ? (
          <Stack direction="row" spacing={0} alignItems="center">
            {
              subject.enableShareLink ? (
                <span>{intl.formatMessage({id: 'enabled'})}</span>
              ) : (
                <span>{intl.formatMessage({id: 'disabled'})}</span>
              )
            }
            <Switch 
              onChange={onEnableSharLinkChange}
              checked={subject.enableShareLink} 
              color="success" 
            />
          </Stack>
        ) : undefined}
      />
      <Stack direction="row" spacing={1}>
        <TextField 
          value={`${process.env.FRONT_URL}/subject/${subject._id}`} 
          disabled={!subject.enableShareLink} 
          sx={{ flex: 1 }} 
          size="small"
        />

        {
          subject.enableShareLink ? (
            <Tooltip title={intl.formatMessage({id: 'copy_clipboard'})} placement="bottom">
              <Button
                className={isSmallDevice ? 'mui_icon_button' : ''}
                disabled={!subject.enableShareLink}
                onClick={copyShareLink}
                variant="outlined"
                color="secondary"
              >
                <Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
                  {!isSmallDevice && (<p>{ didCopyShareLink ? intl.formatMessage({id: 'copied'}) : intl.formatMessage({id: 'copy'}) }</p>)}
                  <CustomIcon name={didCopyShareLink ? 'check' : 'content_copy'} />
                </Stack>
              </Button>                
            </Tooltip>
          ) : (
            <Button 
              className={classnames('disabled_greyed', { mui_icon_button: isSmallDevice })}
              variant="outlined" 
              color="secondary" 
              disabled 
            >
              <Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
                {!isSmallDevice && (<p>{intl.formatMessage({id: 'copy'})}</p>)}
                <CustomIcon name="content_copy" />  
              </Stack>
            </Button>                
          )
        }
        
      </Stack>
    </Stack>
  )
}

export default ShareLinkPanel