import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, IconButton, Popper, Stack } from '@mui/material'
import { ClickAwayListener } from '@mui/base'
import { useIntl } from 'react-intl'

import { Category, CategoryChild } from '../../../../../common/types'
import { CategoriesContext } from '../../../contexts'
import CustomIcon from '../../custom-icon'

import './styles.scss'

interface SelectCategoriesButtonProps {
  onSelectCategory: (categoryId: string) => void,
}

const SelectCategoriesButton = ({
  onSelectCategory,
}: SelectCategoriesButtonProps): JSX.Element | null => {
  const [openedCategoryIds, setOpenedCategoryIds] = useState<string[]>([])
  const [openPopper, setOpenPopper] = useState<boolean>(false)
  
  const buttonRef = useRef()
  const intl = useIntl()

  const { 
    loadCategoriesInfos, 
    categoriesTree,
    categoriesById,
    categories,
  } = useContext(CategoriesContext)

  useEffect(() => {
    if (!categories.length) {
      loadCategoriesInfos()
    }
  }, [])

  const onToggleCategory = (categoryId: string): void => {
    if (openedCategoryIds.includes(categoryId)) {
      setOpenedCategoryIds(openedCategoryIds.filter((openedCategoryId: string) => openedCategoryId !== categoryId))
    } else {
      setOpenedCategoryIds([...openedCategoryIds, categoryId])
    }
  }

  if (!categories) return null

  const selectedCategory: Category | undefined = openedCategoryIds.length ? categoriesById[openedCategoryIds[openedCategoryIds.length - 1]] : undefined
  const categoriesList: Category[] = selectedCategory ? 
    (selectedCategory.childrens || []).map((categoryChild: CategoryChild) => categoriesById[categoryChild.category as string])
    : categoriesTree
  
  return (
    <>
      <Button
        endIcon={(<CustomIcon name={openPopper ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} outlined />)}
        onClick={() => setOpenPopper(!openPopper)}
        ref={buttonRef as any}
        variant="outlined"
        color="secondary"
      >
        {intl.formatMessage({id: 'categories_button_label'})}
      </Button>

      {
        openPopper && (
          <ClickAwayListener onClickAway={() => {
            setOpenedCategoryIds([])
            setOpenPopper(false)
          }}>
            <Popper 
              className="categories_button_popper_container shadow_light"
              open={!!buttonRef.current && openPopper} 
              anchorEl={buttonRef.current}
              placement="bottom"
              sx={{ p: 1 }}
            >
              <Stack>
                {
                  selectedCategory && (
                    <Stack 
                      alignItems="center"
                      direction="row"
                      spacing={0.5}
                      pr="20px"
                    >
                      <IconButton 
                        onClick={() => onToggleCategory(selectedCategory._id!)}
                        size="small"
                      >
                        <CustomIcon name="keyboard_arrow_left" />
                      </IconButton>

                      <Button 
                        onClick={() => onSelectCategory(selectedCategory._id!)}
                        color="secondary"
                        sx={{ 
                          justifyContent: 'flex-start',
                        }}
                      >
                        <b>{intl.formatMessage({id: `category_${selectedCategory.labelCode}`})}</b>
                      </Button>
                    </Stack>
                  )
                }

                {
                  categoriesList.map((category: Category) => {
                    return (
                      <Stack
                        key={`category_button_${category._id}`}
                        justifyContent="space-between"
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                      >
                        <Button 
                          onClick={() => onSelectCategory(category._id!)}
                          color="secondary"
                          sx={{ 
                            justifyContent: 'flex-start', 
                            flex: 1,
                          }}
                        >
                          <p>{intl.formatMessage({id: `category_${category.labelCode}`})}</p>
                        </Button>

                        {
                          (category.childrens || []).length > 0 && (
                            <IconButton 
                              onClick={() => onToggleCategory(category._id!)}
                              size="small"
                            >
                              <CustomIcon name="keyboard_arrow_right" />
                            </IconButton>
                          )
                        }
                      </Stack>
                    )
                  })
                }
              </Stack>
            </Popper>
          </ClickAwayListener>
        )
      }
    </>
  )
}

export default SelectCategoriesButton
