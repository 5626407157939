import { sum } from 'lodash'

import { QuestionSubscription, SubjectSubscription } from '../types'

const getSubjectAcquisitionLevel = (
  subjectSubscription: SubjectSubscription,
): number => {
  const questionAcquisitionLevels: number[] = (subjectSubscription.questionSubscriptions || []).map((questionSubscription: QuestionSubscription) => {
    return getQuestionAcquisitionLevel(questionSubscription)
  })

  return !questionAcquisitionLevels.length ? 0 : Math.round((sum(questionAcquisitionLevels) / questionAcquisitionLevels.length))
}

const getQuestionAcquisitionLevel = (
  questionSubscription: QuestionSubscription,
): number => {
  if (questionSubscription.isAcquired || questionSubscription.acquisitionLevel >= 5) return 100
  return Math.round(((questionSubscription.acquisitionLevel || 0) / 5) * 100)
}

export { getSubjectAcquisitionLevel, getQuestionAcquisitionLevel }
