
import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useIntl } from 'react-intl'
import { format } from 'date-fns'

import { getQuestionAcquisitionLevel } from '../../../../common/utils/subject-subscription'
import { QuestionSubscription, SubjectSubscription } from '../../../../common/types'
import { decomposeSubjectSubscription } from '../../../../common/utils/subject'

interface QuestionSubscriptionInfosModalProps {
  questionSubscription: QuestionSubscription,
  subjectSubscription: SubjectSubscription,
  onClose: () => void,
  open: boolean,
}

const QuestionSubscriptionInfosModal = ({
  questionSubscription,
  subjectSubscription,
  onClose,
  open,
}: QuestionSubscriptionInfosModalProps): JSX.Element => {
  const { subject } = decomposeSubjectSubscription(subjectSubscription)
  const intl = useIntl()

  const isCurrentSpacingHours: boolean = (questionSubscription.currentSpacing || 0) < 24
  const currentSpacing: number = isCurrentSpacingHours ? (questionSubscription.currentSpacing || 0) : ((questionSubscription.currentSpacing || 0) / 24)

  const nextStudyDate: string | undefined = questionSubscription.nextStudyDate ?
    format(questionSubscription.nextStudyDate, 'dd/MM/yyyy') :
    undefined

  const lastStudyDate: string | undefined = questionSubscription.lastStudyDate ?
    format(questionSubscription.lastStudyDate, 'dd/MM/yyyy') :
    undefined


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {intl.formatMessage({id: 'question_infos_title'})}
      </DialogTitle>
      <DialogContent>
        <p> {intl.formatMessage({id: 'question_infos_subject'})} <b>{subject.label}</b> </p>
        <p> {intl.formatMessage({id: 'question_infos_add_date'})} <b>{format(questionSubscription.createdAt!, 'dd/MM/yyyy')}</b> </p>
        <p> {intl.formatMessage({id: 'question_infos_acquisition_level'})} <b>{getQuestionAcquisitionLevel(questionSubscription)}%</b> </p>
        <p> {intl.formatMessage({id: 'question_infos_good_answers_count'})} <b>{questionSubscription.successesCount}</b> </p>
        <p> {intl.formatMessage({id: 'question_infos_wrong_answers_count'})} <b>{questionSubscription.failsCount}</b> </p>
        {nextStudyDate && (<p> {intl.formatMessage({id: 'question_infos_next_study_date'})} <b>{nextStudyDate}</b> </p>)}
        {lastStudyDate && (<p> {intl.formatMessage({id: 'question_infos_last_study_date'})} <b>{format(questionSubscription.lastStudyDate!, 'dd/MM/yyyy')}</b> </p>)}
        <p> {intl.formatMessage({id: 'question_infos_current_spacing'})} <b>{currentSpacing} {`${isCurrentSpacingHours ? intl.formatMessage({id: 'hour'}) : intl.formatMessage({id: 'day'})}${currentSpacing > 1 ? 's' : ''}`}</b> </p>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {intl.formatMessage({id: 'close'})} 
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default QuestionSubscriptionInfosModal
