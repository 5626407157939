import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { Stack } from '@mui/material'

import { Question, QuestionSubscription, QuestionTypesEnum } from '../../../../../common/types'
import { useLoadSubjectSubscription } from '../../../../common/hooks/subjects-subscription'
import TextQuestionPanel from '../../../study/components/text-question-panel'
import QcmQuestionPanel from '../../../study/components/qcm-question-panel'
import Title from '../../../../common/components/title'

import './styles.scss'

// Note : WIP

const QuestionSubscriptionPage = (): JSX.Element | null => {
  const { subjectSubscription } = useLoadSubjectSubscription({
    loadSubjectSubscriptionOnMount: true,
  })

  const params: {
    questionSubscriptionId: string,
    subjectSubscriptionId: string,
  } = useParams()

  const questionSubscription: QuestionSubscription | undefined = useMemo(() => {
    return (params.questionSubscriptionId && subjectSubscription) ? (subjectSubscription.questionSubscriptions || []).find((questionSubscriptionItem: QuestionSubscription) => {
      return questionSubscriptionItem._id === params.questionSubscriptionId
    }) : undefined
  }, [params.questionSubscriptionId, subjectSubscription])
  
  // console.log({subjectSubscription})

  if (!questionSubscription) return null

  const question: Question = questionSubscription.question as Question

  return (
    <Stack className="page_content question_subscription_page_container" spacing={1}>
      <Title title="Question" />

      <Stack direction="row" spacing={2}>
        {
          question.type === QuestionTypesEnum.TEXT && (
            <TextQuestionPanel question={question} />
          )
        }

        {
          question.type === QuestionTypesEnum.QCM && (
            <QcmQuestionPanel question={question} />
          )
        }

        <Stack spacing={2} flex={1}>
          <Stack direction="row" spacing={2}>
            <Stack className="panel" flex={1}>
              <h3>Niveau de Maîtrise :</h3>
              <h1>75%</h1>
            </Stack>
            <Stack className="panel" flex={1}>
              <h3>Réponses</h3>
            </Stack>
          </Stack>
          <Stack className="panel" flex={1}>
            <h3>Historique des réponses</h3>
          </Stack>
        </Stack>
      </Stack>

    </Stack>
  )
}

export default QuestionSubscriptionPage