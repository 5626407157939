
enum StudyTypesLabelsEnum {
  ALL = 'Réviser toutes les questions',
  UNACQUIRED = 'Réviser les questions non acquises',
  ACQUIRED = 'Réviser les acquis',
}

enum StudyTypesDescriptionsEnum {
  ALL = 'Répondre à toutes les questions du sujet, dans un ordre aléatoire',
  UNACQUIRED = 'Revoir les questions marquées comme non acquises ou en cours d\'acquisition, dans un ordre aléatoire',
  ACQUIRED = 'Revoir les questions marquées comme acquises, dans un ordre aléatoire',
}

enum StudyTypesIconsEnum {
  ALL = 'star',
  UNACQUIRED = 'bolt',
  ACQUIRED = 'check_circle',
}

export { 
  StudyTypesDescriptionsEnum,
  StudyTypesLabelsEnum,
  StudyTypesIconsEnum,
}