import React, { createContext, useContext, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

import { DeviceTypesEnum, useResponsive } from '../hooks/responsive'
import { getIsMenuedPage, getMainPage } from '../utils/routes'
import { Page } from '../constants/routes'
import { AuthContext } from './auth'

interface DesktopMenuContextType {
  setIsDesktopMenuOpen: (nextValue: boolean) => void,
  setIsFirstRender: (nextValue: boolean) => void,
  shouldDiplayDesktopMenu: boolean,
  toggleDesktopMenu: () => void,
  isDesktopMenuOpen: boolean,
  isFirstRender: boolean,
  isOfflineMenu: boolean,
  isMenuedPage: boolean,
}

const DesktopMenuContext = createContext<DesktopMenuContextType>({
  shouldDiplayDesktopMenu: false,
  setIsDesktopMenuOpen: () => {},
  toggleDesktopMenu: () => {},
  setIsFirstRender: () => {},
  isDesktopMenuOpen: true,
  isFirstRender: false,
  isOfflineMenu: false,
  isMenuedPage: false,
})

const DesktopMenuContextProvider = ({ children }: { children: JSX.Element }) => {
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState<boolean>(true)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const { isLogged } = useContext(AuthContext)
  const responsive = useResponsive()
  const location = useLocation()

  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)

  const currentMainPage: Page | undefined = useMemo(() => {
    return getMainPage(location.pathname)
  }, [location.pathname]) 

  const shouldDiplayDesktopMenu: boolean = useMemo(() => {
    return isLargeDevice && Boolean(isLogged || currentMainPage?.hasOfflineMenu || false)
  }, [isLargeDevice, currentMainPage, isLogged])

  const isOfflineMenu: boolean = useMemo(() => {
    return shouldDiplayDesktopMenu && !isLogged && (currentMainPage?.hasOfflineMenu || false)
  }, [shouldDiplayDesktopMenu, currentMainPage, isLogged])

  const isMenuedPage = useMemo(() => {
    return getIsMenuedPage(location.pathname)
  }, [location.pathname])

  const toggleDesktopMenu = (): void => {
    if (!isMenuedPage) {
      setIsFirstRender(false)
      setIsDesktopMenuOpen(!isDesktopMenuOpen)
    }
  }

  return (
    <DesktopMenuContext.Provider
      value={{ 
        shouldDiplayDesktopMenu,
        setIsDesktopMenuOpen,
        isDesktopMenuOpen,
        toggleDesktopMenu,
        setIsFirstRender,
        isFirstRender,
        isOfflineMenu,
        isMenuedPage,
      }}
    >
      { children }
    </DesktopMenuContext.Provider>
  )
}

export { 
  DesktopMenuContextProvider, 
  DesktopMenuContext, 
}

