import React, { useContext } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { decomposeSubjectSubscription, getCanUserDeleteSubject } from '../../../../common/utils/subject'
import { LoaderContext, SubjectSubscriptionsContext, UserContext } from '../../contexts'
import { displaySuccessToast, displayWarningToast } from '../../utils/toast'
import { Subject, SubjectSubscription } from '../../../../common/types'
import { useRequest } from '../../hooks/request'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'

interface DeleteSubjectModalProps {
  subjectSubscription: SubjectSubscription,
  onClose: () => void,
  open: boolean,
}

const DeleteSubjectModal = ({
  subjectSubscription,
  onClose,
  open,
}: DeleteSubjectModalProps): JSX.Element => {
  const { loadSubjectSubscriptions } = useContext(SubjectSubscriptionsContext)
  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { user } = useContext(UserContext)
  const { postData } = useRequest()
  const history = useHistory()
  const intl = useIntl()
 
  const canDeleteSubject: boolean = getCanUserDeleteSubject(subjectSubscription, user?._id)
  const { subject } = decomposeSubjectSubscription(subjectSubscription)

  const onConfirm = async (): Promise<void> => {
    displayLoader()
    const response = canDeleteSubject ? 
      await postData('/subject/delete', { subjectId: (subjectSubscription.subject as Subject)._id }) :
      await postData('/subject-subscription/delete', { subjectSubscriptionId: subjectSubscription._id })
    
    if (response.success) {
      displaySuccessToast(intl.formatMessage({id: canDeleteSubject ? 'delete_subject_modal_delete_success' : 'delete_subject_modal_delete_from_space_success'}))
      history.push('/subject-subscriptions')
      loadSubjectSubscriptions()
    } else {
      displayWarningToast(intl.formatMessage({id: 'delete_subject_modal_delete_fail'}))
      hideLoader()
    }
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
    >
      <DialogTitle>
        { intl.formatMessage({id: canDeleteSubject ? 'delete_subject_modal_title' : 'delete_subject_modal_title_from_space'}) }
      </DialogTitle>
      <DialogContent>
        <p> 
          {
            intl.formatMessage({
              id: canDeleteSubject ? 'delete_subject_modal_confirm_text' : 'delete_subject_modal_confirm_text_from_space',
            },{
              subjectLabel: subject.label || '',
            })
          } 
        </p>
        { 
          canDeleteSubject ? (
            <p> <b>{intl.formatMessage({id: 'delete_subject_modal_warn_1'})}</b> {intl.formatMessage({id: 'delete_subject_modal_warn_2'})}</p>
          ) : (
            <p> <b>{intl.formatMessage({id: 'delete_subject_modal_warn_1'})}</b> {intl.formatMessage({id: 'delete_subject_modal_warn_3'})} </p>
          ) 
        }
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {intl.formatMessage({id: 'delete_subject_modal_cancel'})} 
        </Button>
        <Button variant="contained" color="error" onClick={onConfirm}>
          {intl.formatMessage({id: 'delete_subject_modal_confirm'})} 
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteSubjectModal