import React, { useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router'
import { Stack } from '@mui/material'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import { Page, subjectSubscriptionPages } from '../../common/constants/routes'
import { DeviceTypesEnum, useResponsive } from '../../common/hooks/responsive'
import PageMenu from '../../common/components/navigation-menu/page-menu'
import QuestionSubscriptionPage from './pages/question-subscription'
import SubjectSubscriptionPage from './pages/subject-subscription'
import CreateTextQuestionPage from './pages/create-question/text'
import CreateQcmQuestionPage from './pages/create-question/qcm'
import CreateQuestionPage from './pages/create-question'
import UpdateQuestionPage from './pages/update-question'
import UpdateSubjectPage from './pages/update-subject'
import SubjectSettingsPage from './pages/settings'
import CreateStudyPage from './pages/create-study'
import StudyPage from './pages/study'
import NotFound from '../not-found'

const SubjectSubscriptionPageRouter = (): JSX.Element | null => {
  const { subjectSubscriptionId } : { subjectSubscriptionId: string } = useParams()
  const responsive = useResponsive()
  const intl = useIntl()

  const menuPages: Page[] = useMemo(() => {
    return subjectSubscriptionPages.map((item: Page) => ({
      ...item,
      path: item.path.replace(':subjectSubscriptionId', subjectSubscriptionId)
    }))
  }, [subjectSubscriptionId])

  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)

  return (
    <Stack direction="row" width="100%" className={classnames({ menu_page_container : isDesktop })}>
      <PageMenu 
        title={intl.formatMessage({id: 'page_subject_subscription'})}
        titleLink={`/subject-subscription/${subjectSubscriptionId}`}
        pages={menuPages} 
        mainIcon="school"  
      />
      <Switch>
        <Route exact path="/subject-subscription/:subjectSubscriptionId/question-subscription/:questionSubscriptionId" component={QuestionSubscriptionPage} />
        <Route exact path="/subject-subscription/:subjectSubscriptionId/update-question/:questionId" component={UpdateQuestionPage} />
        <Route exact path="/subject-subscription/:subjectSubscriptionId/create-question/text" component={CreateTextQuestionPage} />
        <Route exact path="/subject-subscription/:subjectSubscriptionId/create-question/qcm" component={CreateQcmQuestionPage} />
        <Route exact path="/subject-subscription/:subjectSubscriptionId/create-question" component={CreateQuestionPage} />
        <Route exact path="/subject-subscription/:subjectSubscriptionId/update-subject" component={UpdateSubjectPage} />
        <Route exact path="/subject-subscription/:subjectSubscriptionId/create-study" component={CreateStudyPage} />
        <Route exact path="/subject-subscription/:subjectSubscriptionId/settings" component={SubjectSettingsPage} />
        <Route exact path="/subject-subscription/:subjectSubscriptionId/study/:studyId" component={StudyPage} />
        <Route exact path="/subject-subscription/:subjectSubscriptionId" component={SubjectSubscriptionPage} />
        
        <Route path="*" component={NotFound} />
      </Switch>
    </Stack>
  )
}

export default SubjectSubscriptionPageRouter