import React, { createContext, useContext, useState } from 'react'

import { groupSelection, updateCategoriesSelection } from '../../../common/utils/categories'
import { Category } from '../../../common/types'
import { useRequest } from '../hooks/request'
import { LoaderContext } from './loader'

interface CategoriesContextType {
  updateCategoriesSelection: (selectedCategories: string[], categoryId: string) => string[],
  categoriesParents: { [categoryId: string]: string[] },
  groupSelection: (categoriesIds: string[]) => string[],
  categoriesChilds: { [categoryId: string]: string[] },
  categoriesById: { [categoryId: string]: Category },
  loadCategoriesInfosIfNeeded: () => Promise<void>,
  loadCategoriesInfos: () => Promise<void>,
  loadCategories: () => Promise<void>,
  categoriesTree: Category[],
  categories: Category[],
}

const CategoriesContext = createContext<CategoriesContextType>({
  loadCategoriesInfosIfNeeded: async () => {},
  updateCategoriesSelection: () => [],
  loadCategoriesInfos: async () => {},
  loadCategories: async () => {},
  groupSelection: () => [],
  categoriesParents: {},
  categoriesChilds: {},
  categoriesTree: [],
  categoriesById: {},
  categories: [],
})

const CategoriesContextProvider = ({ children }: { children: JSX.Element }) => {
  const [categoriesParents, setCategoriesParents] = useState<{ [categoryId: string]: string[] }>({})
  const [categoriesChilds, setCategoriesChilds] = useState<{ [categoryId: string]: string[] }>({})
  const [categoriesById, setCategoriesById] = useState<{ [categoryId: string]: Category }>({})
  // const [categoriesLevelOne, setCategoriesLevelOne] = useState<Category[]>([])
  const [categoriesTree, setCategoriesTree] = useState<Category[]>([])
  // const [categoriesDepth, setCategoriesDepth] = useState<number>(0)
  const [categories, setCategories] = useState<Category[]>([])

  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { fetchData } = useRequest()

  const loadCategories = async (): Promise<void> => {
    displayLoader()
    const response = await fetchData('/categories/all')
    setCategories(response.categories || [])
    hideLoader()
  }

  const loadCategoriesInfos = async (): Promise<void> => {
    displayLoader()
    const response = await fetchData('/categories/infos')

    // setCategoriesLevelOne(response.categoriesLevelOne)
    setCategoriesParents(response.categoriesParents)
    setCategoriesChilds(response.categoriesChilds)
    // setCategoriesDepth(response.categoriesDepth)
    setCategoriesById(response.categoriesById)
    setCategoriesTree(response.categoriesTree)
    setCategories(response.categories)
    
    hideLoader()
  }

  const loadCategoriesInfosIfNeeded = async (): Promise<void> => {
    if (!categories.length) {
      return loadCategoriesInfos()
    }
  }

  const innerUpdateCategoriesSelection = (selectedCategories: string[], categoryId: string): string[] => {
    return updateCategoriesSelection(
      categoriesParents,
      categoriesChilds,
      selectedCategories,
      categoryId,
    )
  }

  const innerGroupCategories = (categoriesIds: string[]): string[] => {
    return groupSelection(categoriesChilds, categoriesIds)
  }

  return (
    <CategoriesContext.Provider
      value={{
        updateCategoriesSelection: innerUpdateCategoriesSelection,
        groupSelection: innerGroupCategories,
        loadCategoriesInfosIfNeeded,
        loadCategoriesInfos,
        categoriesParents,
        categoriesChilds,
        loadCategories,
        categoriesTree,
        categoriesById,
        categories,
      }}
    >
      { children }
    </CategoriesContext.Provider>
  )
}

export { CategoriesContextProvider, CategoriesContext }

