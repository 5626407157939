import React from 'react'
import { IconButton, Stack } from '@mui/material'
import { useIntl } from 'react-intl'

import { useResponsive } from '../../../../common/hooks/responsive'
import { AcquisitionStatusEnum } from '../../../../../common/types'
import CustomIcon from '../../../../common/components/custom-icon'

import './styles.scss'

interface ResultPromptProps {
  onSubmitQuestionProgress: (questionScore: AcquisitionStatusEnum) => void,
}

const ResultPrompt = ({
  onSubmitQuestionProgress,
}: ResultPromptProps): JSX.Element => {
  const responsive = useResponsive()
  const intl = useIntl()

  const isSmallDevice: boolean = responsive.isSmallDevice()
  
  if (isSmallDevice) {
    return (
      <Stack 
        className="mobiles_result_prompt_container shadow_light"
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <IconButton 
          onClick={() => onSubmitQuestionProgress(AcquisitionStatusEnum.UNACQUIRED)}
          className="icon_button_large"
          color="secondary"
          size="large" 
        >
          <CustomIcon name="clear" />
        </IconButton>
        
        <b>{intl.formatMessage({id: 'result_prompt_label'})}</b>
  
        <IconButton 
          onClick={() => onSubmitQuestionProgress(AcquisitionStatusEnum.ACQUIRED)}
          className="icon_button_large"
          color="primary" 
          size="large" 
        >
          <CustomIcon name="check" />
        </IconButton>
      </Stack>
    )
  } else {
    return (
      <Stack 
        justifyContent="space-between" 
        alignItems="center" 
        className="panel" 
        direction="row"
        spacing={4} 
        p={3} 
      >
        <IconButton 
          onClick={() => onSubmitQuestionProgress(AcquisitionStatusEnum.UNACQUIRED)}
          className="icon_button_large"
          color="secondary" 
          size="large"   
        >
          <CustomIcon name="clear" />
        </IconButton>

        <b>{intl.formatMessage({id: 'result_prompt_label'})}</b>
        
        <IconButton 
          onClick={() => onSubmitQuestionProgress(AcquisitionStatusEnum.ACQUIRED)}
          className="icon_button_large"
          color="primary" 
          size="large" 
        >
          <CustomIcon name="check" />
        </IconButton>
      </Stack>
    )
  }
}

export default ResultPrompt