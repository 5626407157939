import React, { useContext, useEffect, useState } from 'react'

import { CategoriesContext } from '../../../../common/contexts'
import SelectCategoriesList from './select-categories-list'

interface SelectCategoriesProps {
  onSelectCategory: (categoryId: string) => void,
  selectedCategories: string[],
}

const SelectCategories = ({
  selectedCategories,
  onSelectCategory,
}: SelectCategoriesProps): JSX.Element => {
  const [openedCategoryIds, setOpenedCategoryIds] = useState<string[]>([])
  
  const { 
    loadCategoriesInfos, 
    categoriesParents,
    categoriesTree, 
    categories,
  } = useContext(CategoriesContext)

  useEffect(() => {
    if (!categories.length) {
      loadCategoriesInfos()
    }
  }, [])

  const onToggleCategory = (categoryId: string): void => {
    if (openedCategoryIds.includes(categoryId)) {
      setOpenedCategoryIds(openedCategoryIds.filter((openedCategoryId: string) => openedCategoryId !== categoryId))
    } else {
      setOpenedCategoryIds([...openedCategoryIds, categoryId])
    }
  }

  return (
    <SelectCategoriesList
      selectedCategories={selectedCategories}
      openedCategoryIds={openedCategoryIds}
      categoriesParents={categoriesParents}
      onSelectCategory={onSelectCategory}
      onToggleCategory={onToggleCategory}
      categories={categoriesTree}
    />
  )
}

export default SelectCategories
