import { QuestionSubscription, Subject, SubjectSubscription, SubjectSubscriptionUserRoleEnum } from '../types'

const getCanUserEditSubject = (
  subjectSubscription?: SubjectSubscription,
  userId?: string,
): boolean => {
  if (!userId || !subjectSubscription) return false
  if (userId !== subjectSubscription.user) return false

  const { subject } = decomposeSubjectSubscription(subjectSubscription)
  const isSubjectAuthor: boolean = userId === subject.author
  const isUserAdminOrEditor: boolean = [SubjectSubscriptionUserRoleEnum.ADMIN, SubjectSubscriptionUserRoleEnum.EDITOR].includes(subjectSubscription.role)

  return Boolean(isSubjectAuthor || isUserAdminOrEditor)
}

const getCanUserDeleteSubject = (
  subjectSubscription?: SubjectSubscription,
  userId?: string,
): boolean => {
  return getIsSubjectOwner(subjectSubscription, userId)
}

const decomposeSubjectSubscription = (
  subjectSubscription: SubjectSubscription,
) : {
  questionSubscriptions: QuestionSubscription[],
  subject: Subject,
} => {
  return {
    questionSubscriptions: subjectSubscription.questionSubscriptions || [] as QuestionSubscription[],
    subject: subjectSubscription.subject as Subject,
  }
}

const getIsSubjectOwner = (
  subjectSubscription?: SubjectSubscription,
  userId?: string,
): boolean => {
  if (!userId || !subjectSubscription) return false
  const { subject } = decomposeSubjectSubscription(subjectSubscription)
  const isSubjectAuthor: boolean = userId === subject.author
  return isSubjectAuthor
}

const getSubjectShareLink = (
  subjectSubscription: SubjectSubscription,
): string => {
  const { subject } = decomposeSubjectSubscription(subjectSubscription)
  return `${process.env.FRONT_URL}/subject/${subject._id}`
}

export {
  decomposeSubjectSubscription,
  getCanUserDeleteSubject,
  getCanUserEditSubject,
  getSubjectShareLink,
  getIsSubjectOwner,
}