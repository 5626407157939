import React, { useContext, useState } from 'react'
import { Button, Stack } from '@mui/material'
import { useWatch } from 'react-hook-form'

import SelectCategoriesModal from '../../categories/select-categories-modal'
import CategoriesChips from '../../categories/categories-chips'
import { CategoriesContext } from '../../../contexts'
import CustomIcon from '../../custom-icon'
import { useIntl } from 'react-intl'

interface SelectCategoriesFieldProps {
  setValue: any, // Note : we have to put any here, otherwise we get a typescript error from react-hook-form
  control: any, // Note : we have to put any here, otherwise we get a typescript error from react-hook-form
  fieldName: string,
}

const SelectCategoriesField = ({
  fieldName,
  setValue,
  control,
}: SelectCategoriesFieldProps): JSX.Element => {
  const [openCategoriesDialog, setOpenCategoriesDialog] = useState<boolean>(false)

  const { updateCategoriesSelection } = useContext(CategoriesContext)
  const intl = useIntl()

  const [categories] = useWatch({
    name: [fieldName],
    control,
  })

  const onConfirm = (nextValue: string[]): void => {
    setValue(fieldName, nextValue, { shouldDirty: true })
    setOpenCategoriesDialog(false)
  }

  const onDeleteCategories = (): void => {
    setValue(fieldName, [], { shouldDirty: true })
  }

  const onDeleteCategory = (categoryId: string): void => {
    setValue(fieldName, updateCategoriesSelection(categories, categoryId), { shouldDirty: true })
  }

  return (
    <Stack spacing={1}>
      <Button 
        onClick={() => setOpenCategoriesDialog(true)}
        variant="outlined"
        color="secondary"
        fullWidth
      >
        <Stack 
          justifyContent="space-between"
          alignItems="center" 
          direction="row" 
          width="100%"
        >
          <p>{intl.formatMessage({id: 'select_categories'})}</p> 
          <CustomIcon name="add" />
        </Stack>
      </Button>

      {
        categories.length > 0 && (
          <CategoriesChips 
            onEdit={() => setOpenCategoriesDialog(true)}
            onDeleteCategory={onDeleteCategory}
            onDeleteAll={onDeleteCategories}
            categoriesIds={categories}
            deletable
            editable
          />
        )
      }

      <SelectCategoriesModal 
        onClose={() => setOpenCategoriesDialog(false)}
        open={openCategoriesDialog}
        onChange={onConfirm}
        value={categories}
      />
    </Stack>
  )
}

export default SelectCategoriesField