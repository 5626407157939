import React, { useContext, useState } from 'react'
import { Box, Menu, MenuItem, Stack, Tooltip } from '@mui/material'
import { reverse, sortBy } from 'lodash'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { decomposeSubjectSubscription, getCanUserDeleteSubject, getCanUserEditSubject, getIsSubjectOwner } from '../../../../../common/utils/subject'
import DeleteSubjectModal from '../../../../common/components/subject/delete-subject-modal'
import { DeviceTypesEnum, useResponsive } from '../../../../common/hooks/responsive'
import CustomIcon from '../../../../common/components/custom-icon'
import { UserContext } from '../../../../common/contexts'
import { SubjectSubscriptionsSortOptions } from '../..'
import { 
  SubjectSubscriptionUserRoleLabelsEnum,
  QuestionSubscription, 
  SubjectSubscription, 
  TextQuestionContent, 
  QcmQuestionContent, 
  TextResponse, 
  QcmResponse,
  Question, 
} from '../../../../../common/types'

import './styles.scss'

interface SubjectSubscriptionItemProps {
  sortSubjectsBy: SubjectSubscriptionsSortOptions,
  subjectSubscription: SubjectSubscription,
}

const SubjectSubscriptionItem = ({
  subjectSubscription,
  sortSubjectsBy,
}: SubjectSubscriptionItemProps): JSX.Element => {
  const [openDeleteSubjectModal, setOpenDeleteSubjectModal] = useState<boolean>(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)

  const { user } = useContext(UserContext)
  const responsive = useResponsive()
  const intl = useIntl()

  const questionSubscriptions: QuestionSubscription[] = subjectSubscription.questionSubscriptions as QuestionSubscription[]
  const isSmallOrMedium: boolean = responsive.isLowerThan(DeviceTypesEnum.LARGE)
  const { subject } = decomposeSubjectSubscription(subjectSubscription)

  const canDeleteSubject: boolean = getCanUserDeleteSubject(subjectSubscription, user?._id)
  const canEditSubject: boolean = getCanUserEditSubject(subjectSubscription, user?._id)
  const isSubjectOwner: boolean = getIsSubjectOwner(subjectSubscription, user?._id)
  
  const getSortedQuestionSubscriptions = (questionSubscriptions: QuestionSubscription[]): QuestionSubscription[] => {
    switch (sortSubjectsBy) {
      case SubjectSubscriptionsSortOptions.LATEST: {
        return reverse(sortBy(
          questionSubscriptions,
          (questionSubscription: QuestionSubscription) => questionSubscription.createdAt,
        ))
      }

      case SubjectSubscriptionsSortOptions.OLDEST: {
        return sortBy(
          questionSubscriptions,
          (questionSubscription: QuestionSubscription) => questionSubscription.createdAt,
        )
      }

      case SubjectSubscriptionsSortOptions.ALPHABETIC: {
        return sortBy(
          questionSubscriptions,
          (questionSubscription: QuestionSubscription) => ((questionSubscription.question as Question).content as TextQuestionContent | QcmQuestionContent).question.toLowerCase(),
        )
      }

      default: {
        return questionSubscriptions
      }
    }
  }

  const sortedQuestionSubscriptions = getSortedQuestionSubscriptions(questionSubscriptions)

  return (
    <Stack className="subject_item_container" spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Link className="subject_title_container" to={`/subject-subscription/${subjectSubscription._id}`}>
          <Stack 
            justifyContent="space-between"
            alignItems="center" 
            direction="row" 
            spacing={1}
          >
            <p className="mark_view">{ subject.label }</p>
            <Stack direction="row" spacing={1} alignItems="center">
              {!isSubjectOwner && (
                <Tooltip 
                  title={`${intl.formatMessage({id: 'subjects_you_are'})} ${intl.formatMessage({id: `subject_role_${subjectSubscription.role}`})}`}
                  placement="left"
                >
                  <Box display="flex" alignItems="center">
                    <CustomIcon name="group" outlined />
                  </Box>
                </Tooltip>
              )}

              {/* {
                isSubjectOwner && subject.enableShareSubject && (
                  <Tooltip title="Partagé sur Study space repeat" placement="left">
                    <Box display="flex" alignItems="center">
                      <CustomIcon name="public" outlined color={palette.secondary.light} />
                    </Box>
                  </Tooltip>
                )
              } */}

            </Stack>
          </Stack>
        </Link>

        <div className="icon_button ripple" onClick={e => setMenuAnchorEl(e.currentTarget)}>
          <CustomIcon name="more_horiz" />
        </div>

        <Menu
          onClose={() => setMenuAnchorEl(null)}
          anchorEl={menuAnchorEl}
          open={!!menuAnchorEl}
          autoFocus={false}
          sx={isSmallOrMedium ? {mt: 1} : {ml: 1}}
          anchorOrigin={isSmallOrMedium ? {
            vertical: 'bottom',
            horizontal: 'right',
          } : {
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={isSmallOrMedium ? {
            vertical: 'top',
            horizontal: 'right',
          } : {
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {
            canEditSubject && ([
              (
                <Link 
                  to={`/subject-subscription/${subjectSubscription._id}/update-subject`} 
                  key={`${subjectSubscription._id}_edit`} 
                  className="full_width"
                >
                  <MenuItem>{intl.formatMessage({id: 'subjects_edit'})}</MenuItem>
                </Link>
              ),
              (
                <Link 
                  to={`/subject-subscription/${subjectSubscription._id}/create-question`} 
                  key={`${subjectSubscription._id}_delete`} 
                  className="full_width"
                >
                  <MenuItem>{intl.formatMessage({id: 'subjects_add_question'})}</MenuItem>
                </Link>
              ),
            ])
          }

          <MenuItem onClick={() => setOpenDeleteSubjectModal(true)} color="error">
            { canDeleteSubject ? intl.formatMessage({id: 'subjects_delete'}) : intl.formatMessage({id: 'subjects_delete_from_my_space'}) }
          </MenuItem>
        </Menu>
      </Stack>
      
      {
        sortedQuestionSubscriptions.length > 0 && (
          <Stack sx={{ ml: '14px !important' }}>
            <b>{intl.formatMessage({id: 'subjects_questions'})}</b>
            {
              sortedQuestionSubscriptions.map((questionSubscription: QuestionSubscription, index: number) => {
                const question: Question = questionSubscription.question as Question
                const questionContent: QcmQuestionContent | TextQuestionContent = question.content as TextQuestionContent | QcmQuestionContent        
              
                return (
                  <Stack key={`question_${questionSubscription._id}`}>
                    <p className="mark_view"> {questionContent.question} </p>

                    <Stack sx={{ ml: 2 }}>
                      <b>{intl.formatMessage({id: 'subjects_answers'})}</b>
                      {
                        questionContent.responses.map((response: QcmResponse | TextResponse, index: number) => {
                          return (
                            <p key={`question_${questionSubscription._id}_response_${index}`} className="mark_view">
                              { response.response }
                            </p>
                          )
                        })
                      }
                    </Stack>
                  </Stack>
                )
              })
            }
          </Stack>
        )
      }

      <DeleteSubjectModal 
        onClose={() => setOpenDeleteSubjectModal(false)}
        subjectSubscription={subjectSubscription}
        open={openDeleteSubjectModal}
      />
    </Stack>
  )
}

export default SubjectSubscriptionItem
