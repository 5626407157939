import React, { useEffect, useState } from 'react'
import { Button, Stack, TextField } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash'

import { DeviceTypesEnum, useResponsive } from '../../../hooks/responsive'
import GoBackButton from '../../go-back-button'

interface UpdatePasswordFormValues {
  newPasswordConfirm: string,
  currentPassword: string,
  newPassword: string,
}

interface UpdatePasswordFormProps {
  onSubmit: (currentPassword: string, newPassword: string) => Promise<void>,
  setIncorrectPassword: (nextVal: boolean) => void,
  setShouldResetForm: (nextVal: boolean) => void,
  incorrectPassword: boolean,
  shouldResetForm: boolean,
}

const UpdatePasswordForm = ({
  setIncorrectPassword,
  setShouldResetForm,
  incorrectPassword,
  shouldResetForm,
  onSubmit,
}: UpdatePasswordFormProps): JSX.Element => {
  const [displayPasswordsError, setDisplayPasswordError] = useState<boolean>(false)
  
  const defaultValues = {
    newPasswordConfirm: '',
    currentPassword: '',
    newPassword: '',
  }

  const { register, handleSubmit, reset, control, formState: { errors } } = useForm<UpdatePasswordFormValues>({ defaultValues })  
  const values = useWatch<UpdatePasswordFormValues>({ control })
  const responsive = useResponsive()
  const intl = useIntl()

  useEffect(() => {
    if (incorrectPassword) {
      setIncorrectPassword(false)
    }
    if (displayPasswordsError) {
      setDisplayPasswordError(false)
    }
  }, [values])

  useEffect(() => {
    if (shouldResetForm) {
      reset(defaultValues)
      setShouldResetForm(false)
    }
  }, [shouldResetForm])

  const onFormSubmit = (values: UpdatePasswordFormValues) => {
    if (values.newPassword !== values.newPasswordConfirm) {
      setDisplayPasswordError(true)
    } else {
      onSubmit(values.currentPassword, values.newPassword)
    }
  }

  const hasChanges: boolean = !isEqual(values, defaultValues)
  const canSubmit: boolean = Boolean(hasChanges && !!values.currentPassword && !!values.newPassword && !!values.newPasswordConfirm)
  const isDesktop: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)
  const isMobile: boolean = responsive.isLowerThan(DeviceTypesEnum.MEDIUM)

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={2}>
        <Stack spacing={2} alignItems="center" className="panel">
          <Stack width="100%">
            <p><b>{intl.formatMessage({id: 'recommendation'})} : </b>{intl.formatMessage({id: 'password_tip'})}</p>
          </Stack>
          
          <Stack spacing={1} width={isMobile ? '100%' : undefined}>
            <TextField
              {...register('currentPassword', { required: false, minLength: 4 })}
              placeholder={intl.formatMessage({id: 'current_password'})}
              error={!!errors.currentPassword || incorrectPassword}
              type="password"
              size="small"
              fullWidth
            />
            <TextField
              {...register('newPassword', { required: false })}
              placeholder={intl.formatMessage({id: 'new_password'})}
              error={!!errors.newPassword || displayPasswordsError}
              type="password"
              size="small"
              fullWidth
            />
            <TextField
              {...register('newPasswordConfirm', { required: false })}
              placeholder={intl.formatMessage({id: 'new_password_confirm'})}
              error={!!errors.newPasswordConfirm || displayPasswordsError}
              type="password"
              size="small"
              fullWidth
            />
          </Stack>

          {
            displayPasswordsError && (
              <p className="text_error">{intl.formatMessage({id: 'passwords_not_match'})}</p>
            )
          }

          {
            incorrectPassword && (
              <p className="text_error">{intl.formatMessage({id: 'incorrect_password'})}</p>
            )
          }

        </Stack>
        
        <Stack direction="row" spacing={1} justifyContent={!isDesktop ? 'space-between' : 'flex-end'}>
          {
            !isDesktop && (
              <GoBackButton defaultUrl="/settings" withIcon />
            )
          }

          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              onClick={() => reset(defaultValues)}
              disabled={!hasChanges}
              variant="outlined"
              color="secondary"
            >
              {intl.formatMessage({id: 'reset'})}
            </Button>
            <Button 
              disabled={!canSubmit}
              variant="contained" 
              color="primary" 
              type="submit" 
            >
              {intl.formatMessage({id: 'save'})}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  )
}

export default UpdatePasswordForm
