import React, { useState, useContext } from 'react'
import { Button, Divider, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { displaySuccessToast, displayWarningToast } from '../../common/utils/toast'
import { DeviceTypesEnum, useResponsive } from '../../common/hooks/responsive'
import GoogleLoginButton from '../../common/components/google-login-button'
import { AuthContext, LoaderContext, TranslationContext } from '../../common/contexts'
import LoginForm from '../../common/components/forms/auth/login'
import CustomIcon from '../../common/components/custom-icon'
import { useRequest } from '../../common/hooks/request'
import { Credentials } from '../../../common/types'

import './styles.scss'

const LoginPage = (): JSX.Element => {
  const [incorrectPassword, setIncorrectPassword] = useState<boolean>(false)
  const [unknownUser, setUnknownUser] = useState<boolean>(false)
  const intl = useIntl()

  const { displayLoader, hideLoader } = useContext(LoaderContext)
  const { currentLocale } = useContext(TranslationContext)
  const { updateAuthToken } = useContext(AuthContext)
  const responsive = useResponsive()
  const { postData } = useRequest()

  const onSubmit = async (credentials: Credentials): Promise<void> => {
    displayLoader()
    const response = await postData('/auth/login', { credentials: {...credentials, language: currentLocale} })
    hideLoader()

    if (response.token) {
      updateAuthToken(response.token)
      displaySuccessToast(intl.formatMessage({id: 'login_connexion_success'}))
    } else if (response === 'unknown user') {
      setUnknownUser(true)
    } else if (response === 'incorrect password') {
      setIncorrectPassword(true)
    } else if (response === 'no password') {
      displayWarningToast(intl.formatMessage({id: 'login_try_google_auth'}))
    }
  }

  const isLargeDevice: boolean = responsive.isGreaterThan(DeviceTypesEnum.MEDIUM)

  return (
    <Stack spacing={8} alignItems="center" className="page_content login_page_container">
      <Stack flex={1} width="100%" direction={isLargeDevice ? 'row' : 'column'} alignItems="center">
        <Stack 
          alignItems="center" 
          spacing={2} 
          flex={1}
        >
          <h1>{intl.formatMessage({id: 'login'})}</h1>
         
          <Stack spacing={1} alignItems="center">
            <p> {intl.formatMessage({id: 'login_use_credentials'})} </p>
            <LoginForm 
              setIncorrectPassword={setIncorrectPassword}
              incorrectPassword={incorrectPassword}
              setUnknownUser={setUnknownUser}
              unknownUser={unknownUser} 
              onSubmit={onSubmit}
            />
          </Stack>
          <Link to="/forgot-password">
            <p className="link_inline text_italic">{intl.formatMessage({id: 'login_forgotten_password'})}</p>
          </Link>
        </Stack>

        <Divider 
          orientation={isLargeDevice ? 'vertical' : 'horizontal'} 
          variant="middle" 
          flexItem 
          sx={{ 
            my: isLargeDevice ? 0 : 4, 
            width: isLargeDevice ? undefined : '60%',
            mx: isLargeDevice ? undefined : 'auto',
          }}
        />

        <Stack 
          justifyContent="center" 
          alignItems="center" 
          spacing={2}
          flex={1}
        >
          <Stack spacing={1} alignItems="center">
            <p> {intl.formatMessage({id: 'login_use_google_account'})} </p>
            <GoogleLoginButton />
          </Stack>
        </Stack>
      </Stack>
      
      <Stack spacing={1} alignItems="center">
        <p> {intl.formatMessage({id: 'login_no_account'})} </p>
        <Link to="/register">
          <Button 
            endIcon={(<CustomIcon name="keyboard_arrow_right" />)}
            variant="outlined" 
            color="secondary"
          >
            {intl.formatMessage({id: 'login_register'})}
          </Button>
        </Link>
      </Stack>
    </Stack>
  )
}

export default LoginPage
