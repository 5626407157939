import React from 'react'
import { Stack, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import CustomIcon from '../../../../common/components/custom-icon'

import './styles.scss'

const SubjectSubscriptionsNoContent = (): JSX.Element => {
  const intl = useIntl()

  return (
    <Stack spacing={1} alignItems="center" className="panel empty_content" py={8}>
      <Link to="/create-subject">
        <CustomIcon 
          className="empty_icon"
          name="add_notes" 
          size={75}
          outlined 
        />
      </Link>
      <Stack spacing={2} alignItems="center">
        <p> {intl.formatMessage({id: 'subjects_empty'})} </p>
        <Stack direction="row" spacing={1}>
          {/* <Link to="/subjects">
            <Button 
              endIcon={(<CustomIcon name="search" />)}
              variant="outlined" 
              color="secondary"
            >
              Ajouter un sujet existant
            </Button>
          </Link> */}
          <Link to="/create-subject">
            <Button 
              endIcon={(<CustomIcon name="add" />)}
              variant="outlined" 
              color="secondary"
            >
              {intl.formatMessage({id: 'subjects_create_subject_long'})}
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SubjectSubscriptionsNoContent